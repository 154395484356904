import React, { useCallback, useEffect, useState } from "react";

export interface SpecialCharacterContextInterface {
    lastOpenedCharMenu: HTMLDivElement | null;
    isActiveInPracticeInput: boolean;
    selectedCharLanguage: string | undefined;
    setSelectedCharLanguage: (newValue: string | undefined) => void;
    isRefOpen: (ref: HTMLDivElement) => boolean;
    addOpenRef: ({ ref, isPracticeInput }: { ref: HTMLDivElement; isPracticeInput?: boolean }) => void;
    removeOpenRef: ({ ref, isPracticeInput }: { ref: HTMLDivElement; isPracticeInput?: boolean }) => void;
    closeAllCharMenus: (props?: { newRef: HTMLDivElement }) => void;
}

export const SpecialCharacterContext = React.createContext<SpecialCharacterContextInterface>({
    lastOpenedCharMenu: null,
    isActiveInPracticeInput: false,
    selectedCharLanguage: undefined,
    setSelectedCharLanguage: () => {},
    isRefOpen: () => false,
    addOpenRef: () => {},
    removeOpenRef: () => {},
    closeAllCharMenus: () => {},
});

const SpecialCharacterContextWrapper: React.FC = (props) => {
    const [isActiveInPracticeInput, setIsActiveInPracticeInput] = useState<boolean>(false);
    const [lastOpenedCharMenu, setLastOpenedCharMenu] = useState<HTMLDivElement | null>(null);
    const [allOpenCharMenus, setAllOpenCharMenus] = useState<HTMLDivElement[]>([]);
    const [selectedCharLanguage, setSelectedCharLanguage] = useState<string | undefined>();

    const isRefOpen = useCallback((ref: HTMLDivElement) => allOpenCharMenus.includes(ref), [allOpenCharMenus]);

    const addOpenRef = useCallback(({ ref, isPracticeInput }: { ref: HTMLDivElement; isPracticeInput?: boolean }) => {
        setAllOpenCharMenus((refs) => [...refs, ref]);
        setIsActiveInPracticeInput(!!isPracticeInput);
    }, []);

    const removeOpenRef = useCallback(
        ({ ref: refToRemove, isPracticeInput }: { ref: HTMLDivElement; isPracticeInput?: boolean }) => {
            setAllOpenCharMenus((refs) => refs.filter((ref) => ref !== refToRemove));
            if (isPracticeInput) {
                setIsActiveInPracticeInput(false);
            }
        },
        []
    );

    const closeAllCharMenus = useCallback((props?: { newRef: HTMLDivElement }) => {
        const newRef = props?.newRef;
        setAllOpenCharMenus(newRef ? [newRef] : []);
    }, []);

    useEffect(() => {
        const lastElement = allOpenCharMenus[allOpenCharMenus.length - 1];
        setLastOpenedCharMenu(() => lastElement ?? null);
    }, [allOpenCharMenus]);

    return (
        <SpecialCharacterContext.Provider
            value={{
                selectedCharLanguage,
                lastOpenedCharMenu,
                isActiveInPracticeInput,
                isRefOpen,
                addOpenRef,
                removeOpenRef,
                closeAllCharMenus,
                setSelectedCharLanguage,
            }}
        >
            {props.children}
        </SpecialCharacterContext.Provider>
    );
};

export default SpecialCharacterContextWrapper;
