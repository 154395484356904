import styled from "styled-components";

export const InfoHighlight = styled.span`
    color: ${(props) => props.theme.colors.primary};
`;
export const LimitContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
export const LimitLabel = styled.span`
    padding-bottom: ${(props) => props.theme.base.spacingSmall};
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
`;
