// REACT
import React, { FunctionComponent, memo, useContext } from "react";

// COMPONENTS
import { Row, Col } from "../../../basic/grid";
import { CardMenu } from "../CardMenu";
import { CardContent } from "../CardContent";
import { CardResult } from "../CardResult";

// TYPES
import { State, Item } from "p6m-learning";

// UTILS
import { LanguageCodes, LanguagesContext } from "../../../../context/languages";

// STYLED COMPONENTS
import { Card as CardWrapper, CardContentRow, Flag, CardContentCol } from "./styles";

export type Props = {
    state: State;
    type: Item["type"];
    questionLang: LanguageCodes;
    answerLang: LanguageCodes;
};

const Card: FunctionComponent<Props> = (props) => {
    const { state, type, questionLang, answerLang } = props;
    const { getFlagSource } = useContext(LanguagesContext);

    return (
        <CardWrapper>
            <Row
                vertical="center"
                horizontal="between"
            >
                <Col xs="auto">
                    <Flag src={getFlagSource(questionLang)} />
                </Col>
                <Col xs="auto">
                    <CardMenu />
                    <Flag src={getFlagSource(answerLang)} />
                </Col>
            </Row>

            <CardContentRow>
                <CardContentCol>
                    <CardContent />
                </CardContentCol>
                <Col>
                    <CardResult />
                </Col>
                <CardContentCol>
                    {(type !== "practice" || (["show", "wrong", "success", "dontKnow"] as State[]).includes(state)) && (
                        <CardContent isAnswer />
                    )}
                </CardContentCol>
            </CardContentRow>
        </CardWrapper>
    );
};

export default memo(Card);
