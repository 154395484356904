import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import styled from "styled-components";
import { T } from "@transifex/react";
import { ampli } from "../../../../ampli";
import ReactTooltip from "react-tooltip";
import { accessibleActionProps } from "../../../../helpers/Accessibility";

type Props = {
    disabled?: boolean;
};

const TransparentPhaseSixIcon = styled(PhaseSixIcon)`
    opacity: 0.5;
    color: ${(props) => props.theme.colors.textOnBackground};
`;

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const StyledDisabledContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 8px;
`;

const ExtendedNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 0px 8px;
`;

const LeaderBoardItem: FunctionComponent<Props> = ({ disabled = true }) => {
    const tooltipProps = {
        "data-tip": true,
        "data-for": "TopNavLeaderboardTooltip",
    };
    const tooltipPropsDisabled = {
        "data-tip": true,
        "data-for": "TopNavLeaderboardTooltipDisabled",
    };

    return disabled ? (
        <StyledDisabledContainer
            {...tooltipPropsDisabled}
            {...accessibleActionProps(undefined, 1000)}
        >
            <TransparentPhaseSixIcon
                aria-label="Leaderboards"
                name="leaderboard-nav"
                size="medium"
            />
            <ReactTooltip
                place="bottom"
                id="TopNavLeaderboardTooltipDisabled"
                effect="solid"
            >
                {
                    <T
                        _str="Have a first practice!"
                        _tags="leaderboard,header,tooltip"
                    />
                }
            </ReactTooltip>
        </StyledDisabledContainer>
    ) : (
        <ExtendedNavLink
            activeClassName="is-active"
            to="/leaderBoard"
            onClick={() => ampli.openLeaderboard()}
            tabIndex={1000}
            {...tooltipProps}
        >
            <StyledPhaseSixIcon
                aria-label="Leaderboards"
                name="leaderboard-nav"
                size="medium"
            />
            <ReactTooltip
                place="bottom"
                id="TopNavLeaderboardTooltip"
                effect="solid"
            >
                {
                    <T
                        _str="Leaderboard"
                        _tags="leaderboard,header,tooltip"
                    />
                }
            </ReactTooltip>
        </ExtendedNavLink>
    );
};

export default LeaderBoardItem;
