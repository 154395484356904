// REACT
import React, { useEffect } from "react";
import { useT } from "@transifex/react";

// TYPES
import { SubjectData } from "p6m-subjects";
import { CoverImage } from "p6-react-shared";

// UTILS
import { getSubjectCover } from "../../../helpers/Subjects";

// STYLED COMPONENTS
import { ActiveSubjectOverlay, Container } from "./styles";

export interface SubjectEntryProps {
    subject: SubjectData;
    userRoles?: { isTeacher: boolean; isParent: boolean };
    onSubjectSelect: (subjectName: string) => any;
    active: boolean;
    tabIndex?: number;
    setSubjectEntryIsFocused: React.Dispatch<React.SetStateAction<boolean>>;
    subjectRef: React.RefObject<HTMLDivElement>;
}

const SubjectEntry: React.FC<SubjectEntryProps> = (props) => {
    const t = useT();
    const image = props.subject && getSubjectCover(props.subject);

    useEffect(() => {
        const onFocus = () => props.setSubjectEntryIsFocused(true);
        const onBlur = () => props.setSubjectEntryIsFocused(false);

        if (props.subjectRef.current) {
            props.subjectRef.current.addEventListener("focus", onFocus);
            props.subjectRef.current.addEventListener("blur", onBlur);
        }

        return () => {
            if (props.subjectRef.current) {
                props.subjectRef.current.removeEventListener("focus", onFocus);
                props.subjectRef.current.removeEventListener("blur", onBlur);
            }
        };
    }, [props.subjectRef]);

    return (
        <Container
            key={"subject_" + props.subject.subjectMetadata.subjectIdToOwner.id}
            onClick={() => props.onSubjectSelect(props.subject.subjectMetadata.subjectIdToOwner.id)}
            tabIndex={props.active ? props.tabIndex : -1}
            className="carousel-subject-entry"
            ref={props.subjectRef}
        >
            <CoverImage
                imageId={image || null}
                responsive
                alt={t("Subject Cover {x}", {
                    x: props.subject.subjectContent.name,
                    _tags: "subjectCarousel, home, altText",
                })}
            />
            {props.active ? <ActiveSubjectOverlay /> : null}
        </Container>
    );
};

SubjectEntry.defaultProps = {
    active: false,
};

export default SubjectEntry;
