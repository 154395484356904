// REACT
import React, { FunctionComponent, memo, useState, useEffect } from "react";

// LIBRARIES
import { useT, T } from "@transifex/react";
import { CoverImage } from "p6-react-shared";

// COMPONENTS
import { Row, Col } from "../../../../basic/grid";
import Tooltip from "../../../../basic/tooltip/Tooltip";
import LanguageSelect from "../../LanguageSelect/LanguageSelect";
import { getSubjectCover } from "../../../../../helpers/Subjects";
import PhaseSixIcon from "../../../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMONENTS
import {
    Wrapper,
    ActionsWrapper,
    ActionClose,
    ActionSave,
    Input,
    LanguagesWrapper,
    LanguageText,
    InputError,
} from "./styles";

type Result = {
    value: string;
    primaryLang: string;
    secondaryLang: string;
};

export type Props = {
    afterClose: () => void;
    onSave: (result: Result) => Promise<void>;
};

const SubjectCreate: FunctionComponent<Props> = (props) => {
    const { afterClose, onSave } = props;
    const t = useT();
    const [isActive, setActive] = useState<boolean>(false);
    const [primaryLang, setPrimaryLang] = useState<string>("de");
    const [secondaryLang, setSecondaryLang] = useState<string>("");
    const [value, setValue] = useState<string>("");
    const [error, setError] = useState<string>("");

    useEffect(() => {
        setActive(true);
    }, []);

    const onChange = ({ target: { value } }: any) => {
        setValue(value);
        setError("");
    };

    const t_language = t("Select language", {});
    const t_placeholder = t("write the subject name here", {});

    const cover = getSubjectCover({ subjectContent: { secondaryLang }, subjectMetadata: {} } as any);

    const handleClickSave = () => {
        if (!value) return;
        onSave({
            value,
            primaryLang,
            secondaryLang,
        })
            .then(() => {
                setActive(false);
                afterClose();
            })
            .catch((e: any) => {
                if (e) setError(e);
            });
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, type: "save" | "close") => {
        if (event.key === "Enter") {
            switch (type) {
                case "save":
                    handleClickSave();
                    break;
                case "close":
                    setActive(false);
                    afterClose();
                    break;
            }
        }
    };

    return (
        <Wrapper $active={isActive}>
            <Row vertical="center">
                <Col xs="auto">
                    <CoverImage
                        imageId={cover}
                        size="small"
                        alt=""
                    />
                </Col>
                <Col>
                    <Row>
                        <Col xs={12}>
                            <Input
                                value={value}
                                onChange={onChange}
                                placeholder={t_placeholder}
                                minLength={
                                    process.env.REACT_APP_INPUT_CHARS_MIN ? +process.env.REACT_APP_INPUT_CHARS_MIN : 1
                                }
                                maxLength={
                                    process.env.REACT_APP_INPUT_CHARS_MAX ? +process.env.REACT_APP_INPUT_CHARS_MAX : 254
                                }
                            />
                            {!!error && <InputError>{error}</InputError>}
                        </Col>
                    </Row>
                    <Row vertical="center">
                        <Col xs="auto">
                            <LanguagesWrapper>
                                <Row>
                                    <Col xs="auto">
                                        <Tooltip
                                            position="top"
                                            content={<T _str="Source language" />}
                                        >
                                            <LanguageSelect
                                                withArrow
                                                position={["top", "center"]}
                                                language={primaryLang}
                                                onChange={setPrimaryLang}
                                                displayType="source"
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col xs="auto">
                                        <Tooltip
                                            position="top"
                                            content={<T _str="Target language" />}
                                        >
                                            <LanguageSelect
                                                withArrow
                                                position={["top", "center"]}
                                                language={secondaryLang}
                                                onChange={setSecondaryLang}
                                                displayType="target"
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </LanguagesWrapper>
                        </Col>
                        {!secondaryLang && (
                            <Col xs="auto">
                                <LanguageText>{t_language}</LanguageText>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col xs="auto">
                    <ActionsWrapper>
                        <ActionClose
                            tabIndex={0}
                            onClick={() => {
                                setActive(false);
                                afterClose();
                            }}
                            onKeyDown={(event) => {
                                handleKeyDown(event, "close");
                            }}
                        >
                            <PhaseSixIcon name="close" />
                        </ActionClose>
                        <ActionSave
                            tabIndex={0}
                            $disabled={!value}
                            onClick={handleClickSave}
                            onKeyDown={(event) => {
                                handleKeyDown(event, "save");
                            }}
                        >
                            <PhaseSixIcon name="exercise-ok" />
                        </ActionSave>
                    </ActionsWrapper>
                </Col>
            </Row>
        </Wrapper>
    );
};

export default memo(SubjectCreate);
