// LIBRARIES
import React, { useCallback } from "react";
import { useT } from "@transifex/react";

// HELPERS
import { getCardCount, getShopUrl } from "../../../helpers/Subjects";

// COMPONENTS
import Entry from "./Entry";

// TYPES
import { PracticeType } from "p6m-practice";
import { SubjectActions, SubjectData } from "p6m-subjects";

// STYLES
import { List } from "./styles";

export interface UserSubjectFurtherActionsProps {
    type: "due" | "inactive" | "further" | "expired";
    subject: SubjectData;
    subjectActions: SubjectActions;
    userId: string | undefined;
    isPremiumUser: boolean;
    firstPracticeFinished: boolean;
    allCardsLearned: boolean;
    allCardsInactive: boolean;
    isFamilySubject?: boolean;
    isClassroomSubject: boolean;
    hasNextPracticeDate: boolean;
    isLibraryAccessDisabled: boolean;
    totalCardCount: number;
    inactiveCardCount: number;
    closePopup?: () => void;
    deleteSubject: () => void;
    openResetSubjectModal: () => void;
    redirectToTestsAndExercises: () => void;
}

const UserSubjectFurtherActions: React.FC<UserSubjectFurtherActionsProps> = (props) => {
    const { practice, homework, activate, activateFamily, add, manage, report, approve } = props.subjectActions;
    const t = useT();
    const subjectId = props.subject.subjectMetadata.subjectIdToOwner.id;
    const ownerId = props.subject.subjectMetadata.subjectIdToOwner.ownerId;

    const buy_t = t("Buy", { _tags: "list,home,subject,options,pop-up" });
    const delete_t = t("Delete", { _tags: "list,home,subject,options,pop-up" });
    const learnAgain_t = t("Learn again", {
        _tags: "list,home,subject,options,pop-up",
    });
    const practiceMore_t = t("Practice more", {
        _tags: "list,home,subject,options,pop-up",
    });
    const homework_t = t("Homework", {
        _tags: "list,home,subject,options,pop-up",
    });
    const newWords_t = t("New words", {
        _tags: "list,home,subject,options,pop-up",
    });
    const letsGo_t = t("Let's go", {
        _tags: "list,home,subject,options,pop-up",
    });
    const addCard_t = t("Add card", {
        _tags: "list,home,subject,options,pop-up",
    });
    const manageSubject_t = t("Manage subject", {
        _tags: "list,home,subject,options,pop-up",
    });
    const testsAndExercises_t = t("Tests & Exercises", {
        _tags: "list,home,subject,options,pop-up",
    });
    const reports_t = t("Reports", {
        _tags: "list,home,subject,options,pop-up",
    });
    const resetSubject_t = t("Reset subject", {
        _tags: "list,home,subject,options,pop-up",
    });
    const approveWords_t = t("Approve words", {
        _tags: "list,home,subject,options,pop-up",
    });
    const activate_t = t("Activate", {
        _tags: "list,home,subject,options,pop-up",
    });

    const deleteSubject = () => {
        props.deleteSubject();
    };

    const openResetSubjectModal = () => {
        props.openResetSubjectModal();
    };

    const totalCardCount = props.subject?.groupedCardCount?.cardCounts["LIBRARY"].cardCount || 0;

    let subjectsActionsList: Array<JSX.Element> = [];
    let firstElementIsAlreadyFocused = false;

    // ensure only first non-disabled element will be focussed
    const updateFirstElementFocus = (isDisabled: boolean = false) => {
        if (!firstElementIsAlreadyFocused && !isDisabled) firstElementIsAlreadyFocused = true;
    };
    const createSubjectActions = () => {
        const actionsList = [];
        if (props.subject.isExpired) {
            // if type is "expired" => "buy" is primary action, so don't show here
            if (props.type !== "expired") {
                actionsList.push(
                    <Entry
                        key={"buy_action"}
                        iconName={"cart"}
                        url={getShopUrl(subjectId)}
                        closePopup={props.closePopup}
                        isFirstVisibleElement={!firstElementIsAlreadyFocused}
                    >
                        {buy_t}
                    </Entry>
                );
                updateFirstElementFocus();
            }
            const isDeleteActionDisabled = props.isClassroomSubject && ownerId !== props.userId;
            actionsList.push(
                <Entry
                    key={"delete_action"}
                    iconName={"trash"}
                    onClick={deleteSubject}
                    disabled={isDeleteActionDisabled} // only delete own content
                    closePopup={props.closePopup}
                    isFirstVisibleElement={!firstElementIsAlreadyFocused}
                >
                    {delete_t}
                </Entry>
            );
            updateFirstElementFocus(isDeleteActionDisabled);
        } else {
            // if type is "due" => "learn" is primary action, so don't show here
            if (!props.subject.subjectMetadata.isSharedSubjectOfAdmin && props.type !== "due") {
                // Id of subjects with sequence learning starts with "gapSubject"
                let sequencedPractice = subjectId.indexOf("gapSubject") === 0;
                // no sequenced training when cards are pending
                sequencedPractice = sequencedPractice && getCardCount(props.subject, "SHOULD_PRACTICE_TODAY") < 1;
                const isLearnActionDisabled = props.allCardsLearned || props.allCardsInactive;
                actionsList.push(
                    <Entry
                        key={"learnAgain_action"}
                        iconName={"learn"}
                        disabled={isLearnActionDisabled} // only learn again if NOT all cards finished (all cards in last phase) OR all cards inactive
                        onClick={() => practice(ownerId, subjectId, sequencedPractice)}
                        closePopup={props.closePopup}
                        isFirstVisibleElement={!firstElementIsAlreadyFocused}
                    >
                        {props.allCardsLearned ? learnAgain_t : practiceMore_t}
                    </Entry>
                );
                updateFirstElementFocus(isLearnActionDisabled);
            }
            const homeworkCards =
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MANUAL") +
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MIXED") +
                getCardCount(props.subject, "HOMEWORK_ACTIVATE_MATCH") +
                getCardCount(props.subject, "HOMEWORK_LEARN");
            if (homeworkCards > 0) {
                // hasHomework
                const handleHomeworkAction = useCallback(() => {
                    let practiceType: PracticeType = null;
                    if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MANUAL") > 0) {
                        practiceType = "MANUAL_HOMEWORK";
                    } else if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MIXED") > 0) {
                        practiceType = "MIXED";
                    } else if (getCardCount(props.subject, "HOMEWORK_ACTIVATE_MATCH") > 0) {
                        practiceType = "MATCH";
                    } else if (getCardCount(props.subject, "HOMEWORK_LEARN") > 0) {
                        practiceType = "HOMEWORK_PRACTICE";
                    }
                    homework(ownerId, subjectId, practiceType);
                }, [props.subject]);

                actionsList.push(
                    <Entry
                        key={"homework_action"}
                        iconName={"learn"}
                        onClick={handleHomeworkAction}
                        closePopup={props.closePopup}
                        isFirstVisibleElement={!firstElementIsAlreadyFocused}
                    >
                        {homework_t}
                    </Entry>
                );
                updateFirstElementFocus();
            }
            const isNewWordsLetsGoActionDisabled = props.inactiveCardCount === 0;
            // subjects with nextPracticeDate have "new words" action (instead of "let's go"-action); disabled if no cards (left) for activation
            if (props.hasNextPracticeDate) {
                actionsList.push(
                    <Entry
                        key={"new_words_action"}
                        iconName={"edit"}
                        onClick={() => activate(subjectId)}
                        disabled={isNewWordsLetsGoActionDisabled}
                        closePopup={props.closePopup}
                        isFirstVisibleElement={!firstElementIsAlreadyFocused}
                    >
                        {newWords_t}
                    </Entry>
                );
                updateFirstElementFocus(isNewWordsLetsGoActionDisabled);
                // inactive subjects already have "let's go" as main action; otherwise: "let's go" action disabled if no cards (left) for activation
            } else if (props.type !== "inactive") {
                actionsList.push(
                    <Entry
                        key={"lets_go_action"}
                        iconName={"edit"}
                        onClick={() => activate(subjectId)}
                        disabled={isNewWordsLetsGoActionDisabled}
                        closePopup={props.closePopup}
                        isFirstVisibleElement={!firstElementIsAlreadyFocused}
                    >
                        {letsGo_t}
                    </Entry>
                );
                updateFirstElementFocus(isNewWordsLetsGoActionDisabled);
            }

            actionsList.push(
                <Entry
                    key={"add_action"}
                    iconName={"add"}
                    onClick={() => add(subjectId)}
                    closePopup={props.closePopup}
                    isFirstVisibleElement={!firstElementIsAlreadyFocused}
                >
                    {addCard_t}
                </Entry>
            );
            updateFirstElementFocus();

            actionsList.push(
                <Entry
                    key={"manage_action"}
                    iconName={"library"}
                    disabled={!props.firstPracticeFinished || props.isLibraryAccessDisabled}
                    onClick={() => manage(subjectId)}
                    closePopup={props.closePopup}
                >
                    {manageSubject_t}
                </Entry>
            );

            actionsList.push(
                <Entry
                    key={"tests_action"}
                    iconName={"clipboard"}
                    disabled={totalCardCount === 0}
                    onClick={props.redirectToTestsAndExercises}
                    closePopup={props.closePopup}
                >
                    {testsAndExercises_t}
                </Entry>
            );
            actionsList.push(
                <Entry
                    key={"reports_action"}
                    iconName={"reports"}
                    onClick={() => report(subjectId)}
                    closePopup={props.closePopup}
                >
                    {reports_t}
                </Entry>
            );

            // can only reset if any cards have been activated
            actionsList.push(
                <Entry
                    key={"reset_action"}
                    iconName={"reset"}
                    onClick={openResetSubjectModal}
                    disabled={props.allCardsInactive}
                    closePopup={props.closePopup}
                >
                    {resetSubject_t}
                </Entry>
            );
            if (props.subject.subjectMetadata.expirationDate) {
                // isTrial
                actionsList.push(
                    <Entry
                        key={"buy_action_2"}
                        iconName={"cart"}
                        url={getShopUrl(subjectId)}
                        closePopup={props.closePopup}
                    >
                        {buy_t}
                    </Entry>
                );
            }
            if (getCardCount(props.subject, "UNAPPROVED") > 0) {
                // hasUnapprovedCards
                actionsList.push(
                    <Entry
                        key={"approve_action"}
                        iconName={"school"}
                        onClick={() => approve(subjectId)}
                        closePopup={props.closePopup}
                    >
                        {approveWords_t}
                    </Entry>
                );
            }
            if (ownerId !== props.userId && isNaN(+ownerId)) {
                // !isOwned
                actionsList.push(
                    <Entry
                        key={"delete_action_2"}
                        iconName={"trash"}
                        onClick={deleteSubject}
                        disabled={props.isClassroomSubject && ownerId !== props.userId} // only delete own content
                        closePopup={props.closePopup}
                    >
                        {delete_t}
                    </Entry>
                );
            }
        }
        return actionsList;
    };

    if (props.isFamilySubject) {
        if (props.subject.groupData && props.subject.groupData.isAdmin) {
            //subject.familyOwner.userId === User.userId) {
            subjectsActionsList = createSubjectActions();
        } else {
            // is not family subject admin
            if (!props.subject.isExpired) {
                subjectsActionsList.push(
                    <Entry
                        key={"activate_action"}
                        iconName={"edit"}
                        onClick={() => activateFamily(subjectId, props.userId)}
                        closePopup={props.closePopup}
                    >
                        {activate_t}
                    </Entry>
                );
                subjectsActionsList.push(
                    <Entry
                        key={"reports_action_2"}
                        iconName={"reports"}
                        onClick={() => report(subjectId)}
                        closePopup={props.closePopup}
                    >
                        {reports_t}
                    </Entry>
                );
            }
        }
    } else {
        // is user subject
        subjectsActionsList = createSubjectActions();
    }
    return <List>{subjectsActionsList}</List>;
};

UserSubjectFurtherActions.defaultProps = {
    isFamilySubject: false,
};

export default UserSubjectFurtherActions;
