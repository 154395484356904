import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import Badge from "../../../basic/badge/Badge";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import { useT } from "@transifex/react";
import styled from "styled-components";
import { ampli } from "../../../../ampli";
import ReactTooltip from "react-tooltip";

export type Props = {
    count?: number;
};

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const ExtendedNavLink = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 0px 8px;
`;

const TrophyItem: FunctionComponent<Props> = ({ count }) => {
    const t = useT();
    const tooltipText = t("Achievements", { _tags: "achievements,header,tooltip" });
    const tooltipProps = {
        "data-tip": true,
        "data-for": "TopNavTrophyTooltip",
    };

    return (
        <ExtendedNavLink
            activeClassName="is-active"
            to="/achievements"
            data-qa="achievements-link"
            tabIndex={1000}
            onClick={() => ampli.openedAchievements()}
            {...tooltipProps}
        >
            {count ? (
                <Badge
                    data-qa="achievements-counter"
                    count={count}
                    bigIcon
                >
                    <StyledPhaseSixIcon
                        aria-label={`Achievements: ${count} open`}
                        name="cup"
                        size="medium"
                    />
                </Badge>
            ) : (
                <StyledPhaseSixIcon
                    aria-label={`Achievements: ${count} open`}
                    name="cup"
                    size="medium"
                />
            )}
            <ReactTooltip
                place="bottom"
                id="TopNavTrophyTooltip"
                effect="solid"
            >
                {tooltipText}
            </ReactTooltip>
        </ExtendedNavLink>
    );
};

export default TrophyItem;
