import { getCardsFromSubject } from "../../networking/cards";
import { useCallback, useState } from "react";
import { SubjectUnitCard } from "p6m-subjects";
import { useDispatch } from "react-redux";
import { actions as appStatusActions } from "../../redux/appStatus/appStatusSlice";

const CHUNK_SIZE: number = +(process.env.REACT_APP_CARD_CHUNK_SIZE || 50);

interface UseCardsProps extends LoadCardsOptions {
    subjectId?: string;
}

export interface LoadCardsOptions {
    unitIds?: string[];
    phaseIds?: string[];
    offset?: number;
    resetCards?: boolean;
    searchString?: string;
    noLimit?: boolean;
    chunkSize?: number;
    subjectId?: string;
}

export const useCards = ({ subjectId, unitIds, phaseIds, offset, searchString }: UseCardsProps) => {
    const dispatch = useDispatch();
    const [cards, setCards] = useState<SubjectUnitCard[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);

    const loadCards = useCallback(
        async (options: LoadCardsOptions) => {
            if (options.resetCards) {
                setHasMore(false);
                setIsLoaded(false);
                setHasError(false);
            }

            setIsLoading(true);
            dispatch(appStatusActions.setLoading(true));

            const newOffset = typeof options.offset === "number" ? options.offset : offset;
            const newSearchString = typeof options.searchString === "string" ? options.searchString : searchString;

            const response = await getCardsFromSubject({
                subjectId: options.subjectId || subjectId,
                units: options.unitIds || unitIds,
                phases: (options.phaseIds || phaseIds)?.map((id) => parseInt(id, 10)),
                noLimit: options.noLimit,
                searchString: newSearchString?.trim(),
                offset: newOffset,
                defaultChunkSize: CHUNK_SIZE,
            });

            if (response.status === 200 && response.data.httpCode === 200) {
                const loadedCards = response.data.replyContent.cards;
                // if no card or not the limit chunk size amount is returned we are finished
                setCards((state) => (options.resetCards ? loadedCards : [...state, ...loadedCards]));
                setHasMore(!!loadedCards.length && loadedCards.length % CHUNK_SIZE === 0);
            } else {
                setHasError(true);
            }

            dispatch(appStatusActions.setLoading(false));
            setIsLoading(false);
            setIsLoaded(true);
        },
        [subjectId, unitIds, phaseIds, offset, searchString]
    );

    return {
        loadCards,
        cards,
        hasMore,
        hasError,
        isLoading,
        isLoaded,
    };
};
