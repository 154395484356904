import axios, { AxiosPromise, AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import {
    UserSubjectsResponseData,
    SubjectUnitCard,
    FamilySubjectsResponseData,
    ResponseLibrarySubjectData,
    IdToOwnerId,
    PublisherSubjectResponseData,
    SelfCreatedSubjectResponseData,
    GroupedCardCount,
    SubjectContent,
    SubjectCardCountData,
} from "p6m-subjects";
import { getCardsFromSubject } from "./cards";

export function getUserSubjectsData(): AxiosPromise<IResponse<UserSubjectsResponseData>> {
    return axios.get("/userSubjectsData");
}

export function getSubjectGroupedCardCount(
    subjectId: string,
    ownerId: string
): AxiosPromise<IResponse<GroupedCardCount>> {
    return axios.get(`/groupedCardCount/subject/${subjectId}/${ownerId}`);
}

export type TgetSubjectUnits = {
    subjectId?: string;
    cards?: string[];
    units?: string[];
    filterMode?: "LEARN_NEW" | "PRACTICE" | "LIBRARY";
};

export function getSubjectUnitCards({
    cards = [],
    subjectId,
    units = [],
    filterMode,
}: TgetSubjectUnits): AxiosPromise<IResponse<{ cards: SubjectUnitCard[] }>> {
    return axios.post("/cardList", {
        cards,
        filterMode,
        subjectId,
        units,
    });
}

export function getFamilySubjects(): AxiosPromise<IResponse<FamilySubjectsResponseData>> {
    return axios.get("/familySubjectsData");
}

export function getSubjectsByUserId(
    userId: string,
    allData: boolean = true
): AxiosPromise<IResponse<FamilySubjectsResponseData>> {
    return axios.get(`/userSubjectsFromGroupData/${userId}/${allData}/foreign`);
}

export function getLibrarySubjects(): AxiosPromise<IResponse<{ subjects: ResponseLibrarySubjectData[] }>> {
    return axios.post("/subjectsCombined", {
        filterMode: "LIBRARY",
    });
}

export function getAllSubjectsCardsCounts(): AxiosPromise<IResponse<{ subjectCardCounts: SubjectCardCountData[] }>> {
    return axios.get("/allSubjectsCardCounts");
}

export function getSubjectCardsCount(subjectId: string): AxiosPromise<IResponse<{ cardCount: number }>> {
    return axios.post("/cardCount", { subjectId });
}

export function deleteSubject({ id: subjectId, ownerId }: IdToOwnerId): any {
    return axios.delete(`/${ownerId}/subjects/${subjectId}`);
}

export type PostSubjectProps = {
    ownerId: string;
    subjectId: string;
    withDefaultUnit?: boolean;
    data?: {
        name: string;
        order?: number;
        ownerId?: string;
        primaryLang?: string;
        secondaryLang?: string;
    };
};

export function postSubject({ ownerId, subjectId, data }: PostSubjectProps): any {
    return axios.post(`/${ownerId}/subjects/${subjectId}`, data);
}

export const moveCardsToSubjectAndUnit = async (
    initialSubjectId: string,
    cardIds: IdToOwnerId[],
    newSubjectIdToOwnerId: IdToOwnerId,
    newUnitIdToOwnerId: IdToOwnerId,
    userId: string
): Promise<AxiosResponse<{ httpCode: number; replyContent: string }>[]> => {
    const { data: cardsData } = await getCardsFromSubject({
        subjectId: initialSubjectId,
        noLimit: true,
        cardIds: cardIds.map((idToOwnerId) => idToOwnerId.id),
    });

    if (cardsData?.httpCode === 200) {
        const cards = cardsData.replyContent.cards;
        cards.forEach((card) => {
            card.cardContent.subjectIdToOwner = newSubjectIdToOwnerId;
            card.cardContent.unitIdToOwner = newUnitIdToOwnerId;
        });
        const changedCardsResponses: AxiosResponse<{ httpCode: number; replyContent: string }>[] = await Promise.all(
            cards.map(async (card) => axios.post(`/${userId}/cards/${card.cardIdToOwner.id}`, card.cardContent))
        );
        return changedCardsResponses;
    }
    return [];
};

export function resetSubject(ownerId: string, subjectId: string) {
    return axios.get("/resetSubject/" + ownerId + "/" + subjectId);
}

export const fetchSubjectInfo = ({ ownerId, subjectId }: { ownerId: string; subjectId: string }) => {
    return axios.get<PublisherSubjectResponseData | SelfCreatedSubjectResponseData>(
        `/notIndexedSubject/${ownerId}/${subjectId}`
    );
};

export const purchaseTrial = ({ identifier, extended }: { identifier: string; extended: boolean }) => {
    return axios.post("/purchaseTrial", {
        contentIdentifier: identifier,
        extended,
    });
};

// This endpoint has been added for future use and is currently unusable due to a CORS error.
export function getShopSubject(subjectId: string) {
    return axios.get(`shop-search-server/article/${subjectId}`, {
        baseURL: process.env.REACT_APP_BACKEND_OPEN_CMS_URL,
        headers: {
            Accept: "application/json;version=1.0",
        },
    });
}

export const fetchAllUserSubjectsMetaData = (userDnsId: string) => {
    return axios.get(`/AllUserSubjectMetadata/${userDnsId}`);
};

export function getSubjectIds(): AxiosPromise<IResponse<{ ids: IdToOwnerId[] }>> {
    return axios.post("/subjects", {});
}

export interface BulkResponse {
    data: Array<{
        // TODO: change to SelfCreatedSubjectResponseData | PublisherSubjectResponseData
        data: SubjectContent;
        objectId: IdToOwnerId;
    }>;
}

export function getSubjectListData(subjects: Array<{ id: string; ownerId: string }>) {
    return axios.post<BulkResponse>("/SubjectContent/bulk/get", {
        data: [
            ...subjects.map((item) => ({
                objectId: item,
            })),
        ],
    });
}
