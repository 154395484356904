import React, { FunctionComponent } from "react";
import styled from "styled-components";
import Modal from "../../../basic/modal/Modal";
import { useT } from "@transifex/react";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";
import ReactTooltip from "react-tooltip";
import { accessibleActionProps } from "../../../../helpers/Accessibility";

type Props = {
    link: string;
    showModal: boolean;
    toggleModal: () => void;
};

const Container = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0px 8px;
`;
const StyledIframe = styled.iframe`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 80vh;
    border: none;
`;

const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: ${(props) => props.theme.colors.textOnBackground};
`;

const ExtendedModal = styled(Modal)`
    padding: 0;
`;

const SurveyItem: FunctionComponent<Props> = (props) => {
    const { link, showModal, toggleModal } = props;
    const t = useT();

    const titleTranslation = t("Do you like our application?", { _tags: "survey,header" });
    const tooltipProps = {
        "data-tip": true,
        "data-for": "TopNavSurveyTooltip",
    };

    return (
        <Container
            {...accessibleActionProps(toggleModal, 1000)}
            {...tooltipProps}
        >
            <StyledPhaseSixIcon
                aria-label="Survey"
                name="support"
                size="medium"
            />
            <ReactTooltip
                place="bottom"
                id="TopNavSurveyTooltip"
                effect="solid"
            >
                {titleTranslation}
            </ReactTooltip>
            {showModal && (
                <ExtendedModal
                    active={showModal}
                    afterClose={toggleModal}
                    title={titleTranslation}
                >
                    <StyledIframe
                        title="survey"
                        src={link}
                    />
                </ExtendedModal>
            )}
        </Container>
    );
};

export default SurveyItem;
