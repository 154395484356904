import React from "react";

export function handleButtonKeyPress(
    handler: ((e: React.KeyboardEvent) => void) | undefined
): (e: React.KeyboardEvent) => void {
    return (e: React.KeyboardEvent) => {
        if (e.key === "Enter" || e.key === " ") {
            handler?.(e);
        }
    };
}

export function accessibleActionProps(
    handler?: (e: React.KeyboardEvent | React.MouseEvent) => void,
    tabIndex?: number
): {
    role: string;
    tabIndex: number;
    onKeyDown: (e: React.KeyboardEvent) => void;
    onClick: (e: React.MouseEvent) => void;
} {
    return {
        role: "button",
        tabIndex: tabIndex ?? 0,
        onKeyDown: handleButtonKeyPress(handler),
        onClick: (e: React.MouseEvent) => handler?.(e),
    };
}
