//DEPENDENCIES
import { cloneDeep } from "lodash";
import { ampli } from "../ampli";

//NETWORKING
import { postSubject } from "../networking/subjects";

//REDUX
import { SubjectCounterTypes } from "../redux/subjects/subjectsSlice";

//ASSETS
import gc_shared from "../assets/img/subject-covers/gc_shared.jpg";
import uc_others from "../assets/img/subject-covers/uc_others.jpg";

//UTILS
import { defaultUnitIdPrefix, defaultSubjectIdPrefix, defaultUnitOrder } from "../constants/ContentConstants";
import { importAll } from "./Image";
import { createSingleUnit, getDefaultUnitId } from "./Units";
import { generateUuid } from "./Id";

//TYPES
import { SubjectCounterType, SubjectData, UnitsList } from "p6m-subjects";
import { DropdownOption } from "p6m-viewData";
import {
    DROPDOWN_CONTENT_TYPES,
    DROPDOWN_EDIT_TYPES,
    EDIT_RESPONSE_TYPES,
} from "../hooks/dropdown/useDropdownEditResponsePopup";
import { LanguageCodes } from "../context/languages";

export const getCardCountForLimitedTodayTraining = (subject: SubjectData | null, limit: number | undefined): number => {
    let cardsToPracticeToday = 0;
    if (subject) {
        cardsToPracticeToday = getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TODAY);
        if (limit) {
            // calculate remaining cardLimit
            const learnedToday = getCardCount(subject, SubjectCounterTypes.LEARNED_TODAY);
            limit = Math.max(limit - learnedToday, 0);
            if (limit < cardsToPracticeToday) {
                cardsToPracticeToday = limit;
            }
        }
    }
    return cardsToPracticeToday;
};

export const getCardCountForPracticeMoreToday = function (subject: SubjectData | null) {
    if (!subject) return 0;
    const practiceMoreChunkSize: number = parseInt(process.env.REACT_APP_PRACTICE_MORE_CHUNK_SIZE || "10");
    const availableCards: number = getCardCount(subject, SubjectCounterTypes.PRACTICE);
    return Math.min(practiceMoreChunkSize, availableCards);
};

export const getSubjectDataById = (subjects: Array<SubjectData>, id: string | null): SubjectData | null => {
    if (id === null) return null;
    for (let i = 0; i < subjects.length; i++) {
        if (subjects[i].subjectMetadata.subjectIdToOwner.id === id) {
            return subjects[i];
        }
    }
    return null;
};

export const getFamilySubjectDataById = (familySubjects: Array<any>, id: string | null): SubjectData | null => {
    if (id === null) return null;
    for (let i = 0; i < familySubjects.length; i++) {
        if (Array.isArray(familySubjects[i].subjects)) {
            for (let j = 0; j < familySubjects[i].subjects.length; j++) {
                if (familySubjects[i].subjects[j][1].subjectMetadata.subjectIdToOwner.id === id) {
                    return { ...familySubjects[i].subjects[j][1] };
                }
            }
        }
    }
    return null;
};

export const getFamilySubjectsWithUsernames = (familySubjects: Array<any>) => {
    const derivedSubjects: SubjectData[] = [];
    for (let i = 0; i < familySubjects.length; i++) {
        if (Array.isArray(familySubjects[i].subjects)) {
            for (let j = 0; j < familySubjects[i].subjects.length; j++) {
                derivedSubjects.push({
                    ...familySubjects[i].subjects[j][1],
                    userName: familySubjects[i].userName,
                });
            }
        }
    }
    return derivedSubjects;
};

export const getCardCount = (subject: SubjectData, type: SubjectCounterType): number => {
    if (
        subject &&
        subject.groupedCardCount &&
        subject.groupedCardCount.cardCounts &&
        subject.groupedCardCount.cardCounts[type]
    ) {
        return subject.groupedCardCount.cardCounts[type]!.cardCount;
    }
    return 0;
};

export const getShopUrl = (subjectId: string) => {
    return "https://www.phase-6.de/classic/shop/basket.html?ArticleUUID=" + subjectId;
};

export const getDefaultLanguageCoverImage = (languageCode?: LanguageCodes) => {
    const images = importAll(
        require.context("../assets/img/subject-covers/", false, /uc_[a-zA-Z]{2}\.(png|jpe?g|svg)$/)
    );
    const key: string = `./uc_${languageCode}.jpg`;

    return images[key] || uc_others;
};

type ImageKeys = "image" | "book";
export const getSubjectCover = (subject?: SubjectData, keyToReturn?: ImageKeys): string => {
    if (!subject) return uc_others;

    const baseURL = process.env.REACT_APP_BACKEND_API_URL;
    const {
        subjectContent: { imageId, bookImageId, secondaryLang },
        subjectMetadata: { classroomFlag = false },
    } = subject || {};

    const covers: Record<ImageKeys, string | undefined> = {
        image: imageId ? `${baseURL}media/${imageId}` : undefined,
        book: bookImageId ? `${baseURL}media/${bookImageId}` : undefined,
    };

    if (keyToReturn) {
        return covers[keyToReturn] || "";
    }

    if (covers.image) return covers.image; // ID of this special band if available
    if (covers.book) return covers.book; // ID of the book series
    if (classroomFlag) return gc_shared;

    return getDefaultLanguageCoverImage(secondaryLang);
};

export const getPublisherSubjectCover = (imageId: string) => {
    const baseURL = process.env.REACT_APP_BACKEND_API_URL;
    return !!imageId ? `${baseURL}media/${imageId}` : uc_others;
};

export enum TimeUnitName {
    minute,
    hour,
    day,
}

export const convertSecondsIntoHighestPossibleTimeUnit = (
    seconds: number
): {
    timeUnit: TimeUnitName;
    unitAmount: number;
    showPlural: boolean;
} => {
    const unitMeasureInSeconds: { [key in TimeUnitName]: number } = {
        [TimeUnitName.minute]: 60,
        [TimeUnitName.hour]: 3600,
        [TimeUnitName.day]: 86400,
    };

    const anHourOrLess = seconds <= unitMeasureInSeconds[TimeUnitName.hour];
    const aDayOrLess = seconds <= unitMeasureInSeconds[TimeUnitName.day];

    const unitToDisplay: TimeUnitName = anHourOrLess
        ? TimeUnitName.minute
        : aDayOrLess
        ? TimeUnitName.hour
        : TimeUnitName.day;

    const secondsPerUnit = unitMeasureInSeconds[unitToDisplay];
    const unitAmount = Math.round(seconds / secondsPerUnit);

    return {
        timeUnit: unitToDisplay,
        unitAmount,
        showPlural: unitAmount !== 1,
    };
};

export const getUnitOptionsFromSubject = ({
    subject,
    userId,
    userIsTeacher,
    units,
}: {
    subject: SubjectData;
    userId: string;
    userIsTeacher: boolean;
    units?: UnitsList;
}): DropdownOption[] => {
    if (!subject || !units) return [];
    let newUnitOptions: DropdownOption[] = [];

    cloneDeep(units)
        .sort((a, b) => {
            const res = a.unitContent.order - b.unitContent.order;
            return b.unitContent.order === -1 ? -1 : res;
        })
        .forEach((unit) => {
            const isOwnSubjectAndUnit =
                !subject.subjectMetadata.isSharedSubjectOfAdmin && unit.unitId.ownerId === userId;
            const isSharedSubjectByThisTeacher = subject.subjectMetadata.isSharedSubjectOfAdmin && userIsTeacher;
            const isUncategorized = unit.unitId.id.indexOf(defaultUnitIdPrefix) === 0;
            newUnitOptions.push({
                title: unit.unitContent.name,
                value: unit.unitId.id,
                editable: !isUncategorized && (isOwnSubjectAndUnit || isSharedSubjectByThisTeacher),
                cardCount: unit.cardCount,
            });
        });

    return newUnitOptions;
};

export const getValidSubjectOptionsFromSubjects = (subjects: SubjectData[], userId: string): DropdownOption[] => {
    const validSubjectOptions: DropdownOption[] = [];
    cloneDeep(subjects).forEach((subject) => {
        if (!subject.isExpired) {
            const subjectWasCreatedByUser = subject.subjectMetadata.subjectIdToOwner.ownerId === userId;
            const subjectBelongsToClassroom = subject.subjectMetadata.classroomFlag;
            validSubjectOptions.push({
                title: subject.subjectContent.name,
                value: subject.subjectMetadata.subjectIdToOwner.id,
                editable: subjectWasCreatedByUser && !subjectBelongsToClassroom,
                deletable: !subjectBelongsToClassroom,
                cardCount: subject.allCardsCount,
            });
        }
    });
    return validSubjectOptions;
};

export const getDefaultSubjectId = (ownerId: string) => {
    return defaultSubjectIdPrefix + ownerId;
};

export const isDefaultSubjectById = (id: string): boolean => {
    return id.startsWith(defaultSubjectIdPrefix);
};

export const isDefaultSubject = (subject: SubjectData): boolean => {
    const {
        subjectMetadata: {
            subjectIdToOwner: { id },
        },
    } = subject;
    return isDefaultSubjectById(id);
};

export const sortSubjectsByName = (aSubj: SubjectData, bSubj: SubjectData) => {
    if (!aSubj.subjectContent?.name) return -1;
    if (!bSubj.subjectContent?.name) return 1;
    const {
        subjectContent: { name: aName },
    } = aSubj;
    const {
        subjectContent: { name: bName },
    } = bSubj;
    return aName.toLowerCase() > bName.toLowerCase() ? 1 : -1;
};

export const generateSubjectTemplate = ({
    subjectId = generateUuid(),
    creatorId,
    name,
    primaryLang,
    secondaryLang,
    order,
    withDefaultUnit,
}: {
    subjectId?: string;
    creatorId: string;
    name: string;
    primaryLang?: string;
    secondaryLang?: string;
    order?: number;
    withDefaultUnit?: boolean;
}) => ({
    subjectId: subjectId,
    ownerId: creatorId,
    data: {
        name,
        ownerId: creatorId,
        primaryLang,
        secondaryLang,
        order,
    },
    withDefaultUnit,
});

export const createSingleSubject = async ({
    userId,
    subjectId,
    subjectName,
    subjectOrder,
    withDefaultUnit = true,
    unitName,
    onError,
}: {
    userId?: string;
    subjectId?: string;
    subjectName: string;
    subjectOrder?: number;
    withDefaultUnit?: boolean;
    unitName?: string;
    onError?: (
        contentType: DROPDOWN_CONTENT_TYPES,
        editType: DROPDOWN_EDIT_TYPES,
        responseType: EDIT_RESPONSE_TYPES,
        name: string
    ) => void;
}) => {
    if (userId) {
        ampli.clickCreateSubjectA({ app_screen: "library" });
        const newSubjectId = subjectId || generateUuid();
        let res: { httpCode?: number; name?: string; replyContent?: string } = {};

        try {
            const subjectCreationRes = await postSubject(
                generateSubjectTemplate({
                    subjectId: newSubjectId,
                    creatorId: userId,
                    name: subjectName,
                    primaryLang: "de",
                    order: subjectOrder,
                })
            );
            res = { ...subjectCreationRes?.data, name: subjectName };
        } catch (e) {
            onError?.(
                DROPDOWN_CONTENT_TYPES.SUBJECT,
                DROPDOWN_EDIT_TYPES.CREATION,
                EDIT_RESPONSE_TYPES.ERROR,
                subjectName
            );
        }
        if (withDefaultUnit && unitName) {
            await createSingleUnit({
                userId,
                subjectId: newSubjectId,
                unitId: getDefaultUnitId(newSubjectId),
                unitName,
                unitOrder: defaultUnitOrder,
                onError,
            });
        }

        return res;
    } else {
        onError?.(DROPDOWN_CONTENT_TYPES.SUBJECT, DROPDOWN_EDIT_TYPES.CREATION, EDIT_RESPONSE_TYPES.ERROR, subjectName);
    }
};
