import { navigateElementsByClassName } from "./navigateElementsByClassName";

export const keyDownHandlerForNavigatingElementsByClass = (
    event: React.KeyboardEvent<HTMLElement>,
    classNameToNavigateThrough: string,
    onEnter?: () => void,
    onLeave?: () => void,
    isExternalLink?: boolean
) => {
    if (event.key === "ArrowDown") {
        event.preventDefault();
        navigateElementsByClassName(classNameToNavigateThrough, true, true);
    }
    if (event.key === "ArrowUp") {
        event.preventDefault();
        navigateElementsByClassName(classNameToNavigateThrough, false, true);
    }
    if (event.key === "Enter" && !isExternalLink) {
        !!onEnter && onEnter();
    }
    if (event.key === "Escape" || event.key === "Tab") {
        event.preventDefault();
        !!onLeave && onLeave();
    }
};
