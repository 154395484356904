// REACT
import React, { useContext, useState, useRef, useEffect } from "react";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import { ThemeContext } from "styled-components";
import { InputContainer, StyledInput } from "./styles";

export interface SearchTextInputProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    value: string;
    fixedWidth?: boolean;
    placeholder?: string;
    autofocus?: boolean;
    ariaLabel?: string;
    ariaLabelledBy?: string;
}

const SearchTextInput: React.FC<SearchTextInputProps> = (props) => {
    const themeContext = useContext(ThemeContext);
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const searchInputColor = searchIsFocused ? themeContext.colors.primary : themeContext.colors.textOnBackground;
    const inputRef = useRef<HTMLInputElement>(null);

    const focusInput = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    const clearInput = () => {
        props.onClear();
        focusInput();
    };

    useEffect(() => {
        if (props.autofocus) {
            focusInput();
        }
    }, [props.autofocus]);

    return (
        <InputContainer
            style={{ borderColor: searchInputColor }}
            fixedWidth={props.fixedWidth}
        >
            <PhaseSixIcon name={"search"} />
            <StyledInput
                onFocus={() => {
                    setSearchIsFocused(true);
                }}
                onBlur={() => {
                    setSearchIsFocused(false);
                }}
                onChange={props.onChange}
                value={props.value}
                placeholder={props.placeholder ? props.placeholder : ""}
                ref={inputRef}
                aria-label={props.ariaLabel}
                aria-labelledby={props.ariaLabelledBy}
            />
            {props.value.length > 0 && (
                <PhaseSixIcon
                    name="close"
                    highlighted={searchIsFocused}
                    onClick={clearInput}
                />
            )}
        </InputContainer>
    );
};

export default SearchTextInput;
