import styled, { css } from "styled-components";

export const NavigationWrapper = styled.div`
    height: 70px;
    display: flex;
    flex-direction: row;
`;

export const StyledItem = styled.div`
    display: flex;
    align-items: stretch;
    margin: 0 8px;
    font-size: 24px;
    color: ${({
        theme: {
            colors: { textOnBackground },
        },
    }) => textOnBackground};
    ${({ onClick }) =>
        !!onClick &&
        css`
            &:hover {
                cursor: pointer;
            }
        `}
    & a {
        color: inherit;
        text-decoration: inherit;
        &.is-active {
            display: block;
            color: ${({ theme: { colors } }) => colors.textOnThemeImage};
            background-color: ${({ theme: { colors } }) => colors.backgroundOnThemeImage};
            border-radius: 8px;
            line-height: 1;
        }
    }
    &.disabled,
    & .disabled {
        opacity: ${({ theme: { base } }) => base.hoverOpacity};
    }
`;
