// REACT
import React, { FunctionComponent, memo, useCallback } from "react";

// COMPONENTS
import PhaseSixIcon from "../../phaseSixIcon/PhaseSixIcon";
import { LanguageSelect, Props as LangComponentProps } from "../LanguageSelect";

// STYLED COMPONENTS
import { Wrapper, CharsContainer, Char, FooterContainer, CaseToggle, CharButton, CloseButton } from "./styles";

type Props = {
    chars: string[];
    isUppercase: boolean;
    onClose: (viaKey?: boolean) => void;
    onCaseToggle: () => void;
    onCharSelect: (selectedChar: string) => void;
    onToggleLangSelect: (isOpen: boolean) => void;
} & LangComponentProps;

const SpecialCharMenu: FunctionComponent<Props> = (props) => {
    const {
        chars,
        lang,
        langList,
        isUppercase,
        langSelectIsOpen,
        onToggleLangSelect,
        onLangSelect,
        onClose,
        onCaseToggle,
        onCharSelect,
    } = props;

    const handleClose = useCallback(() => {
        onClose();
    }, []);

    return (
        <Wrapper>
            <CharsContainer>
                {chars.map((char: string, i: number) => (
                    <Char key={char + i}>
                        <CharButton onClick={() => onCharSelect(char)}>{char}</CharButton>
                    </Char>
                ))}
            </CharsContainer>
            <FooterContainer>
                <CaseToggle
                    onClick={() => onCaseToggle()}
                    active={isUppercase}
                >
                    <PhaseSixIcon name="uppercase" />
                </CaseToggle>
                {!!langList && (
                    <LanguageSelect
                        langSelectIsOpen={langSelectIsOpen}
                        lang={lang}
                        langList={langList}
                        onLangSelect={onLangSelect}
                        onToggleLangSelect={onToggleLangSelect}
                    />
                )}
                <CloseButton onClick={handleClose}>
                    <PhaseSixIcon name="close" />
                </CloseButton>
            </FooterContainer>
        </Wrapper>
    );
};

export default memo(SpecialCharMenu);
