import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const MainWrapper = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacing};
`;

export const MainTitle = styled.p`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: 600;
    margin-bottom: ${(props) => props.theme.base.spacingSmall};
    margin-left: ${(props) => props.theme.base.spacingInContent};

    flex: 8;
`;

export const ChildrenContainer = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacingBig};
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadius};
    overflow: hidden;
    padding: 2px;

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: ${(props) => props.theme.base.disabledOpacity};
    }
`;

export const DescriptionText = styled.p`
    color: ${(props) => props.theme.colors.textLight};
    font-size: ${(props) => props.theme.base.mediumSize};
    padding: 12px;
`;

export const DeactivatedText = styled.p`
    color: ${(props) => props.theme.colors.text};
    margin-top: 5px;
`;

export const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    margin-right: 5px;
`;

export const FlexTitleContainer = styled.div`
    display: flex;
    height: 30px;
    margin-bottom: ${(props) => props.theme.base.spacingSmall};

    > p {
        margin-bottom: 0;
    }
`;

export const LockComponentWrapper = styled.div`
    width: 50px;
    height: 30px;
`;
