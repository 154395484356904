import { call, put, select, takeEvery } from "redux-saga/effects";
import { actions, subjectListSelectors } from "./librarySlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { getAllSubjectsCardsCounts, getSubjectIds, getSubjectListData } from "../../networking/subjects";
import { GetSubjectsBulkResponse, LibrarySubject, SubjectCardCountData, SubjectUnitCard } from "p6m-subjects";
import { getDefaultLanguageCoverImage, getPublisherSubjectCover } from "../../helpers/Subjects";
import { PayloadAction } from "@reduxjs/toolkit";
import { getCardsFromSubject } from "../../networking/cards";
import { CardsResponseData } from "p6m-cards";

function* loadSubjectListSaga() {
    yield put(appStatusActions.setLoading(true));

    try {
        const subjectIdsResponse: AxiosResponse<IResponse<{ ids: Array<{ id: string; ownerId: string }> }>> =
            yield call(getSubjectIds);

        const subjectIds = subjectIdsResponse.data.replyContent.ids;

        const subjectListData: GetSubjectsBulkResponse = (yield call(getSubjectListData, subjectIds)).data.replyContent;
        const subjectCardCounts: SubjectCardCountData[] = (yield getAllSubjectsCardsCounts()).data.replyContent
            .subjectCardCounts;

        let totalLibraryCardsCount = 0;
        const subjectListDataAsLibrarySubjects: LibrarySubject[] = subjectListData.data.map((currentSubject) => {
            const coverImg =
                "imageId" in currentSubject.data
                    ? getPublisherSubjectCover(currentSubject.data.imageId)
                    : getDefaultLanguageCoverImage(currentSubject.data.secondaryLang);

            const cardCount = subjectCardCounts.find(
                (subjectCardCountResult: SubjectCardCountData) =>
                    subjectCardCountResult.subjectId.id === currentSubject.objectId.id
            );

            totalLibraryCardsCount += cardCount?.cardCount || 0;

            return {
                id: currentSubject.objectId.id,
                name: currentSubject.data.name,
                primaryLang: currentSubject.data.primaryLang,
                secondaryLang: currentSubject.data.secondaryLang,
                totalCards: cardCount?.cardCount || 0,
                ownerId: currentSubject.objectId.ownerId,
                cover: coverImg,
            };
        });

        const sortedSubjects = subjectListDataAsLibrarySubjects.sort((a, b) => {
            if (b.name > a.name) {
                return -1;
            } else if (b.name < a.name) {
                return 1;
            }

            return 0;
        });

        yield put(actions.setSubjectList({ subjects: sortedSubjects, totalCards: totalLibraryCardsCount }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* searchSubjectListCardsSaga(action: PayloadAction<string>) {
    if (action.payload.length === 0) {
        yield put(actions.resetSubjectListSearch());
    } else {
        yield put(appStatusActions.setLoading(true));
        try {
            const subjects: LibrarySubject[] = yield select(subjectListSelectors.getSubjects);
            const filteredCards: Record<string, SubjectUnitCard[]> = {};

            const response: AxiosResponse<IResponse<CardsResponseData>> = yield call(getCardsFromSubject, {
                searchString: action.payload,
                noLimit: true,
                limitCardsPerSubject: 5,
            });

            subjects.forEach((subject) => {
                filteredCards[subject.id] = response.data.replyContent.cards.filter(
                    (card) => card.subjectIdToOwner.id === subject.id
                );
            });

            yield put(
                actions.setFilteredSubjectListCards({
                    cards: filteredCards,
                    totalCards: response.data.replyContent.cards.length,
                })
            );
        } catch (err) {
            console.log(err);
        } finally {
            yield put(appStatusActions.setLoading(false));
        }
    }
}

export function* librarySaga() {
    yield takeEvery(actions.loadSubjectList, loadSubjectListSaga);
    yield takeEvery(actions.searchSubjectListCards, searchSubjectListCardsSaga);
}
