// LIBRARIES
import React from "react";

// TYPES
import { ReportScreenType } from "p6m-report";
import { SubjectData } from "p6m-subjects";
import { FamilyMember, User, UserPreferences } from "p6m-user";

//STYLES
import { ReportsContainer, ContentContainer, SocialSelectorsContainer } from "./styles";

// COMPONENTS
import FamilyMemberSelector from "../../components/basic/familyMemberSelector/FamilyMemberSelector";

// PAGES
import ReportTopicsSelection from "./pages/topics/ReportTopics";
import SubjectReport from "./SubjectReport";
import InviteChild from "./pages/inviteChild/InviteChild";

export interface ReportsProps {
    user: User;
    isFamilyView: boolean;
    isStrictMode: boolean;
    userHasPremium: boolean;
    userPreferences: UserPreferences;
    screenType: ReportScreenType;
    changeScreen: (type: ReportScreenType) => void;
    subjects: SubjectData[] | null;
    selectedSubject: SubjectData | null;
    onSubjectSelect: (subjectId: string | null) => void;
    familyMembers?: FamilyMember[];
    selectedFamilyMember?: FamilyMember;
    memberHasPremium?: boolean;
    onFamilyMemberSelect?: (memberId: string | undefined) => void;
    showInviteChildScreen?: boolean;
    onInviteChild?: () => void;
    onAddContent(): void;
}

const Reports: React.FC<ReportsProps> = (props) => {
    const {
        user,
        isFamilyView,
        isStrictMode,
        userHasPremium,
        userPreferences,
        screenType,
        changeScreen,
        subjects,
        selectedSubject,
        onSubjectSelect,
        familyMembers,
        selectedFamilyMember,
        memberHasPremium,
        onFamilyMemberSelect,
        showInviteChildScreen,
        onInviteChild,
        onAddContent,
    } = props;

    return (
        <ReportsContainer>
            {isFamilyView && (
                <SocialSelectorsContainer>
                    <FamilyMemberSelector
                        userDnsId={user.userDnsId}
                        familyMembers={familyMembers}
                        selectedFamilyMember={selectedFamilyMember}
                        onFamilyMemberSelect={onFamilyMemberSelect}
                        onInviteChild={onInviteChild}
                    />
                </SocialSelectorsContainer>
            )}
            <ContentContainer>
                {showInviteChildScreen ? (
                    <InviteChild jossoId={user.jossoSessionId} />
                ) : screenType !== "TOPICS" && selectedSubject ? (
                    <SubjectReport
                        user={user}
                        member={selectedFamilyMember}
                        hasPremium={!!(selectedFamilyMember ? memberHasPremium : userHasPremium)}
                        isStrictMode={isStrictMode}
                        userPreferences={userPreferences}
                        selectedSubject={selectedSubject}
                        onSubjectSelect={onSubjectSelect}
                        isOwnSubject={isFamilyView ? selectedFamilyMember?.userId === user.userDnsId : true}
                        screenType={screenType}
                        changeScreen={changeScreen}
                    />
                ) : (
                    <ReportTopicsSelection
                        user={user}
                        member={selectedFamilyMember}
                        subjects={subjects}
                        selectSubject={onSubjectSelect}
                        onAddContent={onAddContent}
                    />
                )}
            </ContentContainer>
        </ReportsContainer>
    );
};

export default Reports;
