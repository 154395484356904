//LIBRARIES
import { cloneDeep } from "lodash";
import { call, delay, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import Cookie from "universal-cookie";

//NETWORKING
import {
    addUserGameStar,
    changeEmail,
    getUserFamilyMembers,
    getUserGameStars,
    getUserGroupsList,
    getUserMetadata,
    getUserPreferences,
    jossoCached,
    postChildrenPreferences,
    postUserPreferences,
    removeUserGameStar,
    resendPassword,
    setUserMetadata,
} from "../../networking/user";
import { synchronize, synchronizeAsync } from "../../networking/synchronization";

//REDUX
import { actions, selectors as userSelectors, selectors } from "./userSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { actions as themeActions, selectors as themeSelectors } from "../theme/themeSlice";
import { actions as subjectsActions } from "../subjects/subjectsSlice";
import { actions as goalsActions } from "../goals/goalsSlice";
import { actions as notificationActions } from "../notifications/notificationsSlice";
import { actions as avatarsActions } from "../avatars/avatarsSlice";
import { actions as responseActions } from "../response/responseSlice";
import { actions as warningsAction } from "../warnings/warningsSlice";

//TYPES
import { FamilyMember, UserGroupsData, User, UserPreferences, IUserStars } from "p6m-user";
import { IResponse } from "p6m-response";
import {
    ChangeEmailParamsType,
    ChildrenSettingsParams,
    ResendPasswordParamsType,
    SaveUserPreferencesParams,
    UserFamilyMembersResponse,
    UserPreferencesResponse,
} from "p6m-networking";
import { SynchronisationReplyContentType, UpdateOperationType } from "p6m-synchronisation";
import { ResponseMessageKeys } from "../../components/connected/response";
import { Theme } from "../../themes/constants";

//UTILS
import { REHYDRATE } from "redux-persist/lib/constants";
import { convertMetadataValuesToBoolean, deconstructUserMetaDataFromAPIResponse } from "../../helpers/User";
import { logEvent } from "../../logging/Logger";
import ResponseConstants from "../../constants/ResponseConstants";
import isTabActive from "../../helpers/ActiveTab";
import { amplitudeLoadAndInit } from "../../helpers/AmplitudeLoadAndInit";
import { mouseFlowLoadAndInit } from "../../helpers/mouseFlowLoadAndInit";
import { survicateLoadAndInit } from "../../helpers/survicateLoadAndInit";

function* initMouseFlowOnRehydrate() {
    const user: User = yield select(userSelectors.user);
    const preferences: UserPreferences = yield select(userSelectors.userPreferences);

    if (!user.jossoSessionId || !preferences.lang) return;

    const { roles = [], email = "", firstName = "", userDnsId = "", gdprStatus = "", isFirstWebLogin = false } = user;

    mouseFlowLoadAndInit({
        roles,
        email,
        firstName,
        userDnsId,
        gdprStatus,
        isFirstWebLogin,
    });
}

function* initSurvicateOnRehydrate() {
    const user: User = yield select(userSelectors.user);
    const preferences: UserPreferences = yield select(userSelectors.userPreferences);

    if (!user.jossoSessionId || !preferences.lang) return;

    const { roles = [], email = "", firstName = "", userDnsId = "", gdprStatus = "", isFirstWebLogin = false } = user;

    survicateLoadAndInit({
        roles,
        email,
        firstName,
        userDnsId,
        gdprStatus,
        isFirstWebLogin,
    });
}

function* initAmpliOnRehydrate() {
    const user: User = yield select(userSelectors.user);
    const preferences: UserPreferences = yield select(userSelectors.userPreferences);
    const userTheme: Theme = yield select(themeSelectors.themeName);

    if (!user.jossoSessionId || !preferences.lang) return;

    const { roles = [], email = "", firstName = "", userDnsId = "", gdprStatus = "", isFirstWebLogin = false } = user;

    amplitudeLoadAndInit(
        {
            roles,
            email,
            firstName,
            userDnsId,
            gdprStatus,
            isFirstWebLogin,
        },
        preferences,
        userTheme
    );
}

function* getUserMetadataAsync() {
    yield put(appStatusActions.setLoading(true));
    try {
        const userMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));
        const transformedUserMetaDataForFrontend = convertMetadataValuesToBoolean(userMetaDataFromDB);
        yield put(actions.setUserMetadata(transformedUserMetaDataForFrontend));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* setVisitedReactAppInMetaDataAsync({ payload }: PayloadAction<boolean>) {
    yield put(appStatusActions.setLoading(true));
    try {
        //get initialMetaData
        const initialUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));

        //update initialMetaData
        const updatedUserMetaDataToSend = {
            userData: { ...initialUserMetaDataFromDB, visitedReactWebApp: payload ? 1 : 0 },
        };

        //send updated metaData
        yield call(setUserMetadata, updatedUserMetaDataToSend);

        //since postRequest doesn't return updated metaData object, we need to query getMetaData again
        //(should now contain updated value) => TODO: Ask backend to return updated metaData on setMetaData!
        const updatedUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));
        const transformedUserMetaDataForFrontend = convertMetadataValuesToBoolean(updatedUserMetaDataFromDB);

        yield put(actions.setUserMetadata(transformedUserMetaDataForFrontend));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* setSeenPhaseModalInMetaDataAsync({ payload }: PayloadAction<number>) {
    yield put(appStatusActions.setLoading(true));
    try {
        //get initialMetaData
        const initialUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));

        //update initialMetaData
        const updatedUserMetaDataToSend = {
            userData: {
                ...initialUserMetaDataFromDB,
                phasesShownData: initialUserMetaDataFromDB.phasesShownData
                    ? { ...initialUserMetaDataFromDB.phasesShownData, [payload]: 1 }
                    : { [payload]: 1 },
            },
        };

        //send updated metaData
        yield call(setUserMetadata, updatedUserMetaDataToSend);

        //since postRequest doesn't return updated metaData object, we need to query getMetaData again
        //(should now contain updated value) => TODO: Ask backend to return updated metaData on setMetaData!
        const updatedUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));
        const transformedUserMetaDataForFrontend = convertMetadataValuesToBoolean(updatedUserMetaDataFromDB);

        yield put(actions.setUserMetadata(transformedUserMetaDataForFrontend));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* setEnableGrammarTutorPromotionInMetaDataAsync({ payload }: PayloadAction<boolean>) {
    yield put(appStatusActions.setLoading(true));
    try {
        //get initialMetaData
        const initialUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));

        //update initialMetaData
        const updatedUserMetaDataToSend = {
            userData: { ...initialUserMetaDataFromDB, enableGrammarTutorPromotion: payload ? 1 : 0 },
        };

        //send updated metaData
        yield call(setUserMetadata, updatedUserMetaDataToSend);

        //since postRequest doesn't return updated metaData object, we need to query getMetaData again
        //(should now contain updated value) => TODO: Ask backend to return updated metaData on setMetaData!
        const updatedUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));
        const transformedUserMetaDataForFrontend = convertMetadataValuesToBoolean(updatedUserMetaDataFromDB);

        yield put(actions.setUserMetadata(transformedUserMetaDataForFrontend));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* setLastTimeGDPRModalCheckedAsync({ payload }: PayloadAction<Date>) {
    yield put(appStatusActions.setLoading(true));
    try {
        //get initialMetaData
        const initialUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));

        const year = `${payload.getFullYear()}`;
        const shortenedDate = payload.toString().split(year)[0] + year;
        //update initialMetaData
        const updatedUserMetaDataToSend = {
            userData: { ...initialUserMetaDataFromDB, lastTimeGDPRChecked: shortenedDate },
        };

        //send updated metaData
        yield call(setUserMetadata, updatedUserMetaDataToSend);

        //since postRequest doesn't return updated metaData object, we need to query getMetaData again
        //(should now contain updated value) => TODO: Ask backend to return updated metaData on setMetaData!
        const updatedUserMetaDataFromDB = deconstructUserMetaDataFromAPIResponse(yield call(getUserMetadata));
        const transformedUserMetaDataForFrontend = convertMetadataValuesToBoolean(updatedUserMetaDataFromDB);

        yield put(actions.setUserMetadata(transformedUserMetaDataForFrontend));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* requestChangeEmailAsync(action: PayloadAction<ChangeEmailParamsType>) {
    yield put(appStatusActions.setLoading(true));

    try {
        const response: AxiosResponse = yield call(changeEmail, action.payload);
        const {
            data: { replyContent },
        } = response;

        if (replyContent === "Success") {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.SUCCESS,
                    responseMessage: ResponseMessageKeys.CONFIRM_EMAIL_CHANGE,
                })
            );
        } else {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.ERROR_REQUESTING_EMAIL_CHANGE,
                })
            );
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* requestConfirmEmailAsync(action: PayloadAction<ResendPasswordParamsType>) {
    yield put(appStatusActions.setLoading(true));

    try {
        const response: AxiosResponse = yield call(resendPassword, action.payload);
        const {
            data: { replyContent },
        } = response;

        if (replyContent === "Success") {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.SUCCESS,
                    responseMessage: ResponseMessageKeys.CONFIRMATION_EMAIL_HAS_BEEN_RESENT,
                })
            );
        } else {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.ERROR_REQUESTING_NEW_CONFIRMATION_EMAIL,
                })
            );
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* resendPasswordAsync(action: PayloadAction<ResendPasswordParamsType>) {
    yield put(appStatusActions.setLoading(true));

    try {
        const response: AxiosResponse<string> = yield call(resendPassword, action.payload);
        const { data } = response;

        if (data === "success") {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.SUCCESS,
                    responseMessage: ResponseMessageKeys.NEW_PASSWORD_HAS_BEEN_SENT,
                })
            );
        } else {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.ERROR_SENDING_NEW_PASSWORD,
                })
            );
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* logoutUserWithoutPermissionAsync() {
    yield put(
        responseActions.showResponse({
            type: ResponseConstants.ERROR,
            responseMessage: ResponseMessageKeys.LOGIN_HAS_TIMED_OUT,
        })
    );
    yield put(actions.logout());
}

function addRemoveUserStarAsync(add: boolean) {
    return function* () {
        const { starsCount } = yield select(selectors.userStars);
        if ((add && starsCount >= 3) || (!add && starsCount <= 0)) return;

        yield put(appStatusActions.setLoading(true));

        try {
            const fetchAction = add ? addUserGameStar : removeUserGameStar;

            const response: AxiosResponse = yield call(fetchAction);
            const {
                data: { replyContent },
            } = response;
            yield put(actions.setUserStars(replyContent));
        } catch (e) {
            console.log(e);
        } finally {
            yield put(appStatusActions.setLoading(false));
        }
    };
}

function* loadUserGroups() {
    const userId = yield select(userSelectors.userId);
    try {
        const userGroupsResponse: AxiosResponse<{
            replyContent: UserGroupsData;
        }> = yield call(getUserGroupsList, userId);
        const {
            data: { replyContent: data },
        } = userGroupsResponse;
        yield put(actions.setUserGroups(data));
    } catch (e) {
        console.log(e);
    }
}

function* refreshPreferencesData() {
    yield put(appStatusActions.setLoading(true));
    try {
        try {
            const userPreferencesResponse: AxiosResponse<UserPreferencesResponse> = yield call(getUserPreferences);
            const { data } = userPreferencesResponse;
            const contentToShow = data.replyContent && cloneDeep(data.replyContent);

            if (contentToShow?.parentSettings2) {
                contentToShow["parentSettings"] = cloneDeep(contentToShow.parentSettings2);
            }

            if (contentToShow) {
                yield put(actions.setUserPreferences(contentToShow));
            }
        } catch (e) {
            console.log(e);
        }

        try {
            yield loadUserGroups();
            const parentAdminResponse: AxiosResponse<UserFamilyMembersResponse> = yield call(getUserFamilyMembers);
            const { data } = parentAdminResponse;

            let users: Array<FamilyMember> = [];
            if (data.replyContent && data.replyContent.users) {
                data.replyContent.users.forEach((u) => {
                    let member: FamilyMember = { ...u.groupMember };
                    member.preferences = u.preferences;
                    if (u.preferences.parentSettings2) {
                        member.preferences.parentSettings = u.preferences.parentSettings2;
                    }
                    users.push(member);
                });
            }

            yield put(actions.setUserFamilyMembers(users));
        } catch (e) {
            console.log(e);
        }
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* updateSubjectOnTTHChange({ payload }: PayloadAction<SaveUserPreferencesParams>) {
    const {
        newPreferences: { tthLimit },
        oldPreferences: { tthLimit: oldTthLimit },
    } = payload;
    if (tthLimit === oldTthLimit) return;

    yield put(subjectsActions.loadUserSubjectsData());
    yield put(subjectsActions.loadFamilySubjects());
}

function* saveUserPreferences(action: PayloadAction<SaveUserPreferencesParams>) {
    // save user preferences!
    yield put(actions.setUserPreferences(action.payload.newPreferences));

    try {
        const userGroupsResponse: AxiosResponse<IResponse<string>> = yield call(
            postUserPreferences,
            action.payload.newPreferences
        );
        const { data } = userGroupsResponse;

        if (data.replyContent !== "User settings updated") {
            yield put(actions.setUserPreferences(action.payload.oldPreferences));

            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.ERROR_SAVING_NEW_PREFERENCES,
                })
            );
            return;
        }
    } catch (e) {
        console.log(e);
    }

    yield updateSubjectOnTTHChange(action);
}

function* saveChildrenPreferences(action: PayloadAction<ChildrenSettingsParams>) {
    // save user preferences!
    yield put(
        actions.updateChildPreferences({
            ...action.payload.newProperties,
            userId: action.payload.userId,
        })
    );

    try {
        const requestObject = {
            ...action.payload.newProperties,
            userId: action.payload.userId,
        };

        const userGroupsResponse: AxiosResponse<IResponse<string>> = yield call(postChildrenPreferences, requestObject);
        const { data } = userGroupsResponse;

        if (data.replyContent !== "Saved") {
            yield put(
                actions.updateChildPreferences({
                    ...action.payload.oldProperties,
                    userId: action.payload.userId,
                })
            );

            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.ERROR_SAVING_NEW_PREFERENCES,
                })
            );
        }
    } catch (e) {
        console.log(e);
    }
}
function* saveThemeSaga() {
    // save selected user theme
    const userId: string = yield select(selectors.userId);
    yield put(themeActions.saveUserTheme(userId));
}

function* logoutSaga() {
    // save selected user theme
    const userId: string = yield select(selectors.userId);
    const jossoId: string = yield select(selectors.jossoId);
    const logoutUrl: string | undefined = yield select(selectors.partnerLogOutUrl);

    yield put(themeActions.saveUserTheme(userId));
    yield put(themeActions.setTheme(Theme.MAIN));
    yield put(themeActions.setThemeName(Theme.MAIN));

    // save future invalid session id
    const cookie = new Cookie();
    let invalidSessions: string = cookie.get("invalidSessions") || "";
    invalidSessions += jossoId + ",";
    cookie.set("invalidSessions", invalidSessions, { maxAge: 86400 });

    // unset redux data
    yield put(goalsActions.clear());
    yield put(subjectsActions.clearSubjectSlice());
    yield put(actions.clearUserStars());
    yield put(avatarsActions.unsetAvatars());
    yield put(warningsAction.clearWarnings());

    // finally log out
    yield put(actions.addLogoutIframe());
    yield put(actions.unsetUser());
    yield put(actions.endSynchronization());

    if (logoutUrl) {
        window.location.replace(logoutUrl);
    }
}

/**
 * Synchronization with changes from other platforms
 */

function* rehydrate(action: any) {
    if (
        action.key === "user" &&
        action.payload &&
        action.payload.user &&
        action.payload.user.jossoSessionId &&
        action.payload.user.jossoSessionId !== ""
    ) {
        yield put(actions.startSynchronization());
    }

    if (action.key === "theme") {
        yield initAmpliOnRehydrate();
        yield initMouseFlowOnRehydrate();
        yield initSurvicateOnRehydrate();
    }
}

function* jossoUpdateSaga() {
    try {
        const {
            data: { replyContent },
        } = yield call(jossoCached);
        if (replyContent && replyContent.jossoSessionId) {
            // updated user data and roles
            yield put(actions.setJossoSessionId(replyContent.jossoSessionId));
            yield put(actions.setUserEmail(replyContent.email));
            yield put(actions.setUserRoles(replyContent.roles));
            const names = {
                displayName: replyContent.displayName,
                firstName: replyContent.firstName,
                lastName: replyContent.lastName,
            };
            yield put(actions.setUserNames(names));

            if (replyContent.partnerName) {
                const { partnerName, partnerLogoUrl, partnerLogOutUrl } = replyContent;
                yield put(
                    actions.setUserPartnerInformation({
                        partnerName,
                        partnerLogoUrl,
                        partnerLogOutUrl,
                    })
                );
            }
        }
    } catch (e) {
        // fail update silently
        logEvent("josso reload failed");
        console.log(e);
    }
}

function* synchronizeAsyncRace() {
    yield race({
        task: call(synchronizeAsyncSaga),
        cancel: take(actions.endSynchronization.type),
    });
}

function* getUserGameStarsAsync() {
    yield put(appStatusActions.setLoading(true));

    try {
        const response: AxiosResponse<IResponse<IUserStars>> = yield call(getUserGameStars);
        const {
            data: { replyContent },
        } = response;

        yield put(actions.setUserStars(replyContent));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* synchronizeAsyncSaga() {
    const initialData = yield call(synchronize);
    let revision = initialData.data?.replyContent?.currentRevision || "";
    while (true) {
        try {
            if (!isTabActive()) {
                yield delay(60 * 1000); // delay 60s if tab or window isn't active
                continue;
            }
            yield delay(1000); // delay anyway
            const response = yield call(synchronizeAsync, revision);

            if (response) {
                const responseCode = response.data.httpCode;
                if (responseCode >= 200 && responseCode < 300) {
                    yield put(appStatusActions.setMaintenance(false));
                    if (response.data?.replyContent) {
                        const replyContent: SynchronisationReplyContentType = response.data.replyContent;

                        if (replyContent) {
                            // set new revision
                            if (replyContent.currentRevision) {
                                revision = replyContent.currentRevision;
                            }

                            // trigger updates conditionally (no forEach, because yield needs to be in generator body)
                            type payloadType = {
                                updatedType: UpdateOperationType;
                                roles: string[];
                            };
                            type updateType = {
                                type: string;
                                payload?: string | payloadType;
                            };
                            let allUpdates: updateType[] = [];

                            for (let i = 0; i < replyContent.changedElements.length; i++) {
                                const changedElement = replyContent.changedElements[i];
                                switch (changedElement.contentType) {
                                    case "Preferences":
                                        allUpdates.push({
                                            type: "pref",
                                            payload: changedElement.ownerId,
                                        });
                                        break;
                                    case "UserCardsTest":
                                    case "SubjectContent": {
                                        // Subject changes that can influence the handling of tests (we check if the id matches the subject id of the test)
                                        allUpdates.push({ type: "subject", payload: changedElement.subjectId });
                                        break;
                                    }
                                    case "SubjectMetadata":
                                    case "CardHistoryEntry": // TODO what is to be done here?
                                    case "CardLearningProgress2":
                                    case "CardMetadata2":
                                    case "SubjectStatistics":
                                        // subject updates without side effects
                                        allUpdates.push({ type: "subject" });
                                        break;
                                    case "UnitContent":
                                    case "CardContent":
                                    case "CardAnnotationData":
                                        allUpdates.push({
                                            type: "subjectUnits",
                                            payload: changedElement.subjectId,
                                        });
                                        break;
                                    case "UserRole":
                                        allUpdates.push({
                                            type: "userRole",
                                            payload: {
                                                updatedType: changedElement.operationType,
                                                roles: changedElement.objectIds.map((o) => o.objectId),
                                            },
                                        });
                                        break;
                                    case "UserGoals":
                                        allUpdates.push({ type: "fetchGoals" });
                                        break;
                                    case "UserStarsUpdated":
                                        allUpdates.push({ type: "fetchUserStars" });
                                        break;
                                    case "NotificationEvent":
                                        allUpdates.push({
                                            type: "loadNotifications",
                                        });
                                        break;
                                    case "JossoInfoUpdated":
                                        allUpdates.push({ type: "josso" });
                                        break;
                                    default:
                                        break;
                                }
                            }

                            const updateIsEqual = (a: updateType, b: updateType) => {
                                if (a.type === b.type) {
                                    // check equality of payloads
                                    if (!a.payload) {
                                        return !b.payload;
                                    }
                                    if (!b.payload) {
                                        return !a.payload;
                                    }

                                    if (typeof a.payload === "string" || typeof b.payload === "string") {
                                        return a.payload === b.payload;
                                    }
                                    const aRoles = a.payload.roles.sort();
                                    const bRoles = b.payload.roles.sort();
                                    return (
                                        a.payload.updatedType === b.payload.updatedType &&
                                        aRoles.length === bRoles.length &&
                                        aRoles.every((arole, index) => bRoles[index] === arole)
                                    );
                                }
                                // different types are always different
                                return false;
                            };
                            let neededUpdates: updateType[] = [];
                            allUpdates.forEach((allUpdate) => {
                                if (!neededUpdates.some((neededUpdate) => updateIsEqual(neededUpdate, allUpdate))) {
                                    neededUpdates.push(allUpdate);
                                }
                            });

                            for (let n = 0; n < neededUpdates.length; n++) {
                                switch (neededUpdates[n].type) {
                                    case "pref": {
                                        const prefPayload = neededUpdates[n].payload;
                                        if (typeof prefPayload === "string") {
                                            yield put(actions.refreshPreferencesData(prefPayload));
                                        }
                                        break;
                                    }
                                    case "subject": {
                                        const subjectPayload = neededUpdates[n].payload;
                                        const testSubjectId =
                                            typeof subjectPayload === "string" ? subjectPayload : undefined;
                                        yield put(
                                            subjectsActions.loadUserSubjectsData({
                                                subjectIdForTestModalUpdate: testSubjectId,
                                            })
                                        );
                                        break;
                                    }
                                    case "subjectUnits": {
                                        const subjectUnitsPayload = neededUpdates[n].payload;
                                        if (typeof subjectUnitsPayload === "string") {
                                            yield put(subjectsActions.loadSubjectUnits(subjectUnitsPayload));
                                        }
                                        break;
                                    }
                                    case "userRole": {
                                        const userRolePayload = neededUpdates[n].payload;
                                        if (userRolePayload && typeof userRolePayload !== "string") {
                                            yield put(actions.updateUserRoles(userRolePayload));
                                        }
                                        break;
                                    }
                                    case "fetchGoals":
                                        yield put(goalsActions.fetchGoals());
                                        break;
                                    case "fetchUserStars":
                                        yield put(actions.fetchUserStars());
                                        break;
                                    case "loadNotifications":
                                        yield put(notificationActions.loadNotifications());
                                        break;
                                    case "josso":
                                        yield put(actions.jossoUpdate());
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                    }
                } else {
                    // handle other responses to prevent unwanted logouts
                    yield delay(60 * 1000);
                    if (response.data.httpCode === 500) {
                        yield put(appStatusActions.setMaintenance(true));
                    }
                    if (response.data.httpCode === 401) {
                        yield put(actions.logout());
                        window.location.replace(window.location.origin);
                    } else {
                        yield put(appStatusActions.setMaintenance(true));
                    }
                }
            }
        } catch (e) {
            yield put(appStatusActions.setMaintenance(true));
            // if the synchronisation request fails
            // log error
            const noLogErrorMessages = ["Network Error"];
            if (noLogErrorMessages.indexOf(e.message) === -1) {
                logEvent("background synchronisation failed with: " + e.message);
            }
            // restart with delay
            yield delay(1000);
        }
    }
}

export function* userSaga() {
    yield takeEvery(actions.fetchUserStars.type, getUserGameStarsAsync);
    yield takeEvery(actions.loadUserMetadata.type, getUserMetadataAsync);
    yield takeEvery(actions.setLastTimeGDPRModalChecked.type, setLastTimeGDPRModalCheckedAsync);
    yield takeEvery(actions.setVisitedReactAppInMetaData.type, setVisitedReactAppInMetaDataAsync);
    yield takeEvery(actions.setSeenPhaseModalInMetaData.type, setSeenPhaseModalInMetaDataAsync);
    yield takeEvery(
        actions.setEnableGrammarTutorPromotionInMetaData.type,
        setEnableGrammarTutorPromotionInMetaDataAsync
    );
    yield takeEvery(actions.changeEmail.type, requestChangeEmailAsync);
    yield takeEvery(actions.confirmEmail.type, requestConfirmEmailAsync);
    yield takeEvery(actions.resendPassword.type, resendPasswordAsync);
    yield takeEvery(actions.logoutUserWithoutPermission.type, logoutUserWithoutPermissionAsync);
    yield takeEvery(actions.loadUserGroups.type, loadUserGroups);
    yield takeEvery(actions.fetchAddUserStar.type, addRemoveUserStarAsync(true));
    yield takeEvery(actions.fetchRemoveUserStar.type, addRemoveUserStarAsync(false));
    yield takeEvery(actions.refreshPreferencesData.type, refreshPreferencesData);
    yield takeEvery(actions.saveUserPreferences.type, saveUserPreferences);
    yield takeEvery(actions.saveChildrenPreferences.type, saveChildrenPreferences);
    yield takeEvery(actions.logout.type, logoutSaga);
    yield takeEvery(actions.saveUserTheme.type, saveThemeSaga);
    yield takeLatest(actions.startSynchronization.type, synchronizeAsyncRace);
    yield takeLatest(actions.jossoUpdate.type, jossoUpdateSaga);
    yield takeEvery(REHYDRATE, rehydrate);
}
