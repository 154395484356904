import styled from "styled-components";
import { Link } from "react-router-dom";

export const Text = styled.p`
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
    color: ${(props) => props.theme.colors.textOnBackground};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        font-weight: bold;
        margin: 0;
        text-align: left;
        color: ${(props) => props.theme.sidebar.mobileColor};
    }
`;

export const StyledText = styled.p`
    display: block;
    text-align: center;
    font-size: 14px;
    margin-bottom: 15px;
    color: #666666;
    padding-bottom: 12px;
`;

export const StyledLinkWrapper = styled.div`
    position: relative;
    height: 70px;
    width: 100px;
    border-radius: 0.5rem;
    margin-bottom: 0.5em;

    &:hover {
        background: ${(props) => props.theme.sidebar.hoverBackgroundColor};
    }

    &.active {
        background: ${(props) => props.theme.colors.backgroundOnThemeImage};

        p {
            color: ${(props) => props.theme.colors.textOnThemeImage};
            text-decoration: underline;
        }
    }

    &.disabled {
        cursor: not-allowed;

        p {
            color: ${(props) => props.theme.colors.text + "50"};
        }
    }

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

export const StyledLinkContainer = styled(Link)`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    line-height: 1;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const StyledDisabledLinkContainer = styled.div`
    width: 100%;
    height: 100%;
    cursor: not-allowed;
    text-decoration: none;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const StyledExternalLinkContainer = styled.a`
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.textOnBackground};

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }
`;
