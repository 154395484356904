import styled from "styled-components";

export const StyledButton = styled.button`
    text-transform: none;
    height: auto;
    border: none;
    font-size: 24px;
    font-weight: 600;
    width: 100%;
    margin-top: 10px;
    font-family: ${(props) => props.theme.base.fontFamily};
    background-color: ${(props) => props.color || props.theme.buttons.primary.backgroundColor};
    padding: 15px 0;
    border-radius: 0.5rem;
    cursor: pointer;
`;

export const StyledButtonText = styled.span`
    display: inline-block;
    text-align: center;
    color: ${(props) => props.theme.buttons.primary.textColor};
`;
