// DEPS
import React, { ReactNode, useContext } from "react";
import { CoverImage } from "p6-react-shared";
import { T, useT } from "@transifex/react";

// CONTEXT
import { LanguageCodes, LanguagesContext } from "../../../context/languages";

// STYLES
import {
    CardCount,
    DeleteEntry,
    FlagImage,
    StyledPhaseSixIcon,
    SubjectHeader,
    SubjectSelection,
    Title,
    Container,
} from "./styles";

interface LibrarySubjectEntryProps {
    onDelete?: (subjectId: string) => void;
    onClick?: () => void;
    subjectId: string;
    title: string;
    totalCards: number;
    primaryLang: LanguageCodes | "";
    secondaryLang: LanguageCodes | "";
    coverImg: string;
    children?: ReactNode;
}

export const LibrarySubjectEntry = ({
    subjectId,
    title,
    totalCards,
    primaryLang,
    secondaryLang,
    onDelete,
    onClick,
    coverImg,
    children,
}: LibrarySubjectEntryProps) => {
    const { getFlagSource, getLanguageTranslation } = useContext(LanguagesContext);
    const t = useT();

    return (
        <Container>
            <SubjectHeader>
                <SubjectSelection onClick={onClick}>
                    <CoverImage
                        imageId={coverImg}
                        size="small"
                        alt=""
                    />
                    <Title>{title}</Title>
                    <CardCount>
                        <T
                            _str="{count} cards"
                            count={totalCards}
                        />
                    </CardCount>
                    <FlagImage
                        src={getFlagSource(primaryLang)}
                        alt={`${t("Source language", {
                            _tags: "subjectItem, languageImage, altText",
                        })}: ${getLanguageTranslation(primaryLang)}`}
                    />
                    <FlagImage
                        src={getFlagSource(secondaryLang)}
                        alt={`${t("Target language", {
                            _tags: "subjectItem, languageImage, altText",
                        })}: ${getLanguageTranslation(secondaryLang)}`}
                    />
                </SubjectSelection>
                {!!onDelete && (
                    <DeleteEntry>
                        <StyledPhaseSixIcon
                            name="trash"
                            onClick={() => onDelete(subjectId)}
                        />
                    </DeleteEntry>
                )}
            </SubjectHeader>
            {children}
        </Container>
    );
};
