import styled from "styled-components";

export const Container = styled.div`
    padding: ${(props) => props.theme.base.spacingSmall};
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: ${(props) => props.theme.base.spacing};
`;
export const Header = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
`;
export const Title = styled.span``;
export const List = styled.div``;
export const FamilyMemberEntry = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacingTiny};
    padding: ${(props) => props.theme.base.spacingSmall};
    background: ${(props) =>
        props.color && props.color === "primary"
            ? props.theme.colors.primary + "55"
            : props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    color: ${(props) =>
        props.color && props.color === "primary" ? props.theme.colors.primary : props.theme.colors.text};
`;
export const FamilyMemberTitle = styled.div`
    margin-bottom: ${(props) => props.theme.base.spacingTiny};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`;
export const AddFamilyMember = styled.div`
    margin-top: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;
`;
export const AddFamilyMemberText = styled.span``;
