// REACT
import React from "react";
import { useT } from "@transifex/react";

// STYLED COMPONENTS
import { PremiumInfoText, PremiumInfoTextWrapper, PremiumTextWrapper } from "./styles";

export interface UserPremiumInformationProps {
    userHasPremium: boolean;
    isTrialAccount?: boolean;
    premiumExpirationTimestamp?: number;
}

function sameDay(currentDateTime: number, premiumExpirationDateTime: number): boolean {
    const currentDate = new Date(currentDateTime);
    const expirationDate = new Date(premiumExpirationDateTime);
    return (
        currentDate.getFullYear() === expirationDate.getFullYear() &&
        currentDate.getMonth() === expirationDate.getMonth() &&
        currentDate.getDate() === expirationDate.getDate()
    );
}

// returns number of days left in premium / -1 if premium has expired
function getLeftPremiumDays(premiumExpirationDate: number): number {
    const currentDateTimestamp = new Date().getTime();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const differenceInMilliseconds = premiumExpirationDate - currentDateTimestamp;

    // if the actual time of the trial already passed, check if it's still the same day anyway
    if (differenceInMilliseconds < 0) {
        return sameDay(currentDateTimestamp, premiumExpirationDate) ? 0 : -1;
    }

    return Math.ceil(differenceInMilliseconds / oneDayInMilliseconds);
}

const UserPremiumInformation: React.FC<UserPremiumInformationProps> = (props) => {
    const t = useT();

    const t_premiumName = t("Premium", { _tags: "premiumInfo" });
    const t_freeAccount = t("Free account", { _tags: "premiumInfo" });
    const t_endsTomorrow = t("ends tomorrow!", { _tags: "premiumInfo" });
    const t_endsToday = t("ends today", { _tags: "premiumInfo" });
    const t_some = t("some", { _tags: "premiumInfo,count,placeholder" });

    // not premium = free and that's all
    if (!props.userHasPremium) {
        return (
            <PremiumInfoTextWrapper id="user-premium">
                <PremiumInfoText>{t_freeAccount}</PremiumInfoText>
            </PremiumInfoTextWrapper>
        );
    }

    const isTrialWithExpirationDate = !!props.isTrialAccount && !!props.premiumExpirationTimestamp;

    const leftDays: string = isTrialWithExpirationDate
        ? getLeftPremiumDays(props.premiumExpirationTimestamp!).toString()
        : t_some;
    const t_daysLeft = t("{daysLeft} days left", { daysLeft: leftDays, _tags: "premiumInfo" });

    const premiumExtraInfo = () => {
        switch (leftDays) {
            case "1":
                return <PremiumInfoText>{t_endsTomorrow}</PremiumInfoText>;
            case "0":
                return <PremiumInfoText>{t_endsToday}</PremiumInfoText>;
            default:
                return <PremiumInfoText>{t_daysLeft}</PremiumInfoText>;
        }
    };

    return (
        <PremiumInfoTextWrapper id="user-premium">
            <PremiumTextWrapper>{t_premiumName + " "}</PremiumTextWrapper>
            {isTrialWithExpirationDate ? premiumExtraInfo() : null}
        </PremiumInfoTextWrapper>
    );
};

export default UserPremiumInformation;
