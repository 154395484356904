import { LoginResponseReplyContent } from "p6m-response";

type UserDataProp = Pick<
    LoginResponseReplyContent,
    "roles" | "email" | "firstName" | "userDnsId" | "gdprStatus" | "isFirstWebLogin"
>;

const scriptId = "mouseflow-script";

const deleteMouseFlowScript = () => {
    // Remove the script when isMouseflowActive is false (e.g., on logout)
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
        existingScript.remove();
    }
};

export const mouseFlowLoadAndInit = (userData: UserDataProp) => {
    const { gdprStatus = "" } = userData;

    const analyzeData = gdprStatus.includes("analytics");

    if (analyzeData) {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.type = "text/javascript";
            script.defer = true;
            script.src = "//cdn.mouseflow.com/projects/eab07262-c246-4c61-a61f-455b2217ae6a.js";
            script.setAttribute("data-mouseflow", "true"); // Mark it to prevent duplicates

            document.head.appendChild(script);
        } else {
            deleteMouseFlowScript();
            mouseFlowLoadAndInit(userData);
        }
    } else {
        deleteMouseFlowScript();
    }
};
