//DEPENDENCIES
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";
import { debounce } from "lodash";
import FocusLock from "react-focus-lock";

//UTILS
import SpecialCharacterContextWrapper from "../../context/specialCharacterSelection";

//HOOKS
import { useEditDropdown } from "../../hooks/dropdown/useEditDropdown";

//REDUX
import { selectors as addCardSelectors, actions as addCardActions } from "../../redux/add/addSlice";

//COMPONENTS
import { CardEditor, Props as EditorProps } from "../../components/connected/addCards/cardEditor";
import DateBox from "../../components/basic/dateBox/DateBox";
import { LanguageSelect } from "../../components/connected/addCards/languageSelect";
import { DictionaryButton } from "../../components/connected/addCards/dictionaryButton";
import { Row, Col, Container } from "../../components/basic/grid";
import { AddCardsButtons } from "../../components/connected/addCards/addCardsButtons";
import { ContentModal } from "../../components/connected/addCards/contentModal";
import DropdownSelection from "../../components/basic/dropdownSelection/DropdownSelection";

//STYLES
import { ContentContainer, DictionaryContainer, FlagsContainer, FlagsCol, SelectorsRow } from "./styles";

type Props = {
    getQuestionRef: EditorProps["getRef"];
    getAnswerRef: EditorProps["getRef"];
    focusQuestion?: boolean;
    onDropdownToggle?: (state: boolean) => void;
};

const answerEditorPositions: EditorProps["positions"] = {
    audio: ["top", "right"],
};

const Add = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const dispatch = useDispatch();

    const { getQuestionRef, getAnswerRef, focusQuestion } = props;
    const [hasAnswerAnnotationChanged, setHasAnswerAnnotationChanged] = useState<boolean>(false);
    const [hasQuestionAnnotationChanged, setHasQuestionAnnotationChanged] = useState<boolean>(false);
    const t = useT();
    const t_questionPlaceholder = t("Gib hier die Frage ein", {});
    const t_answerPlaceholder = t("Gib hier die Antwort ein", {});

    const resetAnnotationChanges = () => {
        setHasQuestionAnnotationChanged(false);
        setHasAnswerAnnotationChanged(false);
    };

    const selectedSubjectId = useSelector(addCardSelectors.getAddCardsSubjectId);
    const selectedUnitId = useSelector(addCardSelectors.getAddCardsUnitId);

    const onAutoSelectSubject = useCallback(
        (id: string) => {
            dispatch(addCardActions.setCardSubjectId(id));
        },
        [dispatch]
    );

    const onAutoSelectUnit = useCallback(
        (id: string) => {
            dispatch(addCardActions.setCardUnitId(id));
        },
        [dispatch]
    );

    const {
        subjects: { dropdownSubjectLabel, dropdownSubjectItems, saveSubjectsEdit },
        units: { dropdownUnitLabel, dropdownUnitItems, saveUnitsEdit },
    } = useEditDropdown({
        subjectId: selectedSubjectId,
        unitId: selectedUnitId,
        onAutoSelectSubject,
        onAutoSelectUnit,
    });

    const debounceItem = debounce((type: "SUBJECT" | "UNIT", passedItemId: string) => {
        if (type === "SUBJECT") {
            dispatch(addCardActions.setCardSubjectId(passedItemId));
        } else if (type === "UNIT") {
            dispatch(addCardActions.setCardUnitId(passedItemId));
        }
    }, 500);

    const onDropdownItemSelect = useCallback(
        (type: "UNIT" | "SUBJECT", passedItemId: string) => {
            const isCurrentSubjectId = type === "SUBJECT" && passedItemId === selectedSubjectId;
            const isCurrentUnitId = type === "UNIT" && passedItemId === selectedUnitId;
            if (passedItemId && !isCurrentSubjectId && !isCurrentUnitId) {
                debounceItem(type, passedItemId);
            }
        },
        [dispatch, selectedSubjectId, selectedUnitId]
    );

    const allSelectedSubjectIdsToUse = useMemo(() => {
        return [selectedSubjectId];
    }, [selectedSubjectId]);

    const allSelectedUnitIdsToUse = useMemo(() => {
        return [selectedUnitId];
    }, [selectedUnitId]);

    const handleDropDownSelectSubject = useCallback(
        (newSubjectIds: string[]) => {
            onDropdownItemSelect("SUBJECT", newSubjectIds[0]);
        },
        [onDropdownItemSelect]
    );

    const handleDropDownSelectUnit = useCallback(
        (newUnitIds: string[]) => {
            onDropdownItemSelect("UNIT", newUnitIds[0]);
        },
        [onDropdownItemSelect]
    );

    return (
        <SpecialCharacterContextWrapper>
            <Container
                fullWidth
                ref={ref}
            >
                <FocusLock>
                    <Row>
                        <Col xs={12}>
                            <Row horizontal="between">
                                <Col
                                    xs={12}
                                    m="none"
                                >
                                    <SelectorsRow cols={{ xs: 1, m: 2 }}>
                                        <Col>
                                            <DropdownSelection
                                                label={dropdownSubjectLabel}
                                                options={dropdownSubjectItems}
                                                editable
                                                multiSelection={false}
                                                selectedIds={allSelectedSubjectIdsToUse}
                                                saveEdit={saveSubjectsEdit}
                                                onSelect={handleDropDownSelectSubject}
                                            />
                                        </Col>
                                        <Col>
                                            <DropdownSelection
                                                label={dropdownUnitLabel}
                                                options={dropdownUnitItems}
                                                editable
                                                multiSelection={false}
                                                selectedIds={allSelectedUnitIdsToUse}
                                                saveEdit={saveUnitsEdit}
                                                onSelect={handleDropDownSelectUnit}
                                            />
                                        </Col>
                                    </SelectorsRow>
                                </Col>
                                <Col
                                    xs={12}
                                    m="auto"
                                >
                                    <Row>
                                        <FlagsCol xs="auto">
                                            <FlagsContainer>
                                                <LanguageSelect type="primary" />
                                                <LanguageSelect type="secondary" />
                                            </FlagsContainer>
                                        </FlagsCol>
                                        <Col xs="auto">
                                            <DateBox />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <ContentContainer>
                                <CardEditor
                                    tabIndex={1}
                                    type="question"
                                    getRef={getQuestionRef}
                                    placeholder={t_questionPlaceholder}
                                    autoFocus={focusQuestion}
                                    setHasQuestionAnnotationChanged={setHasQuestionAnnotationChanged}
                                />
                                <DictionaryContainer>
                                    <DictionaryButton />
                                </DictionaryContainer>
                                <CardEditor
                                    tabIndex={2}
                                    type="answer"
                                    positions={answerEditorPositions}
                                    getRef={getAnswerRef}
                                    placeholder={t_answerPlaceholder}
                                    setHasAnswerAnnotationChanged={setHasAnswerAnnotationChanged}
                                />
                            </ContentContainer>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <AddCardsButtons
                                hasQuestionAnnotationChanged={hasQuestionAnnotationChanged}
                                hasAnswerAnnotationChanged={hasAnswerAnnotationChanged}
                                resetAnnotationChanges={resetAnnotationChanges}
                            />
                        </Col>
                    </Row>
                </FocusLock>
                <ContentModal />
            </Container>
        </SpecialCharacterContextWrapper>
    );
});

export default Add;
