// LIBRARIES
import { call, put, putResolve, select, take, takeEvery } from "redux-saga/effects";
import { RouteComponentProps } from "react-router-dom";
import { PayloadAction } from "@reduxjs/toolkit";
import Cookie from "universal-cookie";

// REDUX
import { actions as loginActions, UserCredentials } from "./loginSlice";
import { actions as userActions, selectors as userSelectors } from "../user/userSlice";
import { selectors as subjectsSelectors, actions as subjectActions } from "../subjects/subjectsSlice";
import { actions as goalsActions } from "../goals/goalsSlice";
import { actions as themeActions, selectors as themeSelectors } from "../theme/themeSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { actions as responseActions } from "../response/responseSlice";
import { actions as modalActions } from "../modal/modalSlice";
import { actions as notificationActions } from "../notifications/notificationsSlice";

// TYPES
import { JossoUpdateResponseType, LoginResponseReplyContent, LoginResponseType } from "p6m-response";
import { UserPreferences } from "p6m-user";
import { ResponseMessageKeys } from "../../components/connected/response";
import { Theme } from "../../themes/constants";

//COMPONENTS
import { GlobalModalView } from "../../helpers/Modal";

//UTILS
import { goToOldAppVersion } from "../../helpers/User";
import ResponseConstants from "../../constants/ResponseConstants";
import { amplitudeLoadAndInit } from "../../helpers/AmplitudeLoadAndInit";
import { SubjectData } from "p6m-subjects";
import { getShowWelcomeScreenData } from "../../helpers/GetShowWelcomeScreen";
import { mouseFlowLoadAndInit } from "../../helpers/mouseFlowLoadAndInit";
import { survicateLoadAndInit } from "../../helpers/survicateLoadAndInit";

// RouteComponentProps adds router history to props
export interface LoginDataType extends RouteComponentProps<any> {
    username: string;
    password: string;
    remember: boolean;
}

function generateUuid() {
    let d = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-mixed-operators
        return (c === "x" ? r : (r & 0x7) | 0x8).toString(16);
    });
    return uuid;
}

export function* loadUserPremiumExpiration(headers: {}) {
    const apiUri = `${process.env.REACT_APP_BACKEND_API_URL}userPremiumExpiration`;
    try {
        const response = yield call(() =>
            fetch(apiUri, {
                method: "GET",
                headers,
            })
        );

        const data = yield call([response, response.json]);

        if (response.ok && data.httpCode === 200 && data.replyContent) {
            yield put(userActions.setUserPremiumExpiration(data.replyContent));
            return true;
        } else {
            yield put(userActions.setUserPremiumExpiration({}));
            return false;
        }
    } catch (error) {
        return false;
    }
}

export function* loadUserPreferences(headers: {}) {
    const apiUri = `${process.env.REACT_APP_BACKEND_API_URL}preferences`;
    try {
        const response = yield call(() =>
            fetch(apiUri, {
                method: "GET",
                headers,
            })
        );

        const data = yield call([response, response.json]);

        if (response.ok && data.httpCode === 200 && data.replyContent) {
            yield put(userActions.setUserPreferences(data.replyContent));
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export function* loadUserData({ replyContent: user }: { replyContent: LoginResponseReplyContent }, email: string) {
    const { clientId = generateUuid(), p6pSessionToken = "", isFirstWebLogin } = user;

    yield put(notificationActions.loadNotifications());
    yield put(userActions.loadUserMetadata());
    yield put(userActions.startSynchronization());
    const userId = yield select(userSelectors.userId);
    const savedUserThemes = yield select(themeSelectors.userThemes);
    const savedTheme = savedUserThemes[userId];
    if (typeof savedTheme !== "undefined") {
        yield put(themeActions.setThemeName(savedTheme));
        yield put(themeActions.setTheme(savedTheme));
    } else {
        yield put(themeActions.setThemeName(Theme.MAIN));
        yield put(themeActions.setTheme(Theme.MAIN));
    }

    const newRequestsHeaders = {
        "X-LBTOKEN": email.toLowerCase(),
        "X-JAUTHTOKEN": p6pSessionToken,
        "X-CLIENTID": clientId,
    };

    yield put(goalsActions.fetchGoals());
    yield put(userActions.loadUserGroups());
    const result: boolean[] = yield Promise.all([
        yield put(userActions.loadUserMetadata()),
        yield loadUserPreferences(newRequestsHeaders),
        yield loadUserPremiumExpiration(newRequestsHeaders),
    ]);

    if (result.every((r) => r)) {
        if (isFirstWebLogin) {
            yield putResolve(subjectActions.loadUserSubjectsData());
            yield take(subjectActions.loadUserSubjectsDataDone.type);

            const subjects: Array<SubjectData> = yield select(subjectsSelectors.subjects);
            const userHasNoSubjects = subjects.length === 0;

            const preinstalledSubjectId = "e5bdc426-c32b-482e-a742-f1f8fe8e4cfa";
            const userHasPreinstalledSubjectOnly =
                subjects.length === 1 && subjects[0].subjectMetadata.subjectIdToOwner.id === preinstalledSubjectId;

            if (userHasNoSubjects || userHasPreinstalledSubjectOnly) {
                const { addWelcomeScreenCookie } = getShowWelcomeScreenData();
                addWelcomeScreenCookie();
            }
        }
    } else {
        // Don't show anything now, because it doesn't help the user
        // yield put(responseActions.showResponse({type: ResponseConstants.WARNING, text: ["Welcome! Some data could not be loaded."]}));
    }
}

export function* loginUser(
    data: JossoUpdateResponseType,
    ssoCredentials: Partial<UserCredentials>,
    history: any,
    redirectToHome: boolean = false,
    redirectUrl?: string
) {
    yield put(userActions.removeLogoutIframe()); // hide logout iframe possibly still there from logout
    const { roles, email, userDnsId, jossoSessionId } = data.replyContent;
    if (roles.find((role) => role === "p6o.p6o2.react")) {
        yield put(userActions.setUser(data.replyContent));
        if (roles.some((role) => role === "p6o.p6o2")) {
            yield loadUserData(data, email);
            const userPreferences: UserPreferences = yield select(userSelectors.userPreferences);
            const theme: Theme = yield select(themeSelectors.themeName);
            yield amplitudeLoadAndInit(data.replyContent, userPreferences, theme);
            yield mouseFlowLoadAndInit(data.replyContent);
            yield survicateLoadAndInit(data.replyContent);
            if (redirectUrl) {
                redirectUrl = decodeURIComponent(redirectUrl);
                history.push(redirectUrl);
            } else if (redirectToHome) history.push("/home");
        } else {
            yield put(loginActions.setCredentials(ssoCredentials));
            yield put(modalActions.setModalView(GlobalModalView.Agreement));
        }
    } else {
        goToOldAppVersion(userDnsId, jossoSessionId);
    }
}

export function* loginWithSSOData({
    payload,
}: PayloadAction<{ data: JossoUpdateResponseType; history: any; redirectUrl?: string }>) {
    const { data, history, redirectUrl } = payload;
    const jossoId = data.replyContent.jossoSessionId;

    // do not allow relogin with saved logged out session id
    const cookie = new Cookie();
    const invalidSessions: string[] = (cookie.get("invalidSessions") || "").split(",");
    if (invalidSessions.includes(jossoId)) {
        yield put(
            responseActions.showResponse({
                type: ResponseConstants.ERROR,
                responseMessage: ResponseMessageKeys.LOGIN_HAS_TIMED_OUT,
            })
        );
        history.push("/login");
    } else {
        // allow login
        const credentials = {
            jossoSessionId: jossoId,
            remember: true,
        };
        const prevUserId: string | undefined = yield select(userSelectors.userId);

        if (prevUserId) {
            yield put(userActions.unsetUser());
        }

        yield loginUser(data, credentials, history, true, redirectUrl);
    }
}

export function* loginAsync(action: PayloadAction<LoginDataType>) {
    yield put(appStatusActions.setLoading(true));

    let userMail = action.payload.username;
    if (!/@/.test(action.payload.username)) {
        userMail = action.payload.username + "@" + process.env.REACT_APP_DEFAULT_MAIL_DOMAIN;
    }
    let credentials: UserCredentials = {
        username: userMail,
        password: action.payload.password,
        jossoSessionId: "",
        remember: action.payload.remember,
    };

    let apiUri = process.env.REACT_APP_BACKEND_API_URL + "login";
    let body = JSON.stringify(credentials);
    let headers = { "X-LBTOKEN": userMail.toLowerCase() };
    try {
        const response = yield fetch(apiUri, {
            method: "POST",
            body: body,
            headers: headers,
        });

        const data: LoginResponseType = yield response.json();

        if (response.ok && data.httpCode === 200 && data.replyContent) {
            const { jossoSessionId: receivedJossoSessionId } = data.replyContent;
            const ssoCredentials = { jossoSessionId: receivedJossoSessionId, remember: action.payload.remember };
            yield loginUser(data, ssoCredentials, action.payload.history, true);
        } else {
            yield put(
                responseActions.showResponse({
                    type: ResponseConstants.ERROR,
                    responseMessage: ResponseMessageKeys.WRONG_USER_OR_PASSWORD,
                })
            );
        }
    } catch (error) {
        yield put(
            responseActions.showResponse({
                type: ResponseConstants.ERROR,
                responseMessage: ResponseMessageKeys.LOGIN_FAILED,
            })
        );
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* loginSaga() {
    yield takeEvery(loginActions.login.type, loginAsync);
    yield takeEvery(loginActions.loginWithSSOData.type, loginWithSSOData);
}
