//DEPENDENCIES
import { takeEvery, call, put, select } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";

//NETWORK
import { getGamesScore, getCardsList, addGameScore } from "../../networking/games";
import { normalizeStr } from "../../helpers/normalizeStr";

//REDUX
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { selectors as subjectSelectors } from "../subjects/subjectsSlice";
import { selectors as userSelectors } from "../user/userSlice";
import { actions } from "./gamesSlice";

//TYPES
import { IResponse } from "p6m-response";
import { GameScoreData, GameSubject, CardsList } from "p6m-games";
import { SubjectData, SubjectUnitCard } from "p6m-subjects";
import { WordMatchGameType } from "../../views/games/WordMatch/WordMatchGame";

function* getGamesSubjects() {
    yield put(appStatusActions.setLoading(true));
    try {
        const response: AxiosResponse<IResponse<GameScoreData[]>> = yield call(getGamesScore);
        const {
            data: { replyContent },
        } = response;

        const subjects = yield select(subjectSelectors.subjects);

        if (!subjects.length) return;

        const userDnsId: string = yield select(userSelectors.userId);

        const gameSubjects: GameSubject[] = subjects
            .filter((subject: SubjectData) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { ownerId },
                    },
                } = subject;
                return ownerId !== userDnsId;
            })
            .filter(({ isExpired = true }: SubjectData) => !isExpired)
            .filter((subject: SubjectData) => {
                const {
                    subjectMetadata: { disabled = true },
                } = subject;
                return !disabled;
            })
            .filter((subject: SubjectData) => {
                const {
                    subjectMetadata: { classroomFlag = false },
                } = subject;
                return !classroomFlag;
            })
            .filter((subject: SubjectData) => {
                // not purchasable content has either no purchased date or 1970-1-1
                const {
                    subjectContent: { isActive = false },
                    subjectMetadata: { purchasedDate },
                } = subject;
                const ms = purchasedDate ? new Date(purchasedDate).getTime() : 0;
                return isActive || ms > 0;
            })
            .filter((subject: SubjectData) => {
                const { groupedCardCount: { cardCounts = {} } = {} } = subject;
                const cardsCount: number = Object.values(cardCounts).reduce((result: number, value: any) => {
                    const { cardCount } = value;
                    return result + cardCount;
                }, 0);
                return cardsCount > 0;
            })
            .reduce((result: GameSubject[], subject: SubjectData) => {
                const {
                    subjectMetadata: {
                        subjectIdToOwner: { id: subjectId },
                    },
                } = subject;
                const { score }: GameScoreData =
                    replyContent.find(({ subjectId: { id } }) => id === subjectId) ||
                    ({ score: { match: 0 } } as GameScoreData);
                result.push({
                    ...subject,
                    score,
                });
                return result;
            }, [])
            .sort((firstSubj: GameSubject, secondSubj: GameSubject) => {
                let { subjectContent: { name = "" } = {} } = firstSubj;
                let { subjectContent: { name: secondName = "" } = {} } = secondSubj;
                name = normalizeStr(name);
                secondName = normalizeStr(secondName);
                return name > secondName ? 1 : -1;
            });
        yield put(actions.setMatchGameSubjects(gameSubjects));
    } catch (e) {
        console.log(e);
        yield put(appStatusActions.setMaintenance(true));
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* getWordMatchCards({ payload }: PayloadAction<{ id: string; type: WordMatchGameType; loading?: boolean }>) {
    const { id, type, loading = false } = payload;
    const isActiveFilter: boolean = type === "normal";
    const cardsNumber: number = 24;

    if (loading) yield put(appStatusActions.setLoading(true));

    try {
        const response: AxiosResponse<IResponse<CardsList>> = yield call(getCardsList, id);
        const {
            data: { replyContent },
        } = response;
        const result: { [key: number]: SubjectUnitCard } = {};

        let { cards } = replyContent;

        if (isActiveFilter) {
            cards = cards.filter(({ normal, opposite }) => normal.active || opposite.active);
        }

        while (Object.keys(result).length < cardsNumber && Object.keys(result).length < cards.length) {
            const index: number = Math.floor(Math.random() * cards.length);
            // @ts-ignore
            if (Object.keys(result).includes(index)) continue;
            result[index] = cards[index];
        }

        yield put(actions.setMatchGameCards(Object.values(result)));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* setMatchGameScore({ payload: { score, subject } }: PayloadAction<{ score: number; subject: GameSubject }>) {
    try {
        const {
            subjectMetadata: { subjectIdToOwner },
        } = subject;
        yield call<any>(addGameScore, score, subjectIdToOwner);
        yield put(actions.setMatchGameScore({ score, subjectId: subjectIdToOwner.id }));
    } catch (e) {
        console.log(e);
    }
}

export function* gamesSaga() {
    yield takeEvery(actions.fetchMatchGame, getGamesSubjects);
    yield takeEvery(actions.fetchMatchGameCards, getWordMatchCards);
    yield takeEvery(actions.fetchAddMatchGameScore, setMatchGameScore);
}
