import styled, { keyframes } from "styled-components";

export const Limit = styled.div`
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${(props) => props.theme.base.spacingSmall};
`;
export const LimitLabel = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const DateHeader = styled.span`
    flex: 1.5;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
`;

export const DueHeader = styled.span`
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
`;

export const LearnedHeader = styled.span`
    flex: 1;
    font-weight: bold;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
`;
export const Action = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
`;

export const DateText = styled.span`
    flex: 1.5;
    color: ${(props) => props.theme.colors.text};
`;

export const DueText = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
`;
export const LearnedText = styled.span`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
    text-align: center;
`;

const SmoothAppear = keyframes`
    0% {transform: scaleY(0.5); transform-origin: top; opacity: 0}
    100% {transform: scaleY(1); transform-origin: top; opacity: 1}
 `;

export const Details = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    padding: ${(props) => props.theme.base.spacing};
    padding-top: 0;
    margin: 0;
    animation: ${SmoothAppear} 0.2s;
`;

export const Detail = styled.li`
    flex: 1;
    display: flex;
    padding-bottom: ${(props) => props.theme.base.spacingInContent};
`;

export const Label = styled.span`
    flex: 2;
    color: ${(props) => props.theme.colors.text};
`;
export const Value = styled.span`
    flex: 3;
    color: ${(props) => props.theme.colors.text};
`;

export const NoDataText = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
`;
