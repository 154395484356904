// LIBRARIES
import React, { ChangeEvent, useState, useEffect } from "react";
import { debounce } from "lodash";

// TYPES
import { UserPreferences } from "p6m-user";

// COMPONENTS
import RangeSlider from "../../basic/rangeSlider/RangeSlider";

// STYLES
import { Container, SelectedItemInfo, HiddenLabelForScreenReaders } from "./styles";

export interface ReportTTHSettingProps {
    userPreferences: UserPreferences;
    updateTTH: (value: number) => void;
    labelText: string;
}

const updateLimitInBackendDebounced = debounce(
    (newLimit, functionToCall) => {
        functionToCall(newLimit);
    },
    500,
    {
        leading: false,
        trailing: true,
    }
);

const ReportTTHSetting: React.FC<ReportTTHSettingProps> = (props) => {
    const [value, setValue] = useState<number>(props.userPreferences.tthLimit || 30);

    useEffect(() => {
        if (!!props.userPreferences.tthLimit && value !== props.userPreferences.tthLimit)
            setValue(props.userPreferences.tthLimit);
    }, [props.userPreferences.tthLimit]);

    const handleChangeRangeSlider = (event: ChangeEvent<HTMLInputElement>) => {
        setValue(+event.target.value);
        updateLimitInBackendDebounced(+event.target.value, props.updateTTH);
    };

    // update limit in backend on blur only for input field to reduce backend calls
    const handleChangeInput = (event: ChangeEvent<HTMLInputElement>) => {
        const eventValue = Math.floor(+event.target.value);
        setValue(eventValue);
    };

    const onBlur = () => {
        if (value > 200) {
            setValue(200);
            updateLimitInBackendDebounced(200, props.updateTTH);
        } else if (value < 5) {
            setValue(5);
            updateLimitInBackendDebounced(5, props.updateTTH);
        } else {
            updateLimitInBackendDebounced(value, props.updateTTH);
        }
    };

    return (
        <Container>
            <HiddenLabelForScreenReaders
                id="daily_limit_label_slider"
                htmlFor="range_slider"
            >
                {props.labelText}
            </HiddenLabelForScreenReaders>
            <RangeSlider
                id="range_slider"
                value={value}
                min={5}
                max={200}
                onChange={handleChangeRangeSlider}
                aria-labelledby="daily_limit_label_slider"
                aria-hidden
                tabIndex={-1}
            />
            <HiddenLabelForScreenReaders
                id="daily_limit_label_input"
                htmlFor="range_input"
            >
                {props.labelText}
            </HiddenLabelForScreenReaders>
            <SelectedItemInfo
                id="range_input"
                type="number"
                // this removed all leading zeroes if there are any
                value={Number(value).toString()}
                min="5"
                max="200"
                onChange={handleChangeInput}
                onBlur={onBlur}
                aria-labelledby="daily_limit_label_input"
                tabIndex={200}
            />
        </Container>
    );
};

export default ReportTTHSetting;
