// REACT
import React, { FunctionComponent, PropsWithChildren } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import { Row, Col } from "../../basic/grid";

// STYLED COMPONENTS
import { Title, FullHeightRow, Search } from "./styles";

type Props = {
    title: string;
    onSearch?: (result: string) => void;
};

const ActivationLayout: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const { children, title, onSearch } = props;
    const t = useT();

    const t_search = t("Search", {});

    return (
        <>
            <Row vertical="center">
                <Col>
                    <Title id="ActivationListTitle">{title}</Title>
                </Col>
                {!!onSearch && (
                    <Col xs={3}>
                        <Search
                            placeholder={t_search}
                            onChangeInput={onSearch}
                            iconName="search"
                            ariaLabel={t_search}
                            ariaLabelledBy="ActivationListTitle"
                        />
                    </Col>
                )}
            </Row>
            <FullHeightRow>
                <Col>{children}</Col>
            </FullHeightRow>
        </>
    );
};

export default ActivationLayout;
