// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { T, useT } from "@transifex/react";
// import FocusLock from "react-focus-lock";

// MODELS
import { SubjectData } from "p6m-subjects";
import { FamilyMember, User, UserPreferences } from "p6m-user";
import { ReportScreenType } from "p6m-report";

// COMPONENTS
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";
import ReportPhases from "./pages/phases/ReportPhases";
import ReportActivity from "./pages/activity/ReportActivity";
import ReportDifficult from "./pages/difficult/ReportDifficult";
import ReportPreview from "./pages/preview/ReportPreview";
import ReportControl from "./pages/control/ReportControl";
import Modal from "../../components/basic/modal/Modal";
import PlusFlag from "../../components/basic/plusFlag/PlusFlag";
import { FilledButton } from "../../components/basic/button/Button";

// STATE
import { actions as modalActions } from "../../redux/modal/modalSlice";

// HELPERS
import { getBuyPremiumUrl } from "../../helpers/Url";
import { toDDMMYY } from "../../helpers/timeFormat";
import { GlobalModalView } from "../../helpers/Modal";
import { FocusableElement, navigateElementsByClassName } from "../../helpers/navigateElementsByClassName";

// STYLES
import { InfoSubtext, ButtonWrapper, ModalTextContainer } from "./pages/phases/styles";
import {
    Content,
    Header,
    LastPractice,
    SubjectReportContainer,
    SubjectTitle,
    Tab,
    TabContent,
    TabMenu,
    TabText,
    GoBackWrapper,
} from "./styles";
import { InfoTitle } from "./pages/styles";

export interface SubjectReportProps {
    user: User;
    member?: FamilyMember;
    hasPremium: boolean;
    isStrictMode: boolean;
    userPreferences: UserPreferences;
    screenType: ReportScreenType;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
    changeScreen: (type: ReportScreenType) => void;
    onSubjectSelect: (subjectId: string | null) => void;
}

const SubjectReport: React.FC<SubjectReportProps> = (props) => {
    const t = useT();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [activeTabElement, setActiveTabElement] = useState<FocusableElement | undefined>(undefined);

    const lastPracticed_t = t("last practiced: ", { _tags: "report,header,colon" });
    const menuTabKeys: { [key in ReportScreenType]?: JSX.Element } = {
        PHASES: (
            <T
                _str="Phases"
                _tags="report,type,tab,button"
            />
        ),
        ACTIVITY: (
            <T
                _str="Activity"
                _tags="report,type,tab,button"
            />
        ),
        PREVIEW: (
            <T
                _str="7-day preview"
                _tags="report,type,tab,button"
            />
        ),
        DIFFICULT: (
            <T
                _str="Difficult cards"
                _tags="report,type,tab,button"
            />
        ),
        CONTROL: (
            <T
                _str="Input control"
                _tags="report,type,tab,button"
            />
        ),
    };

    const buyPlusModalTitle = <T _str="Unlock reports" />;
    const buyPlusTitle = <T _str="More insight, more motivation!" />;
    const buyPlusSubTitle = (
        <T _str="keep track of your learning progress with the help of reports and thereby optimize your learning success" />
    );
    const buyPlusIntendedForChildSubTitle = (
        <T _str="Stay on top of your child's learning activity with the reports. Get the PLUS functionalities and then transfer them to your child in 'Mein Konto'." />
    );
    const plusButtonText = <T _str="Unlock PLUS features" />;

    const plusFlag = props.hasPremium ? null : <PlusFlag />;
    const isSubjectReportOfChild = !props.isOwnSubject && props.member?.userId;
    const shopPlusUrl = getBuyPremiumUrl();

    const goToTab = useCallback(
        (screen: ReportScreenType) => {
            if (props.hasPremium || screen === "PHASES") {
                props.changeScreen(screen);
                const newActiveElement = document.getElementById(screen);
                newActiveElement && setActiveTabElement(newActiveElement);
            } else if (props.isStrictMode) {
                dispatch(modalActions.setModalView(GlobalModalView.StrictPremium));
            } else {
                setShowModal(true);
            }
        },
        [props, dispatch]
    );

    const renderContent = () => {
        if (props.hasPremium) {
            switch (props.screenType) {
                case "ACTIVITY":
                    return (
                        <ReportActivity
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            isOwnSubject={props.isOwnSubject}
                        />
                    );
                case "PREVIEW":
                    return (
                        <ReportPreview
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            isOwnSubject={props.isOwnSubject}
                        />
                    );
                case "DIFFICULT":
                    return (
                        <ReportDifficult
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                        />
                    );
                case "CONTROL":
                    return (
                        <ReportControl
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                        />
                    );
                case "PHASES":
                default:
                    return (
                        <ReportPhases
                            selectedSubject={props.selectedSubject}
                            ownerId={props.member ? props.member.userId : props.user.userDnsId}
                            ownerName={props.member?.name}
                            userPreferences={props.userPreferences}
                        />
                    );
            }
        }
        return (
            <ReportPhases
                selectedSubject={props.selectedSubject}
                ownerId={props.member ? props.member.userId : props.user.userDnsId}
                ownerName={props.member?.name}
                userPreferences={props.userPreferences}
            />
        );
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (document.getElementById("subject-report-container")?.contains(document.activeElement)) {
                let focusedElement;
                if (event.key === "Escape") {
                    focusedElement = activeTabElement;
                } else if (event.key === "ArrowRight") {
                    event.preventDefault();
                    focusedElement = navigateElementsByClassName("reportTabsArrowNav", true, true, activeTabElement);
                } else if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    focusedElement = navigateElementsByClassName("reportTabsArrowNav", false, true, activeTabElement);
                }

                if (focusedElement?.id) {
                    goToTab(focusedElement.id as ReportScreenType);
                    focusedElement.focus();
                }

                if (event.key === "Tab" && event.shiftKey) {
                    if (document.activeElement === activeTabElement) {
                        event.preventDefault();
                        const goBackWrapperElement = document.getElementById("go-back-wrapper");
                        goBackWrapperElement?.focus();
                    }
                }
            }
            if (event.key === "Enter" && showModal) {
                const firstScreenType = Object.keys(menuTabKeys)[0];
                const firstTabElement = document.getElementById(firstScreenType);

                if (firstTabElement) {
                    setActiveTabElement(firstTabElement);
                }
            }
        };

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [activeTabElement, showModal]);

    useEffect(() => {
        const firstScreenType = Object.keys(menuTabKeys)[0];
        const firstTabElement = document.getElementById(firstScreenType);

        if (firstTabElement) {
            setActiveTabElement(firstTabElement);
        }
    }, []);

    useEffect(() => {
        !showModal && activeTabElement?.focus();
    }, [showModal, activeTabElement]);

    return (
        <SubjectReportContainer id="subject-report-container">
            <Header>
                <GoBackWrapper
                    id="go-back-wrapper"
                    onClick={() => props.onSubjectSelect(null)}
                    onKeyDown={(e) => e.key === "Enter" && props.onSubjectSelect(null)}
                    tabIndex={1}
                >
                    <PhaseSixIcon name="chevron-left" />
                    <SubjectTitle
                        role="heading"
                        aria-level={1}
                    >
                        {props.selectedSubject.subjectContent.name}
                    </SubjectTitle>
                </GoBackWrapper>
                <LastPractice>
                    {lastPracticed_t + " "}
                    {props.selectedSubject.subjectMetadata && props.selectedSubject.subjectMetadata.lastPracticedDate
                        ? toDDMMYY(props.selectedSubject.subjectMetadata.lastPracticedDate)
                        : "-"}
                </LastPractice>
            </Header>
            <TabContent>
                <TabMenu>
                    {(Object.keys(menuTabKeys) as ReportScreenType[]).map((screenType) => (
                        <Tab
                            id={screenType}
                            key={screenType}
                            selected={props.screenType === screenType}
                            onClick={() => goToTab(screenType)}
                            onKeyDown={(e) => e.key === "Tab" && goToTab(screenType)}
                            className="reportTabsArrowNav"
                            tabIndex={activeTabElement?.id == screenType ? 1 : -1}
                        >
                            <TabText
                                role="heading"
                                aria-level={2}
                            >
                                {menuTabKeys[screenType]}
                                {screenType !== "PHASES" && plusFlag}
                            </TabText>
                        </Tab>
                    ))}
                </TabMenu>
                <Content>{renderContent()}</Content>

                {showModal && (
                    <Modal
                        active={showModal}
                        preventCloseOnBg={true}
                        title={buyPlusModalTitle}
                        beforeClose={() => setShowModal(false)}
                        autoFocus={false}
                    >
                        <ModalTextContainer>
                            <InfoTitle>{buyPlusTitle}</InfoTitle>
                            <InfoSubtext>
                                {isSubjectReportOfChild ? buyPlusIntendedForChildSubTitle : buyPlusSubTitle}
                            </InfoSubtext>
                        </ModalTextContainer>

                        <ButtonWrapper>
                            <FilledButton
                                onClick={() => {
                                    window.open(shopPlusUrl, "_blank");
                                }}
                                textSize="xl"
                            >
                                {plusButtonText}
                            </FilledButton>
                        </ButtonWrapper>
                    </Modal>
                )}
            </TabContent>
        </SubjectReportContainer>
    );
};

const isNewMemberId = (prevProps: SubjectReportProps, nextProps: SubjectReportProps) => {
    return prevProps.member?.userId !== nextProps.member?.userId;
};

export default React.memo(SubjectReport, isNewMemberId);
