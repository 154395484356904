// LIBRARIES
import React from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import ReportTTHSettingView from "../../../../components/connected/reportTTHSetting";

// STYLES
import { InfoImage, InfoImageContainer, InformationBox, InfoTextContainer, InfoSubtext } from "../styles";
import { Limit, LimitLabel } from "./styles";

export interface ActivityInfoBoxProps {
    descriptionText: string;
    isOwnSubject: boolean;
    loadData: () => void;
    active?: boolean;
}

const basicImage = require("../../../../assets/img/avatar/monster_basic.png");
const activeImage = require("../../../../assets/img/avatar/monster_glasses.png");

const ActivityInfoBox: React.FC<ActivityInfoBoxProps> = ({
    descriptionText,
    isOwnSubject,
    loadData,
    active = false,
}) => {
    const t = useT();
    const daily_limit_t = t("Daily card limit", { _tags: "report,label" });

    return (
        <InformationBox>
            <InfoImageContainer>
                <InfoImage
                    src={active ? activeImage : basicImage}
                    alt=""
                />
            </InfoImageContainer>
            <InfoTextContainer>
                <InfoSubtext>{descriptionText}</InfoSubtext>
                <Limit>
                    {isOwnSubject && (
                        <>
                            <LimitLabel>{daily_limit_t}</LimitLabel>
                            <ReportTTHSettingView
                                onUpdate={loadData}
                                labelText={daily_limit_t}
                            />
                        </>
                    )}
                </Limit>
            </InfoTextContainer>
        </InformationBox>
    );
};

export default ActivityInfoBox;
