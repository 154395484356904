import styled from "styled-components";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

export const Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const InputContainer = styled.div<{ isHighlighted: boolean }>`
    background: ${(props) => props.theme.colors.backgroundContrast};
    color: ${(props) => props.theme.colors.textLight};
    flex: 1;
    height: 50px;
    transition: all 0.15s ease-in-out 0s;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    box-shadow: inset 0 2px 8px ${(props) => props.theme.colors.shadow};
    padding: ${(props) => props.theme.base.spacingInContent} ${(props) => props.theme.base.spacing};
    border-radius: 25px;
    border-color: ${({ isHighlighted, theme: { colors } }) => (isHighlighted ? colors.primary : colors.text)};
`;

export const StyledInput = styled.input`
    width: 100%;
    min-width: 150px;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: inline;
    height: auto;
    flex: 1;
    padding: 0 0 0 ${(props) => props.theme.base.spacingInContent};
    font-size: ${(props) => props.theme.base.bigSize};
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    transition: all 0.15s ease-in-out 0s;

    &:focus {
        outline: none;
    }
`;

export const LanguageSelection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

export const SelectToggle = styled.button`
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0);
    border: medium none;
    box-shadow: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: inherit;
    padding: 0;
`;
export const AngleIcon = styled.div`
    line-height: 1;
    padding-left: 4px;
`;

export const LanguageList = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};

    border-radius: ${(props) => props.theme.base.borderRadius};
    top: 100%;
    margin-top: 5px;
    right: 0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    div:last-child {
        margin-bottom: 0;
    }
`;
export const LanguageListEntry = styled.div`
    display: flex;
    flex-grow: 1;
    background: ${(props) => props.theme.colors.backgroundContrast};
    align-items: center;
    cursor: pointer;
    outline: inherit;
    padding: ${(props) => props.theme.base.spacingSmall};
    border: none;

    &:focus {
        box-shadow: inset -1px 0 0 ${({ theme: { colors } }) => colors.text},
            inset 0 -1px 0 ${({ theme: { colors } }) => colors.text},
            inset 1px 0 0 ${({ theme: { colors } }) => colors.text},
            inset 0 1px 0 ${({ theme: { colors } }) => colors.text};
        border-radius: ${(props) => props.theme.base.borderRadius};
    }

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
    }

    > i {
        color: ${(props) => props.theme.colors.text};
    }
`;

export const LanguageText = styled.span`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    font-size: 16px;
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    flex: 1;
    white-space: nowrap;
`;
export const SelectedIconContainer = styled.div`
    margin-left: ${(props) => props.theme.base.spacingSmall};
    > i {
        color: ${(props) => props.theme.colors.primary};
    }
`;
export const AngleRightIcon = styled(PhaseSixIcon)`
    margin: 0 ${(props) => props.theme.base.spacingSmall};
`;
export const FlagImage = styled.img`
    height: 24px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const FlagImageList = styled.img`
    height: 32px;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const SearchResultsContainer = styled.div`
    position: absolute;
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-bottom-left-radius: ${(props) => props.theme.base.borderRadius};
    border-bottom-right-radius: ${(props) => props.theme.base.borderRadius};
    border-top-right-radius: ${(props) => props.theme.base.borderRadius};
    top: 100%;
    width: 100%;
    border-width: 1px;
    border-color: ${(props) => props.theme.colors.primary};
    z-index: 3;
    overflow: hidden;
`;
export const SearchResultContainer = styled.div<{ active?: boolean }>`
    padding: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
    width: 100%;
    border-width: 1px;
    border-bottom-color: ${(props) => props.theme.colors.backgroundMain};
    background: ${(props) => (props.active ? props.theme.colors.backgroundMain : "none")};
    display: flex;
    flex-direction: row;

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
        cursor: pointer;
    }
`;
export const SearchResultImage = styled.img`
    width: 32px;
    margin-right: ${(props) => props.theme.base.spacingInContent};
    border-radius: ${(props) => props.theme.base.spacingInContent};
`;
export const SearchResultText = styled.p`
    font-family: ${(props) => props.theme.base.fontFamily};
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.mediumSize};
`;
export const NoResultsContainer = styled(SearchResultContainer)`
    background: ${(props) => props.theme.colors.backgroundMain};

    &:hover {
        background: ${(props) => props.theme.colors.backgroundMain};
        cursor: default;
    }
`;
export const NoResultText = styled(SearchResultText)`
    color: ${(props) => props.theme.colors.primary};
`;

export const ClearInputButton = styled.div`
    cursor: pointer;
`;
