import styled from "styled-components";

export const List = styled.ul`
    list-style: none;
    padding: ${(props) => props.theme.base.spacingTiny};
    margin: 0;

    > :first-child {
        margin-top: 0;
    }

    > :last-child {
        margin-bottom: 0;
    }
`;
export const StyledEntry = styled.li`
    margin: ${(props) => props.theme.base.spacing} 0;
`;
