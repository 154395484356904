import { RootState } from "../../store/store";
import { createSlice, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { SubjectUnitCard } from "p6m-subjects";
import { LearningState, StartParams, Item, Annotations, ResultActions } from "p6m-learning";
import { getNextItemIndex, getNextSliceItemIndex } from "../../helpers/learning";
import { validateAnnotations } from "../../helpers/Cards";
import { selectors as subjectSelectors } from "../subjects/subjectsSlice";
import { selectors as userSelectors } from "../user/userSlice";
import { selectors as goalsSelectors } from "../goals/goalsSlice";
import { activationTypeToMode, practiceTypeToActivationMode } from "../../helpers/Tracking";
import { ampli } from "../../ampli";

const initialState: LearningState = {
    subjectId: "",
    type: 0,
    direction: 0,
    cards: [],
    areCardsLoaded: false,
    session: "",
    items: [],
    currentItem: 0,
    modals: [],
    cardsId: [],
    annotations: {},
    state: "default",
    userValue: "",
    iterator: 0,
    filter: "LIBRARY",
    isPrepareForTest: false,
    dragNDropResolvedCards: 0,
};

export type StartActionPayload = StartParams & { testId: string };

export const learningSlice = createSlice({
    name: "learning",
    initialState,
    reducers: {
        fetch: () => {},
        fetchAnnotations: () => {},
        savePracticeTestResult: (_state: LearningState, _action: PayloadAction<string>) => {},
        clear: (state: LearningState) => setData(state, initialState),
        start: (_state: LearningState, _action: PayloadAction<StartActionPayload>) => {},
        startPracticeWithDueCards: (
            _state: LearningState,
            _action: PayloadAction<{
                subjectId: string;
                limit?: number;
                history?: any;
            }>
        ) => {},
        startPracticeWithAdditionalCards: (
            _state: LearningState,
            _action: PayloadAction<{
                subjectId: string;
                history?: any;
            }>
        ) => {},
        setData: (state: LearningState, { payload }: PayloadAction<Partial<LearningState>>) => {
            setData(state, payload);
            state.areCardsLoaded = true;
        },
        updateCard: (state: LearningState, { payload }: PayloadAction<SubjectUnitCard>) => {
            const {
                cardIdToOwner: { id },
            } = payload;
            const cardsToUpdate = state.cards.filter(({ cardIdToOwner: { id: findId } }) => id === findId); // we can have 2 cards with same id
            if (!cardsToUpdate.length) return;

            cardsToUpdate.forEach((card) => {
                const newData = { ...card, ...payload };
                const index = state.cards.findIndex((search) => search === card);
                if (index < 0) return;
                state.cards[index] = newData;
            });
        },
        updateItem: (
            state: LearningState,
            { payload }: PayloadAction<{ item: Item | Item[]; newData: Partial<Item> }>
        ) => {
            const { item, newData } = payload;
            const { items } = state;
            (Array.isArray(item) ? item : [item]).forEach((item) => {
                let index = items.findIndex(
                    (search) =>
                        item.cardIndex === search.cardIndex &&
                        item.slice === search.slice &&
                        item.direction === search.direction
                );
                if (index < 0) return;
                state.items[index] = { ...item, ...newData };
            });
        },
        setCurrentItem: (state: LearningState, { payload }: PayloadAction<number>) => {
            state.currentItem = payload;
        },
        setModal: (state: LearningState, { payload }: PayloadAction<LearningState["modals"]>) => {
            state.modals = payload;
        },
        pushModal: (state: LearningState, { payload }: PayloadAction<LearningState["modals"][0]>) => {
            const modals = [...state.modals];
            modals.push(payload);
            state.modals = modals;
        },
        removeModal: (state: LearningState, { payload }: PayloadAction<LearningState["modals"][0]>) => {
            const modals = [...state.modals];
            state.modals = modals.filter((modal) => modal !== payload);
        },
        setTyping: (_state: LearningState, _action: PayloadAction<boolean>) => {},
        setAccelerate: (state: LearningState, { payload }: PayloadAction<LearningState["accelerate"]>) => {
            state.accelerate = payload;
        },
        setPlayAudio: (state: LearningState, { payload }: PayloadAction<LearningState["playAudio"]>) => {
            state.playAudio = payload;
        },
        setEnforce: (state: LearningState, { payload }: PayloadAction<LearningState["enforce"]>) => {
            state.enforce = payload;
        },
        saveAnnotations: (state: LearningState, { payload }: PayloadAction<Annotations & { isAnswer?: boolean }>) => {
            const index = state.currentItem;
            const item = state.items[index];
            if (!item) return;
            const {
                cardIdToOwner: { id },
                cardContent: { question, answer },
            } = state.cards[item.cardIndex];

            if (!validateAnnotations([question, answer], [payload.question, payload.answer])) return;

            // send "annotation created" event to amplitude if validation succeeded
            ampli.noteAdded({ app_screen: "practice", location: payload.isAnswer ? "answer" : "question" });

            state.annotations[id] = payload;
        },
        changeDirection: changeDirection,
        goNext: getItemChanger(false, false),
        goBack: getItemChanger(false, true),
        goNextSlice: getItemChanger(true, false),
        goBackSlice: getItemChanger(true, true),
        updateState: (state: LearningState, { payload }: PayloadAction<LearningState["state"]>) => {
            state.state = payload;
        },
        resolveCard: (_state: LearningState, _action: PayloadAction<{ action: ResultActions; value?: string }>) => {},
        activateSlice: () => {},
        setUserValue: (state: LearningState, { payload }: PayloadAction<string>) => {
            state.userValue = payload;
        },
        sendFeedback: (_state: LearningState, _action: PayloadAction<string>) => {},
        setShowExitFirstPracticeModal: (state: LearningState, { payload }: PayloadAction<string | undefined>) => {
            if (!payload) return;
            const users = [...(state.usersToNotShowExitIntentPopup || [])];
            if (users.includes(payload)) return;
            users.push(payload);
            state.usersToNotShowExitIntentPopup = users;
        },
        incrementDragNDropResolvedCards: (state: LearningState) => {
            const { dragNDropResolvedCards } = state;
            state.dragNDropResolvedCards = dragNDropResolvedCards + 1;
        },
    },
});

/* EXPORTS */
export const { actions, reducer } = learningSlice;

export const selectors = {
    iterator: (state: RootState) => state.learning.iterator,
    allData: (state: RootState) => state.learning,
    filter: (state: RootState) => state.learning.filter,
    subjectId: (state: RootState) => state.learning.subjectId,
    subject: (state: RootState) => {
        const subjectId = selectors.subjectId(state);
        if (!subjectId) return undefined;
        return subjectSelectors.getSubjectById(subjectId)(state);
    },
    unitId: (state: RootState) => {
        const card = selectors.currentCard(state);
        if (!card) return undefined;
        const {
            unitIdToOwner: { id },
        } = card;

        return id;
    },
    unit: (state: RootState) => {
        const unitId = selectors.unitId(state) || "";
        const subjectId = selectors.subjectId(state) || "";

        return subjectSelectors.getUnitById(unitId, subjectId)(state);
    },
    annotations: (state: RootState) => state.learning.annotations,
    type: (state: RootState) => state.learning.type,
    direction: (state: RootState) => state.learning.direction,
    cards: (state: RootState) => state.learning.cards,
    areCardsLoaded: (state: RootState) => state.learning.areCardsLoaded,
    cardsId: (state: RootState) => state.learning.cardsId,
    session: (state: RootState) => state.learning.session,
    practiceType: (state: RootState) => state.learning.practiceType,
    items: (state: RootState) => state.learning.items,
    getItem: (index: number) => (state: RootState) => {
        return state.learning.items?.[index];
    },
    itemDataGetter: createSelector(
        [(state: RootState) => state.learning.items],
        (items) => (index: number) => items?.[index]
    ),
    currentItemIndex: (state: RootState) => {
        return state.learning.currentItem || 0;
    },
    cardDataGetter: createSelector(
        [(state: RootState) => state.learning.cards],
        (cards) => (cardIndex: number) => cards?.[cardIndex]
    ),
    cardData: (cardIndex: number) => (state: RootState) => selectors.cardDataGetter(state)(cardIndex),
    currentItem: (state: RootState) => {
        const items = selectors.items(state);
        const i = selectors.currentItemIndex(state);
        return items?.[i];
    },
    currentCard: (state: RootState) => {
        const currentItem = selectors.currentItem(state);
        const { cardIndex = 0 } = currentItem || {};
        return selectors.cards(state)?.[cardIndex];
    },
    currentAnnotations: (state: RootState) => {
        const card = selectors.currentCard(state);
        if (!card) return undefined;
        const annotations = selectors.annotations(state);
        return annotations[card.cardIdToOwner.id];
    },
    filteredItemsGetter: createSelector(
        [(state: RootState) => state.learning.items],
        (items) => (filter: { type?: Item["type"] | Item["type"][]; slice?: Item["slice"] | Item["slice"][] }) => {
            let { type, slice } = filter;
            let result = items;
            if (slice !== undefined) {
                slice = Array.isArray(slice) ? slice : [slice];
                result = result.filter(({ slice: search }) => (slice as Item["slice"][]).includes(search));
            }
            if (type) {
                type = Array.isArray(type) ? type : [type];
                result = result.filter(({ type: search }) => (type as Item["type"][]).includes(search));
            }
            return result;
        }
    ),
    filteredItems:
        (filter: { type?: Item["type"] | Item["type"][]; slice?: Item["slice"] | Item["slice"][] }) =>
        (state: RootState) =>
            selectors.filteredItemsGetter(state)(filter),
    currentSlice: createSelector(
        [(state: RootState) => state.learning.currentItem, (state: RootState) => state.learning.items],
        (index, items) => {
            const item = items[index];
            if (!item) return [];
            return items.filter((search) => search.slice === item.slice);
        }
    ),
    currentSliceCards: createSelector(
        [
            (state: RootState) => state.learning.currentItem,
            (state: RootState) => state.learning.items,
            (state: RootState) => state.learning.cards,
        ],
        (index, items, cards) => {
            const item = items[index];
            if (!item) return [];
            const slice = items.filter((search) => search.slice === item.slice).map(({ cardIndex }) => cardIndex);
            return cards.filter((card, i) => slice.includes(i));
        }
    ),
    modals: (state: RootState) => state.learning.modals,
    isFinished: (state: RootState) => {
        const items = selectors.items(state);
        if (!items?.length) return false;
        return items.every(({ resolved }) => !!resolved);
    },
    hasNextCard: (state: RootState) => {
        const items = selectors.items(state);
        if (!items?.length || items.length <= 1) return false;
        return items.filter(({ resolved }) => !!resolved).length > 1;
    },
    typing: (state: RootState) => {
        const { inputEnabledForPracticeWeb = true } = state.user.userPreferences;
        return inputEnabledForPracticeWeb;
    },
    accelerate: (state: RootState) => {
        const { acceleratePractice = true } = state.user.userPreferences;
        const { accelerate = acceleratePractice } = state.learning;
        return accelerate;
    },
    playAudio: (state: RootState) => {
        const { audioPlaybackSetting = true } = state.user.userPreferences;
        const { playAudio = audioPlaybackSetting } = state.learning;
        return playAudio;
    },
    enforce: (state: RootState) => {
        const { retypeCorrectAnswerWeb = false, enforceCorrectAnswerWeb = false } = state.user.userPreferences;
        const userEnforce = +retypeCorrectAnswerWeb + +enforceCorrectAnswerWeb;
        const { enforce = userEnforce } = state.learning;
        return enforce;
    },
    state: (state: RootState) => state.learning.state,
    cardTexts: (isAnswer: boolean) => (state: RootState) => {
        const item = selectors.currentItem(state);
        const card = selectors.currentCard(state);
        const { subjectContent: { hideAnswerExampleInSwapped = false } = {} } = selectors.subject(state) || {};

        if (!item || !card) return { text: "", example: "", transcription: "" };
        const { direction } = item;
        const {
            cardContent: { question, questionTranscription, answer, answerTranscription },
        } = card;

        let {
            cardContent: { questionExample, answerExample },
        } = card;

        if (direction === "opposite" && hideAnswerExampleInSwapped) {
            questionExample = answerExample;
            answerExample = "";
        }

        const result = [
            {
                text: question || "",
                example: questionExample || "",
                transcription: questionTranscription || "",
            },
            {
                text: answer || "",
                example: answerExample || "",
                transcription: answerTranscription || "",
            },
        ];

        if (direction === "opposite") result.reverse();

        return result[+isAnswer];
    },
    cardAnnotation: (isAnswer: boolean) => (state: RootState) => {
        const item = selectors.currentItem(state);
        if (!item) return undefined;
        const currentAnnotations = selectors.currentAnnotations(state) || {};
        const { direction } = item;
        const result = [currentAnnotations.question, currentAnnotations.answer];
        if (direction === "opposite") result.reverse();
        return result[+isAnswer];
    },
    cardDirection: (state: RootState) => {
        const item = selectors.currentItem(state);
        if (!item) return undefined;
        return item.direction;
    },
    cardLang:
        ({ isAnswer, considerPracticeDirection = true }: { isAnswer: boolean; considerPracticeDirection?: boolean }) =>
        (state: RootState) => {
            const item = selectors.currentItem(state);
            const subject = selectors.subject(state);
            if (!subject || !item) return undefined;

            const {
                subjectContent: { primaryLang, secondaryLang },
            } = subject;
            const result = [primaryLang, secondaryLang];

            const { direction } = item;

            if (considerPracticeDirection && direction === "opposite") {
                result.reverse();
            }
            return result[+isAnswer];
        },
    cardPhase: (state: RootState) => {
        const item = selectors.currentItem(state);
        const card = selectors.currentCard(state);
        if (!item || !card) return 0;
        const { direction } = item;
        const { opposite, normal } = card;
        const isOpposite = direction === "opposite";
        return [normal, opposite][+isOpposite]?.phase || 0;
    },
    oppositeItem: (state: RootState) => {
        const item = selectors.currentItem(state);
        if (!item || !item.hasOpposite) return undefined;
        return selectors.items(state).find(({ cardIndex, direction }) => {
            return cardIndex === item.cardIndex && direction !== item.direction;
        });
    },
    userValue: (state: RootState) => state.learning.userValue,
    disabledNoInput: (state: RootState) => {
        const { parentSettings2: { practiceSettingsLockWeb = false } = {}, inputEnabledForPracticeWeb = false } =
            state.user.userPreferences || {};
        return practiceSettingsLockWeb && inputEnabledForPracticeWeb;
    },
    isPrepareForTest: (state: RootState) => state.learning.isPrepareForTest,
    getShowExitFirstPracticeModal: function (state: RootState) {
        const userId = userSelectors.userId(state);
        const userMetaData = userSelectors.userMetadata(state);
        if (!userId) return false;
        const users = state.learning.usersToNotShowExitIntentPopup || [];
        if (users.includes(userId)) return false;
        const userGoals = goalsSelectors.goals(state);
        if (!userGoals) return false;
        const isFirstPracticeFinished = userMetaData.hasFirstPractice || userGoals?.goals.firstPracticeFinished;
        return !isFirstPracticeFinished;
    },
    gerDragNDropResolvedCards: function (state: RootState) {
        return state.learning.dragNDropResolvedCards || 0;
    },
    practiceLogParams: createSelector(
        [
            (state: RootState) => state.learning.cards.length,
            (state: RootState) => state.learning.type,
            (state: RootState) => state.learning.isPrepareForTest,
            (state: RootState) => state.learning.startsFrom,
            (state: RootState) => {
                const subjId = state.learning.subjectId;
                if (!subjId) return undefined;
                const subject = subjectSelectors.getSubjectById(subjId)(state);
                if (!subject) return undefined;
                const {
                    subjectContent: { relentless },
                } = subject;
                return relentless;
            },
            (state: RootState) => state.learning.prepareForTestType,
            (state: RootState) => state.learning.practiceType,
        ],
        (cards, type, isPrepareForTest, startsFrom, relentless, prepareForTestType = 0, practiceType) => {
            if (relentless === undefined || !cards) return undefined;
            const activationMode = startsFrom === "activation" && !isPrepareForTest ? type : 4;
            const activationType = isPrepareForTest ? prepareForTestType : type;
            const isExercise = practiceType === "exercise";
            return {
                startsFrom,
                book_has_accessment_mode: relentless,
                activation_mode: practiceTypeToActivationMode(activationMode),
                mode: activationTypeToMode(activationType, isPrepareForTest, isExercise),
            };
        }
    ),
};

function setData(state: LearningState, newData: Partial<LearningState>) {
    Object.entries(newData).forEach(([key, value]) => {
        //@ts-ignore !!! omg
        state[key] = value;
    });
}

function getItemChanger(isSlice: boolean, isBack: boolean) {
    return (state: LearningState, { payload }: PayloadAction<number | undefined>) => {
        const { items = [], currentItem } = state;
        const index = payload === undefined ? currentItem : payload;
        const nextItem = [getNextItemIndex, getNextSliceItemIndex][+isSlice](items, index, isBack);
        if (nextItem < 0) return;
        if (state.currentItem === nextItem) state.iterator += 1;
        state.currentItem = nextItem;
        setData(state, {
            state: "default",
            userValue: "",
        });
    };
}

function changeDirection(state: LearningState, { payload }: PayloadAction<Item["direction"]>) {
    const item = state.items[state.currentItem];
    if (!item) return;
    if (item.type !== "memorize") return;

    item.direction = payload;
    if (payload === "both") addOpposite(state, item);
    else swapDirection(state, item);
}

function swapDirection(state: LearningState, item: Item) {
    const items = state.items.filter(({ type, cardIndex }) => type === "practice" && cardIndex === item.cardIndex); // find all cards to change in practice
    if (!items.length) return;

    const itemToChange = items[0];
    const itemToDelete = !!items[1] ? state.items.indexOf(items[1]) : -1;

    itemToChange.hasOpposite = false;
    itemToChange.direction = item.direction;

    if (itemToDelete < 0) return;
    state.items.splice(itemToDelete, 1);
}

function addOpposite(state: LearningState, item: Item) {
    const items = state.items.filter(({ type, cardIndex }) => type === "practice" && cardIndex === item.cardIndex); // find all cards to change in practice
    // items may has one or two items (opposite or normal or opposite and normal)
    if (items.length !== 1) return;

    const itemToChange = items[0];
    itemToChange.hasOpposite = true;
    state.items.push({
        ...itemToChange,
        resolved: false,
        submitCount: 0,
        direction: itemToChange.direction !== "normal" ? "normal" : "opposite",
    });
}
