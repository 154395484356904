import React from "react";
import { useT } from "@transifex/react";
import { ResultActions } from "p6m-learning";

export enum ButtonTypes {
    memorize,
    compareAnswerSuccess,
    acceptOrRetype,
    iDontKnow,
    showAnswer,
    rightOrWrong,
    nextCard,
    acceptOrNext,
    disabledAcceptOrRetype,
    compareAnswerDanger,
    finishPractice,
}

type colorType = "primary" | "neutral" | "danger" | "success" | undefined;

export interface PracticeContextInterface {
    getButtonSet: (type: ButtonTypes) => [string, colorType, ResultActions | "disabled"][];
}

export const PracticeContext = React.createContext<PracticeContextInterface>({
    getButtonSet: () => [["", undefined, "disabled"]],
});

const PracticeContextWrapper: React.FC = (props) => {
    const t = useT();
    const getButtonSet = (type: ButtonTypes): [string, colorType, ResultActions | "disabled"][] => {
        switch (type) {
            case ButtonTypes.memorize:
                return [[t("MEMORIZE"), "primary", "onMemorize"]];
            case ButtonTypes.compareAnswerSuccess:
                return [[t("Compare Answer"), "primary", "onCompare"]];
            case ButtonTypes.acceptOrRetype:
                return [
                    [t("Accept as correct"), "neutral", "onAsCorrect"],
                    [t("Compare Answer"), "primary", "onRetype"],
                ];
            case ButtonTypes.iDontKnow:
                return [[t("I don't know"), "neutral", "onDontKnow"]];
            case ButtonTypes.showAnswer:
                return [[t("Show answer"), "primary", "onShowAnswer"]];
            case ButtonTypes.rightOrWrong:
                return [
                    [t("I was wrong"), "danger", "onWasWrong"],
                    [t("I was right"), "success", "onAsCorrect"],
                ];
            case ButtonTypes.nextCard:
                return [[t("Next Card"), "success", "onGoNext"]];
            case ButtonTypes.acceptOrNext:
                return [
                    [t("Accept as correct"), "neutral", "onAsCorrect"],
                    [t("Next Card"), "success", "onGoNext"],
                ];
            case ButtonTypes.disabledAcceptOrRetype:
                return [
                    [t("Accept as correct"), undefined, "disabled"],
                    [t("Compare Answer"), "primary", "onRetype"],
                ];
            case ButtonTypes.compareAnswerDanger:
                return [[t("Compare Answer"), "danger", "onRetype"]];
            case ButtonTypes.finishPractice:
                return [[t("Finish Practice"), "success", "onGoNext"]];
            default:
                return [["", undefined, "disabled"]];
        }
    };

    return (
        <PracticeContext.Provider
            value={{
                getButtonSet,
            }}
        >
            {props.children}
        </PracticeContext.Provider>
    );
};

export default PracticeContextWrapper;
