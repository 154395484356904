// LIBRARIES
import React from "react";
import styled from "styled-components";
import moment from "moment";
import { useT } from "@transifex/react";

// COMPONENTS
import { SubjectData } from "p6m-subjects";
import { CoverImage } from "p6-react-shared";
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";

// HELPERS
import { getSubjectCover } from "../../../helpers/Subjects";
import { accessibleActionProps } from "./../../../helpers/Accessibility";

export interface ActiveSubjectProps {
    activeSubject: SubjectData | null;
    dueToday: number;
    possibleToday: number;
    dueTomorrow: number;
    learnNew: number;
    hasActivatedCards: boolean;
    userHasPremium: boolean;
    userRoles: { isTeacher: boolean; isParent: boolean };
    onGoToTraining: (type: "today" | "additional" | "tomorrow") => any;
    onGoToTestsAndExercises: () => void;
    onAddCards: () => any;
    openShop: () => any;
    tabIndex?: number;
    tabIndexPrimaryAction?: number;
    hasSubjects: boolean;
}

const Container = styled.div`
    display: flex;
    background-color: ${(props) => props.theme.colors.backgroundContrast};
    flex: 4;
    padding: 1rem;
    border-radius: ${(props) => props.theme.base.borderRadius};
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: ${process.env.REACT_APP_UPPER_GRID_STEP_BORDER}px) {
        min-width: 100%;
    }
`;

const SubjectTitle = styled.h1`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.titleBigSize};
    font-weight: 500;
    font-family: ${(props) => props.theme.base.fontFamily};
    margin: 0 0 12px 0;
    overflow: hidden;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
`;
const LastPracticeText = styled.span`
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: 400;
`;
const LastPractice = styled.div`
    height: 20px;
    margin: ${(props) => props.theme.base.spacingSmall} 0;
`;
const LastPracticeDate = styled.span`
    color: ${(props) => props.theme.colors.primary};
    margin-left: ${(props) => props.theme.base.spacingInContent};
`;

const SubjectTiles = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-self: stretch;
`;

const SubjectButtons = styled.div`
    height: 240px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
`;
const TrainingButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
`;
const TrainingButton = styled.button<{ color?: string }>`
    height: 135px;
    margin-left: ${(props) => props.theme.base.spacingInContent};
    text-align: center;
    display: flex;
    flex: 1;
    border: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) =>
        props.color
            ? props.color === "primary"
                ? props.theme.colors.backgroundContrast
                : props.color === "third"
                ? props.theme.colors.backgroundContrast
                : props.color === "inactive"
                ? props.theme.colors.textLight
                : props.theme.colors.text
            : props.theme.colors.text};
    background-color: ${(props) =>
        props.color
            ? props.color === "primary"
                ? props.theme.buttons.primary.backgroundColor
                : props.color === "third"
                ? props.theme.buttons.third.backgroundColor
                : props.theme.buttons.secondary.backgroundColor
            : props.theme.buttons.secondary.backgroundColor};
    border-radius: 0.5rem;

    cursor: ${(props) => (props.color && props.color === "inactive" ? "not-allowed" : "pointer")};

    &:not(:disabled) {
        &:hover {
            box-shadow: ${(props) =>
                props.color && props.color === "inactive" ? "transparent" : props.theme.base.hoverBoxShadow};
        }
    }
`;
const TrainingIconContainer = styled(PhaseSixIcon)`
    margin-bottom: 4px;
    color: inherit;
`;
const TrainingButtonText = styled.span`
    margin-top: -10px;
`;
const TrainingButtonCardCount = styled.span`
    margin-top: -5px;
    font-size: 24px;
`;
const ManageButtons = styled.div`
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
`;
const ManageButton = styled.button`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border: none;
    margin: ${(props) => props.theme.base.spacingInContent} 0 0 ${(props) => props.theme.base.spacingInContent};
    padding: 0 ${(props) => props.theme.base.spacing};
    border-radius: ${(props) => props.theme.base.borderRadius};
    cursor: ${(props) => (props.color === "inactive" ? "not-allowed" : "pointer")};
    user-select: ${(props) => (props.color === "inactive" ? "none" : "")};
    color: ${(props) =>
        props.color
            ? props.color === "primary"
                ? props.theme.colors.backgroundContrast
                : props.color === "inactive"
                ? props.theme.colors.textLight
                : props.theme.colors.text
            : props.theme.colors.text};
    background-color: ${(props) =>
        props.color && props.color === "primary"
            ? props.theme.colors.primary
            : props.theme.buttons.secondary.backgroundColor};

    &:hover {
        box-shadow: ${(props) => (props.color === "inactive" ? "none" : props.theme.base.hoverBoxShadow)};
    }
`;
const ManageIcon = styled(PhaseSixIcon)`
    flex: 1;
    color: inherit;
`;
const ManageButtonText = styled.span`
    flex: 7;
    font-size: ${(props) => props.theme.base.bigSize};
    margin-left: ${(props) => props.theme.base.spacingTiny};
`;
const CoverImageContainer = styled.div`
    position: relative;

    & > img {
        vertical-align: top; // todo: this needs to be moved to the shared components library in the future!
    }
`;
const CoverImagePlaceholder = styled.button`
    width: 180px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-style: dotted;
    background: none;
    border-color: ${(props) => props.theme.colors.primary};
    > i {
        font-size: 90px;
    }
`;
const PlaceholderText = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.primary};
`;

const ActiveSubject: React.FC<ActiveSubjectProps> = (props) => {
    const t = useT();

    const {
        activeSubject,
        dueToday,
        dueTomorrow,
        hasActivatedCards,
        learnNew,
        onGoToTestsAndExercises,
        onGoToTraining,
        openShop,
        possibleToday,
    } = props;

    const hasCardsDue = dueToday > 0;
    const totalCardCount = activeSubject?.groupedCardCount?.cardCounts["LIBRARY"].cardCount || 0;
    const allowPrepareForTest = !!activeSubject && totalCardCount !== 0;

    const t_lastPracticed = t("last practiced: ", { _tags: "activeSubject,home,colon" });
    const t_notPractisedYet = t("Not practised yet.", { _tags: "activeSubject,home,fullstop" });
    const t_noContentSelected = t("No content selected", { _tags: "activeSubject,title,home" });
    const t_practiceToday = t("Practise today", { _tags: "activeSubject,home" });
    const t_practiceMore = t("Practise more", { _tags: "activeSubject,home" });
    const t_tomorrow = t("tomorrow", { _tags: "activeSubject,home" });
    const t_moreWords = t("more words ({newWords})", { _tags: "activeSubject,home", newWords: learnNew });
    const t_letsGo = t("Let's go ({newWords})", { _tags: "activeSubject,home", newWords: learnNew });
    const t_testsAndExercises = t("Tests & Exercises", { _tags: "activeSubject,home" });
    const t_choose_a_subject = t("Choose a book", { _tags: "activeSubject,home" });

    let manageText = t_letsGo;
    if (hasActivatedCards || hasCardsDue) {
        manageText = t_moreWords;
    }

    const imageId = activeSubject && getSubjectCover(activeSubject);

    const goToTrainingToday = () => {
        if (hasCardsDue) {
            onGoToTraining("today");
        } else if (possibleToday > 0) {
            onGoToTraining("additional");
        }
    };

    const onAddCards = () => {
        if (learnNew > 0) {
            props.onAddCards();
        }
    };

    const renderMainButton = () => {
        if (hasCardsDue) {
            return (
                <TrainingButton
                    color="primary"
                    {...accessibleActionProps(goToTrainingToday, props.tabIndexPrimaryAction)}
                >
                    <TrainingIconContainer name="learn" />
                    <TrainingButtonText>{t_practiceToday}</TrainingButtonText>
                    <TrainingButtonCardCount>{dueToday}</TrainingButtonCardCount>
                </TrainingButton>
            );
        } else if (possibleToday > 0) {
            return (
                <TrainingButton
                    color="third"
                    {...accessibleActionProps(goToTrainingToday, props.tabIndex)}
                >
                    <TrainingIconContainer name="learn" />
                    <TrainingButtonText>{t_practiceMore}</TrainingButtonText>
                    <TrainingButtonCardCount>{possibleToday}</TrainingButtonCardCount>
                </TrainingButton>
            );
        }
        return (
            <TrainingButton
                color="inactive"
                disabled={!props.hasSubjects}
            >
                <TrainingIconContainer name="learn" />
                <TrainingButtonText>{t_practiceToday}</TrainingButtonText>
                <TrainingButtonCardCount>0</TrainingButtonCardCount>
            </TrainingButton>
        );
    };

    const renderImage = () => {
        if (imageId) {
            return (
                <CoverImageContainer>
                    <CoverImage
                        imageId={imageId}
                        alt=""
                    />
                </CoverImageContainer>
            );
        }
        return (
            <CoverImagePlaceholder onClick={openShop}>
                <PhaseSixIcon
                    name="search-book"
                    highlighted
                />
                <PlaceholderText>{t_choose_a_subject}</PlaceholderText>
            </CoverImagePlaceholder>
        );
    };

    return (
        <Container>
            <SubjectTitle>{activeSubject ? activeSubject.subjectContent.name : t_noContentSelected}</SubjectTitle>
            <LastPractice>
                <LastPracticeText>{t_lastPracticed}</LastPracticeText>
                <LastPracticeDate>
                    {activeSubject && activeSubject.subjectMetadata.lastPracticedDate
                        ? moment(activeSubject.subjectMetadata.lastPracticedDate).format("DD.MM.YYYY")
                        : t_notPractisedYet}
                </LastPracticeDate>
            </LastPractice>
            <SubjectTiles>
                {renderImage()}
                <SubjectButtons>
                    <TrainingButtons>
                        {renderMainButton()}
                        <TrainingButton
                            color={!props.hasSubjects ? "inactive" : activeSubject ? "" : "second"}
                            {...accessibleActionProps(() => onGoToTraining("tomorrow"), props.tabIndex)}
                            disabled={!props.hasSubjects}
                        >
                            <TrainingIconContainer name="reports" />
                            <TrainingButtonText>{t_tomorrow}</TrainingButtonText>
                            <TrainingButtonCardCount>{dueTomorrow}</TrainingButtonCardCount>
                        </TrainingButton>
                    </TrainingButtons>
                    <ManageButtons>
                        <ManageButton
                            color={learnNew === 0 ? "inactive" : hasCardsDue ? "" : "primary"}
                            {...accessibleActionProps(
                                onAddCards,
                                learnNew === 0 ? -1 : hasCardsDue ? props.tabIndex : props.tabIndexPrimaryAction
                            )}
                            disabled={!props.hasSubjects}
                        >
                            <ManageIcon name="annotation" />
                            <ManageButtonText>{manageText}</ManageButtonText>
                        </ManageButton>
                        <ManageButton
                            color={allowPrepareForTest ? "" : "inactive"}
                            {...accessibleActionProps(
                                allowPrepareForTest ? onGoToTestsAndExercises : undefined,
                                allowPrepareForTest ? props.tabIndex : undefined
                            )}
                            disabled={!props.hasSubjects}
                        >
                            <ManageIcon name="clipboard" />
                            <ManageButtonText>{t_testsAndExercises}</ManageButtonText>
                        </ManageButton>
                    </ManageButtons>
                </SubjectButtons>
            </SubjectTiles>
        </Container>
    );
};

export default ActiveSubject;
