//DEPENDENCIES
import styled from "styled-components";

export const ModalTextContainer = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
`;

export const InfoSubtext = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
    margin-top: 40px;
`;

export const InfoLinkContainer = styled.div<{ selected: boolean }>`
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.selected ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    background: ${(props) => (props.selected ? props.theme.colors.primary : "transparent")};
`;
export const InfoLink = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const InfoLinkText = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
`;
export const InfoLinkTextSmall = styled.span`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
`;
export const Phases = styled.div`
    display: flex;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
    overflow: auto;
`;

export const Phase = styled.div`
    height: 100%;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    cursor: pointer;

    > div {
        border: 1px solid transparent;
    }

    :hover {
        > div {
            border: ${(props) => `1px solid ${props.theme.colors.primary}`};
        }
    }
`;

export const BarArea = styled.div<{ selected: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    background: ${(props) => props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;
export const NonFilledArea = styled.div<{ fill: number }>`
    flex: ${(props) => props.fill};
`;
export const FilledArea = styled.div<{ fill: number; selected: boolean }>`
    margin: 2px;
    flex: ${(props) => props.fill};
    position: relative;
    background: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.textLight)};
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
`;
export const Number = styled.div<{ selected: boolean }>`
    position: absolute;
    left: 0;
    right: 0;
    top: -25px;
    text-align: center;
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};
`;
export const PhaseName = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
    min-height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;
export const ButtonWrapper = styled.div`
    width: 100%;
    margin-bottom: ${(props) => props.theme.base.spacing};
    display: flex;
    justify-content: center;
    align-items: center;
`;
