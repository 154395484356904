// LIBRARIES
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useT } from "@transifex/react";
import { debounce } from "lodash";

// UTILS
import { ResponseType } from "../../constants/ResponseConstants";

//STYLED-COMPONENTS
import { BottomContainer, Container, ContentContainer, DropdownContainer, TitleContainer } from "./styles";

// COMPONENTS
import LibrarySubjectEntry from "../../components/connected/librarySubjectEntry/LibrarySubjectEntry";
import DropdownSelection from "../../components/basic/dropdownSelection/DropdownSelection";
import SearchTextInput from "../../components/basic/searchTextInput/SearchTextInput";
import Loading from "../../components/connected/loading";

// TYPES
import { User } from "p6m-user";
import { SubjectData, EntryChange } from "p6m-subjects";
import { DropdownOption } from "p6m-viewData";

// HOOKS
import { useCards } from "./useCards";

// REDUX
import { useSelector } from "react-redux";
import { selectors as modalSelectors } from "../../redux/modal/modalSlice";
import { GlobalModalView } from "../../helpers/Modal";

export interface ManageProps {
    user: User;
    subjectOptions: DropdownOption[];
    unitOptions: DropdownOption[];
    subject: SubjectData;
    subjectId?: string;
    availablePhases: number[];
    initialSearchText?: string;
    subjectLabel: string;
    unitLabel: string;
    defaultSubjectItem?: DropdownOption;
    defaultUnitItem?: DropdownOption;
    onSubjectSelect: (subjectId: string | undefined, searchValue: string) => void;
    setResponse: (responseType: ResponseType, text: string) => void;
    saveSubjectEdit: (changes: EntryChange[]) => void;
    saveUnitEdit: (changes: EntryChange[]) => void;
}

const CHUNK_SIZE: number = +(process.env.REACT_APP_CARD_CHUNK_SIZE || 50);

const Manage: React.FC<ManageProps> = (props) => {
    const t = useT();
    const {
        user,
        setResponse,
        availablePhases,
        initialSearchText = "",
        onSubjectSelect: selectSubject,
        subjectLabel,
        unitLabel,
        subjectOptions,
        unitOptions,
        subject: currentSubject,
        subjectId: currentSubjectId,
        saveSubjectEdit,
        saveUnitEdit,
    } = props;

    const currentModalView = useSelector(modalSelectors.modalView);
    const [previousModalView, setPreviousModalView] = useState(currentModalView);

    const activationPhase_t = t("Activation phase", { _tags: "title" });
    const longTermMemory_t = t("Long-term memory", { _tags: "title" });
    const phaseText = t("Phase", { _tags: "title" });
    const loadCardErrorText = t("Cards could not be loaded.", {
        _tags: "error,tooltip",
    });

    const phaseLabel = t("Phase", { _tags: "library,label" });

    const [currentSubjectOptions, setCurrentSubjectOptions] = useState<DropdownOption[]>(subjectOptions);

    useEffect(() => {
        setCurrentSubjectOptions(subjectOptions);
    }, [subjectOptions]);

    useEffect(() => {
        if (
            previousModalView === GlobalModalView.ChangeCardSubject ||
            previousModalView === GlobalModalView.DeleteContent
        ) {
            loadCardsRef.current({ resetCards: true });
        }

        setPreviousModalView(currentModalView);
    }, [currentModalView]);

    // filter options
    const [phaseOptions, setPhaseOptions] = useState<DropdownOption[]>([]);

    // search by text
    const [searchValue, setSearchValue] = useState(initialSearchText);

    const [selectedUnitIds, setSelectedUnitIds] = useState<string[]>([]);
    const [selectedPhaseIds, setSelectedPhaseIds] = useState<string[]>([]);
    const [dataCallOffset, setDataCallOffset] = useState<number>(0);

    const forbiddenSubjectNames = subjectOptions
        .filter((subjectOption) => {
            return currentSubjectOptions.every((option) => option.value !== subjectOption.value);
        })
        .map((subject) => subject.title);

    const { cards, hasMore, loadCards, isLoading, isLoaded, hasError } = useCards({
        subjectId: currentSubjectId,
        unitIds: selectedUnitIds,
        phaseIds: selectedPhaseIds,
        searchString: searchValue,
    });

    const loadCardsRef = useRef(loadCards);
    loadCardsRef.current = loadCards;

    const fetchCardsDebounced = useMemo(() => {
        return debounce((opts: Parameters<typeof loadCards>[0]) => {
            return loadCardsRef.current(opts);
        }, 500);
    }, [loadCardsRef, loadCards]);

    const handleRefreshCardList = () => {
        setTimeout(() => {
            loadCardsRef.current({
                resetCards: true,
                chunkSize: hasMore ? (cards.length > 0 ? cards.length : CHUNK_SIZE) : undefined,
                noLimit: !hasMore,
            });
        }, 10);
    };

    const onPhaseChange = (phases: string[]) => {
        setSelectedPhaseIds(phases);
        loadCardsRef.current({ phaseIds: phases, resetCards: true });
    };

    const canSwap: boolean = useMemo(() => {
        return !cards.find((card) => {
            const {
                cardContent: { swappable },
            } = card;
            return !swappable;
        });
    }, [cards]);

    //SUBJECT DROPDOWN HANDLER
    const handleDropDownSelectSubject = (newSubjectIds: string[]) => {
        const subjectIdToSwitchTo = newSubjectIds[0];
        if (subjectIdToSwitchTo && currentSubjectId !== subjectIdToSwitchTo) {
            selectSubject(subjectIdToSwitchTo, searchValue);
            loadCardsRef.current({
                resetCards: true,
                unitIds: [],
                phaseIds: [],
                subjectId: subjectIdToSwitchTo,
            });
        }
    };

    const handleDropDownResetSubjectEdit = useCallback(() => {
        setCurrentSubjectOptions(subjectOptions);
    }, [subjectOptions]);

    //UNIT DROPDOWN HANDLER
    const handleDropDownSelectUnit = (units: string[]) => {
        setSelectedUnitIds(units);
        loadCardsRef.current({ unitIds: units, resetCards: true });
    };

    //SEARCH AND SCROLL
    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        if (input.trimStart() !== searchValue.trimStart()) {
            setSearchValue(input);
            fetchCardsDebounced({
                resetCards: true,
                unitIds: selectedUnitIds,
                phaseIds: selectedPhaseIds,
                searchString: input,
            });
        }
    };

    const handleSearchClear = () => {
        setSearchValue("");
        fetchCardsDebounced({
            resetCards: true,
            unitIds: selectedUnitIds,
            phaseIds: selectedPhaseIds,
            searchString: "",
        });
    };

    const loadAllCards = async () => {
        setDataCallOffset(0);
        await loadCardsRef.current({ noLimit: true, resetCards: true });
    };

    const onScroll = useCallback(() => {
        if (isLoaded && !isLoading && hasMore && cards.length <= dataCallOffset + CHUNK_SIZE) {
            const newOffset = dataCallOffset + CHUNK_SIZE;
            setDataCallOffset(newOffset);
            loadCardsRef.current({ offset: newOffset });
        }
    }, [hasMore, cards.length, dataCallOffset, isLoaded, isLoading, loadCardsRef]);

    useEffect(() => {
        const phaseLimitStrings = {
            0: activationPhase_t,
            [availablePhases.length - 1]: longTermMemory_t,
        };
        const effectPhaseOptions: DropdownOption[] = availablePhases.map((phase) => ({
            title: phaseLimitStrings[phase] || `${phaseText} ${phase}`,
            value: phase.toString(),
            editable: false,
        }));

        setPhaseOptions(effectPhaseOptions);
    }, [phaseText, availablePhases, activationPhase_t, longTermMemory_t]);

    useEffect(() => {
        setSelectedPhaseIds([]);
        setSelectedUnitIds([]);
    }, [currentSubject, user.userDnsId]);

    useEffect(() => {
        setDataCallOffset(0);
    }, [selectedUnitIds, selectedPhaseIds, currentSubjectId]);

    useEffect(() => {
        loadCardsRef.current({ resetCards: true });
    }, [loadCardsRef]);

    useEffect(() => {
        if (hasError) {
            setResponse("ERROR", loadCardErrorText);
        }
    }, [hasError, setResponse]);

    return (
        <Container>
            <TitleContainer>
                <DropdownContainer>
                    <DropdownSelection
                        label={subjectLabel}
                        labelId="libraryTitle"
                        options={currentSubjectOptions}
                        multiSelection={false}
                        selectedIds={currentSubjectId ? [currentSubjectId] : []}
                        editable
                        forbiddenNames={forbiddenSubjectNames}
                        onSelect={handleDropDownSelectSubject}
                        saveEdit={saveSubjectEdit}
                        resetEdit={handleDropDownResetSubjectEdit}
                        contentType="subjects"
                    />
                    <DropdownSelection
                        label={unitLabel}
                        options={unitOptions}
                        multiSelection
                        selectedIds={selectedUnitIds}
                        editable
                        onSelect={handleDropDownSelectUnit}
                        saveEdit={saveUnitEdit}
                        contentType="units"
                    />
                    <DropdownSelection
                        label={phaseLabel}
                        options={phaseOptions}
                        multiSelection
                        selectedIds={selectedPhaseIds}
                        onSelect={onPhaseChange}
                        editable={false}
                        contentType="phases"
                    />
                </DropdownContainer>
                <SearchTextInput
                    onChange={handleSearchChange}
                    onClear={handleSearchClear}
                    value={searchValue}
                    fixedWidth
                    ariaLabel={t("Search Library", { _tags: "dictionary,library" })}
                    ariaLabelledBy="libraryTitle"
                />
            </TitleContainer>
            <ContentContainer>
                <Loading />
                <LibrarySubjectEntry
                    isLoaded={isLoaded}
                    isOverview={false}
                    canSwap={canSwap}
                    subject={currentSubject}
                    cards={cards}
                    units={unitOptions}
                    isFirstInList
                    limitCardsShown={false}
                    onScroll={onScroll}
                    onLoadAllCards={loadAllCards}
                    hasMoreCards={hasMore}
                    refreshCardList={handleRefreshCardList}
                />
            </ContentContainer>
            <BottomContainer />
        </Container>
    );
};

export default Manage;
