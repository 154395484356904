// LIBRARIES
import React from "react";
import { useDispatch, useSelector } from "react-redux";

// REDUX
import { selectors as userSelector, actions as userActions } from "../../../redux/user/userSlice";

// COMPONENTS
import ReportTTHSetting from "./ReportTTHSetting";
import { SaveUserPreferencesParams } from "p6m-networking";

const ReportTTHSettingView: React.FC<{ onUpdate?: () => void; labelText: string }> = (props) => {
    const userPreferences = useSelector(userSelector.userPreferences);
    const dispatch = useDispatch();

    const updateTTH = (value: number) => {
        let newPreferences = { ...userPreferences };
        newPreferences.tthLimit = value;
        let request: SaveUserPreferencesParams = {
            newPreferences: newPreferences,
            oldPreferences: { ...userPreferences },
        };
        dispatch(userActions.saveUserPreferences(request));
        if (props.onUpdate) props.onUpdate();
    };

    return (
        <ReportTTHSetting
            userPreferences={userPreferences}
            updateTTH={updateTTH}
            labelText={props.labelText}
        />
    );
};

export default ReportTTHSettingView;
