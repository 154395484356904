// LIBRARIES
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { SubjectsByStatus, SubjectActions } from "p6m-subjects";

// REDUX
import { selectors as userSelectors, actions as userActions } from "../../redux/user/userSlice";
import {
    actions as subjectsActions,
    derived as subjectDerived,
    derived as subjectsDerived,
    selectors,
} from "../../redux/subjects/subjectsSlice";
import { actions as practiceActions } from "../../redux/practice/practiceSlice";
import { actions as learningActions } from "../../redux/learning/learningSlice";
import { actions as activationActions } from "../../redux/activation/activationSlice";
import { actions as addActions } from "../../redux/add/addSlice";
import { actions as manageActions } from "../../redux/manage/manageSlice";
import { actions as prepareActions } from "../../redux/prepare/prepareSlice";
import { actions as approveActions } from "../../redux/approve/approveSlice";
import { actions as modalActions, selectors as modalSelectors } from "../../redux/modal/modalSlice";
import { selectors as testsSelectors } from "../../redux/tests/testsSlice";

// COMPONENTS
import Home from "./Home";
import { GlobalModalView } from "../../helpers/Modal";

//UTILS
import { useShowGDPRModal } from "../../hooks/useShowGDPRModal";

const HomeWrapper: React.FC<{}> = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const currentModalView = useSelector(modalSelectors.modalView);
    const allSubjects = useSelector(subjectDerived.nonExpiredNotEmptySubjects);

    //ConsumableSharedTestData
    const sharedTestMetaData = useSelector(testsSelectors.sharedTestMetaData);
    const sharedTestSubject = useSelector(selectors.getSubjectById(sharedTestMetaData?.subjectId || ""));
    const subjectAssignmentModal = useSelector(selectors.subjectAssignmentModal);

    const consumableSharedTest = {
        subject: sharedTestSubject,
        metaData: sharedTestMetaData,
    };

    const [showFreeContentAd, setShowFreeContentAd] = useState<boolean>(true);

    const userId: string | undefined = useSelector(userSelectors.userId);
    const userFirstName: string | undefined = useSelector(userSelectors.user).firstName;
    const hasGrammarTutor = useSelector(userSelectors.hasGrammarTutor);
    const isGrammarTutorInvited = useSelector(userSelectors.isGrammarTutorInvited);
    const userRoles = {
        isTeacher: useSelector(userSelectors.isTeacher),
        isParent: useSelector(userSelectors.isParent),
        hasGrammarTrainer: hasGrammarTutor || isGrammarTutorInvited,
    };

    const shopFreeContentsCounts = useSelector(userSelectors.shopFreeContentsCounts);
    const subjectsByStatus: SubjectsByStatus = useSelector(subjectsDerived.subjectsByStatus);

    if (useShowGDPRModal() && !currentModalView) {
        setTimeout(() => {
            dispatch(modalActions.setModalView(GlobalModalView.GDPRModal));
        }, 1000);
    }

    const isForceSwitched = useSelector(userSelectors.isForceSwitched);
    const shouldShowInfoModal = isForceSwitched && window.sessionStorage.getItem("switchInfoModalClosed") === null;

    useEffect(() => {
        if (shouldShowInfoModal && !currentModalView) {
            dispatch(modalActions.setModalView(GlobalModalView.SwitchInfo));
        }
    }, [shouldShowInfoModal]);

    useEffect(() => {
        dispatch(subjectsActions.loadUserSubjectsData());
        dispatch(subjectsActions.loadLibrarySubjects());
        dispatch(subjectsActions.loadFamilySubjects());
        dispatch(userActions.refreshPreferencesData(userId || ""));
    }, [dispatch, userId]);

    useEffect(() => {
        if (shopFreeContentsCounts === 0) {
            setShowFreeContentAd(false);
        }
    }, [shopFreeContentsCounts]);

    const subjectActions: SubjectActions = {
        practice: (...props) => {
            const [, subjectId] = props;
            dispatch(
                learningActions.startPracticeWithDueCards({
                    subjectId,
                    history,
                    limit: props[3],
                })
            );
        },
        practiceMore: (...props) => {
            const [, subjectId] = props;
            dispatch(
                learningActions.startPracticeWithAdditionalCards({
                    subjectId,
                    history,
                    limit: props[3],
                })
            );
        },
        homework: (ownerId, subjectId, practiceType) => {
            dispatch(
                practiceActions.setInitialData({
                    ownerId: ownerId,
                    subjectId: subjectId,
                    sequencedPractice: false,
                })
            );
            dispatch(practiceActions.setPracticeType(practiceType));
            history.push("/practice");
        },
        activate: (subjectId) => {
            dispatch(activationActions.setSubjectId(subjectId));
            history.push("/activation/" + subjectId); // maybe remove id from URL later
        },
        activateFamily: (subjectId, userId) => {
            dispatch(activationActions.setSubjectId(subjectId));
            dispatch(activationActions.setType("FAMILY"));
            dispatch(activationActions.setUserId(userId));
            history.push("/activation");
        },
        activateGroup: (subjectId) => {
            dispatch(activationActions.setSubjectId(subjectId));
            dispatch(activationActions.setType("GROUP"));
            dispatch(activationActions.setUserId(userId));
            history.push("/activation");
        },
        add: (subjectId) => {
            dispatch(addActions.setCardSubjectId(subjectId));
            dispatch(addActions.setSubjectId(subjectId));
            history.push("/add");
        },
        manage: (subjectId) => {
            dispatch(manageActions.setSubjectId(subjectId));
            history.push(`/manage/${subjectId}`);
        },
        prepare: (subjectId) => {
            dispatch(prepareActions.setSubjectId(subjectId));
            history.push(`/prepare-test/${subjectId}`);
        },
        report: (subjectId) => {
            history.push("/reports/?subjectId=" + subjectId);
        },
        approve: (subjectId) => {
            dispatch(approveActions.setSubjectId(subjectId));
            history.push("/approve");
        },
    };

    const openShop = () => {
        dispatch(modalActions.setModalView(GlobalModalView.AddSubject));
    };
    const openChooseIncludedContentModal = () => {
        dispatch(modalActions.setModalView(GlobalModalView.ChooseIncludedContent));
    };
    const closeAd = () => setShowFreeContentAd(false);

    return (
        <Home
            userId={userId}
            userFirstName={userFirstName}
            showFreeContentAd={showFreeContentAd}
            freeContentAmount={shopFreeContentsCounts || 0}
            userRoles={userRoles}
            allSubjects={allSubjects}
            dueSubjects={subjectsByStatus.due}
            inactiveSubjects={subjectsByStatus.inactive}
            furtherSubjects={subjectsByStatus.further}
            expiredSubjects={subjectsByStatus.expired}
            subjectAssignmentModal={subjectAssignmentModal}
            subjectActions={subjectActions}
            openShop={openShop}
            openChooseIncludedContentModal={openChooseIncludedContentModal}
            closeAd={closeAd}
            consumableSharedTest={consumableSharedTest}
        />
    );
};

export default HomeWrapper;
