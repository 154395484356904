// REACT
import { useSelector } from "react-redux";
import { useRef, useEffect, useCallback } from "react";

// REDUX
import { selectors } from "../../redux/learning/learningSlice";

// UTILS
import { getTestResults } from "../../networking/tests";
import { ampli, PracticeFinishedPxpProperties, PracticeStartedPxpProperties } from "../../ampli";

// TYPES
import { SubjectUnitCard } from "p6m-subjects";
import { Item } from "p6m-learning";
import { LanguageCodes } from "../../context/languages";

function useLogPracticeEvents({
    testId,
    isFinished,
    areCardsLoaded,
    targetLanguage,
}: {
    testId?: string;
    isFinished: boolean;
    areCardsLoaded: boolean;
    targetLanguage?: LanguageCodes;
}) {
    // used for activation & normal practice
    const cardsCurrentlyPracticed = useSelector(selectors.filteredItems({ type: "practice" }));
    // used for "practice more" feature
    const cardsPreviouslyActivated = useSelector(selectors.cards);
    const practiceLogParams = useSelector(selectors.practiceLogParams);
    const isPracticeStartedDone = useRef<boolean>(false);
    const isPracticeFinishedDone = useRef<boolean>(false);

    const cards_learned: number = cardsCurrentlyPracticed.filter(({ resolved }) => resolved)?.length || 0;
    const getTestResultAmount = async (testId: string) => {
        const result = await getTestResults({ testId });
        return result.data.replyContent.objects.length;
    };

    const getDirection = useCallback(() => {
        const allCards =
            cardsPreviouslyActivated.length > cardsCurrentlyPracticed.length
                ? cardsPreviouslyActivated
                : cardsCurrentlyPracticed;

        const allCardsForPracticeWithNormalDirection =
            "direction" in allCards[0] ? (allCards as Item[]).filter((card: Item) => card.direction === "normal") : [];

        const allActivatedCardsWithNormalDirection =
            "cardIdToOwner" in allCards[0]
                ? (allCards as SubjectUnitCard[]).filter((card: SubjectUnitCard) => "normal" in card) // only cards learned in 'normal' direction have this prop
                : [];

        const allCardsWithNormalDirectionLength = Math.max(
            allCardsForPracticeWithNormalDirection.length,
            allActivatedCardsWithNormalDirection.length
        );

        if (allCardsWithNormalDirectionLength === allCards.length) return "source_to_target";
        if (allCardsWithNormalDirectionLength === 0) return "target_to_source";
        return "both";
    }, [cardsPreviouslyActivated, cardsCurrentlyPracticed]);

    useEffect(() => {
        if (!practiceLogParams || isPracticeFinishedDone.current || !isFinished) return;
        const { book_has_accessment_mode, activation_mode, mode } = practiceLogParams;

        const practiceFinishedProps: PracticeFinishedPxpProperties = {
            cards_for_practice: cardsCurrentlyPracticed.length || cardsPreviouslyActivated.length,
            book_has_accessment_mode,
            activation_mode,
            mode,
            cards_learned,
        };

        if (practiceFinishedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                practiceFinishedProps.revision_no = resultAmount || 0;
            });

            if (
                practiceFinishedProps.mode === "exercise_moved_to_practice" ||
                practiceFinishedProps.mode === "exercise_only"
            ) {
                practiceFinishedProps.test_origin = "teacher";
                practiceFinishedProps.exercise_id = testId;
            }
        }

        isPracticeFinishedDone.current = true;
        ampli.practiceFinishedPxp(practiceFinishedProps);
    }, [practiceLogParams, cards_learned, isFinished, testId]);

    useEffect(() => {
        if (!practiceLogParams || isPracticeStartedDone.current || !areCardsLoaded) return;
        const { book_has_accessment_mode, activation_mode, mode, startsFrom } = practiceLogParams;
        if (!startsFrom) return;

        const direction = getDirection();
        const practiceStartedProps: PracticeStartedPxpProperties = {
            cards_for_practice: cardsCurrentlyPracticed.length || cardsPreviouslyActivated.length,
            book_has_accessment_mode,
            activation_mode,
            mode,
            learning_direction: direction,
            content_target_language: targetLanguage?.toUpperCase() || "OTHER",
        };

        if (practiceStartedProps.mode !== "regular_practice" && testId) {
            getTestResultAmount(testId).then((resultAmount) => {
                // as the first time the exercise will be done should result in the revisionNo equaling 1
                // we need to increase the number of already finished tests by 1 respectively
                practiceStartedProps.revision_no = (resultAmount || 0) + 1;
            });

            if (
                practiceStartedProps.mode === "exercise_moved_to_practice" ||
                practiceStartedProps.mode === "exercise_only"
            ) {
                practiceStartedProps.test_origin = "teacher";
                practiceStartedProps.exercise_id = testId;
            }
        }

        isPracticeStartedDone.current = true;
        ampli.practiceStartedPxp(practiceStartedProps);
    }, [practiceLogParams, testId, cardsPreviouslyActivated, cardsCurrentlyPracticed, areCardsLoaded, targetLanguage]);

    useEffect(() => {
        if (!areCardsLoaded) {
            isPracticeStartedDone.current = false;
            isPracticeFinishedDone.current = false;
        }
    }, [areCardsLoaded]);
}

export default useLogPracticeEvents;
