// REACT
import React, {
    FunctionComponent,
    useState,
    useCallback,
    useEffect,
    useRef,
    useMemo,
    ChangeEventHandler,
    KeyboardEventHandler,
} from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";
import { selectors as appStatusSelectors } from "../../../../redux/appStatus/appStatusSlice";
import { actions as subjectsActions } from "../../../../redux/subjects/subjectsSlice";
import { selectors as userSelectors } from "../../../../redux/user/userSlice";
import { selectors as learningSelectors } from "../../../../redux/learning/learningSlice";

// TYPES
import { State, AnimationType, ResultActions } from "p6m-learning";

// UTILS
import { getCardContentInfo } from "../../../../helpers/Cards";
import { ButtonTypes } from "../../../../context/practice";

// COMPONENTS
import Component, { Props } from "./CardResult";

// STYLED COMPONENTS
import { keyframesGenerator } from "../Cards/styles";

const animations = (["slideIn", "slideInBack", "slideOut", "slideOutBack", "fadeIn", "fadeOut"] as AnimationType[]).map(
    (animation) => keyframesGenerator(animation).getName()
);

// need this timeout because you can click faster then leading state changes!!
const preventActionByTime = (() => {
    let timer: NodeJS.Timeout | undefined;
    return (): boolean => {
        if (timer) return true;
        timer = setTimeout(() => (timer = undefined), 200);
        return false;
    };
})();

export const CardResult: FunctionComponent = () => {
    const state = useSelector(selectors.state);
    const { type } = useSelector(selectors.currentItem);
    const { text } = useSelector(selectors.cardTexts(true));
    const lang = useSelector(selectors.cardLang({ isAnswer: true }));
    const isTypingActive = useSelector(selectors.typing);
    const isForegroundLoading = useSelector(appStatusSelectors.isLoading);
    const isBackgroundLoading = useSelector(appStatusSelectors.isBackgroundLoading);
    const prevValue = useSelector(selectors.userValue);
    const disabledNoInput = useSelector(selectors.disabledNoInput);
    const modals = useSelector(selectors.modals);
    const isFinished = useSelector(selectors.isFinished);
    const userId = useSelector(userSelectors.userId);
    const subjectId = useSelector(learningSelectors.subjectId);
    const activeSubject = useSelector(learningSelectors.subject);

    const [value, setValue] = useState<string>("");

    const elementRef = useRef<HTMLDivElement>(null);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const dispatch = useDispatch();

    const isLoading = isForegroundLoading || isBackgroundLoading;

    const buttonsIndex = useGetButtons(!!value, type === "practice", state, isTypingActive, isFinished);

    //focus element on card appear
    useEffect(() => {
        if (state === "show") return;
        if (modals.length) return; // prevent tab listener if any modal exists

        const statesWithAnimation = ["default"]; // animation start if you go next cart so it is default state only now
        const time = statesWithAnimation.includes(state) ? 200 : 0;

        type Listener = (props: AnimationEvent) => void;
        const animationFinish = new Promise<[NodeJS.Timeout, Listener, Listener]>((resolve) => {
            // resolve promise if no animation start
            const timer: NodeJS.Timeout = setTimeout(() => resolve([timer, onAnimationStart, onAnimationEnd]), time); // wait if animation start
            function onAnimationEnd({ animationName }: AnimationEvent) {
                if (!animations.includes(animationName)) return;
                document.removeEventListener("animationend", onAnimationEnd);
                resolve([timer, onAnimationStart, onAnimationEnd]);
            }

            function onAnimationStart({ animationName }: AnimationEvent) {
                if (!animations.includes(animationName)) return;
                clearTimeout(timer); // clear timer if animation start
                document.removeEventListener("animationstart", onAnimationStart);
                document.addEventListener("animationend", onAnimationEnd);
            }

            document.addEventListener("animationstart", onAnimationStart);
        });

        animationFinish.then(() => {
            const elements = getElements(elementRef?.current);
            elements[0]?.focus();
        });

        return function () {
            animationFinish.then(([timer, onAnimationStart, onAnimationEnd]) => {
                clearTimeout(timer);
                document.removeEventListener("animationstart", onAnimationStart);
                document.removeEventListener("animationend", onAnimationEnd);
            });
        };
    }, [elementRef, state, modals]);

    const ownerId = useMemo(
        () => activeSubject?.subjectMetadata.subjectIdToOwner.ownerId || userId,
        [activeSubject, userId]
    );

    const showPracticeFinishedModal = useCallback(() => dispatch(actions.pushModal("done")), [dispatch]);

    const callback: Props["callback"] = useCallback(
        (action) => {
            if (isLoading || preventActionByTime()) return;

            if (isFinished && action === "onGoNext") {
                // Added a delay to allow time to update the number of cards.
                setTimeout(() => {
                    if (!ownerId) return;

                    dispatch(
                        subjectsActions.loadSubjectGroupedCardCount({
                            ownerId,
                            subjectId,
                            onLoadingFinished: showPracticeFinishedModal,
                        })
                    );
                }, 1000);
            }

            dispatch(actions.resolveCard({ action, value }));
            if (action !== "onAsCorrect") {
                dispatch(actions.setUserValue(value));
            }

            setValue("");
        },
        [isLoading, preventActionByTime, isFinished, ownerId, dispatch, subjectId, showPracticeFinishedModal, value]
    );

    const toggleTyping = useCallback(() => {
        dispatch(actions.setTyping(!isTypingActive));
        setTimeout(() => {
            if (!textareaRef.current) return;
            textareaRef.current.focus();
        });
    }, [dispatch, isTypingActive]);

    const textAreaChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback((e) => {
        const value = e.target.value;
        setValue(value);
    }, []);

    const textAreaKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
        (e) => {
            const keyCode = e.which || e.keyCode;
            if (keyCode === 13 && !e.shiftKey) {
                e.preventDefault();
                e.stopPropagation();
                const relation: ResultActions[] = [
                    "onMemorize",
                    "onCompare",
                    "onRetype",
                    "onDontKnow",
                    "onShowAnswer",
                    "onAsCorrect",
                    "onGoNext",
                    "onGoNext",
                    "onRetype",
                    "onRetype",
                ];
                const action: ResultActions = relation[buttonsIndex] || "onCompare";
                callback(action);
            }
        },
        [callback, buttonsIndex]
    );

    const specialCharSelectCallback = useCallback(
        (char: string) => {
            const textarea = elementRef.current?.querySelector("textarea");
            if (!textarea) return;

            const lastCursorPosition = textarea.selectionStart;

            setValue((prevState: string) => {
                const textBeforeCursor = prevState.substring(0, lastCursorPosition);
                const textAfterCursor = prevState.substring(lastCursorPosition);
                return textBeforeCursor + char + textAfterCursor;
            });

            if (
                ![
                    ButtonTypes.compareAnswerSuccess,
                    ButtonTypes.acceptOrRetype,
                    ButtonTypes.iDontKnow,
                    ButtonTypes.disabledAcceptOrRetype,
                    ButtonTypes.compareAnswerDanger,
                ].includes(buttonsIndex)
            )
                return;

            setTimeout(() => {
                textarea.selectionStart = lastCursorPosition + 1;
                textarea.selectionEnd = lastCursorPosition + 1;
                textarea.focus();
            }, 10);
        },
        [buttonsIndex, elementRef]
    );

    const showArrows = useShowArrows(state, callback);

    return (
        <Component
            ref={elementRef}
            editorRef={textareaRef}
            state={state}
            value={value}
            prevValue={prevValue}
            isPractice={type === "practice"}
            buttonsIndex={buttonsIndex}
            title={getCardContentInfo(text).title}
            lang={lang}
            isLoading={isLoading}
            disabledNoInput={disabledNoInput}
            textAreaChange={textAreaChange}
            textAreaKeyDown={textAreaKeyDown}
            typingIsActive={isTypingActive}
            toggleTyping={toggleTyping}
            onCharSelect={specialCharSelectCallback}
            callback={callback}
            showArrows={showArrows}
        />
    );
};

function useGetButtons(
    hasValue: boolean,
    isPractice: boolean,
    state: State,
    typing: boolean,
    isFinished: boolean
): number {
    const enforce = useSelector(selectors.enforce);

    return useMemo(() => {
        if (isFinished) return ButtonTypes.finishPractice;

        type ButtonsByState = Partial<Record<State, number>>;
        type TypingButtons = ButtonsByState;
        type NoTypingButtons = ButtonsByState;
        type ButtonsByTyping = [NoTypingButtons, TypingButtons];

        const memoButtons: ButtonsByTyping = [
            {
                default: ButtonTypes.memorize,
                success: ButtonTypes.nextCard,
            },
            {
                default: ButtonTypes.compareAnswerSuccess,
                wrong: [ButtonTypes.acceptOrRetype, ButtonTypes.acceptOrRetype, ButtonTypes.disabledAcceptOrRetype][
                    enforce
                ],
                success: ButtonTypes.nextCard,
            },
        ];

        const practiceButtons: ButtonsByTyping = [
            {
                default: ButtonTypes.showAnswer,
                show: ButtonTypes.rightOrWrong,
                success: ButtonTypes.nextCard,
                wrong: ButtonTypes.nextCard,
            },
            {
                default: hasValue ? ButtonTypes.compareAnswerSuccess : ButtonTypes.iDontKnow,
                dontKnow: [ButtonTypes.nextCard, ButtonTypes.compareAnswerDanger, ButtonTypes.compareAnswerDanger][
                    enforce
                ],
                wrong: [ButtonTypes.acceptOrNext, ButtonTypes.acceptOrRetype, ButtonTypes.disabledAcceptOrRetype][
                    enforce
                ],
                success: ButtonTypes.nextCard,
            },
        ];

        const buttonsByType = [memoButtons, practiceButtons][+isPractice];

        const buttonsByTyping = buttonsByType[+typing];

        const buttonsByState = buttonsByTyping[state];

        return buttonsByState || 0;
    }, [hasValue, state, isPractice, typing, enforce, isFinished]);
}

function getElements(element?: HTMLElement | null) {
    if (!element) return [];
    return Array.from(element.querySelectorAll("[tabindex]"))
        .filter((element) => parseInt(element.getAttribute("tabindex") || "0") >= 0)
        .sort((curr, next) => {
            const curTab = parseInt(curr.getAttribute("tabindex") || "0");
            const nextTab = parseInt(next.getAttribute("tabindex") || "0");
            return curTab - nextTab;
        }) as HTMLElement[];
}

function useShowArrows(state: State, callback: Props["callback"]): boolean {
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
        if (state !== "show") return;
        const enterListener = function (e: KeyboardEvent) {
            if (e.code !== "Enter") return;
            e.preventDefault();
            e.stopPropagation();
            setShow(true);
        };
        document.addEventListener("keydown", enterListener);
        return function () {
            document.removeEventListener("keydown", enterListener);
            setShow(false);
        };
    }, [state]);

    useEffect(() => {
        if (state !== "show") return;
        const arrowsListener = function (e: KeyboardEvent) {
            const { code } = e;
            if (!["ArrowRight", "ArrowLeft"].includes(code)) return;
            e.preventDefault();
            e.stopPropagation();
            type Action = Parameters<Props["callback"]>[0];
            const relation: Record<typeof code, Action> = {
                ArrowLeft: "onWasWrong",
                ArrowRight: "onAsCorrect",
            };

            if (!relation[code]) return;
            callback(relation[code]);
        };
        document.addEventListener("keydown", arrowsListener);
        return function () {
            document.removeEventListener("keydown", arrowsListener);
        };
    }, [state, callback]);

    if (state !== "show") return false;
    return show;
}
