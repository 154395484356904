import axios from "axios";
import authInterceptor from "./interceptors/authInterceptor";
import { store } from "../store/store";
import { actions } from "../redux/appStatus/appStatusSlice";

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;

axios.interceptors.request.use(authInterceptor);

// handle connection status
axios.interceptors.response.use(
    (response) => {
        // turn maintenance off, if request succeeds
        const isInMaintenance = store.getState().appStatus.showMaintenancePage;
        if (isInMaintenance) store.dispatch(actions.setMaintenance(false));
        return response;
    },
    (_error) => {
        if (_error.code === "ECONNABORTED") {
            //e.g. Chrome opens the "Save As"-Dialog and automatically aborts all running requests (e.g. synchronizeAsync-Request) we want to ensure that request fails silently, synchronizeAsyncRace is continued and while-loop stays uninterrupted.
            console.warn("Request timed out, but handling it silently");
            return Promise.resolve({ data: { httpCode: 202 } }); // Resolve with a positive mock response (202 = request accepted) to not break while loop on synchronizeAsyncSaga but also imply that a successful further handling is not guaranteed
        }
        // turn maintenance on, if request fails
        store.dispatch(actions.setMaintenance(true));
    }
);
