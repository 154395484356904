// LIBRARIES
import React, { useCallback, useEffect, useState, useRef } from "react";

// TYPES
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";

// HELPERS
import { keyDownHandlerForNavigatingElementsByClass } from "../../../helpers/keyDownHandlerForNavigatingElementsByClass";

// STYLED COMPONENTS
import { StyledEntry } from "./styles";
import { EntryLink, EntryText, EntryIcon } from "../../../views/tests/DropdownActions/styles";

interface EntryProps {
    key: string;
    iconName: TIconNames;
    onClick?: () => void;
    url?: string;
    closePopup?: () => void;
    disabled?: boolean;
    isFirstVisibleElement?: boolean;
}

const Entry: React.FC<EntryProps> = (props) => {
    const entryRef = useRef<HTMLLIElement>(null);
    const [forceFocus, setForceFocus] = useState<boolean>(!!props.isFirstVisibleElement);
    useEffect(() => {
        if (props.isFirstVisibleElement && !forceFocus) setForceFocus(true);
    }, [props.isFirstVisibleElement]);

    useEffect(() => {
        if (forceFocus && entryRef.current) {
            entryRef.current.focus();
            setForceFocus(false);
        }
    }, [forceFocus]);

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLElement>, isExternalLink?: boolean) => {
            const { onClick, closePopup } = props;
            keyDownHandlerForNavigatingElementsByClass(
                event,
                "subjectActionListItem",
                onClick ? onClick : undefined,
                closePopup ? closePopup : undefined,
                isExternalLink
            );
        },
        [props.onClick, props.closePopup]
    );

    // do not react on click of disabled
    if (props.disabled) {
        return (
            <StyledEntry ref={entryRef}>
                <EntryLink disabled>
                    <EntryText>{props.children}</EntryText>
                    <EntryIcon name={props.iconName} />
                </EntryLink>
            </StyledEntry>
        );
    }
    if (props.url) {
        // external action => open in new tab
        return (
            <StyledEntry
                ref={entryRef}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                className="subjectActionListItem"
            >
                <EntryLink
                    href={props.url}
                    target={"_blank"}
                >
                    <EntryText>{props.children}</EntryText>
                    <EntryIcon name={props.iconName} />
                </EntryLink>
            </StyledEntry>
        );
    }

    return (
        <StyledEntry
            ref={entryRef}
            tabIndex={0}
            onClick={props.onClick}
            onKeyDown={handleKeyDown}
            className="subjectActionListItem"
        >
            <EntryLink>
                <EntryText>{props.children}</EntryText>
                <EntryIcon name={props.iconName} />
            </EntryLink>
        </StyledEntry>
    );
};

export default Entry;
