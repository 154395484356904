import styled, { css } from "styled-components";

export const ReportsContainer = styled.div`
    height: calc(100% - ${(props) => props.theme.base.spacing});
    min-height: 600px;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-y: hidden;
    gap: ${(props) => props.theme.base.spacing};
`;
export const SubjectReportContainer = styled.div`
    width: calc(100% - ${(props) => props.theme.base.spacing});
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: ${(props) => props.theme.base.spacing};
    background: ${(props) => props.theme.colors.backgroundContrast};
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
export const SocialSelectorsContainer = styled.div`
    min-width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;

export const ContentContainer = styled.div`
    flex: 3;
    width: 100%;
`;
export const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin-bottom: ${(props) => props.theme.base.spacing};
`;
export const List = styled.div`
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
`;
export const Subject = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const GoBackWrapper = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    width: 0;
    color: ${(props) => props.theme.colors.primary};
    background: none;
    border: none;
    cursor: pointer;
    font-size: ${(props) => props.theme.base.mediumSize};
`;
export const SubjectTitle = styled.span`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 ${(props) => props.theme.base.spacing};
`;
export const LastPractice = styled.span`
    color: ${(props) => props.theme.colors.text};
`;
export const TabContent = styled.div`
    display: flex;
    flex-direction: column;
    height: calc(100% - (2 * ${(props) => props.theme.base.spacing}));
`;
export const TabMenu = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;
export const Tab = styled.button<{ selected: boolean }>`
    background: none;
    padding: ${(props) => props.theme.base.spacingSmall} ${(props) => props.theme.base.spacing};
    color: ${(props) => props.theme.colors.text};
    border-top: 1px solid transparent;
    border-right: 1px solid transparent;
    border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
    border-left: 1px solid transparent;
    cursor: pointer;
    font-size: ${(props) => props.theme.base.mediumSize};

    ${(props) =>
        props.selected &&
        css`
            color: ${(props) => props.theme.colors.primary};
            border-top: 1px solid ${(props) => props.theme.colors.textLight};
            border-right: 1px solid ${(props) => props.theme.colors.textLight};
            border-bottom: 1px solid transparent;
            border-left: 1px solid ${(props) => props.theme.colors.textLight};

            border-top-left-radius: ${(props) => props.theme.base.borderRadiusSmall};
            border-top-right-radius: ${(props) => props.theme.base.borderRadiusSmall};
        `};
`;
export const TabText = styled.span`
    position: relative;
`;
export const Content = styled.div`
    display: flex;
    height: calc(100% - (2 * ${(props) => props.theme.base.spacing}));
`;
export const NoDataContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const NoDataText = styled.span`
    text-align: center;
    color: ${(props) => props.theme.colors.text};
`;
