// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";
import { useT } from "@transifex/react";

// MODELS
import { ActivityDateStatistic, SubjectData } from "p6m-subjects";

// COMPONENTS
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";
import ActivityInfoBox from "./ActivityInfoBox";

// NETWORKING
import { getReportActivityData } from "../../../../networking/reports";

// HELPERS
import { toDDMMYY } from "../../../../helpers/timeFormat";

// STYLES
import { Action, Details, Detail, Label, Value, NoDataText } from "./styles";
import { Container, Entry, Header, List, Statistics, TableWrapper, Text, TextHeader } from "../styles";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
}

const ReportActivity: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const [data, setData] = useState<Array<ActivityDateStatistic> | null>(null);

    const noData_t = t("There is no data to display.", { _tags: "report,activity,message" });
    const description_t = t(
        "This report shows how many cards have been due on the particular day and how many cards have been learned.",
        {
            _tags: "report,description",
        }
    );

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: subjectReport },
        } = await getReportActivityData(props.ownerId || "", props.selectedSubject.subjectMetadata.subjectIdToOwner);
        const dateList = subjectReport.dayActivityList
            .sort((a, b) => {
                if (new Date(a.date).getTime() < new Date(b.date).getTime()) return 1;
                return -1;
            })
            .slice(0, 30); // max 30 entries
        setData(dateList);
    }, [props.ownerId, props.selectedSubject.subjectMetadata.subjectIdToOwner]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "activity",
        });
    }, []);

    const dateHeader_t = t("Date", { _tags: "title,report" });
    const dueHeader_t = t("Due", { _tags: "title,report" });
    const learnedHeader_t = t("Learned", { _tags: "title,report" });
    const today_t = t("Today", { _tags: "date,label,report" });
    const regularLearned_t = t("Learned regularly", { _tags: "label,report" });
    const learnedForTest_t = t("Learned for test", { _tags: "label,report" });
    const newVocabulary_t = t("New cards", { _tags: "label,report" });
    const learningTime_t = t("Learning time (in min)", { _tags: "label,report" });

    const handleDisplayDetails = (index: number) => {
        if (selectedDay === index) {
            // close open details
            setSelectedDay(null);
        } else {
            setSelectedDay(index);
        }
    };

    return (
        <Container>
            <ActivityInfoBox
                descriptionText={description_t}
                loadData={loadData}
                isOwnSubject={props.isOwnSubject}
            />
            <TableWrapper>
                <Entry>
                    <Header>
                        <TextHeader>{dateHeader_t}</TextHeader>
                        <TextHeader center>{dueHeader_t}</TextHeader>
                        <TextHeader center>{learnedHeader_t}</TextHeader>
                        <Action />
                    </Header>
                </Entry>
                <List>
                    {data &&
                        (data.length > 0 ? (
                            data.map((day, index) => {
                                const selected = index === selectedDay;
                                const now = new Date();
                                const dayDate = new Date(day.date);
                                const isToday =
                                    now.getDate() === dayDate.getDate() &&
                                    now.getMonth() === dayDate.getMonth() &&
                                    now.getFullYear() === dayDate.getFullYear();
                                const minutesLearned = Math.ceil(day.learningDuration / 60);
                                return (
                                    <Entry
                                        key={"day_" + index}
                                        tabIndex={1}
                                        onBlur={() => setSelectedDay(null)}
                                        onClick={() => handleDisplayDetails(index)}
                                        onKeyUp={(e) => e.key === "Enter" && handleDisplayDetails(index)}
                                    >
                                        <Statistics>
                                            <Text>{isToday ? today_t : toDDMMYY(day.date)}</Text>
                                            <Text center>{day.totalDueCards}</Text>
                                            <Text center>{day.dueCards}</Text>
                                            <Action>
                                                <PhaseSixIcon name={selected ? "chevron-up" : "chevron-down"} />
                                            </Action>
                                        </Statistics>
                                        {selected && (
                                            <Details>
                                                <Detail>
                                                    <Label>{regularLearned_t}</Label>
                                                    <Value>{day.dueCards.toLocaleString("de-DE")}</Value>
                                                </Detail>
                                                <Detail>
                                                    <Label>{learnedForTest_t}</Label>
                                                    <Value>{day.notDueCards.toLocaleString("de-DE")}</Value>
                                                </Detail>
                                                <Detail>
                                                    <Label>{newVocabulary_t}</Label>
                                                    <Value>{day.newCards.toLocaleString("de-DE")}</Value>
                                                </Detail>
                                                <Detail>
                                                    <Label>{learningTime_t}</Label>
                                                    <Value>{minutesLearned}</Value>
                                                </Detail>
                                            </Details>
                                        )}
                                    </Entry>
                                );
                            })
                        ) : (
                            <NoDataText>{noData_t}</NoDataText>
                        ))}
                </List>
            </TableWrapper>
        </Container>
    );
};

export default ReportActivity;
