// LIBRARIES
import React from "react";
import { useT } from "@transifex/react";

// TYPES
import { User } from "p6m-user";

// COMPONENTS
import { EmailNotConfirmed } from "./EmailNotConfirmed";

// STYLES
import { UserEmailWrapper, UserNameAndEmailWrapper, UserNameWrapper } from "./styles";

export const UserNameAndEmail: React.FC<{ user: User; onClick: () => any; isUserStrict: boolean }> = (props) => {
    const { isUserStrict } = props;
    const t = useT();

    const isUserUnconfirmed = props.user.roles && props.user.roles.includes("unconfirmed");
    const anonymousUser = t("Anonymous User", { _tags: "name, placeholder" });
    let userName = props.user.firstName + " " + props.user.lastName;
    if (!props.user.email || props.user.email.indexOf("@phase-6.org") > -1) {
        // is anonymous user
        userName = anonymousUser;
    }

    return (
        <UserNameAndEmailWrapper className={`${isUserUnconfirmed && !isUserStrict ? "no-padding-bottom" : ""}`}>
            <UserNameWrapper data-qa="user-menu-dropdown_user-name">{userName}</UserNameWrapper>
            <UserEmailWrapper data-qa="user-menu-dropdown_user-email">{props.user.email}</UserEmailWrapper>
            {isUserUnconfirmed && !isUserStrict && <EmailNotConfirmed onClick={props.onClick} />}
        </UserNameAndEmailWrapper>
    );
};
