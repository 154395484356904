// LIBRARIES
import React, { useContext, useState, useCallback, useEffect } from "react";
import { ThemeContext } from "styled-components";
import { useT } from "@transifex/react";
import { useMediaQuery } from "react-responsive";
import { ampli } from "../../../ampli";
import FocusLock from "react-focus-lock";

// TYPES
import { User, UserPremiumExpiration } from "p6m-user";
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";

// COMPONENTS
import UserPremiumInformation from "../../basic/userPremiumInformation/UserPremiumInformation";
import UserMenuEntry, { UserMenuEntryProps } from "../../complex/userMenuEntry/UserMenuEntry";
import Popup from "../../basic/popup/Popup";
import UserAvatar from "../../basic/userAvatar/UserAvatar";
import { EmailNotConfirmed } from "./EmailNotConfirmed";
import { UserNameAndEmail } from "./UserNameAndEmail";

// HELPERS
import {
    getBuyPremiumUrl,
    getImpressumUrl,
    getMeinKontoUrl,
    getSupportChatUrl,
    getSupportUrl,
} from "../../../helpers/Url";
import { GlobalModalView } from "../../../helpers/Modal";
import { navigateElementsByClassName } from "../../../helpers/navigateElementsByClassName";
import { keyDownHandlerForNavigatingElementsByClass } from "../../../helpers/keyDownHandlerForNavigatingElementsByClass";

// STYLES
import {
    FirstNameContainer,
    FocusedMenuEntry,
    LogoutSeparator,
    MobileContentWrapper,
    popupStyles,
    UserMenuContainer,
    UserMenuEntriesWrapper,
    UserNameContainer,
} from "./styles";

export interface UserMenuProps {
    user: User;
    userPremiumExpiration: UserPremiumExpiration;
    userHasPremium: boolean;
    isMobileView?: boolean;
    userAvatar?: string;
    isUserStrict: boolean;
    hasBetaInvite: boolean;
    setModalView: (view: GlobalModalView) => void;
    switchVersion: () => void;
    logoutAction: () => void;
    closeMobileMenu?: () => void;
}

type UserMenuWithClosePopupProps = Omit<UserMenuEntryProps, "onClickAction"> & {
    "data-autofocus"?: boolean;
    onClickAction?: (showModal?: boolean) => void;
    tooltipText?: string;
};

const UserMenu: React.FC<UserMenuProps> = (props: UserMenuProps) => {
    const { closeMobileMenu, setModalView, userAvatar, isUserStrict, hasBetaInvite } = props;
    const t = useT();

    const t_accountManage = t("Manage my account", { _tags: "userMenu,header" });
    const t_settings = t("Preferences", { _tags: "userMenu,header" });
    const t_support = t("FAQ/Support", { _tags: "userMenu,header" });
    const t_supportChat = t("Support Chat", { _tags: "userMenu,header" });
    const t_imprint = t("Impressum", { _tags: "userMenu,header" });
    const t_recommend = t("Recommend to friend", { _tags: "userMenu,header" });
    const t_switchVersion = t("Switch version", { _tags: "userMenu,header" });
    const t_unlockPremium = t("Unlock Premium", { _tags: "userMenu,header" });
    const t_logout = t("Sign out", { _tags: "userMenu,header" });
    const t_dataProtection = t("Data protection", { _tags: "userMenu,header" });
    const t_strictUserDisabledOptionsText = t(
        "This functionality is locked because this account is connected to an institution",
        { _tags: "userMenu,header" }
    );

    const theme = useContext(ThemeContext);

    const isUserUnconfirmed = props.user.roles && props.user.roles.includes("unconfirmed");

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    const isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const accountProps: { title: string; navTarget: string; external: boolean; icon?: TIconNames } = {
        title: t_accountManage,
        navTarget: getMeinKontoUrl(props.user.jossoSessionId),
        external: true,
    };
    if (isUserUnconfirmed && !isUserStrict) {
        accountProps.icon = "user-unconfirmed";
    } else {
        accountProps.icon = "user";
    }

    const userDoesntHavePremium = !(props.userHasPremium || props.userPremiumExpiration.isTrial);

    const logShareApp = () => {
        ampli.shareApp({
            app_screen: "learncenter",
        });
    };

    const PopupContent = (PopupActions?: any) => {
        const closePopup = !!PopupActions && !!PopupActions.closePopup ? PopupActions.closePopup : () => {};
        const isMobileView = !!PopupActions && !!PopupActions.isMobileView ? PopupActions.isMobileView : false;
        const UserMenuEntryWithClosePopup = (props: UserMenuWithClosePopupProps) => {
            const { onClickAction, disabled = false, tooltipText } = props;

            // workaround to skip the first option if it's disabled - as FocusLock's autofocus=false doesn't seem to work
            useEffect(() => {
                if (props["data-autofocus"] === false && disabled)
                    navigateElementsByClassName("userMenuEntry", true, true);
            }, []);

            const handleKeyDown = useCallback(
                (event: React.KeyboardEvent<HTMLElement>, disabled: boolean, isExternalLink?: boolean) => {
                    // todo: focus the opening element again once the dropdown was closed (once it's properly focusable)
                    if (!!onClickAction) {
                        keyDownHandlerForNavigatingElementsByClass(
                            event,
                            "userMenuEntry",
                            !disabled ? onClickAction : undefined,
                            closePopup,
                            isExternalLink
                        );
                    } else {
                        keyDownHandlerForNavigatingElementsByClass(event, "userMenuEntry", undefined, closePopup);
                    }
                },
                [onClickAction, closePopup]
            );

            return (
                <UserMenuEntry
                    {...props}
                    disabledTooltipText={tooltipText}
                    disabled={disabled}
                    onClickAction={(showModal?: boolean) => {
                        if (disabled) return;
                        if (!!showModal && isMobileView) {
                            closePopup();
                        }
                        if ((!props.modal || !showModal) && !!closePopup) closePopup();
                        onClickAction?.(showModal);
                    }}
                    onKeyDownAction={handleKeyDown}
                />
            );
        };
        return (
            <div data-qa="user-menu-dropdown">
                <FocusLock>
                    {!isMobileView && (
                        <UserNameAndEmail
                            user={props.user}
                            isUserStrict={isUserStrict}
                            onClick={() => {
                                setModalView(GlobalModalView.MailAddressConfirmation);
                                closePopup();
                            }}
                        />
                    )}
                    <UserMenuEntriesWrapper data-qa="user-menu-dropdown_menu-list">
                        <UserMenuEntryWithClosePopup
                            key={"account"}
                            data-autofocus={!isUserStrict}
                            tooltipText={isUserStrict ? t_strictUserDisabledOptionsText : undefined}
                            disabled={isUserStrict}
                            {...accountProps}
                        />
                        <UserMenuEntryWithClosePopup
                            title={t_settings}
                            data-autofocus={isUserStrict} // only autofocus this if first element is disabled
                            key={"settings"}
                            icon={"settings"}
                            headerComponent={false}
                            onClickAction={() => {
                                setModalView(GlobalModalView.Settings);
                                closePopup();
                            }}
                        />

                        {isUserStrict ? (
                            <>
                                <UserMenuEntryWithClosePopup
                                    title={t_supportChat}
                                    key={"supportChat"}
                                    navTarget={getSupportChatUrl()}
                                    external
                                    icon={"help"}
                                />
                                <UserMenuEntryWithClosePopup
                                    title={t_imprint}
                                    key={"impressum"}
                                    svgIcon={theme.icons.impressum}
                                    onClickAction={() => {
                                        setModalView(GlobalModalView.StrictUserImpressumIframe);
                                        closePopup();
                                    }}
                                />
                                <UserMenuEntryWithClosePopup
                                    title={t_dataProtection}
                                    key={"dataProtection"}
                                    icon={"data-protection-on"}
                                    onClickAction={() => {
                                        setModalView(GlobalModalView.DataProtectionIframe);
                                        closePopup();
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <UserMenuEntryWithClosePopup
                                    title={t_support}
                                    key={"support"}
                                    navTarget={getSupportUrl()}
                                    external
                                    icon={"help"}
                                />
                                <UserMenuEntryWithClosePopup
                                    title={t_imprint}
                                    key={"impressum"}
                                    navTarget={getImpressumUrl()}
                                    external
                                    svgIcon={theme.icons.impressum}
                                />
                            </>
                        )}

                        <UserMenuEntryWithClosePopup
                            title={t_recommend}
                            key={"share"}
                            icon={"share"}
                            tooltipText={isUserStrict ? t_strictUserDisabledOptionsText : undefined}
                            disabled={isUserStrict}
                            onClickAction={() => {
                                setModalView(GlobalModalView.Share);
                                closePopup();
                                logShareApp();
                            }}
                        />
                        {userDoesntHavePremium && (
                            <FocusedMenuEntry key={"no-premium-container"}>
                                <UserMenuEntryWithClosePopup
                                    title={t_unlockPremium}
                                    key="premium"
                                    external
                                    icon="plus"
                                    userDefinedIconColor="white"
                                    disableHover={true}
                                    {...(isUserStrict
                                        ? {
                                              onClickAction: () => setModalView(GlobalModalView.StrictPremium),
                                          }
                                        : { navTarget: getBuyPremiumUrl() })}
                                />
                            </FocusedMenuEntry>
                        )}
                        {!isUserStrict && hasBetaInvite && (
                            <UserMenuEntryWithClosePopup
                                title={t_switchVersion}
                                key={"switch"}
                                icon={"arrows-right-left"}
                                onClickAction={props.switchVersion}
                            />
                        )}
                    </UserMenuEntriesWrapper>
                    <LogoutSeparator />
                    <UserMenuEntriesWrapper>
                        <UserMenuEntryWithClosePopup
                            title={t_logout}
                            key={"logout"}
                            icon={"logout"}
                            onClickAction={props.logoutAction}
                        />
                    </UserMenuEntriesWrapper>
                </FocusLock>
            </div>
        );
    };

    const anonymousUser = t("Anonymous User", { _tags: "name, placeholder" });
    let userName = props.user.firstName;
    if (!props.user.email || props.user.email.indexOf("@phase-6.org") > -1 || userName === "") {
        // is anonymous user or doesn't have first or last name
        userName = anonymousUser;
    }

    const [isMobileViewOpen, setIsMobileViewOpen] = useState(false);
    if (isMobileView) {
        return (
            <MobileContentWrapper>
                <UserMenuContainer
                    className={`${isMobileView ? "reverse-direction" : ""}`}
                    onClick={() => setIsMobileViewOpen(!isMobileViewOpen)}
                >
                    <UserNameContainer className={`${isMobileView ? "push-to-left" : ""}`}>
                        <FirstNameContainer id="user-first-name">{userName}</FirstNameContainer>
                        <UserPremiumInformation
                            userHasPremium={props.userHasPremium}
                            isTrialAccount={props.userPremiumExpiration.isTrial}
                            premiumExpirationTimestamp={props.userPremiumExpiration.expirationDate}
                        />
                    </UserNameContainer>
                    <UserAvatar
                        userAvatar={userAvatar}
                        size={"medium"}
                        isOnBackground
                    />
                </UserMenuContainer>
                {isMobileViewOpen && isUserUnconfirmed && !isUserStrict && (
                    <EmailNotConfirmed
                        onClick={() => {
                            setModalView(GlobalModalView.MailAddressConfirmation);
                            closeMobileMenu && closeMobileMenu();
                        }}
                    />
                )}
                {isMobileViewOpen && (
                    <PopupContent
                        closePopup={closeMobileMenu}
                        isMobileView={true}
                    />
                )}
            </MobileContentWrapper>
        );
    }

    return (
        <Popup
            content={PopupContent}
            position={["bottom", "right"]}
            style={popupStyles}
        >
            <UserMenuContainer
                data-qa="user-menu-button"
                className={`${isMobileView ? "reverse-direction" : ""}`}
                tabIndex={-1}
            >
                <UserNameContainer className={`${isMobileView ? "push-to-left" : ""}`}>
                    <FirstNameContainer id="user-first-name">{userName}</FirstNameContainer>
                    <UserPremiumInformation
                        userHasPremium={props.userHasPremium}
                        isTrialAccount={props.userPremiumExpiration.isTrial}
                        premiumExpirationTimestamp={props.userPremiumExpiration.expirationDate}
                    />
                </UserNameContainer>
                <UserAvatar
                    userAvatar={userAvatar}
                    size={"medium"}
                    isOnBackground
                />
            </UserMenuContainer>
        </Popup>
    );
};

UserMenu.defaultProps = {
    isMobileView: false,
};

export default UserMenu;
