// LIBRARIES
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ampli } from "../../../../ampli";
import { useT } from "@transifex/react";

// TYPES
import { PreviewDateStatistic, SubjectData } from "p6m-subjects";

// NETWORKING
import { getReportPreviewData } from "../../../../networking/reports";

// COMPONENTS
import ActivityInfoBox from "../activity/ActivityInfoBox";

// STYLES
import { InfoHighlight } from "./styles";
import { Container, Entry, Header, List, Statistics, TextHeader, Text } from "../styles";

export interface ReportsPreviewProps {
    ownerId?: string;
    selectedSubject: SubjectData;
    isOwnSubject: boolean;
}

const ReportPreview: React.FC<ReportsPreviewProps> = (props) => {
    const t = useT();
    const [selectedDay, setSelectedDay] = useState<number | null>(null);
    const dayRefs = [
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
        useRef<HTMLLIElement>(null),
    ];
    const [data, setData] = useState<Array<PreviewDateStatistic> | null>(null);

    const weekday_t = t("Weekday", { _tags: "title,report,preview" });
    const dueCards_t = t("Due cards", { _tags: "title,report,preview" });
    const noData_t = t("No preview available", { _tags: "placeholder,report,preview" });

    const basic_info_t = t("This shows how many cards will be due in the next days.", {
        _tags: "report,description",
    });

    const loadData = useCallback(async () => {
        const response = await getReportPreviewData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner
        );
        const {
            data: {
                replyContent: { predictionList: data },
            },
        } = response;
        setData(data);
    }, [props.ownerId, props.selectedSubject]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "7day_preview",
        });
    }, []);

    const onDayClick = (index: number) => {
        setSelectedDay(index);
    };

    // unselect phases
    const handleClick = useCallback(
        (event: MouseEvent) => {
            const target = event.target;
            const phaseClicked = dayRefs.some((ref) => ref && target instanceof Node && ref.current?.contains(target));
            if (!phaseClicked) {
                setTimeout(() => {
                    setSelectedDay(null);
                }, 100);
            }
        },
        [dayRefs, setSelectedDay]
    );

    useEffect(() => {
        if (selectedDay !== null) {
            document.addEventListener("mousedown", handleClick);
            return () => document.removeEventListener("mousedown", handleClick);
        }
    }, [selectedDay, handleClick]);

    const weekdayNames = [
        t("Sunday", { _tags: "label,list,reports" }),
        t("Monday", { _tags: "label,list,reports" }),
        t("Tuesday", { _tags: "label,list,reports" }),
        t("Wednesday", { _tags: "label,list,reports" }),
        t("Thursday", { _tags: "label,list,reports" }),
        t("Friday", { _tags: "label,list,reports" }),
        t("Saturday", { _tags: "label,list,reports" }),
    ];
    const getWeekdayName = (entry: PreviewDateStatistic) => {
        const weekday = new Date(entry.date).getDay();
        return weekdayNames[weekday];
    };

    const renderInfoBox = () => {
        let description = basic_info_t;
        const isDaySelected = !!data && selectedDay !== null;
        const vocabularyAmount = t("{amount} cards", {
            amount: isDaySelected ? data![selectedDay!].recommendedCards : 0,
            _tags: "report,description",
        });
        if (isDaySelected) {
            description = t(
                "On {date} there will be {vocabularyAmount} cards due in total if you don't keep practicing.",
                {
                    date: getWeekdayName(data![selectedDay!]),
                    vocabularyAmount: <InfoHighlight>{vocabularyAmount}</InfoHighlight>,
                    _tags: "report,description",
                }
            );
        }

        return (
            <ActivityInfoBox
                descriptionText={description}
                loadData={loadData}
                isOwnSubject={props.isOwnSubject}
                active={isDaySelected}
            />
        );
    };

    return (
        <Container>
            {renderInfoBox()}
            <List>
                <Entry>
                    <Header>
                        <TextHeader>{weekday_t}</TextHeader>
                        <TextHeader center>{dueCards_t}</TextHeader>
                    </Header>
                </Entry>

                {data?.length ? (
                    data.map((day, index) => (
                        <Entry
                            key={`day_${index}`}
                            ref={dayRefs[index]}
                            selected={index === selectedDay}
                            onClick={() => onDayClick(index)}
                            onBlur={() => setSelectedDay(null)}
                            onFocus={() => onDayClick(index)}
                            tabIndex={1}
                        >
                            <Statistics>
                                <Text>{getWeekdayName(day)}</Text>
                                <Text center>{day.recommendedCards}</Text>
                            </Statistics>
                        </Entry>
                    ))
                ) : (
                    <Entry>
                        <Statistics>
                            <Text>{noData_t}</Text>
                        </Statistics>
                    </Entry>
                )}
            </List>
        </Container>
    );
};

export default ReportPreview;
