// LIBRARIES
import React, { FunctionComponent, useContext, useState, useCallback, useEffect } from "react";
import FocusLock, { useFocusScope } from "react-focus-lock";
import { t } from "@transifex/native";

// COMPONENTS
import Popup from "../../../basic/popup/Popup";
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

// UTILS
import { LanguageCodes, LanguagesContext } from "../../../../context/languages";

//TYPES
import { SubjectData } from "p6m-subjects";

// STYLED COMPONENTS
import { FlagImage, FlagsContainer, FlagItem, FlagImageWrapper, Arrow } from "./styles";

type Props = {
    subject?: SubjectData;
    language?: string;
    withArrow?: boolean;
    position?: any;
    displayType?: "source" | "target";
    onChange?: (langKey: string) => void;
};

const LanguageSelect: FunctionComponent<Props> = ({
    subject,
    language = "others",
    onChange,
    withArrow = false,
    position = ["bottom", "center"],
    displayType,
}) => {
    const isPublisherContent = !!subject?.subjectContent.inAppId.length;

    const { languageTranslations, getLanguageTranslation, getFlagSource } = useContext(LanguagesContext);
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState<LanguageCodes>(language as LanguageCodes);

    useEffect(() => {
        if (language !== selectedLanguage) setSelectedLanguage(language as LanguageCodes);
    }, [language]);

    const sourceLangTranslation = t("Source language", {
        _tags: "subjectItem, languageImage, altText",
    });
    const targetLangTranslation = t("Target language", {
        _tags: "subjectItem, languageImage, altText",
    });

    let altText = "";
    switch (displayType) {
        case "source":
            altText = `${sourceLangTranslation}: ${getLanguageTranslation(selectedLanguage)}`;
            break;
        case "target":
            altText = `${targetLangTranslation}: ${getLanguageTranslation(selectedLanguage)}`;
            break;
    }

    const onToggle = useCallback((isOpen: boolean) => {
        setPopupOpen(isOpen);
    }, []);

    const contentAsCallback = useCallback(
        ({ closePopup }) => {
            const { focusNext, focusPrev } = useFocusScope();
            const confirmLanguage = (languageCode: LanguageCodes) => {
                if (!!onChange) onChange(languageCode);
                setSelectedLanguage(languageCode);
                closePopup();
            };
            const handleKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, languageCode: LanguageCodes) => {
                if (event.key === "Enter") {
                    confirmLanguage(languageCode);
                }
                if (event.key === "Tab") {
                    event.preventDefault();
                    closePopup();
                }
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    focusNext();
                }
                if (event.key === "ArrowUp") {
                    event.preventDefault();
                    focusPrev();
                }
            };

            return (
                <FlagsContainer
                    data-qa="languages"
                    tabIndex={-1}
                >
                    <FocusLock autoFocus={true}>
                        {Object.entries(languageTranslations).map(([languageCode, languageNameTranslation]) => {
                            return (
                                <FlagItem
                                    key={languageCode}
                                    onClick={() => {
                                        confirmLanguage(languageCode as LanguageCodes);
                                    }}
                                    tabIndex={0}
                                    data-autofocus={languageCode === selectedLanguage}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>) => {
                                        handleKeyDown(event, languageCode as LanguageCodes);
                                    }}
                                >
                                    <span>{languageNameTranslation}</span>
                                    <img
                                        src={getFlagSource(languageCode as LanguageCodes)}
                                        alt=""
                                    />
                                </FlagItem>
                            );
                        })}
                    </FocusLock>
                </FlagsContainer>
            );
        },
        [languageTranslations, onChange, selectedLanguage]
    );

    return (
        <Popup
            autoPosition
            position={position}
            content={contentAsCallback}
            minChildWidth
            arrow
            onToggle={onToggle}
            isDisabled={isPublisherContent}
        >
            <FlagImageWrapper
                $isPublisherContent={isPublisherContent}
                tabIndex={0}
            >
                <FlagImage
                    src={getFlagSource(selectedLanguage)}
                    alt={altText}
                />
                {withArrow && (
                    <Arrow>
                        <PhaseSixIcon name={popupOpen ? "chevron-up" : "chevron-down"} />
                    </Arrow>
                )}
            </FlagImageWrapper>
        </Popup>
    );
};

export default LanguageSelect;
