import { LoginResponseReplyContent } from "p6m-response";

type UserDataProp = Pick<
    LoginResponseReplyContent,
    "roles" | "email" | "firstName" | "userDnsId" | "gdprStatus" | "isFirstWebLogin"
>;

const scriptId = "survicate-script";

const purgeSurvicateData = () => {
    const existingScript = document.getElementById(scriptId);
    if (existingScript) {
        existingScript.remove();

        document.querySelectorAll('script[src*="survicate"]').forEach((script) => script.remove());

        // Remove all link elements containing "survicate"
        document.querySelectorAll('link[href*="survicate"]').forEach((link) => link.remove());

        // Remove all style elements related to Survicate
        document.querySelectorAll('style[id*="survicate"]').forEach((style) => style.remove());

        const survicateBox = document.getElementById("survicate-box");
        if (survicateBox) survicateBox.remove();
    }

    const removeMatchingKeys = (storage: Storage) => {
        Object.keys(storage).forEach((key) => {
            if (key.includes("survi")) {
                storage.removeItem(key);
            }
        });
    };

    removeMatchingKeys(localStorage);
    removeMatchingKeys(sessionStorage);
};

export const survicateLoadAndInit = (userData: UserDataProp) => {
    const { gdprStatus = "" } = userData;

    const analyzeData = gdprStatus.includes("analytics");

    if (analyzeData) {
        if (!document.getElementById(scriptId)) {
            const script = document.createElement("script");
            script.id = scriptId;
            script.src = "https://survey.survicate.com/workspaces/7697bcf2843ee30112ae75a9b7cd18e9/web_surveys.js";
            script.async = true;

            document.head.appendChild(script);
        } else {
            purgeSurvicateData();
            survicateLoadAndInit(userData);
        }
    } else {
        purgeSurvicateData();
    }
};
