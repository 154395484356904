// REACT
import React from "react";
import { useT } from "@transifex/react";

// TYPES
import { IUserGoals } from "p6m-goalsData";

// STYLED COMPONENTS
import styled from "styled-components";

// COMPONENTS
import SettingsToggleInput, { ToggleTypes } from "../../basic/settingsToggleInput/SettingsToggleInput";
import SettingsItemWrapper from "../settingsItemWrapper/SettingsItemWrapper";

// UTILS
import { Theme } from "../../../themes/constants";

export interface SettingsAppearanceProps {
    currentTheme: Theme;
    userGoals: IUserGoals;
    updateTheme: (newTheme: Theme) => any;
}

const MainWrapper = styled.div`
    margin-top: ${(props) => props.theme.base.spacing};
`;

const SettingsAppearance: React.FC<SettingsAppearanceProps> = (props) => {
    const t = useT();

    const t_locked = t("Unlock in achievements", { _tags: "appearanceSettings" });

    const t_designs = t("theme_change_label1", { _tags: "appearanceSettings" });
    const t_designsInput1 = t("gray_theme_name", { _tags: "appearanceSettings" });
    const t_designsInput2 = t("dark_theme_name", { _tags: "appearanceSettings" });
    const t_designsInputInfo = t("theme_change_information1", { _tags: "appearanceSettings" });

    const t_additionalDesigns = t("theme_change_label2", { _tags: "appearanceSettings" });
    const t_additionalDesignsInput1 = t("orange_theme_name", { _tags: "appearanceSettings" });
    const t_additionalDesignsInput2 = t("pink_theme_name", { _tags: "appearanceSettings" });
    const t_additionalDesignsInput3 = t("blue_theme_name", { _tags: "appearanceSettings" });
    const t_additionalDesignsInfo = t("theme_change_information2", { _tags: "appearanceSettings" });

    const { currentTheme, userGoals, updateTheme } = props;
    const areMainDesignsDisabled = !userGoals.goals || !userGoals.goals.learned3DaysInRow;
    const areAdditionalDesignsDisabled = !userGoals.goals || !userGoals.goals.learned7DaysIn10DaysPeriod;

    return (
        <MainWrapper>
            <SettingsItemWrapper
                title={t_designs}
                description={t_designsInputInfo}
                disabled={areMainDesignsDisabled}
                disabledDescription={t_locked}
            >
                <SettingsToggleInput
                    labelText={t_designsInput1}
                    checkedValue={currentTheme === Theme.MAIN}
                    toggleType={ToggleTypes.CheckIcon}
                    isDisabled={areMainDesignsDisabled}
                    onValueChanged={(checked) => {
                        if (!!checked) updateTheme(Theme.MAIN);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_designsInput2}
                    checkedValue={currentTheme === Theme.DARK}
                    toggleType={ToggleTypes.CheckIcon}
                    isDisabled={areMainDesignsDisabled}
                    onValueChanged={(checked) => {
                        if (!!checked) updateTheme(Theme.DARK);
                    }}
                />
            </SettingsItemWrapper>

            <SettingsItemWrapper
                title={t_additionalDesigns}
                description={t_additionalDesignsInfo}
                disabled={areAdditionalDesignsDisabled}
                disabledDescription={t_locked}
            >
                <SettingsToggleInput
                    labelText={t_additionalDesignsInput1}
                    checkedValue={currentTheme === Theme.ORANGE}
                    toggleType={ToggleTypes.CheckIcon}
                    isDisabled={areAdditionalDesignsDisabled}
                    onValueChanged={(checked) => {
                        if (!!checked) updateTheme(Theme.ORANGE);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_additionalDesignsInput2}
                    checkedValue={currentTheme === Theme.PINK}
                    toggleType={ToggleTypes.CheckIcon}
                    isDisabled={areAdditionalDesignsDisabled}
                    onValueChanged={(checked) => {
                        if (!!checked) updateTheme(Theme.PINK);
                    }}
                />
                <SettingsToggleInput
                    labelText={t_additionalDesignsInput3}
                    checkedValue={currentTheme === Theme.BLUE}
                    toggleType={ToggleTypes.CheckIcon}
                    isDisabled={areAdditionalDesignsDisabled}
                    onValueChanged={(checked) => {
                        if (!!checked) updateTheme(Theme.BLUE);
                    }}
                />
            </SettingsItemWrapper>
        </MainWrapper>
    );
};

export default SettingsAppearance;
