import React, { FunctionComponent } from "react";

import { StyledCounter, StyledPhaseSixIcon } from "./styles";
import { useT } from "@transifex/react";
import { accessibleActionProps } from "../../../../helpers/Accessibility";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

export type Props = {
    count?: number;
};

const Container = styled.div`
    padding: 0px 8px;
    display: flex;
    align-items: center;
`;

const RocketItem: FunctionComponent<Props> = (props) => {
    const { count } = props;
    const t = useT();

    const posStreakTranslation = t("Days learned in a row:", { _tags: "rocket,header" });
    const negStreakTranslation = t("Days not learned in a row:", { _tags: "rocket,header" });

    let tooltipContent = posStreakTranslation + " 0";
    if (count && count >= 0) {
        tooltipContent = posStreakTranslation + " " + count;
    } else if (count) {
        tooltipContent = negStreakTranslation + " " + count * -1;
    }

    const tooltipProps = {
        "data-tip": true,
        "data-for": "TopNavRocketTooltip",
    };

    return (
        <Container
            {...accessibleActionProps(undefined, 1000)}
            {...tooltipProps}
        >
            {!!count && <StyledCounter>{count}</StyledCounter>}
            <StyledPhaseSixIcon
                aria-label={tooltipContent}
                name="rocket"
                size="medium"
            />
            <ReactTooltip
                place="bottom"
                id="TopNavRocketTooltip"
                effect="solid"
            >
                {tooltipContent}
            </ReactTooltip>
        </Container>
    );
};

export default RocketItem;
