import {
    actions,
    selectors as subjectSelectors,
    TComplexSubjectsRestProps,
    SubjectTargetType,
    TComplexUnitsRestProps,
} from "./subjectsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, put, call, select, all } from "redux-saga/effects";
import {
    UserSubjectsResponseData,
    SubjectUnitData,
    SubjectUnitCountResponseData,
    SubjectData,
    FamilySubjectsResponseData,
    GroupData,
    SubjectCardCountData,
    ResponseLibrarySubjectData,
} from "p6m-subjects";
import { IdToOwnerId } from "p6m-shared";
import { IResponse } from "p6m-response";
import { AxiosResponse } from "axios";
import { SharedTestMetaData } from "p6m-tests";

import {
    getUserSubjectsData,
    getFamilySubjects,
    getLibrarySubjects,
    deleteSubject,
    postSubject,
    getAllSubjectsCardsCounts,
    resetSubject,
    PostSubjectProps,
    getSubjectGroupedCardCount,
    getSubjectIds,
    getSubjectListData,
    BulkResponse,
} from "../../networking/subjects";
import { PostUnitProps } from "../../networking/units";
import { actions as userActions } from "../user/userSlice";
import { actions as responseActions } from "../response/responseSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { selectors as testsSelectors } from "../tests/testsSlice";
import { createAssignmentModal } from "../tests/testsSaga";
import { getShowWelcomeScreenData } from "../../helpers/GetShowWelcomeScreen";

import {
    getSubjectUnits,
    getSubjectUnitsCount,
    deleteUnit,
    postUnit,
    getAllUnits,
    getAllSubjectUnitsCount,
} from "../../networking/units";
import { ResponseMessageKeys } from "../../components/connected/response";
import { getDefaultUnitId } from "../../helpers/Units";
import { defaultUnitOrder } from "../../constants/ContentConstants";
import { t } from "@transifex/native";
import dayjs from "dayjs";

export function* loadFamilySubjectsSaga() {
    try {
        const response: AxiosResponse<IResponse<FamilySubjectsResponseData>> = yield call(getFamilySubjects);
        // only handle if server responded
        if (response) {
            if (response.data.httpCode === 401) {
                yield put(userActions.logoutUserWithoutPermission());
            } else if (response.data.httpCode === 200) {
                const {
                    data: {
                        replyContent: { subjects: resSubject = [], groupsData },
                    },
                } = response;
                const subjects = resSubject!.map((subject) => {
                    const subjectData = subject[1];
                    subjectData["subjects"] = subjectData.subjects || [];

                    const groupId = subject[0].groupId;
                    const groupData: GroupData | undefined = groupsData[groupId] || undefined;

                    if (groupData) {
                        groupData["isAdmin"] = subject[0].admin;
                        subjectData["userName"] = subject[0].name;
                        subjectData["groupData"] = groupData;
                    }

                    return subjectData;
                });

                yield put(actions.setFamilySubjects(subjects));
            } else {
                yield put(
                    responseActions.showResponse({
                        type: "WARNING",
                        responseMessage: ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_FAMILY,
                    })
                );
            }
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* loadUserSubjectsDataSaga(
    action: PayloadAction<{ withUnits?: boolean; subjectIdForTestModalUpdate?: string }>
) {
    yield put(appStatusActions.setLoading(true));
    const { payload } = action;
    try {
        const response: AxiosResponse<IResponse<UserSubjectsResponseData>> = yield call(getUserSubjectsData);
        // only handle if server responded
        if (response) {
            if (response.data.httpCode === 401) {
                yield put(userActions.logoutUserWithoutPermission());
            } else if (response.data.httpCode === 200) {
                const {
                    data: { replyContent },
                } = response;

                const subjects: SubjectData[] = replyContent.subjects?.map((subjectWrapper) => subjectWrapper[1]) || [];

                yield put(actions.setUserSubjects(subjects));

                if (payload?.subjectIdForTestModalUpdate) {
                    yield handleTestSubjectLoaded(payload.subjectIdForTestModalUpdate);
                }

                if (payload?.withUnits) {
                    yield loadUserSubjectsUnitsSaga();
                }
            } else {
                yield put(
                    responseActions.showResponse({
                        type: "WARNING",
                        responseMessage: ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_USER,
                    })
                );
            }
        }
    } catch (e) {
        yield put(appStatusActions.setMaintenance(true));
    } finally {
        yield put(appStatusActions.setLoading(false));
        yield put(actions.loadUserSubjectsDataDone());
    }
}

export function* loadSubjectListContentSaga() {
    yield put(appStatusActions.setLoading(true));

    try {
        const getSubjectIdsRes: AxiosResponse<IResponse<{ ids: IdToOwnerId[] }>> = yield call(getSubjectIds);
        const subjectIds = getSubjectIdsRes.data.replyContent.ids;
        const subjectListDataRes: AxiosResponse<IResponse<BulkResponse>> = yield call(getSubjectListData, subjectIds);

        if (getSubjectIdsRes.data.httpCode === 401 || subjectListDataRes.data.httpCode === 401) {
            yield put(userActions.logoutUserWithoutPermission());
        }

        yield put(actions.setSubjectListContent(subjectListDataRes.data.replyContent.data));
    } catch (e) {
        yield put(
            responseActions.showResponse({
                type: "WARNING",
                responseMessage: ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_USER,
            })
        );
        yield put(appStatusActions.setMaintenance(true));
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* loadUserSubjectUnitsSaga({
    payload,
}: PayloadAction<string | { subjectId: string; target: SubjectTargetType }>) {
    const subjectId = typeof payload === "string" ? payload : payload.subjectId;
    const target = typeof payload === "string" ? "subjects" : payload.target;
    yield put(appStatusActions.setLoading(true));
    try {
        const subject: SubjectData | undefined = yield select(subjectSelectors.getSubjectById(subjectId, target));

        if (!subject) {
            const action = (
                {
                    subjects: loadSubjectListContentSaga,
                    librarySubjects: loadLibrarySubjectsSaga,
                } as Record<SubjectTargetType, any>
            )[target];
            yield action();
        }

        const {
            data: { replyContent: response },
        }: AxiosResponse<IResponse<{ units: SubjectUnitData[] }>> = yield call(getSubjectUnits, subjectId);
        const units = response.units;

        const {
            data: { replyContent: countResponse },
        }: AxiosResponse<IResponse<{ units: SubjectUnitCountResponseData[] }>> = yield call(
            getSubjectUnitsCount,
            subjectId
        );
        const counts = countResponse.units;

        const result: SubjectData["units"] = units.map((unit: SubjectUnitData) => {
            const {
                unitId: { id },
            } = unit;
            const count =
                counts.find((count: SubjectUnitCountResponseData) => {
                    const {
                        unitId: { id: findId },
                    } = count;
                    return id === findId;
                }) || ({} as SubjectUnitCountResponseData);
            return { ...unit, ...count };
        });

        yield put(
            actions.setSubjectUnits({
                subjectId,
                units: result,
                target,
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* loadUserSubjectsUnitsSaga() {
    try {
        const data: { [subjectId: string]: SubjectData["units"] } = {};
        const {
            data: {
                replyContent: { units },
            },
        }: AxiosResponse<IResponse<{ units: SubjectUnitData[] }>> = yield call(getAllUnits);
        const {
            data: {
                replyContent: { units: unitsCounts },
            },
        }: AxiosResponse<IResponse<{ units: SubjectUnitCountResponseData[] }>> = yield call(getAllSubjectUnitsCount);

        for (let i = 0; i < units.length; i++) {
            const unitId = units[i].unitId.id;
            const unitCount = unitsCounts.find((uc) => uc.unitId.id === unitId);
            const subjectId = units[i].unitContent.subjectIdToOwner.id;
            const subjectUnits = data[subjectId] || [];
            subjectUnits.push({
                ...units[i],
                cardCount: unitCount?.cardCount || 0,
            });
            data[subjectId] = subjectUnits;
        }
        yield put(actions.setSubjectsUnits(data));
    } catch (e) {
        console.log(e);
    }
}

export function* loadLibrarySubjectsSaga() {
    yield put(appStatusActions.setLoading(true));
    try {
        const response: AxiosResponse<IResponse<{ subjects: ResponseLibrarySubjectData[] }>> = yield call(
            getLibrarySubjects
        );
        if (response.data.httpCode === 401) {
            yield put(userActions.logoutUserWithoutPermission());
        } else if (response.data.httpCode === 200) {
            const {
                data: { replyContent },
            } = response;
            const cardsCountsForAllSubjects: SubjectCardCountData[] = (yield getAllSubjectsCardsCounts()).data
                .replyContent.subjectCardCounts;
            const subjectsWithCardsCounts =
                replyContent.subjects.map((currentSubject) => {
                    const today = dayjs();
                    const isExpired = !!currentSubject.subjectMetadata.expirationDate && today.isAfter(currentSubject.subjectMetadata.expirationDate, "days");

                    const cardsCountForSubject = cardsCountsForAllSubjects.find(
                        (subjectCardCountResult) => subjectCardCountResult.subjectId.id === currentSubject.subjectId.id
                    );

                    return {
                        allCardsCount: cardsCountForSubject?.cardCount || 0,
                        ...currentSubject,
                        isExpired,
                    } as SubjectData;
                });

            yield put(actions.setLibrarySubjects(subjectsWithCardsCounts));
        } else {
            yield put(
                responseActions.showResponse({
                    type: "WARNING",
                    responseMessage: ResponseMessageKeys.COULD_NOT_LOAD_SUBJECTS_LIBRARY,
                })
            );
        }
    } catch (e) {
        yield put(appStatusActions.setMaintenance(true));
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

export function* resetSubjectSaga({ payload }: PayloadAction<string>) {
    const subject: SubjectData = yield select(subjectSelectors.getSubjectById(payload));
    yield call(
        resetSubject,
        subject.subjectMetadata.subjectIdToOwner.ownerId,
        subject.subjectMetadata.subjectIdToOwner.id
    );
    yield put(actions.loadUserSubjectsData({ withUnits: true }));
}

async function createSubjectWithDefaultUnit(...props: Parameters<typeof postSubject>) {
    try {
        const [{ subjectId, ownerId }] = props;
        const createdSubjectResult: any = await postSubject(...props);
        const unitIdToCreate = getDefaultUnitId(subjectId);
        await postUnit({
            unitId: unitIdToCreate,
            ownerId,
            subjectId,
            name: t("General", {}),
            order: defaultUnitOrder,
        });
        return createdSubjectResult;
    } catch (e) {
        console.log(e);
    }
}

function complexRestGenerator<T extends TComplexSubjectsRestProps | TComplexUnitsRestProps>(type: "unit" | "subject") {
    const createSubject = (props: Required<PostSubjectProps & { withDefaultUnit?: boolean }>) => {
        const { withDefaultUnit, ...restProps } = props;
        return withDefaultUnit ? createSubjectWithDefaultUnit(restProps) : postSubject(restProps);
    };
    const updateSubject = postSubject;

    const createUnit = (props: PostUnitProps) => postUnit(props);
    const updateUnit = postUnit;

    const actionsByTypeMap = {
        unit: {
            created: createUnit,
            updated: updateUnit,
            deleted: deleteUnit,
        },
        subject: {
            created: createSubject,
            updated: updateSubject,
            deleted: deleteSubject,
        },
    };

    const actionsRelation: any = {
        created: actionsByTypeMap[type].created,
        updated: actionsByTypeMap[type].updated,
        deleted: actionsByTypeMap[type].deleted,
    };

    const deleteDataMap = ({ ownerId, subjectId, unitId }: T | any) => {
        const idToDelete = type === "subject" ? subjectId : unitId;
        return {
            ownerId,
            subjectId,
            id: idToDelete,
            unitId,
        };
    };

    const getNameMaps: Record<string, any> = {
        unit: ({ name }: PostUnitProps) => name,
        subject: ({ data: { name } = { name: "" } }: PostSubjectProps) => name,
    };

    const getResponseMessageKey: Record<string, any> = {
        unit: {
            deleted: ResponseMessageKeys.UNIT_DELETED,
            created: ResponseMessageKeys.UNIT_CREATED,
            updated: ResponseMessageKeys.UNIT_UPDATED,
        },
        subject: {
            deleted: ResponseMessageKeys.SUBJECT_DELETED,
            created: ResponseMessageKeys.SUBJECT_CREATED,
            updated: ResponseMessageKeys.SUBJECT_UPDATED,
        },
    };

    return function* ({ payload }: PayloadAction<T>) {
        yield put(appStatusActions.setLoading(true));
        try {
            const requests: any[] = Object.entries(payload).reduce((result, entry) => {
                const [requestType, value = []] = entry;

                const requests = value.map((entryData: any) => {
                    const data = requestType === "deleted" ? deleteDataMap(entryData) : entryData;
                    return call(actionsRelation[requestType], data);
                });

                return result.concat(requests);
            }, [] as any);

            const notifications = Object.entries(payload)
                .reduce((result, entry) => {
                    const [requestType, value = []] = entry;

                    const notification = value
                        .map((entryData: any) => {
                            return getNameMaps[type](entryData);
                        })
                        .map((name: string) => [getResponseMessageKey[type][requestType], name]);

                    return result.concat(notification);
                }, [] as any)
                .map((response: [ResponseMessageKeys, string]) =>
                    put(
                        responseActions.showResponse({
                            type: "SUCCESS",
                            responseMessage: response[0],
                            responseMessageReplacements: [{ name: response[1] }],
                        })
                    )
                );

            yield all(requests);

            yield put(actions.loadLibrarySubjects());

            if (notifications?.length) {
                yield all(notifications);
            }
        } catch (e) {
            console.log(e);
        } finally {
            yield put(appStatusActions.setLoading(false));
        }
    };
}

function* loadSubjectGroupedCardCountSaga({
    payload,
}: PayloadAction<{ ownerId: string; subjectId: string; onLoadingFinished: () => void }>) {
    yield put(appStatusActions.setLoading(true));

    const activeSubjectId = payload.subjectId;
    const subjects = yield select(subjectSelectors.subjects);

    try {
        const { data } = yield call(getSubjectGroupedCardCount, activeSubjectId, payload.ownerId);

        const updatedSubjects = subjects.map((subject: SubjectData) => {
            const isTargetSubject = subject.subjectMetadata.subjectIdToOwner.id === activeSubjectId;
            return {
                ...subject,
                groupedCardCount: isTargetSubject ? data.replyContent : subject.groupedCardCount,
            };
        });

        yield put(actions.setUserSubjects(updatedSubjects));
    } catch (e) {
        console.log(e);
    } finally {
        payload.onLoadingFinished();
    }

    yield put(appStatusActions.setLoading(false));
}

export const complexSubjectsRest = complexRestGenerator<TComplexSubjectsRestProps>("subject");

export const complexUnitsRest = complexRestGenerator<TComplexUnitsRestProps>("unit");

export function* subjectsSaga() {
    yield takeEvery(actions.loadSubjectListContent.type, loadSubjectListContentSaga);
    yield takeEvery(actions.loadUserSubjectsData.type, loadUserSubjectsDataSaga);
    yield takeEvery(actions.loadFamilySubjects.type, loadFamilySubjectsSaga);
    yield takeEvery(actions.loadSubjectUnits.type, loadUserSubjectUnitsSaga);
    yield takeEvery(actions.loadLibrarySubjects.type, loadLibrarySubjectsSaga);
    yield takeEvery(actions.complexSubjectsRest.type, complexSubjectsRest);
    yield takeEvery(actions.complexUnitsRest.type, complexUnitsRest);
    yield takeEvery(actions.resetSubject.type, resetSubjectSaga);
    yield takeEvery(actions.loadSubjectGroupedCardCount.type, loadSubjectGroupedCardCountSaga);
    yield takeEvery(actions.loadUserSubjectsUnits.type, loadUserSubjectsUnitsSaga);
}

/*
HELPER Functions
 */

function* handleTestSubjectLoaded(subjectId: string) {
    const sharedTestMetaData: null | SharedTestMetaData = yield select(testsSelectors.sharedTestMetaData);
    if (sharedTestMetaData && sharedTestMetaData.subjectId === subjectId) {
        //Welcome screen shall only be shown, if user has no subjects. User just assigned subject of sharedTest succesfully to him/herself. So no need to show welcome modal anymore
        const { deleteWelcomeScreenCookie } = getShowWelcomeScreenData();
        deleteWelcomeScreenCookie();

        const subjectData = yield select(subjectSelectors.getSubjectById(sharedTestMetaData.subjectId));

        const modal = yield createAssignmentModal(
            +sharedTestMetaData.cardsCount,
            subjectData.subjectContent,
            subjectData?.subjectMetadata,
            subjectData
        );
        yield put(actions.setSubjectAssignmentModal(modal));
        yield put(appStatusActions.setLoading(false));
    }
}
