// REACT
import React, { memo, useCallback, useRef, useEffect } from "react";

// LIBRARIES
import { useT } from "@transifex/react";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.bubble.css";
import ReactQuill from "react-quill";
import "quill-paste-smart";
import { ampli } from "../../../ampli";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import { SpecialCharSelect, Props as SpecialCharProps } from "../../basic/specialCharSelect";
import { Files, Props as FileProps } from "../addCards/files";
import { IdTypeDivider } from "../addCards/files/Files";
import { RecordAudio } from "../addCards/recordAudio";

// STYLED COMPONENTS
import {
    Editor,
    EditorContainer,
    MediaContainer,
    MediaItem,
    EditorFooter,
    AnnotationWrapper,
    AnnotationIconWrapper,
    AnnotationTextarea,
    CharsWrapper,
} from "./styles";

type Positions = Partial<Record<Required<FileProps>["type"], Required<FileProps>["position"]>>;

export interface Props {
    configuration: "ADD" | "PRACTICE";
    type: "question" | "answer";
    languageCode?: string;
    value: string;
    placeholder: string;
    onChange: ReactQuill["props"]["onChange"];
    tabIndex?: number;
    files: FileProps["files"];
    positions?: Positions;
    onFilesChange?: FileProps["onChange"];
    annotation?: string;
    onAnnotationChange?: (value: string) => void;
    getRef?: (editorRef: any) => void;
    autoFocus?: boolean;
}

const getEditor = (ref: React.RefObject<ReactQuill>) => {
    const { current } = ref;
    if (!current) return;
    return current.getEditor();
};

const modules = {
    toolbar: [["bold", "italic", "underline"], [{ color: [] }], ["clean"]],
    keyboard: {
        bindings: {
            tab: false,
        },
    },
    clipboard: {
        allowed: {
            tags: ["br", "span", "p", "ul", "ol", "li"],
        },
        keepSelection: true,
        substituteBlockElements: false,
    },
};

const formats = ["bold", "italic", "underline", "color"];

const editorLimit = 8192;

const fileLimit: number = 2;

const defaultFilesValue: Props["files"] = [];

const defaultPositionsValue: Props["positions"] = {};

const specialCharSelectPosition: SpecialCharProps["position"] = ["bottom", "right"];

const CardEditor: React.FC<Props> = (props) => {
    const {
        tabIndex,
        value,
        type,
        languageCode,
        placeholder,
        onChange,
        files = defaultFilesValue,
        positions = defaultPositionsValue,
        onFilesChange,
        annotation = "",
        onAnnotationChange,
        getRef,
        autoFocus = false,
    } = props;

    const t = useT();
    const quillEditorRef = useRef<ReactQuill>(null);

    const t_annotationPlaceholder = t("Click to add annotation", {});
    const t_placeholder = t(placeholder, {});

    const disableRecord: boolean =
        fileLimit <= files.map((file) => file.split(IdTypeDivider)[1]).filter((type) => type === "audio").length;

    useEffect(() => {
        if (!quillEditorRef || !getRef) return;
        getRef(quillEditorRef);
    }, [quillEditorRef, getRef]);

    useEffect(() => {
        if (!autoFocus) return;
        const editor = getEditor(quillEditorRef);
        if (!editor) return;

        setTimeout(() => {
            editor.focus();
            editor.setSelection(editor.getLength(), 0);
        });

        editor.once("text-change", (delta, oldContents, source) => {
            if (source !== "api") return;
            setTimeout(() => {
                editor.focus();
                editor.setSelection(editor.getLength(), 0);
            });
        });
    }, [quillEditorRef, autoFocus]);

    useEffect(() => {
        const editor = getEditor(quillEditorRef);
        const element = editor?.root;
        if (!element) return;

        const tabFocusListener = () => {
            if (!editor) return;
            editor.setSelection(editor.getLength(), 0);
        };

        element.addEventListener("tabFocused", tabFocusListener);

        return () => element.removeEventListener("tabFocused", tabFocusListener);
    }, [quillEditorRef]);

    const handleCharAdd = useCallback(
        (newChar: string) => {
            const editor = getEditor(quillEditorRef);
            if (!editor) return;

            const { index } = editor.getSelection(true);

            editor.insertText(index, newChar);
        },
        [quillEditorRef]
    );

    const onKeyDown: ReactQuill["props"]["onKeyDown"] = useCallback(
        (e) => {
            const editor = getEditor(quillEditorRef);
            const editorLength = editor?.getLength() || 0;
            if (editorLength > editorLimit) e.preventDefault();
        },
        [quillEditorRef]
    );

    const onRecordAudio = useCallback(
        (newAudio: string) => {
            if (!onFilesChange) return;
            onFilesChange([...files, newAudio]);
            ampli.mediaUploaded({ attachment_type: "recording" });
        },
        [files, onFilesChange]
    );

    useEffect(() => {
        // Make formatting buttons non tabbable
        if (!!quillEditorRef.current?.editor?.root) {
            const wrapperElement = quillEditorRef.current.editor.root.closest(".quill");

            if (wrapperElement) {
                wrapperElement.querySelectorAll(".ql-toolbar button, .ql-toolbar .ql-picker-label").forEach((btn) => {
                    btn.setAttribute("tabindex", "-1");
                });
            }
        }
    }, [quillEditorRef]);

    return (
        <EditorContainer>
            <MediaContainer>
                {(["image", "audio"] as Extract<FileProps["type"], "image" | "audio">[]).map((type) => {
                    const position = positions[type] || positions["all"];
                    return (
                        <MediaItem key={type}>
                            <Files
                                files={files}
                                position={position}
                                onChange={onFilesChange}
                                type={type}
                                limit={fileLimit}
                            />
                        </MediaItem>
                    );
                })}
                {!disableRecord && (
                    <MediaItem>
                        <RecordAudio
                            onChange={onRecordAudio}
                            disabled={disableRecord}
                        />
                    </MediaItem>
                )}
            </MediaContainer>
            <Editor
                onKeyDown={onKeyDown}
                ref={quillEditorRef}
                value={value}
                placeholder={t_placeholder}
                onChange={onChange}
                modules={modules}
                formats={formats}
                preserveWhitespace
                tabIndex={tabIndex}
            />
            <EditorFooter>
                {!!onAnnotationChange && (
                    <AnnotationWrapper>
                        <AnnotationIconWrapper>
                            <PhaseSixIcon name="edit-avatar" />
                        </AnnotationIconWrapper>
                        <AnnotationTextarea
                            data-qa={type === "question" ? "notequestion" : "noteanswer"}
                            value={annotation}
                            placeholder={t_annotationPlaceholder}
                            onChange={({ target: { value } }: any) => onAnnotationChange(value)}
                        />
                    </AnnotationWrapper>
                )}
                <CharsWrapper>
                    <SpecialCharSelect
                        editorRef={quillEditorRef}
                        onCharSelect={handleCharAdd}
                        position={specialCharSelectPosition}
                        lang={languageCode}
                    />
                </CharsWrapper>
            </EditorFooter>
        </EditorContainer>
    );
};

export default memo(CardEditor);
