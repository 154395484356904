// LIBRARIES
import React from "react";
import { T, useT } from "@transifex/react";
import styled from "styled-components";
import moment from "moment";
import { SubjectData, SubjectActions } from "p6m-subjects";

// HELPERS
import { getShopUrl } from "../../../../helpers/Subjects";
import { accessibleActionProps } from "./../../../../helpers/Accessibility";

// COMPONENTS
import PhaseSixIcon from "../../../basic/phaseSixIcon/PhaseSixIcon";

export interface UserSubjectsListEntryActionProps {
    type: "due" | "inactive" | "further" | "expired";
    subject: SubjectData;
    subjectActions: SubjectActions;
    dueCardCount?: number;
    shouldPracticeToday?: number;
    nextPracticeDate?: string;
    userId: string | undefined;
    tabIndex?: number;
}

const Action = styled.div`
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    margin-left: ${(props) => props.theme.base.spacingSmall};
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const Link = styled.a`
    font-size: ${(props) => props.theme.base.bigSize};
    font-weight: ${(props) => props.theme.base.weight};
    font-family: ${(props) => props.theme.base.fontFamily};
    margin-left: ${(props) => props.theme.base.spacingSmall};
    color: ${(props) => props.theme.colors.text};
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const DefaultAction = styled(Action)`
    color: ${(props) => props.theme.colors.text};
    text-transform: uppercase;
    cursor: pointer;
`;
const HighlightAction = styled(Action)`
    color: ${(props) => props.theme.colors.primary};
    text-transform: uppercase;
    cursor: pointer;
`;
const PracticeDueLaterAction = styled(Action)`
    color: ${(props) => props.theme.colors.green};
    cursor: default;
`;
const ActionText = styled.span`
    margin: 0 ${(props) => props.theme.base.spacingTiny};
`;

const UserSubjectsListEntryAction: React.FC<UserSubjectsListEntryActionProps> = (props) => {
    const t = useT();
    const subjectId = props.subject.subjectMetadata.subjectIdToOwner.id;
    const hasCardsDue = props.shouldPracticeToday && props.shouldPracticeToday > 0;

    const calculateDiffDays = (nextPracticeDate: string) => {
        const date = new Date(nextPracticeDate);
        const formattedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        const eventdate = moment(formattedDate);
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const todaysdate = moment(today);
        return eventdate.diff(todaysdate, "days");
    };

    const renderPracticeDueLaterAction = (diffDays: number) => {
        const practiceDueInfo =
            diffDays !== 1
                ? t("Practice due in {x} days", { x: diffDays, _tags: "subject,practiceDue" })
                : t("Practice due in 1 day", { x: diffDays, _tags: "subject,practiceDue" });

        return (
            <PracticeDueLaterAction>
                <ActionText>{practiceDueInfo}</ActionText>
                <PhaseSixIcon name="clock" />
            </PracticeDueLaterAction>
        );
    };

    if (props.type === "due") {
        const ownerId = props.subject.subjectMetadata.subjectIdToOwner.ownerId;
        if (hasCardsDue) {
            return (
                <HighlightAction
                    {...accessibleActionProps(
                        () => props.subjectActions.practice(ownerId, subjectId, false, props.shouldPracticeToday),
                        props.tabIndex
                    )}
                >
                    <ActionText>
                        <T _str="practice" />
                    </ActionText>
                    <PhaseSixIcon
                        name="learn"
                        highlighted
                    />
                    <ActionText>{props.shouldPracticeToday}</ActionText>
                </HighlightAction>
            );
        }
        return (
            <DefaultAction
                {...accessibleActionProps(
                    () => props.subjectActions.practiceMore(ownerId, subjectId, false),
                    props.tabIndex
                )}
            >
                <ActionText>
                    <T _str="practice more" />
                </ActionText>
                <PhaseSixIcon name="learn" />
            </DefaultAction>
        );
    } else if (props.type === "inactive") {
        let diffDays;
        if (props.nextPracticeDate) {
            diffDays = calculateDiffDays(props.nextPracticeDate);
        }
        if (diffDays && 0 < diffDays && diffDays <= 2000) {
            return renderPracticeDueLaterAction(diffDays);
        } else {
            return (
                <DefaultAction
                    {...accessibleActionProps(() => props.subjectActions.activate(subjectId), props.tabIndex)}
                >
                    <ActionText>
                        <T _str="Los geht's" />
                    </ActionText>
                    <PhaseSixIcon name="annotation" />
                </DefaultAction>
            );
        }
    } else if (props.type === "further") {
        // if has nextPracticeDate
        let diffDays;
        if (props.nextPracticeDate) {
            diffDays = calculateDiffDays(props.nextPracticeDate);
        }
        if (diffDays && 0 < diffDays && diffDays <= 2000) {
            return renderPracticeDueLaterAction(diffDays);
        }
        return (
            <DefaultAction {...accessibleActionProps(() => props.subjectActions.add(subjectId), props.tabIndex)}>
                <ActionText>
                    <T _str="Add cards" />
                </ActionText>
                <PhaseSixIcon name="add" />
            </DefaultAction>
        );
    }

    return (
        <Link
            href={getShopUrl(subjectId)}
            target="_blank"
            tabIndex={props.tabIndex}
        >
            <ActionText>
                <T _str="Unlock" />
            </ActionText>
            <PhaseSixIcon name="lock" />
        </Link>
    );
};

export default UserSubjectsListEntryAction;
