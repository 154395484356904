// DEPS
import React, { useEffect, useState, useCallback } from "react";
import { useT } from "@transifex/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AxiosResponse } from "axios";

// TYPES
import { IResponse } from "p6m-response";
import { DictionaryWordResults } from "p6m-dictionary";

// STATE
import { actions, selectors } from "../../redux/add/addSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";
import { actions as responseActions } from "../../redux/response/responseSlice";

// HOOKS
import { useRequest } from "../../hooks/useRequest";
import { useAppVersionCheck } from "../../hooks/useAppVersionCheck";

// NETWORK
import { getWordDetailed } from "../../networking/dictionary";

// HELPERS
import ResponseConstants from "../../constants/ResponseConstants";
import { isSupported } from "../../helpers/Dictionary";

// COMPONENTS
import Component from "./Add";

const AddWrapper: React.FC = () => {
    const { cardId: cardIdFromUrl, ownerId: ownerIdFromUrl } = useParams<{ cardId?: string; ownerId?: string }>();
    const userId = useSelector(userSelectors.userId);
    const [questionRef, setQuestionRef] = useState<any>();
    const [answerRef, setAnswerRef] = useState<any>();
    const [isDropdownsOpenCounter, setDropdownsOpenCounter] = useState<number>(0);

    const modal = useSelector(selectors.getAddCardModal);
    const isSaving = useSelector(selectors.isSaving);

    useAppVersionCheck();

    const t = useT();

    const t_canAddAnnotations = t("You can add an annotation to both, the question and the answer.", {});
    const t_publisherContentCanNotBeModified = t(
        "Direct modification of cards from bought publisher contents is not possible.",
        {}
    );

    const dispatch = useDispatch();

    const isMyCard: boolean = !ownerIdFromUrl || !cardIdFromUrl || userId === ownerIdFromUrl;

    // use effect to start creating card
    useEffect(
        function () {
            if (ownerIdFromUrl && cardIdFromUrl) return;

            dispatch(actions.setCardId(undefined)); // clear cardId if it exists
            return () => {
                dispatch(actions.clearAddCardContents()); // clear saved content
            };
        },
        [dispatch, cardIdFromUrl, ownerIdFromUrl]
    );

    // use effect to start edit card and fetch all info
    useEffect(() => {
        if (!ownerIdFromUrl || !cardIdFromUrl) return;

        dispatch(
            actions.fetchCardContent({
                ownerId: ownerIdFromUrl,
                cardId: cardIdFromUrl,
            })
        );
    }, [dispatch, cardIdFromUrl, ownerIdFromUrl]);

    // use effect for non-editable card (if it isn't your card)
    useEffect(
        function () {
            if (isMyCard) return;

            dispatch(
                responseActions.showResponse({
                    type: ResponseConstants.WARNING,
                    text: [t_canAddAnnotations, t_publisherContentCanNotBeModified],
                })
            );
        },
        [dispatch, isMyCard, t_canAddAnnotations, t_publisherContentCanNotBeModified]
    );

    useAutoComplete(answerRef, questionRef);

    useEffect(() => {
        return () => {
            dispatch(actions.clearAddCardContents());
        };
    }, [dispatch]);

    const onDropdownToggleCallback = useCallback((state: boolean) => {
        const counterChange: number = state ? 1 : -1;
        setDropdownsOpenCounter((prevState: number) => prevState + counterChange);
    }, []);

    return (
        <Component
            getQuestionRef={setQuestionRef}
            getAnswerRef={setAnswerRef}
            focusQuestion={!modal && !isSaving && isDropdownsOpenCounter <= 0}
            onDropdownToggle={onDropdownToggleCallback}
        />
    );
};

export default AddWrapper;

function useAutoComplete(answerRef: any, questionRef: any) {
    const primaryLang = useSelector(selectors.getAddCardsPrimaryLang);
    const secondaryLang = useSelector(selectors.getAddCardsSecondaryLang);
    const hasPremium = useSelector(userSelectors.userHasPremium);

    const getWordDetailedRequest = useRequest(getWordDetailed, true);

    useEffect(() => {
        if (!hasPremium) return;
        if (!answerRef || !questionRef) return;
        if (!primaryLang || !secondaryLang) return;
        if (!isSupported({ primaryLang, secondaryLang })) return;

        const { current } = questionRef;
        const editor = current.getEditor();
        const { current: answCurrent } = answerRef;
        const answerEditor = answCurrent.getEditor();

        const removeAnswerAutocomplete = registerAnswerAutocomplete(
            editor,
            answerEditor,
            primaryLang,
            secondaryLang,
            getWordDetailedRequest
        );

        return () => {
            if (removeAnswerAutocomplete) removeAnswerAutocomplete();
        };
    }, [answerRef, questionRef, primaryLang, secondaryLang, hasPremium, getWordDetailedRequest]);
}

function registerAnswerAutocomplete(
    questionEditor: any,
    answerEditor: any,
    pL: string,
    sL: string,
    request: typeof getWordDetailed
): () => void {
    const questionElement = questionEditor.root as HTMLInputElement;

    const targetLang = [pL, sL].find((lang) => lang !== "de");

    const memoRequest = (function () {
        const results: Record<string, string> = {};
        let promise: Promise<string> | undefined = undefined;

        return function () {
            const text: string = questionEditor.getText();
            const length: number = text.split(" ").filter((word) => !!word).length;

            if (answerEditor.getLength() > 1 || text.length <= 1 || !targetLang || length > 2) {
                return Promise.resolve("");
            }

            if (text in results) return Promise.resolve(results[text]);

            if (promise) return promise; // return if is in progress

            promise = request(text, "de", targetLang).then(function (response) {
                const result = getResponseResult(response, pL);
                results[text] = result;
                promise = undefined;
                return result;
            });

            return promise;
        };
    })();

    const onBlurListener = function () {
        const isAlreadyAnswer = answerEditor.getText().trim().length > 0 ? true : false;
        if (isAlreadyAnswer) return;

        memoRequest().then(function (result) {
            if (result && result.length) {
                answerEditor.setText(result);
                answerEditor.setSelection(result.length);
            }
        });
    };
    questionElement.addEventListener("blur", onBlurListener);

    return function () {
        questionElement.removeEventListener("blur", onBlurListener);
    };
}

function getResponseResult(response: AxiosResponse<IResponse<DictionaryWordResults>>, pL: string): string {
    const {
        data: {
            replyContent: { languages = [] },
        },
    } = response;
    const language = languages.find(({ language }: any) => language === pL);
    if (!language || !language.sortedClasses) return "";
    const key = language.sortedClasses[0];
    if (!key) return "";
    const examples = language.wordClasses[key].find(({ examples = [] }) => !!examples.length)?.examples || [];
    const translation = examples[0]?.target;
    return translation || "";
}
