// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";
import { useT } from "@transifex/react";

// MODELS
import { ReportPeriod, SubjectData, SubjectReportActivityData } from "p6m-subjects";

// NETWORKING
import { getReportInputControlData } from "../../../../networking/reports";

// HELPERS
import { stripMediaIds } from "../../../../helpers/Cards";

// STYLES
import {
    InformationBox,
    InfoImageContainer,
    InfoImage,
    InfoTextContainer,
    Container,
    Entry,
    List,
    Header,
    Statistics,
    Text,
    TextHeader,
    TableWrapper,
    InfoSubtext,
} from "../styles";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
}

const ReportControl: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [period] = useState<ReportPeriod>("ONE_MONTH"); // ask bernd if period selection will come - maybe use for "load more"
    const [data, setData] = useState<SubjectReportActivityData[] | null>(null);

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: data },
        } = await getReportInputControlData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner,
            period
        );
        setData(data);
    }, [props.ownerId, props.selectedSubject.subjectMetadata.subjectIdToOwner, period]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "input_control",
        });
    }, []);

    const infoText_t = t("The cards that weren't learned correctly are shown here.", {
        _tags: "report,control,message",
    });
    const question_t = t("Question", { _tags: "report,control,message" });
    const answer_t = t("Correct answer", { _tags: "report,control,message" });
    const correctAnswer_t = t("Given Answer", { _tags: "report,control,message" });
    const noData_t = t("There is no data to display.", { _tags: "report,control,message" });

    return (
        <Container>
            <InformationBox>
                <InfoImageContainer>
                    <InfoImage
                        src={require("../../../../assets/img/avatar/monster_basic.png")}
                        alt=""
                    />
                </InfoImageContainer>
                <InfoTextContainer>
                    <InfoSubtext>{infoText_t}</InfoSubtext>
                </InfoTextContainer>
            </InformationBox>
            <TableWrapper>
                <Entry>
                    <Header>
                        <TextHeader>{question_t}</TextHeader>
                        <TextHeader>{answer_t}</TextHeader>
                        <TextHeader>{correctAnswer_t}</TextHeader>
                    </Header>
                </Entry>
                {/* Positive tabindex ensures scrollable element is focusable in correct order. Problem: it makes div focusable, even when its not interactive (non clickable, non scrollable). TODO: Remove tabindex once sidenavigation tabindex is refactored and set back to 0 */}
                <List tabIndex={1}>
                    {data &&
                        (data.length > 0 ? (
                            data.map((entry, index) => {
                                return (
                                    <Entry key={"control_" + index}>
                                        <Statistics>
                                            <Text dangerouslySetInnerHTML={{ __html: stripMediaIds(entry.question) }} />
                                            <Text dangerouslySetInnerHTML={{ __html: stripMediaIds(entry.answer) }} />
                                            <Text
                                                dangerouslySetInnerHTML={{
                                                    __html: entry.userAnswer ? stripMediaIds(entry.userAnswer) : "",
                                                }}
                                            />
                                        </Statistics>
                                    </Entry>
                                );
                            })
                        ) : (
                            <Entry>
                                <Statistics>
                                    <Text>{noData_t}</Text>
                                </Statistics>
                            </Entry>
                        ))}
                </List>
            </TableWrapper>
        </Container>
    );
};

export default ReportControl;
