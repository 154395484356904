// REACT
import React, { FunctionComponent, useEffect } from "react";

// REDUX
import { useSelector, useDispatch } from "react-redux";
import { selectors, actions } from "../../../../redux/learning/learningSlice";

// COMPONENTS
import Component from "./Card";

export const Card: FunctionComponent = () => {
    const { type } = useSelector(selectors.currentItem);
    const state = useSelector(selectors.state);
    const annotations = useSelector(selectors.currentAnnotations);
    const questionLang = useSelector(selectors.cardLang({ isAnswer: false }));
    const answerLang = useSelector(selectors.cardLang({ isAnswer: true }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (annotations) return;
        dispatch(actions.fetchAnnotations());
    }, [dispatch, annotations]);

    return (
        <Component
            state={state}
            type={type}
            questionLang={questionLang || ""}
            answerLang={answerLang || ""}
        />
    );
};
