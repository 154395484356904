import React, { useCallback, useMemo } from "react";
import { useT } from "@transifex/react";

export type LanguageCodes =
    | "ag"
    | "ar"
    | "bg"
    | "bp"
    | "cs"
    | "da"
    | "de"
    | "el"
    | "en"
    | "es"
    | "et"
    | "fa"
    | "fi"
    | "fr"
    | "ga"
    | "hr"
    | "hu"
    | "is"
    | "it"
    | "ja"
    | "ko"
    | "la"
    | "lt"
    | "lv"
    | "mt"
    | "nl"
    | "no"
    | "pl"
    | "ps"
    | "pt"
    | "ro"
    | "ru"
    | "sk"
    | "sl"
    | "sv"
    | "ti"
    | "tr"
    | "uk"
    | "zh"
    | "others";

const flags: Record<LanguageCodes, any> = {
    ag: require("../assets/img/flags/ag.png"),
    ar: require("../assets/img/flags/ar.png"),
    bg: require("../assets/img/flags/bg.png"),
    bp: require("../assets/img/flags/bp.png"),
    cs: require("../assets/img/flags/cs.png"),
    da: require("../assets/img/flags/da.png"),
    de: require("../assets/img/flags/de.png"),
    el: require("../assets/img/flags/el.png"),
    en: require("../assets/img/flags/en.png"),
    es: require("../assets/img/flags/es.png"),
    et: require("../assets/img/flags/et.png"),
    fa: require("../assets/img/flags/fa.png"),
    fi: require("../assets/img/flags/fi.png"),
    fr: require("../assets/img/flags/fr.png"),
    ga: require("../assets/img/flags/ga.png"),
    hr: require("../assets/img/flags/hr.png"),
    hu: require("../assets/img/flags/hu.png"),
    is: require("../assets/img/flags/is.png"),
    it: require("../assets/img/flags/it.png"),
    ja: require("../assets/img/flags/ja.png"),
    ko: require("../assets/img/flags/ko.png"),
    la: require("../assets/img/flags/la.png"),
    lt: require("../assets/img/flags/lt.png"),
    lv: require("../assets/img/flags/lv.png"),
    mt: require("../assets/img/flags/mt.png"),
    nl: require("../assets/img/flags/nl.png"),
    no: require("../assets/img/flags/no.png"),
    pl: require("../assets/img/flags/pl.png"),
    ps: require("../assets/img/flags/ps.png"),
    pt: require("../assets/img/flags/pt.png"),
    ro: require("../assets/img/flags/ro.png"),
    ru: require("../assets/img/flags/ru.png"),
    sk: require("../assets/img/flags/sk.png"),
    sl: require("../assets/img/flags/sl.png"),
    sv: require("../assets/img/flags/sv.png"),
    ti: require("../assets/img/flags/ti.png"),
    tr: require("../assets/img/flags/tr.png"),
    uk: require("../assets/img/flags/uk.png"),
    zh: require("../assets/img/flags/zh.png"),
    others: require("../assets/img/flags/others.png"),
};

const initialTranslations: Record<LanguageCodes, string> = {
    ag: "Ancient Greek",
    ar: "Arabic",
    bg: "Bulgarian",
    bp: "Brazilian",
    cs: "Czech",
    da: "Danish",
    de: "German",
    el: "Greek",
    en: "English",
    es: "Spanish",
    et: "Estonian",
    fa: "Farsi",
    fi: "Finnish",
    fr: "French",
    ga: "Irish",
    hr: "Croatian",
    hu: "Hungarian",
    is: "Icelandic",
    it: "Italian",
    ja: "Japanese",
    ko: "Korean",
    la: "Latin",
    lt: "Lithuanian",
    lv: "Latvian",
    mt: "Maltese",
    nl: "Dutch",
    no: "Norwegian",
    pl: "Polish",
    ps: "Pashto",
    pt: "Portuguese",
    ro: "Romanian",
    ru: "Russian",
    sk: "Slovak",
    sl: "Slovenian",
    sv: "Swedish",
    ti: "Tigrinya",
    tr: "Turkish",
    uk: "Ukrainian",
    zh: "Chinese",
    others: "Others",
};

export type LanguagesContextType = {
    languageTranslations: Record<LanguageCodes, string>;
    getLanguageTranslation: (languageCode: LanguageCodes | "") => any;
    getFlagSource: (languageCode: LanguageCodes | "") => any;
};

export const LanguagesContext = React.createContext<LanguagesContextType>({
    languageTranslations: initialTranslations,
    getLanguageTranslation: (_code) => initialTranslations["others"],
    getFlagSource: (_code) => flags["others"],
});

const LanguagesContextWrapper: React.FC = (props) => {
    const t = useT();
    const languageTranslations = useMemo(() => {
        return {
            ag: t("Ancient Greek"),
            ar: t("Arabic"),
            bg: t("Bulgarian"),
            bp: t("Brazilian"),
            cs: t("Czech"),
            da: t("Danish"),
            de: t("German"),
            el: t("Greek"),
            en: t("English"),
            es: t("Spanish"),
            et: t("Estonian"),
            fa: t("Farsi"),
            fi: t("Finnish"),
            fr: t("French"),
            ga: t("Irish"),
            hr: t("Croatian"),
            hu: t("Hungarian"),
            is: t("Icelandic"),
            it: t("Italian"),
            ja: t("Japanese"),
            ko: t("Korean"),
            la: t("Latin"),
            lt: t("Lithuanian"),
            lv: t("Latvian"),
            mt: t("Maltese"),
            nl: t("Dutch"),
            no: t("Norwegian"),
            pl: t("Polish"),
            ps: t("Pashto"),
            pt: t("Portuguese"),
            ro: t("Romanian"),
            ru: t("Russian"),
            sk: t("Slovak"),
            sl: t("Slovenian"),
            sv: t("Swedish"),
            ti: t("Tigrinya"),
            tr: t("Turkish"),
            uk: t("Ukrainian"),
            zh: t("Chinese"),
            others: t("Others"),
        };
    }, [t]);

    const getLanguageTranslation = useCallback(
        (languageCode: LanguageCodes | "") => {
            if (languageCode === "") return languageTranslations["others"];
            return languageTranslations[languageCode];
        },
        [languageTranslations]
    );

    const getFlagSource = useCallback(
        (languageCode: LanguageCodes | "") => {
            if (languageCode === "") return flags["others"];
            return flags[languageCode] || flags["others"];
        },
        [flags]
    );

    return (
        <LanguagesContext.Provider
            value={{ languageTranslations, getLanguageTranslation: getLanguageTranslation, getFlagSource }}
        >
            {props.children}
        </LanguagesContext.Provider>
    );
};

export default LanguagesContextWrapper;
