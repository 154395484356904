// LIBRARIES
import React from "react";
import { useT } from "@transifex/react";

// TYPES
import { SubjectData } from "p6m-subjects";
import { User, FamilyMember } from "p6m-user";

// COMPONENTS
import PhaseSixIcon from "../../../../components/basic/phaseSixIcon/PhaseSixIcon";

// STYLES
import {
    Container,
    Header,
    List,
    Title,
    SubjectTitle,
    Statistics,
    Total,
    LastPractice,
    StatisticEntry,
    NoPhasesContainer,
    NoPhasesIcon,
    NoPhasesText,
    NoPhasesHeader,
    NoPhasesBody,
    NoPhasesInfo,
    NoPhasesButton,
} from "./styles";
import SubjectListItem from "./SubjectListItem";

export interface ReportsProps {
    user: User;
    member?: FamilyMember;
    subjects: SubjectData[] | null;
    selectSubject: (subjectId: string) => void;
    onAddContent(): void;
}

const ReportTopics: React.FC<ReportsProps> = (props) => {
    const t = useT();

    const t_collection = t("Collection", { _tags: "title, reports" });
    const t_inactive = t("Inactive", { _tags: "title, reports" });
    const t_active = t("Active", { _tags: "title, reports" });
    const t_finished = t("Finished", { _tags: "title, reports" });
    const t_total = t("Total", { _tags: "title, reports" });
    const t_lastPractice = t("Last Practice", { _tags: "title, reports" });

    const noPhasesHeader_t = t("No content available yet!", {
        member: props.member && props.member.userId !== props.user.userDnsId ? props.member.name : props.user.firstName,
        _tags: "reports,message",
    });
    const noPhasesInfoTextSelect_t = t("You can select a content via the phase6 website.", {
        member: props.member?.name ?? "you",
        _tags: "reports,message",
    });
    const noPhasesInfoTextTrial_t = t("All contents can be tested for 7 days free of charge and without obligation.", {
        _tags: "reports,message",
    });
    const noPhasesButtonText_t = t("There is no data for Select content now.", { _tags: "reports,message" });

    const renderNoPhasesPage = () => {
        return (
            <NoPhasesContainer>
                <NoPhasesIcon>
                    <PhaseSixIcon
                        name="documents"
                        size="medium"
                    />
                </NoPhasesIcon>
                <NoPhasesText>
                    <NoPhasesHeader>{noPhasesHeader_t}</NoPhasesHeader>
                    <NoPhasesBody>
                        <NoPhasesInfo>{noPhasesInfoTextSelect_t}</NoPhasesInfo>
                        <NoPhasesInfo>{noPhasesInfoTextTrial_t}</NoPhasesInfo>
                    </NoPhasesBody>
                    <NoPhasesButton onClick={props.onAddContent}>{noPhasesButtonText_t}</NoPhasesButton>
                </NoPhasesText>
            </NoPhasesContainer>
        );
    };

    return (
        <Container>
            {props.subjects && props.subjects.length > 0 ? (
                <Header>
                    <Title>
                        <SubjectTitle>{t_collection}</SubjectTitle>
                    </Title>
                    <Statistics>
                        <StatisticEntry>{t_inactive}</StatisticEntry>
                        <StatisticEntry>{t_active}</StatisticEntry>
                        <StatisticEntry>{t_finished}</StatisticEntry>
                        <Total>{t_total}</Total>
                    </Statistics>
                    <LastPractice>{t_lastPractice}</LastPractice>
                </Header>
            ) : null}
            <List>
                {props.subjects &&
                    (props.subjects.length > 0
                        ? props.subjects.map((subject) => (
                              <SubjectListItem
                                  key={subject.subjectMetadata.subjectIdToOwner.id}
                                  subject={subject}
                                  selectSubject={props.selectSubject}
                              />
                          ))
                        : renderNoPhasesPage())}
            </List>
        </Container>
    );
};

export default ReportTopics;
