// Deps
import React from "react";
import { T } from "@transifex/react";

// Components
import Modal from "../../basic/modal/Modal";
import Description from "./subComponents/Description";
import Warning from "./subComponents/Warning";
import ConsumeButtonText from "./subComponents/ConsumeButtonText";
import { DefaultFocus } from "../../basic/defaultFocus/DefaultFocus";

// Types
import { CoverImage } from "p6-react-shared";
import { SubjectAssignmentModal as ModalType } from "p6m-subjects";

// Styles
import { ButtonWrapper, ContentWrapper, InfoWrapper, SubjectTitle, Overlay } from "./styles";
import { OutlinedButton, FilledButton } from "../../basic/button/Button";

interface SubjectAssignmentModalProps {
    data: ModalType;
    showOverlay: boolean;
    isStrictMode: boolean;
    onSubmit(): void;
    onCancel(): void;
}

export const SubjectAssignmentModal = ({
    data,
    showOverlay,
    isStrictMode,
    onSubmit,
    onCancel,
}: SubjectAssignmentModalProps) => {
    return (
        <>
            {showOverlay && <Overlay />}
            <Modal
                active
                headerComponent={false}
                afterClose={() => onCancel()}
            >
                <ContentWrapper>
                    <DefaultFocus />
                    <InfoWrapper isStrict={isStrictMode}>
                        <Description
                            totalCards={data.totalCards}
                            modalType={data.type}
                        />
                        <CoverImage
                            imageId={data.imageUrl}
                            responsive
                            size="small"
                            alt=""
                        />
                        <SubjectTitle>{data.subjectName}</SubjectTitle>
                    </InfoWrapper>
                    <Warning
                        modalType={data.type}
                        isStrictMode={isStrictMode}
                    />
                    <ButtonWrapper>
                        <OutlinedButton
                            onClick={onCancel}
                            textSize="xl"
                            useVerticalPadding
                            useModalZIndex
                        >
                            <T
                                _str="Later"
                                _tags="Learn"
                            />
                        </OutlinedButton>
                        <FilledButton
                            onClick={onSubmit}
                            textSize="xl"
                            useVerticalPadding
                            useModalZIndex
                        >
                            <ConsumeButtonText
                                modalType={data.type}
                                isStrictMode={isStrictMode}
                            />
                        </FilledButton>
                    </ButtonWrapper>
                </ContentWrapper>
            </Modal>
        </>
    );
};
