// LIBRARIES
import React, { useCallback } from "react";
import { useT } from "@transifex/react";

// TYPES
import { SubjectData } from "p6m-subjects";

// COMPONENTS
import { CoverImage } from "p6-react-shared";

// HELPERS
import { getSubjectCover } from "../../../../helpers/Subjects";
import { toDDMMYY } from "../../../../helpers/timeFormat";

// STYLES
import { Subject, Title, SubjectTitle, Statistics, Total, LastPractice, StatisticEntry } from "./styles";

export interface SubjectListItemProps {
    subject: SubjectData;
    selectSubject: (subjectId: string) => void;
}

const SubjectListItem: React.FC<SubjectListItemProps> = ({ subject, selectSubject }) => {
    const t = useT();
    const t_Cards = t("Cards", { _tags: "reports,own cards,title,additional,text" });

    const {
        groupedCardCount: subjectsGroupedCardCount,
        subjectMetadata: {
            subjectIdToOwner: { id: subjectId },
            isSharedSubjectOfAdmin,
            lastPracticedDate,
        },
        subjectContent: { name: subjectName },
    } = subject;

    const image = getSubjectCover(subject);
    const cardsInactive = subjectsGroupedCardCount?.cardCounts.LEARN_NEW.cardCount || 0;
    const cardsActive = subjectsGroupedCardCount?.cardCounts.ACTIVE_NOT_LEARNED.cardCount || 0;
    const cardsFinished = subjectsGroupedCardCount?.cardCounts.LAST_PHASE.cardCount || 0;
    const cardsTotal = cardsInactive + cardsActive + cardsFinished;

    const handleSubjectSelect = useCallback(() => {
        selectSubject(subjectId);
    }, [selectSubject, subjectId]);

    const onKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLDivElement>) => {
            if (e.key === "Enter") handleSubjectSelect();
        },
        [handleSubjectSelect]
    );

    return (
        <Subject
            key={"subject_" + subjectId}
            onClick={handleSubjectSelect}
            onKeyDown={onKeyDown}
            tabIndex={1}
        >
            <Title>
                <CoverImage
                    imageId={image}
                    size="small"
                    alt=""
                />
                <SubjectTitle>
                    {subjectName}
                    {isSharedSubjectOfAdmin ? " " + t_Cards : ""}
                </SubjectTitle>
            </Title>
            <Statistics>
                <StatisticEntry>{cardsInactive.toLocaleString("de-DE")}</StatisticEntry>
                <StatisticEntry>{cardsActive.toLocaleString("de-DE")}</StatisticEntry>
                <StatisticEntry>{cardsFinished.toLocaleString("de-DE")}</StatisticEntry>
                <Total>{cardsTotal.toLocaleString("de-DE")}</Total>
            </Statistics>
            <LastPractice>{lastPracticedDate ? toDDMMYY(lastPracticedDate) : "-"}</LastPractice>
        </Subject>
    );
};

export default SubjectListItem;
