// REACT
import React, { useContext } from "react";

//COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import TranslatedLanguage from "../../basic/translatedLanguage/TranslatedLanguage";

// CONSTANTS
import DictionaryConstants from "../../../constants/DictionaryConstants";

// TYPES
import { AvailableLanguageCodes } from "p6m-dictionary";

// UTILS
import { LanguagesContext } from "../../../context/languages";

// STYLED COMPONENTS
import { FlagImage, LanguageComboEntry, LanguageComboList, LanguageSelectorText, OkPhaseSixIcon } from "./styles";

export interface LanguageComboProps {
    dictionarySearchLanguage: AvailableLanguageCodes;
    setLanguage: (searchLanguage: AvailableLanguageCodes) => any;
}

const LanguageCombo: React.FC<LanguageComboProps> = (props) => {
    const { getFlagSource } = useContext(LanguagesContext);
    return (
        <LanguageComboList>
            {DictionaryConstants.targetLanguages.map((language) => {
                const active = props.dictionarySearchLanguage === language.languageCode;
                return (
                    <LanguageComboEntry
                        key={language.languageCode}
                        active={active}
                        onClick={() => props.setLanguage(language.languageCode)}
                    >
                        <FlagImage
                            src={getFlagSource("de")}
                            alt=""
                        />
                        <PhaseSixIcon name={"arrows-right-left"} />
                        <FlagImage
                            src={getFlagSource(language.languageCode)}
                            alt=""
                        />
                        <LanguageSelectorText active={active}>
                            <TranslatedLanguage languageToTranslate="German" />
                        </LanguageSelectorText>
                        <LanguageSelectorText active={active}>-</LanguageSelectorText>
                        <LanguageSelectorText active={active}>
                            <TranslatedLanguage languageToTranslate={language.name} />
                        </LanguageSelectorText>
                        {active ? (
                            <OkPhaseSixIcon
                                name={"exercise-ok"}
                                highlighted
                            />
                        ) : undefined}
                    </LanguageComboEntry>
                );
            })}
        </LanguageComboList>
    );
};

export default LanguageCombo;
