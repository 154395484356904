// LIBRARIES
import React, { useCallback } from "react";
import { useT } from "@transifex/react";

// COMPONENTS
import UserMenuEntry from "../../complex/userMenuEntry/UserMenuEntry";

// STYLES
import { EmailNotConfirmedWrapper } from "./styles";
import { keyDownHandlerForNavigatingElementsByClass } from "../../../helpers/keyDownHandlerForNavigatingElementsByClass";

export const EmailNotConfirmed: React.FC<{ onClick: () => any }> = (props) => {
    const t = useT();
    const t_emailConfirm = t("Confirm my email", { _tags: "userMenu,header" });

    const handleKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLElement>) => {
            keyDownHandlerForNavigatingElementsByClass(event, "userMenuEntry", props.onClick);
        },
        [props.onClick]
    );

    return (
        <EmailNotConfirmedWrapper>
            <UserMenuEntry
                title={t_emailConfirm}
                key={"confirm"}
                modalTitle={"Confirm Email"}
                onClickAction={props.onClick}
                onKeyDownAction={handleKeyDown}
            />
        </EmailNotConfirmedWrapper>
    );
};
