//DEPENDENCIES
import React, { FunctionComponent, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useT } from "@transifex/react";

//REDUX
import { selectors } from "../../../../redux/learning/learningSlice";
import { actions as responseActions } from "../../../../redux/response/responseSlice";

//NETWORK
import { postNewCard } from "../../../../networking/cards";

//UTILS
import { getCardContentInfo, generateCardMediaString } from "../../../../helpers/Cards";

//COMONENTS
import { CardEditTemplate } from "../../../../mock/cards/cardTemplate";
import Component, { Props as ComponentProps } from "./UpdateCardModal";

//TYPES
import { SubjectUnitCard } from "p6m-subjects";
import { Props as ModalProps } from "../../../basic/modal/Modal";

export type Props = {
    card: SubjectUnitCard;
    afterSave?: (newCardData: SubjectUnitCard) => void;
} & Partial<ModalProps>;

export const UpdateCardModal: FunctionComponent<Props> = (props) => {
    const questionLanguageCode = useSelector(selectors.cardLang({ isAnswer: false, considerPracticeDirection: false }));
    const answerLanguageCode = useSelector(selectors.cardLang({ isAnswer: true, considerPracticeDirection: false }));

    const { card, afterSave = () => {}, ...restProps } = props;

    const {
        cardContent: { question, answer },
    } = card;

    const questionContent = getCardContentInfo(question);
    const answerContent = getCardContentInfo(answer);

    const [active, setActive] = useState(true);
    const [newQuestion, setQuestion] = useState<string>(questionContent.htmlTitle);
    const [newAnswer, setAnswer] = useState<string>(answerContent.htmlTitle);
    const [questionFiles, setQuestionFiles] = useState<ComponentProps["questionFiles"]>(questionContent.media);
    const [answerFiles, setAnswerFiles] = useState<ComponentProps["answerFiles"]>(answerContent.media);

    const dispatch = useDispatch();
    const t = useT();

    const T_saveSuccess = t("Card saved", {});
    const T_saveFailed = t("Saving failed", {});

    const onSaveCallback = useCallback(async () => {
        if (!newQuestion || !newAnswer) return;

        const answerWithFiles: string = newAnswer + generateCardMediaString(answerFiles);
        const questionWithFiles: string = newQuestion + generateCardMediaString(questionFiles);

        const {
            cardIdToOwner: { ownerId, id },
            cardContent: { unitIdToOwner, subjectIdToOwner },
        } = card;

        const newCard = {
            ...CardEditTemplate,
            answer: answerWithFiles,
            question: questionWithFiles,
            ownerId,
            subjectIdToOwner,
            unitIdToOwner,
        };

        const cardResponse = await postNewCard(id, ownerId, newCard);
        if (cardResponse.status === 200 && cardResponse.data.httpCode === 200) {
            dispatch(responseActions.showResponse({ type: "SUCCESS", text: [T_saveSuccess] }));
            afterSave({
                ...card,
                cardContent: {
                    ...card.cardContent,
                    question: newCard.question,
                    answer: newCard.answer,
                },
            });
            setActive(false);
        } else {
            dispatch(responseActions.showResponse({ type: "ERROR", text: [T_saveFailed] }));
        }
    }, [newQuestion, newAnswer, answerFiles, questionFiles, card, T_saveSuccess, T_saveFailed, afterSave, dispatch]);

    return (
        <Component
            active={active}
            question={newQuestion}
            questionLanguageCode={questionLanguageCode}
            onQuestionChange={setQuestion}
            onQuestionFilesChange={setQuestionFiles}
            questionFiles={questionFiles}
            answer={newAnswer}
            answerLanguageCode={answerLanguageCode}
            onAnswerChange={setAnswer}
            onAnswerFilesChange={setAnswerFiles}
            answerFiles={answerFiles}
            onSave={onSaveCallback}
            {...restProps}
        />
    );
};
