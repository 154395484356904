// LIBRARIES
import React from "react";
import { useHistory } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions as modalActions } from "../../../redux/modal/modalSlice";
import { selectors as userSelectors } from "../../../redux/user/userSlice";
import { actions as testsActions } from "../../../redux/tests/testsSlice";
import { actions as subjectActions } from "../../../redux/subjects/subjectsSlice";

// COMPONENTS
import UserSubjectFurtherActions from "./UserSubjectFurtherActions";

// HELPERS
import { GlobalModalView } from "../../../helpers/Modal";

// TYPES
import { SubjectActions, SubjectData } from "p6m-subjects";

// HOOKS
import { useFirstPracticeResult } from "../../../hooks/useFirstPracticeResult";

export interface UserSubjectFurtherActionsProps {
    type: "due" | "inactive" | "further" | "expired";
    subject: SubjectData;
    userId: string | undefined;
    subjectActions: SubjectActions;
    totalCardCount: number;
    inactiveCardCount: number;
    closePopup?: () => void;
    allCardsLearned: boolean;
    isClassroomSubject: boolean;
    allCardsInactive: boolean;
    hasNextPracticeDate: boolean;
}

const UserSubjectFurtherActionsView: React.FC<UserSubjectFurtherActionsProps> = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const userHasPremium: boolean = useSelector(userSelectors.userHasPremium);
    const userPreferences = useSelector(userSelectors.userPreferences);
    const isLibraryAccessDisabled = !userPreferences.parentSettings?.libraryAccess || false;
    const userHasFirstPractice = useFirstPracticeResult();

    const deleteSubject = async () => {
        if (props.userId) {
            props.closePopup && props.closePopup();
            const {
                subjectContent: { name: subjectName },
                subjectMetadata: { subjectIdToOwner },
                groupedCardCount,
            } = props.subject;

            dispatch(
                modalActions.setData({
                    contentType: "subjects",
                    contentNames: [subjectName],
                    itemIds: [subjectIdToOwner],
                    deletableCardCount: groupedCardCount?.cardCounts.LIBRARY.cardCount || 0,
                    selectedSubjectId: subjectIdToOwner.id,
                    callback: () => dispatch(subjectActions.loadUserSubjectsData()),
                })
            );
            dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
        }
    };

    const openResetSubjectModal = () => {
        props.closePopup && props.closePopup();
        const data = { subject: props.subject };
        dispatch(modalActions.setData(data));
        dispatch(modalActions.setModalView(GlobalModalView.ResetSubjectCards));
    };

    const redirectToTestsAndExercises = () => {
        dispatch(testsActions.setSubject(props.subject));
        history.push("/tests");
    };

    return (
        <UserSubjectFurtherActions
            userId={props.userId}
            type={props.type}
            subject={props.subject}
            subjectActions={props.subjectActions}
            totalCardCount={props.totalCardCount}
            inactiveCardCount={props.inactiveCardCount}
            closePopup={props.closePopup}
            deleteSubject={deleteSubject}
            openResetSubjectModal={openResetSubjectModal}
            isPremiumUser={userHasPremium}
            firstPracticeFinished={userHasFirstPractice}
            allCardsLearned={props.allCardsLearned}
            isClassroomSubject={props.isClassroomSubject}
            allCardsInactive={props.allCardsInactive}
            hasNextPracticeDate={props.hasNextPracticeDate}
            isLibraryAccessDisabled={isLibraryAccessDisabled}
            redirectToTestsAndExercises={redirectToTestsAndExercises}
        />
    );
};

export default UserSubjectFurtherActionsView;
