// LIBRARIES
import React from "react";
import { generatePath, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ampli } from "../../../ampli";

//NETWORK
import { deleteCards } from "../../../networking/cards";
import { deleteSubject } from "../../../networking/subjects";
import { deleteUnit } from "../../../networking/units";

//REDUX
import { actions as modalActions, selectors as modalSelectors } from "../../../redux/modal/modalSlice";
import { actions as subjectsActions } from "../../../redux/subjects/subjectsSlice";
import { actions as responseActions } from "../../../redux/response/responseSlice";

//TYPES
import { GlobalModalView } from "../../../helpers/Modal";
import { IdToOwnerId } from "p6m-subjects";
import { EditableContentType } from "p6m-viewData";

//COMPONENTS
import DeleteContentModal from "./DeleteContentModal";
import { ResponseMessageKeys } from "../response";

const DeleteContentModalWrapper: React.FC = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const {
        contentType,
        contentNames = [],
        itemIds,
        deletableCardCount,
        selectedSubjectId,
        selectedCardCount,
        refreshCardList,
        callback,
    }: {
        contentType: EditableContentType;
        contentNames?: string[];
        itemIds: IdToOwnerId[];
        deletableCardCount: number;
        selectedSubjectId?: string;
        selectedCardCount?: number;
        refreshCardList?: () => void;
        callback?: () => void;
    } = useSelector(modalSelectors.data);

    const close = (selectedSubjectDeleted?: boolean) => {
        if (selectedSubjectDeleted && history.location.pathname.match(/^\/manage\//)) {
            history.push({ pathname: generatePath("/manage") });
        }
        dispatch(modalActions.setModalView(GlobalModalView.None));
    };

    const reloadSubjects = () => {
        dispatch(subjectsActions.loadLibrarySubjects());
        dispatch(subjectsActions.loadUserSubjectsUnits());
    };

    const deleteContent = async () => {
        if (contentType === "subjects") {
            try {
                await Promise.all(itemIds.map((subjectId) => deleteSubject(subjectId)));
                dispatch(
                    responseActions.showResponse({
                        type: "SUCCESS",
                        responseMessage: ResponseMessageKeys.SUBJECT_DELETED,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            } catch (e) {
                dispatch(
                    responseActions.showResponse({
                        type: "ERROR",
                        responseMessage: ResponseMessageKeys.ERROR_DELETING_SUBJECT,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            }
            reloadSubjects();
        } else if (contentType === "units") {
            try {
                await Promise.all(itemIds.map((idToOwnerId) => deleteUnit(idToOwnerId)));
                dispatch(
                    responseActions.showResponse({
                        type: "SUCCESS",
                        responseMessage: ResponseMessageKeys.UNIT_DELETED,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            } catch (e) {
                dispatch(
                    responseActions.showResponse({
                        type: "ERROR",
                        responseMessage: ResponseMessageKeys.UNIT_DELETED,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            }
            reloadSubjects();
        } else if (contentType === "cards") {
            try {
                await deleteCards(itemIds);
                ampli.clickedEditCardsButton({
                    cards_number: itemIds.length,
                    user_action: "deleteCard",
                });
                dispatch(
                    responseActions.showResponse({
                        type: "SUCCESS",
                        responseMessage: ResponseMessageKeys.CARD_DELETED,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            } catch (e) {
                dispatch(
                    responseActions.showResponse({
                        type: "ERROR",
                        responseMessage: ResponseMessageKeys.ERROR_DELETING_CARD,
                        responseMessageReplacements: [{ name: contentNames.join(", ") }],
                    })
                );
            }
            reloadSubjects();
        }

        refreshCardList?.();
        callback?.();

        const selectedSubjectDeleted =
            contentType === "subjects" && !!itemIds.find((subject) => subject.id === selectedSubjectId);
        close(selectedSubjectDeleted);
    };

    return (
        <DeleteContentModal
            contentType={contentType}
            names={contentNames}
            deletableCardCount={deletableCardCount}
            selectedCardCount={selectedCardCount}
            deleteContent={deleteContent}
        />
    );
};

export default DeleteContentModalWrapper;
