// REACT
import React from "react";
import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";

// COMPONENTS
import Modal from "../../basic/modal/Modal";

// TYPES
import { TIconNames } from "../../basic/phaseSixIcon/PhaseSixIcon";

// STYLED COMPONENTS
import {
    StyledExternalLinkContainer,
    StyledIframe,
    StyledLinkContainer,
    StyledLinkWrapper,
    StyledModalTriggerContainer,
    Text,
    UserMenuIcon,
} from "./styles";

export interface UserMenuEntryProps {
    title: string;
    icon?: TIconNames;
    svgIcon?: React.FC;
    navTarget?: string;
    external?: boolean;
    modal?: boolean;
    showModal?: boolean;
    modalTitle?: string;
    modalInnerComponent?: React.ReactNode;
    onClickAction: (showModal?: boolean | any) => any;
    onKeyDownAction?: (event: React.KeyboardEvent<HTMLElement>, disabled: boolean, isExternalLink?: boolean) => void;
    userDefinedIconColor?: string;
    disableHover?: boolean;
    headerComponent?: boolean;
    provideModalActions?: boolean;
    modalBodyStyles?: any;
    disabled?: boolean;
    disabledTooltipText?: string;
}

const UserMenuEntry: React.FC<UserMenuEntryProps> = (props) => {
    const { disabled = false } = props;
    let icon = null;

    let isP6Icon = !!props.icon;
    let isSvgIcon = !!props.svgIcon;

    if (isP6Icon || isSvgIcon) {
        icon = (
            <UserMenuIcon
                {...props}
                isActive={false}
            />
        );
    }

    // Media query for the mobile rendering
    const lowerBorderQuery = "(max-width: " + process.env.REACT_APP_LOWER_GRID_STEP_BORDER + "px)";
    let isMobileView: Boolean = useMediaQuery({
        query: lowerBorderQuery,
    });

    const { title, modal, disableHover, navTarget, external, disabledTooltipText } = props;
    const { onClickAction = () => {}, onKeyDownAction } = props;

    const handleKeyDownAction = (event: React.KeyboardEvent<HTMLElement>) => {
        if (!!onKeyDownAction) onKeyDownAction(event, disabled);
    };

    // props for the Tooltip to work
    let toolTipProps: { [key: string]: any } = {};
    if (props.disabled && typeof disabledTooltipText != "undefined" && disabledTooltipText.length > 0) {
        toolTipProps = {
            "data-tip": "",
            "data-for": title + "_tooltip",
        };
    }

    if (modal && !disabled) {
        return (
            <StyledLinkWrapper
                tabIndex={0}
                className={`userMenuEntry ${disableHover ? "no-hover" : ""}`}
                data-qa="user-menu-dropdown_menu-item"
                disableHover={disableHover}
                onKeyDown={handleKeyDownAction}
            >
                <StyledModalTriggerContainer
                    className={`${isMobileView ? "reverse-direction" : ""}`}
                    onClick={() => onClickAction(true)}
                >
                    {icon}
                    <Text>{title}</Text>
                </StyledModalTriggerContainer>
                {props.showModal && (
                    <Modal
                        style={{ padding: 0, height: `100%` }}
                        title={props.modalTitle}
                        active={!!props.showModal}
                        provideActions={props.provideModalActions}
                        modalBodyStyle={props.modalBodyStyles}
                        headerComponent={props.headerComponent === false ? false : undefined}
                        afterClose={() => onClickAction(false)}
                    >
                        {external && navTarget && <StyledIframe src={navTarget} />}
                        {props.modalInnerComponent}
                    </Modal>
                )}
            </StyledLinkWrapper>
        );
    } else if (navTarget && !disabled) {
        if (external) {
            return (
                <StyledLinkWrapper
                    className={`${disableHover ? "no-hover" : ""}`}
                    data-qa="user-menu-dropdown_menu-item"
                >
                    <StyledExternalLinkContainer
                        href={navTarget!}
                        target={"_blank"}
                        onClick={onClickAction}
                        className={`userMenuEntry ${isMobileView ? "reverse-direction" : ""}`}
                        tabIndex={0}
                        onKeyDown={(event: React.KeyboardEvent<HTMLAnchorElement>) => {
                            !!onKeyDownAction && onKeyDownAction(event, disabled, true);
                        }}
                        disabled={disabled}
                        {...toolTipProps}
                    >
                        {icon}
                        <Text>{title}</Text>
                    </StyledExternalLinkContainer>
                    {!!disabledTooltipText && (
                        <ReactTooltip id={title + "_tooltip"}>{disabledTooltipText}</ReactTooltip>
                    )}
                </StyledLinkWrapper>
            );
        } else {
            return (
                <>
                    <StyledLinkWrapper
                        tabIndex={0}
                        className={`userMenuEntry ${disableHover ? "no-hover" : ""}`}
                        data-qa="user-menu-dropdown_menu-item"
                        onKeyDown={handleKeyDownAction}
                        {...toolTipProps}
                    >
                        <StyledLinkContainer
                            to={!disabled && navTarget!}
                            onClick={onClickAction}
                            className={`${isMobileView ? "reverse-direction" : ""}`}
                            disabled={disabled}
                        >
                            {icon}
                            <Text>{title}</Text>
                        </StyledLinkContainer>
                    </StyledLinkWrapper>
                    {!!disabledTooltipText && (
                        <ReactTooltip id={title + "_tooltip"}>{disabledTooltipText}</ReactTooltip>
                    )}
                </>
            );
        }
    } else {
        return (
            <>
                <StyledLinkWrapper
                    tabIndex={0}
                    className={`userMenuEntry ${disableHover ? "no-hover" : ""}`}
                    data-qa="user-menu-dropdown_menu-item"
                    onKeyDown={handleKeyDownAction}
                    {...toolTipProps}
                >
                    <StyledModalTriggerContainer
                        className={`${isMobileView ? "reverse-direction" : ""}`}
                        onClick={onClickAction}
                        disabled={disabled}
                    >
                        {icon}
                        <Text>{title}</Text>
                    </StyledModalTriggerContainer>
                </StyledLinkWrapper>
                {!!disabledTooltipText && <ReactTooltip id={title + "_tooltip"}>{disabledTooltipText}</ReactTooltip>}
            </>
        );
    }
};

export default UserMenuEntry;
