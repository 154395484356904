import { takeEvery, put, putResolve, call, select, all } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { IResponse } from "p6m-response";
import { actions, selectors, StartActionPayload } from "./learningSlice";
import { actions as appStatusActions } from "../appStatus/appStatusSlice";
import { selectors as subjectSelectors, SubjectCounterTypes } from "../subjects/subjectsSlice";
import { actions as userActions, selectors as userSelectors } from "../user/userSlice";
import { actions as responseActions } from "../response/responseSlice";
import { actions as warningsActions } from "../warnings/warningsSlice";
import {
    compareAnswer as compareAnswerAxios,
    compareAnswerNoTyping,
    cardActivationFiltered,
    submitAnswer,
    updateStatistic,
    sendFeedback as sendFeedbackAxios,
    practiceCard as submitTestCardAnswer,
} from "../../networking/practice";
import { getCardAnnotation, updateCardAnnotation, getSubjectUnitCards } from "../../networking/cards";
import {
    generateItems,
    getPhaseBeforeAnswer,
    getResolved,
    getSubmitCount,
    preventSubmitRequest,
    createCardItemHistory,
    getStatisticType,
    preventPrepareForTestRequest,
    getPracticeRequestType,
    getIsRegularPractice,
} from "../../helpers/learning";
import { getCardContentInfo, getCardData, validateAnnotations } from "../../helpers/Cards";
import { getCardCount, getCardCountForPracticeMoreToday } from "../../helpers/Subjects";
import { generateUuid } from "../../helpers/Id";
import { SubjectUnitCard, SubjectData } from "p6m-subjects";
import { User, UserPreferences } from "p6m-user";
import { TgetSubjectUnits } from "../../networking/subjects";

import { LearningState, Item, Annotations, Directions, ResultActions, Modals } from "p6m-learning";

import { SaveUserPreferencesParams } from "p6m-networking";
import { ResponseMessageKeys } from "../../components/connected/response";
import { saveTestResult } from "../../networking/tests";

export const [itemHistory, , clearItemsHistory] = createCardItemHistory();

const responseMessages = [
    ResponseMessageKeys.SESSION_MEMORIZED_FINISHED,
    ResponseMessageKeys.WRONG_ANSWER,
    ResponseMessageKeys.FEEDBACK_SENT,
    ResponseMessageKeys.FEEDBACK_EMPTY,
    ResponseMessageKeys.NOTE_SAVED,
];

function* fetchPreferences() {
    const { userDnsId }: User = yield select(userSelectors.user);
    if (!userDnsId) return;
    yield putResolve(userActions.refreshPreferencesData(userDnsId));
}

function* fetch() {
    let cards: LearningState["cards"] = yield select(selectors.cards);
    let cardsId: LearningState["cardsId"] = yield select(selectors.cardsId);
    let subjectId: LearningState["subjectId"] = yield select(selectors.subjectId);
    let session: string | undefined = yield select(selectors.session);

    if (!session) {
        session = generateUuid();
    }

    const existedCardsId = cards.map(({ cardIdToOwner: { id } }) => id);

    if (!cardsId.length) {
        yield putResolve(actions.setData({ cardsId: existedCardsId }));
        cardsId = yield select(selectors.cardsId);
    }

    cards = cards.filter(({ cardIdToOwner: { id } }) => cardsId.includes(id));

    const cardsToFetch = cardsId.filter((id) => !existedCardsId.includes(id));
    if (cardsToFetch.length) {
        yield put(appStatusActions.setLoading(true));
        try {
            const filterMode: Required<TgetSubjectUnits>["filterMode"] = yield select(selectors.filter);
            const {
                data: {
                    replyContent: { cards: newCards },
                },
            } = yield call(getSubjectUnitCards, {
                cards: cardsToFetch,
                subjectId,
                filterMode,
            });
            cards.push(...newCards);
        } catch (e) {
            console.log(e);
        } finally {
            yield put(appStatusActions.setLoading(false));
        }
    }

    if (!subjectId) {
        subjectId = cards[0].subjectIdToOwner.id;
    }

    const direction: number = yield select(selectors.direction);
    const type: number = yield select(selectors.type);
    const items = generateItems(cards, direction, type);

    // Change direction for card that comes from Backend
    const lastSlice = Math.max(...items.map(({ slice }) => slice));
    items
        .filter(({ type }) => type === "practice")
        .forEach((item) => {
            const { cardIndex, direction, slice } = item;
            const { normal, opposite } = cards[cardIndex];

            if (["opposite", "both"].includes(direction) && !opposite) return (item.direction = "normal");
            if (["normal", "both"].includes(direction) && !normal) {
                item.direction = "opposite";
                item.slice = slice + lastSlice + 1; // create a new slice for opposite card
            }
        });

    yield putResolve(
        actions.setData({
            items,
            cards,
            cardsId,
            subjectId,
            session,
        })
    );

    yield fetchPreferences();
}

function* start({ payload }: PayloadAction<StartActionPayload>) {
    const { history, startsFrom = "activation", practiceType, ...restPayload } = payload;
    yield putResolve(actions.clear());
    let dataToUse;
    if (!!practiceType) {
        dataToUse = {
            practiceType,
            startsFrom,
            ...restPayload,
        };
    } else {
        dataToUse = {
            startsFrom,
            ...restPayload,
        };
    }
    yield putResolve(actions.setData(dataToUse));
    yield fetch();
    clearItemsHistory();

    const targetUrl = payload.isPrepareForTest ? "/practice/" + payload.testId : "/practice";
    history?.push(targetUrl);
}

function* startPracticeWithDueCards({ payload }: PayloadAction<{ subjectId: string; history?: any }>) {
    const { subjectId, history } = payload;
    const subject: SubjectData | undefined = yield select(subjectSelectors.getSubjectById(subjectId));
    if (!subject) return;

    const limit = getCardCount(subject, SubjectCounterTypes.SHOULD_PRACTICE_TODAY);

    yield put(appStatusActions.setLoading(true));
    try {
        const filterMode = "PRACTICE";
        const response: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>> = yield call(getSubjectUnitCards, {
            subjectId,
            filterMode,
        });
        let {
            data: {
                replyContent: { cards },
            },
        } = response;

        if (cards.length > limit) {
            cards.length = limit;
        } else if (!cards.length) {
            return;
        }

        yield putResolve(
            actions.start({
                subjectId,
                testId: "",
                cards,
                type: 3,
                direction: 0,
                history,
                filter: filterMode,
                startsFrom: "withDueCards",
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* startPracticeWithAdditionalCards({ payload }: PayloadAction<{ subjectId: string; history?: any }>) {
    const { subjectId, history } = payload;
    const subject: SubjectData | undefined = yield select(subjectSelectors.getSubjectById(subjectId));
    if (!subject) return;

    const limit = getCardCountForPracticeMoreToday(subject);

    yield put(appStatusActions.setLoading(true));
    try {
        const filterMode = "PRACTICE";
        const response: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>> = yield call(getSubjectUnitCards, {
            subjectId,
            filterMode,
        });
        let {
            data: {
                replyContent: { cards },
            },
        } = response;

        if (cards.length > limit) {
            cards.length = limit;
        }

        yield putResolve(
            actions.start({
                subjectId,
                testId: "",
                cards,
                type: 3,
                direction: 0,
                history,
                filter: filterMode,
                startsFrom: "withAdditionalCards",
            })
        );
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* activateSlice() {
    const { slice }: Item = yield select(selectors.currentItem);
    if (slice === undefined) return;

    const items: Item[] = yield select(selectors.filteredItems({ slice }));
    const unresolvedItems = items.filter(({ resolved }) => !resolved);
    if (!unresolvedItems.length) {
        yield put(actions.goNextSlice());
        return;
    }

    const unresolvedItemsByDirection: Record<Directions, Item[]> = {
        normal: unresolvedItems.filter(({ direction }) => ["normal", "both"].includes(direction)),
        opposite: unresolvedItems.filter(({ direction }) => ["opposite", "both"].includes(direction)),
    };

    yield put(appStatusActions.setLoading(true));
    try {
        const getCardData: (index: number) => SubjectUnitCard = yield select(selectors.cardDataGetter);
        const subjectId: string = yield select(selectors.subjectId);

        const results: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>>[] = yield all(
            Object.entries(unresolvedItemsByDirection)
                .filter(([, items]) => items.length)
                .map(([key, items]) => {
                    return call(cardActivationFiltered, {
                        activate: true,
                        // direction: key.toUpperCase() as Uppercase<Directions>,
                        direction: key.toUpperCase() as any,
                        cards: items.map(({ cardIndex }) => getCardData(cardIndex).cardIdToOwner.id),
                        subjectId,
                    });
                })
        );
        if (!results) return;
        yield all(
            results.map((result) => {
                const {
                    data: {
                        replyContent: { cards },
                    },
                } = result;
                return all(cards.map((newCard: any) => put(actions.updateCard(newCard))));
            })
        );

        yield putResolve(actions.updateItem({ item: unresolvedItems, newData: { resolved: true } }));
        yield put(responseActions.showResponse({ type: "SUCCESS", responseMessage: responseMessages[0] }));
        yield put(actions.goNextSlice());
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

type ActionsData = {
    learnDirection: "NORMAL" | "OPPOSITE";
    cardId: string;
    ownerId: string;
    sessionId: string;
    subjectId: string;
    unitId: string;
    questionText: string;
    answerText: string;
    phase: number;
    isPractice: boolean;
    accelerate: boolean;
    item: Item;
    isDue: boolean;
    forceRight?: boolean;
    forceWrong?: boolean;
};

function destructCardData(card: SubjectUnitCard, isOpposite: boolean) {
    const {
        normal,
        opposite,
        cardContent: { question, answer },
        cardIdToOwner: { id: cardId, ownerId },
        subjectIdToOwner: { id: subjectId },
        unitIdToOwner: { id: unitId },
    } = card;
    const [questionText, answerText] = (function () {
        const result = [question, answer].map((text) => getCardContentInfo(text, "title"));
        if (isOpposite) result.reverse();
        return result;
    })();
    const phase = [normal, opposite][+isOpposite]?.phase || 0;
    const isDue = [normal, opposite][+isOpposite]?.isDue;
    return {
        cardId,
        ownerId,
        subjectId,
        unitId,
        questionText,
        answerText,
        phase,
        isDue,
    };
}

function* getAllData(action: ResultActions) {
    const item: Item = yield select(selectors.currentItem);
    const card: SubjectUnitCard = yield select(selectors.currentCard);
    const sessionId: string = yield select(selectors.session);
    const accelerate: boolean = yield select(selectors.accelerate);

    if (!item || !card) return undefined;
    const { direction, type } = item;

    const isOpposite = direction === "opposite";

    const force = (
        {
            onMemorize: true,
            onAsCorrect: true,
            onDontKnow: false,
            onWasWrong: false,
        } as Partial<Record<ResultActions, boolean>>
    )[action];

    const result: ActionsData = {
        ...destructCardData(card, isOpposite),
        learnDirection: ["NORMAL", "OPPOSITE"][+isOpposite] as any,
        sessionId,
        isPractice: type === "practice",
        accelerate,
        forceRight: force === true ? true : undefined,
        forceWrong: force === false ? true : undefined,
        item,
    };

    return result;
}

function* compareAnswer(data: ActionsData, { value }: Parameters<(typeof actions)["resolveCard"]>[0]) {
    const {
        answerText: correctAnswerText,
        learnDirection,
        phase: phaseBeforeAnswering,
        cardId,
        ownerId,
        forceRight,
        forceWrong,
    } = data;

    const compareData: any = {
        correctAnswerText,
        learnDirection,
        phaseBeforeAnswering,
        userAnswerText: value,
        forceRight,
        forceWrong,
    };

    const compareAction = [forceRight, forceWrong].includes(true) ? compareAnswerNoTyping : compareAnswerAxios;

    yield put(appStatusActions.setBackgroundLoading(true));

    try {
        const {
            data: {
                replyContent: { isCorrect },
            },
        }: AxiosResponse<IResponse<{ isCorrect: boolean }>> = yield call(compareAction, {
            ownerId,
            cardId,
            data: compareData,
        });

        return !!isCorrect;
    } catch (e) {
        console.log(e);
        return false;
    } finally {
        yield put(appStatusActions.setBackgroundLoading(false));
    }
}

function* activateCard(
    data: ActionsData,
    answerCorrect: boolean,
    { value = "" }: Parameters<(typeof actions)["resolveCard"]>[0]
) {
    if (!answerCorrect) return;
    const { cardId, subjectId, item } = data;
    const { direction } = item;

    const directions = direction !== "both" ? [direction.toUpperCase()] : ["NORMAL", "OPPOSITE"];

    yield put(appStatusActions.setBackgroundLoading(true));
    try {
        const results: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>>[] = yield all(
            directions.map((direction) => {
                // @ts-ignore !!!OMG WHY??????
                return call(cardActivationFiltered, {
                    activate: answerCorrect,
                    cards: [cardId],
                    subjectId,
                    direction,
                });
            })
        );
        if (!results) return;

        const {
            data: {
                replyContent: {
                    cards: [newCard],
                },
            },
        } = results[results.length - 1];

        yield putResolve(actions.updateItem({ item, newData: { resolved: answerCorrect } }));
        yield call(updateStatistic, { cardId: [cardId], Type: "CARD_ACTIVATED", typedLetters: value.length });

        if (!newCard) return;
        yield putResolve(actions.updateCard(newCard));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setBackgroundLoading(false));
    }
}

function* checkPhases(phase: number) {
    const phases: Record<number, boolean> = yield select(userSelectors.getUserShownPhases);
    if (phase <= 1 || phases[phase]) return;
    yield putResolve(actions.pushModal("newPhase"));
}

function* sendPracticeStatistic(
    answerCorrect: boolean,
    isRegularPractice: boolean,
    cardId: string,
    typedLetters: number
) {
    const statisticType = getStatisticType(answerCorrect, isRegularPractice);

    yield call(updateStatistic, {
        cardId: [cardId],
        Type: statisticType,
        typedLetters,
    });
}

function* practiceCard(
    data: ActionsData,
    answerCorrect: boolean,
    { value = "", action }: Parameters<(typeof actions)["resolveCard"]>[0]
) {
    const { learnDirection, phase, sessionId, ownerId, cardId, subjectId, unitId, item, isDue } = data;

    const typing: boolean = yield select(selectors.typing);
    const isPrepareForTest: boolean = yield select(selectors.isPrepareForTest);
    const history = itemHistory(item, { action, phase, submitCount: item.submitCount, isDue });
    const isRegularPractice = getIsRegularPractice(isPrepareForTest, history);

    const submitCount: number = getSubmitCount(history);
    const resolved: boolean = getResolved(history, answerCorrect);
    const phaseBeforeAnswering = getPhaseBeforeAnswer(history);
    const preventSubmit = preventSubmitRequest(history, isDue, typing);
    const prevUserValue: string = yield select(selectors.userValue);
    const userValue = action === "onAsCorrect" ? prevUserValue : value;

    const newItem: Partial<Item> = {
        submitCount,
        resolved,
    };

    yield putResolve(actions.updateItem({ item, newData: newItem }));
    yield sendPracticeStatistic(answerCorrect, isRegularPractice, cardId, userValue.length);

    yield put(
        warningsActions.doAction({
            action,
            isCorrect: answerCorrect,
            typing,
        })
    );

    if (!isRegularPractice) {
        const preventPrepareForTestSubmit = preventPrepareForTestRequest(history, typing);
        if (preventPrepareForTestSubmit) return;

        const requestTestCardData = {
            direction: learnDirection,
            type: "PREPARE_FOR_TEST" as const,
            sessionId,
        };
        yield submitTestCardAnswer({ ownerId, cardId, data: requestTestCardData });

        return;
    }

    if (preventSubmit) return;

    const requestType = getPracticeRequestType(typing, action);

    const requestData = {
        answerCorrect,
        answerText: userValue,
        learnDirection,
        phaseBeforeAnswering,
        type: requestType,
        sessionId,
    };

    yield put(appStatusActions.setBackgroundLoading(true));
    try {
        yield call(submitAnswer, { ownerId, cardId, data: requestData });

        const {
            data: {
                replyContent: { cards = [] },
            },
        }: AxiosResponse<IResponse<{ cards: SubjectUnitCard[] }>> = yield call(getSubjectUnitCards, {
            cards: [cardId],
            subjectId,
            units: [unitId],
        });

        if (!cards || !cards.length) return;
        const newCard = cards.reduce((result, card) => {
            return { ...result, ...card };
        }, {} as SubjectUnitCard);
        yield putResolve(actions.updateCard(newCard));
        if (answerCorrect) {
            yield checkPhases(destructCardData(newCard, item.direction === "opposite").phase);
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setBackgroundLoading(false));
    }
}

function* beforeResolveCard(action: ResultActions) {
    const relations: Partial<Record<ResultActions, any>> = {
        onGoNext: function* () {
            yield put(actions.goNext());
            return false;
        },
        onShowAnswer: function* () {
            yield put(actions.updateState("show"));
            return false;
        },
    };
    if (!relations[action]) return true;
    const result: boolean = yield relations[action]();
    return result;
}

function* afterResolveCard(action: ResultActions, compare: boolean) {
    const relations: Partial<Record<ResultActions, any>> = {
        onCompare: function* () {
            if (compare) return true;
            yield put(responseActions.showResponse({ type: "ERROR", responseMessage: responseMessages[1] }));
            return false;
        },
        onWasWrong: function () {
            return true;
        },
        onDontKnow: function* () {
            yield put(actions.updateState("dontKnow"));
            return false;
        },
    };
    yield put(actions.updateState(compare ? "success" : "wrong"));
    if (!relations[action]) return compare;
    const result: boolean = yield relations[action]();
    return result;
}

function* resolveCard({ payload }: PayloadAction<Parameters<(typeof actions)["resolveCard"]>[0]>) {
    const beforeResolve: boolean = yield beforeResolveCard(payload.action);
    if (!beforeResolve) return;

    const data: ActionsData | undefined = yield getAllData(payload.action);
    if (!data) return;

    const compareResult: boolean = yield compareAnswer(data, payload);

    const action = [activateCard, practiceCard][+data.isPractice];

    yield action(data, compareResult, payload);

    const afterResolve: boolean = yield afterResolveCard(payload.action, compareResult);
    if (!afterResolve) return;

    const modals: Modals[] = yield select(selectors.modals);
    if (modals.length) return;

    if (data.accelerate) {
        yield put(actions.goNext());
    }
}

function* fetchAnnotations() {
    const card: SubjectUnitCard = yield select(selectors.currentCard);
    const annotations: Record<string, Annotations> = yield select(selectors.annotations);
    const {
        cardIdToOwner: { id: cardId, ownerId },
        subjectIdToOwner: { id: subjectId },
    } = card;
    yield put(appStatusActions.setLoading(true));
    try {
        const {
            data: { replyContent: annotationReply },
        }: AxiosResponse<IResponse<any>> = yield call(getCardAnnotation, cardId, ownerId, subjectId);
        const { answerAnnotation, questionAnnotation } = annotationReply;
        const result = { ...annotations };
        result[cardId] = {
            question: questionAnnotation,
            answer: answerAnnotation,
        };
        yield put(actions.setData({ annotations: result }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* saveAnnotations({ payload }: PayloadAction<Annotations>) {
    const card: SubjectUnitCard = yield select(selectors.currentCard);
    const {
        cardIdToOwner: { id: cardId, ownerId },
        subjectIdToOwner: { id: subjectId, ownerId: subjectOwnerId },
        cardContent: { question, answer },
    } = card;
    const areAnnotationsValid = validateAnnotations([question, answer], [payload.question, payload.answer]);
    if (!areAnnotationsValid) {
        yield put(
            responseActions.showResponse({
                type: "ERROR",
                responseMessage: ResponseMessageKeys.ANNOTATION_VALIDATION_ERROR,
            })
        );
        return;
    }

    yield put(appStatusActions.setLoading(true));
    try {
        const data = {
            questionAnnotation: payload.question,
            answerAnnotation: payload.answer,
        };
        yield call(updateCardAnnotation, cardId, subjectOwnerId, ownerId, subjectId, data);
        yield put(responseActions.showResponse({ type: "SUCCESS", responseMessage: responseMessages[4] }));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* sendFeedback({ payload }: PayloadAction<string>) {
    if (!payload) {
        yield put(responseActions.showResponse({ type: "ERROR", responseMessage: responseMessages[3] }));
        return;
    }
    const userValue: string = yield select(selectors.userValue);
    const { text: questionText } = yield select(selectors.cardTexts(false));
    const { text: answerText } = yield select(selectors.cardTexts(true));

    const questionRawText = generateFeedbackCardText(questionText);
    const answerRawText = generateFeedbackCardText(answerText);

    const {
        subjectIdToOwner: { id: subjectId },
        unitIdToOwner: { id: unitId },
        cardIdToOwner: { id: cardId },
    }: SubjectUnitCard = yield select(selectors.currentCard);

    yield put(appStatusActions.setLoading(true));
    try {
        yield call(sendFeedbackAxios, {
            article_uuid: subjectId,
            content_set_uuid: unitId,
            content_uuid: cardId,
            question: questionRawText,
            answer: answerRawText,
            user_answer: userValue,
            user_message: payload,
        });
        yield put(responseActions.showResponse({ type: "SUCCESS", responseMessage: responseMessages[2] }));
        yield put(actions.removeModal("feedback"));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(appStatusActions.setLoading(false));
    }
}

function* savePracticeTestResult({ payload }: PayloadAction<string>) {
    const practiced: Item[] = yield select(selectors.filteredItems({ type: "practice" }));
    const cardsAnsweredRight = practiced.filter((item) => item.submitCount === 1).length;

    try {
        yield call(saveTestResult, {
            testId: payload,
            totalCardsInTest: practiced.length,
            cardsAnsweredRight: cardsAnsweredRight,
            cardsPracticed: practiced.length,
        });
    } catch (err) {
        console.log(err);
    }
}

function* setTyping({ payload }: PayloadAction<boolean>) {
    const userPreferences: UserPreferences = yield select(userSelectors.userPreferences);
    const request: SaveUserPreferencesParams = {
        newPreferences: {
            ...userPreferences,
            inputEnabledForPracticeWeb: payload,
        },
        oldPreferences: { ...userPreferences },
    };
    yield put(userActions.saveUserPreferences(request));
}

function* setShowExitFirstPracticeModal({ payload }: PayloadAction<string | undefined>) {
    if (payload) return;
    const userId: string | undefined = yield select(userSelectors.userId);
    if (!userId) return;
    yield put(actions.setShowExitFirstPracticeModal(userId));
}

export function* learningSaga() {
    yield takeEvery(actions.fetch, fetch);
    yield takeEvery(actions.savePracticeTestResult, savePracticeTestResult);
    yield takeEvery(actions.start.type, start);
    yield takeEvery(actions.startPracticeWithDueCards.type, startPracticeWithDueCards);
    yield takeEvery(actions.startPracticeWithAdditionalCards.type, startPracticeWithAdditionalCards);
    yield takeEvery(actions.resolveCard, resolveCard);
    yield takeEvery(actions.activateSlice, activateSlice);
    yield takeEvery(actions.fetchAnnotations, fetchAnnotations);
    yield takeEvery(actions.saveAnnotations, saveAnnotations);
    yield takeEvery(actions.sendFeedback, sendFeedback);
    yield takeEvery(actions.setTyping, setTyping);
    yield takeEvery(actions.setShowExitFirstPracticeModal, setShowExitFirstPracticeModal);
}

function generateFeedbackCardText(text: string) {
    const { title } = getCardData(text);
    return title;
}
