// LIBRARIES
import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { SubjectData } from "p6m-subjects";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import SubjectEntry from "../../basic/subjectEntry/SubjectEntry";
import { useT } from "@transifex/react";

// HELPERS
import { accessibleActionProps } from "./../../../helpers/Accessibility";

export interface SubjectCarouselProps {
    subjects: Array<SubjectData>;
    activeSubjectId: string | null;
    userRoles: { isTeacher: boolean; isParent: boolean };
    onSubjectSelect: (id: string) => any;
    onAddSubject: () => any;
    onCreateSubject: () => any;
    tabIndex?: number;
}

interface FocusableElement extends HTMLElement {
    focus(): void;
}

const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
`;

const CarouselContainer = styled.div`
    position: relative;
    display: flex;
    height: 242px;
`;

const SubjectsContainer = styled.div`
    position: absolute;
    top: 0;
    transition: all 1s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const NavigationIcons = styled.span`
    display: flex;
    flex-direction: row;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.iconSmall};
`;
const Navigation = styled.div`
    height: 20px;
    margin: ${(props) => props.theme.base.spacingSmall} 0;
`;

const NavigationButton = styled.a<{ active?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.active ? props.theme.colors.primary : props.theme.colors.text)};
    opacity: ${(props) => (props.active ? 1 : 0.5)};

    &:hover {
        cursor: ${(props) => (props.active ? "pointer" : "default")};
    }
`;
const NavigationPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;

const AddSubjectEntry = styled.div<{ hasSubjects: boolean }>`
    color: ${(props) => props.theme.colors.text};
    background: none;
    overflow: hidden;
    height: 240px;
    width: 180px;
    padding: 16px 32px 32px 32px;
    margin-top: ${(props) => (props.hasSubjects ? "-6px" : "")}; // correct to match subject images
    border: 2px dashed ${(props) => props.theme.colors.text};
    border-radius: ${({ theme: { base } }) => base.borderRadius};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1px;
    &:hover {
        color: ${(props) => props.theme.colors.primary};
        border-color: ${(props) => props.theme.colors.primary};
    }
`;
const StyledPhaseSixIcon = styled(PhaseSixIcon)`
    color: inherit;
`;
const AddSubjectText = styled.p`
    text-align: center;
    font-size: 16px;
`;

const CAROUSEL_STEP_LENGTH = 2;
const CAROUSEL_ELEMENT_WIDTH = 190;

const SubjectCarousel: React.FC<SubjectCarouselProps> = (props) => {
    const t = useT();
    const t_chooseBook = t("Choose a book", { _tags: "subjectCarousel,home" });
    const t_createBook = t("Create vocabulary", { _tags: "subjectCarousel,home" });
    const carouselRef = useRef<HTMLDivElement>(null);
    const subjectsContainerRef = useRef<HTMLDivElement>(null);
    const [subjectEntryIsFocused, setSubjectEntryIsFocused] = useState(false);
    const [addOrCreateEntryisFocused, setAddOrCreateEntryisFocused] = useState(false);
    const [addOrCreateEntryWasFocusedAtLast, setAddOrCreateEntryWasFocusedAtLast] = useState(false);
    const [addOrCreateEntryIsFocusAble, setAddOrCreateEntryIsFocusAble] = useState(false);
    const subjectAddRef = useRef<HTMLDivElement>(null);
    const subjectCreateRef = useRef<HTMLDivElement>(null);
    const subjectRefs = useMemo(
        () => Array.from({ length: props.subjects.length }, () => React.createRef<HTMLDivElement>()),
        [props.subjects]
    );

    const activeSubjectPosition = useMemo(
        () =>
            props.subjects.findIndex(
                (subject) => subject.subjectMetadata.subjectIdToOwner.id === props.activeSubjectId
            ),
        [props.subjects, props.activeSubjectId]
    );

    const [scrollContainerWidthDifference, setScrollContainerWidthDifference] = useState(0);
    const [entriesVisible, setEntriesVisible] = useState(CAROUSEL_STEP_LENGTH);
    const [position, setPosition] = useState(0);

    const goBack = useCallback(() => {
        setPosition((position) => {
            let newPosition = position - CAROUSEL_STEP_LENGTH;
            if (newPosition < 0) newPosition = 0;
            return newPosition;
        });
    }, [setPosition]);
    const goForth = useCallback(() => {
        setPosition((position) => {
            let newPosition = position + CAROUSEL_STEP_LENGTH;
            if (newPosition > props.subjects.length - 1) newPosition = props.subjects.length - 1;
            if (newPosition < 0) newPosition = 0;
            return newPosition;
        });
    }, [setPosition, props.subjects]);

    const handleScroll = useCallback(
        (event: any) => {
            const ref = carouselRef;
            const target = event.target;
            if (ref && target instanceof Node && ref.current?.contains(target)) {
                event.returnValue = false;
                event.preventDefault();
                if (event.deltaY && event.deltaY > 0) {
                    goForth();
                } else {
                    goBack();
                }
                return false;
            }
        },
        [carouselRef, goBack, goForth]
    );

    const calcWidthDiff = useCallback(() => {
        const carouselRefElement = carouselRef?.current;
        const subjectsContainerRefElement = subjectsContainerRef?.current;
        let newWidthDiff = 0;
        if (carouselRefElement && subjectsContainerRefElement) {
            newWidthDiff = carouselRefElement.offsetWidth - subjectsContainerRefElement.offsetWidth;

            const fullSubjectsShown = Math.floor(carouselRefElement.offsetWidth / CAROUSEL_ELEMENT_WIDTH); // img 180px + margin 10px
            setEntriesVisible(fullSubjectsShown);
        }
        setScrollContainerWidthDifference(newWidthDiff);
    }, [carouselRef, subjectsContainerRef, setScrollContainerWidthDifference]);

    useEffect(() => {
        window.addEventListener("resize", calcWidthDiff);
        document.addEventListener("wheel", handleScroll, { passive: false });
        return () => document.removeEventListener("wheel", handleScroll);
    }, [handleScroll, calcWidthDiff]);

    useEffect(() => {
        calcWidthDiff();
    }, [calcWidthDiff, props.subjects]);

    useEffect(() => {
        setPosition(activeSubjectPosition >= 0 ? activeSubjectPosition : 0);
    }, [activeSubjectPosition]);

    // move subjects according to state position
    const leftStyle = {
        left:
            scrollContainerWidthDifference > 0
                ? 1
                : Math.max(scrollContainerWidthDifference, position * -CAROUSEL_ELEMENT_WIDTH + 1) + "px",
    };

    const onTabCarousel = (forward: boolean = true) => {
        const focusableElements: NodeListOf<FocusableElement> = document.querySelectorAll(".carousel-subject-entry");
        const focusableArray: FocusableElement[] = Array.from(focusableElements);
        const currentFocusIndex: number = focusableArray.indexOf(document.activeElement as FocusableElement);
        let nextIndex: number;
        if (forward) {
            nextIndex = currentFocusIndex + 1;
        } else {
            nextIndex = currentFocusIndex - 1;
        }
        if (nextIndex >= 0 && nextIndex < focusableElements.length) {
            // ADD / CREATE should not automatically get clicked
            if (!focusableArray[nextIndex].classList.contains("doTabActionOnEnter")) {
                focusableArray[nextIndex].click();
            }
            focusableArray[nextIndex].focus();
        }
    };

    useEffect(() => {
        if (subjectEntryIsFocused) {
            setAddOrCreateEntryWasFocusedAtLast(false);
        }
        if (addOrCreateEntryisFocused) {
            setAddOrCreateEntryWasFocusedAtLast(true);
        }
        const handleKeyPress = (event: KeyboardEvent) => {
            if (subjectEntryIsFocused || addOrCreateEntryisFocused) {
                if (event.key === "ArrowRight") {
                    event.preventDefault();
                    onTabCarousel(true);
                } else if (event.key === "ArrowLeft") {
                    event.preventDefault();
                    onTabCarousel(false);
                }
            }
        };

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [subjectEntryIsFocused, addOrCreateEntryisFocused]);

    useEffect(() => {
        const onFocus = () => {
            setAddOrCreateEntryisFocused(true);
        };
        const onBlur = () => {
            setAddOrCreateEntryisFocused(false);
        };

        const atLeastOneOfRequiredRefs = subjectAddRef || subjectCreateRef;

        if (atLeastOneOfRequiredRefs.current) {
            atLeastOneOfRequiredRefs.current.addEventListener("focus", onFocus);
            atLeastOneOfRequiredRefs.current.addEventListener("blur", onBlur);
        }

        return () => {
            if (atLeastOneOfRequiredRefs.current) {
                atLeastOneOfRequiredRefs.current.removeEventListener("focus", onFocus);
                atLeastOneOfRequiredRefs.current.removeEventListener("blur", onBlur);
            }
        };
    }, [subjectAddRef.current, subjectCreateRef.current]);

    useEffect(() => {
        if ((subjectEntryIsFocused || !addOrCreateEntryWasFocusedAtLast) && props.subjects.length != 0) {
            setAddOrCreateEntryIsFocusAble(false);
        } else {
            setAddOrCreateEntryIsFocusAble(true);
        }
    }, [subjectEntryIsFocused, addOrCreateEntryWasFocusedAtLast, props.subjects.length]);

    return (
        <Container>
            <Navigation>
                <NavigationIcons>
                    <NavigationButton
                        onClick={goBack}
                        active={position !== 0}
                        tabIndex={-1}
                    >
                        <NavigationPhaseSixIcon name={"chevron-left"} />
                    </NavigationButton>
                    <NavigationButton
                        onClick={goForth}
                        active={position < props.subjects.length + 1 /* +1 for ADD */ - entriesVisible}
                        tabIndex={-1}
                    >
                        <NavigationPhaseSixIcon name={"chevron-right"} />
                    </NavigationButton>
                </NavigationIcons>
            </Navigation>
            <CarouselContainer ref={carouselRef}>
                <SubjectsContainer
                    style={leftStyle}
                    ref={subjectsContainerRef}
                >
                    {props.subjects.map((subject) => {
                        return (
                            <SubjectEntry
                                key={subject.subjectMetadata.subjectIdToOwner.id}
                                subject={subject}
                                userRoles={props.userRoles}
                                active={props.activeSubjectId === subject.subjectMetadata.subjectIdToOwner.id}
                                onSubjectSelect={props.onSubjectSelect}
                                tabIndex={
                                    addOrCreateEntryisFocused || addOrCreateEntryWasFocusedAtLast ? -1 : props.tabIndex
                                }
                                setSubjectEntryIsFocused={setSubjectEntryIsFocused}
                                subjectRef={subjectRefs[props.subjects.indexOf(subject)]}
                            />
                        );
                    })}
                    {props.subjects.length > 0 ? (
                        <AddSubjectEntry
                            hasSubjects={true}
                            className="carousel-subject-entry doTabActionOnEnter"
                            ref={subjectAddRef}
                            {...accessibleActionProps(
                                props.onAddSubject,
                                addOrCreateEntryIsFocusAble ? props.tabIndex : -1
                            )}
                        >
                            <StyledPhaseSixIcon
                                name={"add"}
                                size={"big"}
                            />
                            <AddSubjectText>{t_chooseBook}</AddSubjectText>
                        </AddSubjectEntry>
                    ) : (
                        <AddSubjectEntry
                            hasSubjects={false}
                            className="carousel-subject-entry doTabActionOnEnter"
                            ref={subjectCreateRef}
                            {...accessibleActionProps(
                                props.onCreateSubject,
                                addOrCreateEntryIsFocusAble ? props.tabIndex : -1
                            )}
                        >
                            <StyledPhaseSixIcon
                                name={"add"}
                                size={"big"}
                            />
                            <AddSubjectText>{t_createBook}</AddSubjectText>
                        </AddSubjectEntry>
                    )}
                </SubjectsContainer>
            </CarouselContainer>
        </Container>
    );
};

export default SubjectCarousel;
