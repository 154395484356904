// LIBRARIES
import React, { useCallback, useEffect, useState } from "react";
import { ampli } from "../../../../ampli";
import { useT } from "@transifex/react";

// MODELS
import { SubjectData, SubjectReportDifficultCardData } from "p6m-subjects";

// NETWORKING
import { getReportDifficultData } from "../../../../networking/reports";

// HELPERS
import { stripMediaIds } from "../../../../helpers/Cards";

// STYLES
import { CountHeader } from "./styles";
import { NoDataContainer } from "../../styles";
import { Container, Entry, Header, List, Statistics, Text, TextHeader, TableWrapper } from "../styles";

export interface ReportsProps {
    ownerId?: string;
    selectedSubject: SubjectData;
}

const ReportDifficult: React.FC<ReportsProps> = (props) => {
    const t = useT();
    const [data, setData] = useState<SubjectReportDifficultCardData[] | null>(null);

    const loadData = useCallback(async () => {
        const {
            data: { replyContent: difficultCards },
        } = await getReportDifficultData(
            props.ownerId || "",
            props.selectedSubject.subjectMetadata.subjectIdToOwner,
            "ONE_MONTH"
        );
        setData(difficultCards);
    }, [props.ownerId, props.selectedSubject]);

    useEffect(() => {
        loadData();
    }, [loadData]);

    useEffect(() => {
        ampli.openReportsTab({
            reports_tab: "difficult_cards",
        });
    }, []);

    const questionHeader_t = t("Question", { _tags: "reports,difficult,title" });
    const answerHeader_t = t("Correct answer", { _tags: "reports,difficult,title" });
    const countHeader_t = t("Wrong answers", { _tags: "reports,difficult,title" });
    const noData_t = t("There is no data do display", { _tags: "reports,message" });

    return (
        <Container>
            <TableWrapper>
                <Entry>
                    <Header>
                        <TextHeader>{questionHeader_t}</TextHeader>
                        <TextHeader>{answerHeader_t}</TextHeader>
                        <CountHeader>{countHeader_t}</CountHeader>
                    </Header>
                </Entry>
                {/* Positive tabindex ensures scrollable element is focusable in correct order. Problem: it makes div focusable, even when its not interactive (non clickable, non scrollable). TODO: Remove tabindex once sidenavigation tabindex is refactored and set back to 0 */}
                <List tabIndex={1}>
                    {data &&
                        (data.length > 0 ? (
                            [...data].map((card, index) => {
                                return (
                                    <Entry key={"card_" + index}>
                                        <Statistics>
                                            <Text dangerouslySetInnerHTML={{ __html: stripMediaIds(card.question) }} />
                                            <Text dangerouslySetInnerHTML={{ __html: stripMediaIds(card.answer) }} />
                                            <Text center>{card.counter.toLocaleString("de-DE")}</Text>
                                        </Statistics>
                                    </Entry>
                                );
                            })
                        ) : (
                            <NoDataContainer key={"noData"}>
                                <Statistics>
                                    <Text>{noData_t}</Text>
                                </Statistics>
                            </NoDataContainer>
                        ))}
                </List>
            </TableWrapper>
        </Container>
    );
};

export default ReportDifficult;
