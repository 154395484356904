import React, { FunctionComponent, memo } from "react";
import { useT } from "@transifex/react";
import Modal, { Props as ModalProps } from "../../../basic/modal/Modal";
import { Row, Col } from "../../../basic/grid";
import CardEditor, { Props as TextEditorProps } from "../../../complex/textEditor/CardEditor";

import { Title, ContentContainer, QATitle, ModalFooter } from "./styles";
import { BlankButton, FilledButton } from "../../../basic/button/Button";

export type Props = {
    active: boolean;
    question?: string;
    questionLanguageCode?: string;
    questionFiles: TextEditorProps["files"];
    onQuestionChange: (value: string) => void;
    onQuestionFilesChange: TextEditorProps["onFilesChange"];
    answer?: string;
    answerLanguageCode?: string;
    answerFiles: TextEditorProps["files"];
    onAnswerChange: (value: string) => void;
    onAnswerFilesChange: TextEditorProps["onFilesChange"];
    onSave: () => void;
} & Partial<ModalProps>;

const UpdateCardModal: FunctionComponent<Props> = (props) => {
    const {
        active,
        answer = "",
        onAnswerChange,
        question = "",
        onQuestionChange,
        answerLanguageCode,
        questionLanguageCode,
        onAnswerFilesChange,
        onQuestionFilesChange,
        questionFiles,
        answerFiles,
        onSave,
        ...restProps
    } = props;
    const t = useT();

    const t_title = t("Edit Card", {});
    const t_questionPlaceholder = t("Gib hier die Frage ein", {});
    const t_answerPlaceholder = t("Gib hier die Antwort ein", {});
    const t_questionTitle = t("Question", {});
    const t_answerTitle = t("Answer", {});
    const t_cancel = t("Cancel", {});
    const t_next = t("Save and next", {});

    const disabled: boolean = !question || !answer;

    return (
        <Modal
            active={active}
            title={<Title>{t_title}</Title>}
            destroyOnClose
            modalBodyStyle={{ width: 980 }}
            footerComponent={({ modalActions: { hide = () => {} } = {} }) => (
                <ModalFooter>
                    <Row horizontal="end">
                        <Col xs="auto">
                            <FilledButton
                                onClick={() => {
                                    hide();
                                }}
                                upperCase
                            >
                                {t_cancel}
                            </FilledButton>
                        </Col>
                        <Col xs="auto">
                            <BlankButton
                                disabled={disabled}
                                onClick={() => {
                                    if (disabled) return;
                                    onSave();
                                }}
                                upperCase
                            >
                                {t_next}
                            </BlankButton>
                        </Col>
                    </Row>
                </ModalFooter>
            )}
            {...restProps}
        >
            <ContentContainer>
                <Row horizontal="between">
                    <Col xs={6}>
                        <QATitle>{t_questionTitle}</QATitle>
                        <CardEditor
                            tabIndex={1}
                            type="question"
                            configuration="ADD"
                            placeholder={t_questionPlaceholder}
                            value={question}
                            languageCode={questionLanguageCode}
                            onChange={onQuestionChange}
                            files={questionFiles}
                            onFilesChange={onQuestionFilesChange}
                            autoFocus
                        />
                    </Col>
                    <Col xs={6}>
                        <QATitle>{t_answerTitle}</QATitle>
                        <CardEditor
                            tabIndex={2}
                            type="answer"
                            configuration="ADD"
                            placeholder={t_answerPlaceholder}
                            value={answer}
                            languageCode={answerLanguageCode}
                            onChange={onAnswerChange}
                            files={answerFiles}
                            onFilesChange={onAnswerFilesChange}
                        />
                    </Col>
                </Row>
            </ContentContainer>
        </Modal>
    );
};

export default memo(UpdateCardModal);
