import Cookie, { CookieSetOptions } from "universal-cookie";

// delete cookie from all domain and subdomains
export function deleteCookieFromAllSubdomains(name: string, cookieOptions: CookieSetOptions = {}) {
    const { domain = document.location.hostname, ...restOptions } = cookieOptions;
    const domainParts = domain.split(".").filter((part) => !!part);
    const mainDomain = domainParts.splice(-2, 2).join(".");

    if (mainDomain) domainParts.push(mainDomain);

    const cookie = new Cookie();

    cookie.remove(name, restOptions);

    domainParts.forEach((currentPart, index, currentArray) => {
        const domainToDelete = currentArray.slice(index).join(".");
        const domainToDeleteWithDot = "." + domainToDelete;
        [domainToDeleteWithDot, domainToDelete].forEach((domain) => {
            cookie.remove(name, {
                ...cookieOptions,
                domain,
            });
        });
    });
}

export const purgeAmplitudeStorageFromClient = () => {
    const cookie = new Cookie();

    const allCookies = cookie.getAll();

    const removeStoredData = (input: any) => {
        const allAmplitudeKeys = Object.keys(input).filter((key) => key.includes("AMP_"));
        allAmplitudeKeys.forEach((amplitudeKey) => {
            deleteCookieFromAllSubdomains(amplitudeKey);
            localStorage.removeItem(amplitudeKey);
        });
    };

    removeStoredData(allCookies);
    removeStoredData({ ...localStorage });
};
