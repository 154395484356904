import styled from "styled-components";

export const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    padding: 2rem 0;
    gap: 2rem;
    overflow: hidden;
`;

export const TableWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    padding: 0 2px;
`;

export const List = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    margin: 0;
    padding: 0;
    padding-bottom: 1rem;
`;

export const Header = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
`;

export const Entry = styled.li<{ selected?: boolean }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin: 0 2px;
    border-radius: ${(props) => props.theme.base.borderRadiusSmall};
    color: ${(props) => (props.selected ? props.theme.colors.primary : props.theme.colors.text)};

    &:after {
        content: "";
        border-bottom: 1px solid ${(props) => props.theme.colors.textLight};
        width: calc(100% - ${(props) => props.theme.base.borderRadiusSmall});
        display: block;
        margin: 0 auto;
    }
`;

export const Statistics = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacing};
    cursor: pointer;
    gap: 1rem;
`;

export const InformationBox = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 550px;
    max-width: 230px;
`;

export const InfoImageContainer = styled.div`
    flex: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const InfoImage = styled.img`
    height: auto;
    width: 230px;
    margin-bottom: -40px;
    z-index: 1;
`;

export const InfoTextContainer = styled.div<{ selected?: boolean }>`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 60px ${(props) => props.theme.base.spacing} ${(props) => props.theme.base.spacing};
    color: ${(props) => (props.selected ? props.theme.colors.backgroundContrast : props.theme.colors.text)};
    background: ${(props) => (props.selected ? props.theme.colors.primary : "transparent")};
    border-radius: ${(props) => props.theme.base.borderRadiusBig};
    border: ${(props) => (props.selected ? "none" : "1px solid " + props.theme.colors.textLight)};
`;

export const InfoTitle = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.titleSize};
    font-weight: bold;
`;

export const InfoSubtext = styled.p`
    text-align: center;
    font-size: ${(props) => props.theme.base.bigSize};
`;

export const Text = styled.span<{ center?: boolean }>`
    flex: 1;
    color: ${(props) => props.theme.colors.text};
    text-align: ${(props) => (props.center ? "center" : "unset")};
    overflow: auto;
`;

export const TextHeader = styled(Text)`
    font-weight: bold;
`;
