// REACT
import React, {
    FunctionComponent,
    PropsWithChildren,
    useState,
    useEffect,
    useCallback,
    Children,
    useContext,
    useMemo,
    useRef,
} from "react";
import ReactQuill from "react-quill";

// COMPONENTS
import PhaseSixIcon from "../phaseSixIcon/PhaseSixIcon";
import Component, { Props as ComponentProps } from "./SpecialCharSelect";
import { SpecialCharMenu } from "./SpecialCharMenu";

// UTILS
import { SpecialCharacterContext } from "../../../context/specialCharacterSelection";

// STYLES
import { SpecialCharacterButton } from "./styles";

export type Props = {
    belongsToPracticeInput?: boolean;
    lang?: string;
    onCharSelect: (selectedChar: string) => void;
    editorRef?: React.RefObject<ReactQuill | HTMLTextAreaElement>;
} & Partial<Omit<ComponentProps, "isOpen" | "menu">>;

export const SpecialCharSelect: FunctionComponent<PropsWithChildren<Props>> = (props) => {
    const specialCharSelectRef = useRef<HTMLDivElement | null>(null);
    const {
        lastOpenedCharMenu,
        isActiveInPracticeInput: isActiveInPracticeWindow,
        selectedCharLanguage,
        isRefOpen,
        addOpenRef,
        removeOpenRef,
        setSelectedCharLanguage,
        closeAllCharMenus,
    } = useContext(SpecialCharacterContext);

    const {
        children,
        belongsToPracticeInput = false,
        lang: initialLang,
        onCharSelect,
        editorRef,
        ...restComponentWrapperProps
    } = props;

    const [updatedLang, setUpdatedLang] = useState<string | undefined>(initialLang);

    const isOpen = useMemo(
        () =>
            belongsToPracticeInput
                ? isActiveInPracticeWindow
                : isRefOpen(specialCharSelectRef?.current as HTMLDivElement),
        [belongsToPracticeInput, isActiveInPracticeWindow, specialCharSelectRef, isRefOpen]
    );

    const handleToggleCharSelect = useCallback(
        ({ isOpen }: { isOpen: boolean }) => {
            const charSelectRef = specialCharSelectRef.current;

            if (!(charSelectRef instanceof HTMLDivElement)) return;

            const openMenu = () => {
                addOpenRef({ ref: charSelectRef, isPracticeInput: belongsToPracticeInput });
            };

            const closeMenu = () => {
                removeOpenRef({ ref: charSelectRef, isPracticeInput: belongsToPracticeInput });
                setTimeout(() => editorRef?.current?.focus(), 350);
            };

            isOpen ? openMenu() : closeMenu();
        },
        [editorRef, specialCharSelectRef, belongsToPracticeInput, addOpenRef, removeOpenRef]
    );

    const handleOpen = useCallback(() => {
        handleToggleCharSelect({ isOpen: true });
    }, [handleToggleCharSelect]);

    const handleClose = useCallback(() => {
        if (belongsToPracticeInput || lastOpenedCharMenu === specialCharSelectRef?.current) {
            handleToggleCharSelect({ isOpen: false });
        }
    }, [specialCharSelectRef, belongsToPracticeInput, lastOpenedCharMenu, handleToggleCharSelect]);

    const handleCharSelect = (selectedChar: string) => {
        onCharSelect(selectedChar);
    };

    useEffect(() => {
        const cleanUpOpenCharMenus = () => {
            closeAllCharMenus();
        };

        return cleanUpOpenCharMenus();
    }, [closeAllCharMenus]);

    return (
        <Component
            ref={specialCharSelectRef}
            isOpen={isOpen}
            menu={
                <SpecialCharMenu
                    specialCharSelectRef={specialCharSelectRef}
                    onClose={handleClose}
                    onCharSelect={handleCharSelect}
                    lang={belongsToPracticeInput ? selectedCharLanguage ?? updatedLang : updatedLang}
                    onLangSelect={(lang: string) => {
                        belongsToPracticeInput && setSelectedCharLanguage(lang);
                        setUpdatedLang(lang);
                    }}
                />
            }
            {...restComponentWrapperProps}
        >
            {!!children ? (
                Children.only(children)
            ) : (
                <SpecialCharacterButton onClick={handleOpen}>
                    <PhaseSixIcon name="special-character" />
                </SpecialCharacterButton>
            )}
        </Component>
    );
};
