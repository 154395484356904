import styled from "styled-components";

export const MainWrapper = styled.div`
    background: ${(props) => props.theme.colors.backgroundContrast};
    min-height: 50px;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    margin-bottom: 1px;
`;

export const ToggleText = styled.p`
    flex: 9;
    color: ${(props) => props.theme.colors.text};
    font-size: ${(props) => props.theme.base.bigSize};
`;

export const LabelComponent = styled.label`
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.theme.base.spacingSmall};
    cursor: pointer;
    border-radius: ${(props) => props.theme.base.borderRadius};
`;
