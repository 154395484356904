// LIBRARIES
import React, { useMemo, useCallback, useEffect, useRef, useState } from "react";
import { useT } from "@transifex/react";
import InfiniteScroll from "react-infinite-scroller";
import { isSafeInteger } from "lodash";

//COMPONENTS
import LeaderBoardUserItem from "../../components/basic/leaderboardUserItem/leaderboardUserItem/LeaderBoardUserItem";
import PhaseSixIcon from "../../components/basic/phaseSixIcon/PhaseSixIcon";
import Tooltip from "../../components/basic/tooltip/Tooltip";
import PersonalPreferencesPopupContent from "../../components/connected/PersonalPreferencesPopupContent/PersonalPreferencesPopupContent";
import OwnLeaderBoardUserItem from "../../components/basic/leaderboardUserItem/ownLeaderBoardUserItem/OwnLeaderBoardUserItem";
import LeaderboardSearchPopupContent from "../../components/connected/LeaderboardSearchPopupContent/LeaderboardSearchPopupContent";

// Hooks
import { useRankingPosition } from "./useRankingPosition";
import { useLeaderboardTooltips } from "../../hooks/useLeaderboardTooltips";

//Types
import {
    User,
    UserGroup,
    LeaderboardTabs,
    LeaderboardStats,
    SchoolData,
    UserStats,
    LeaderboardDataTimeParam,
} from "p6m-user";
import { LeaderboardTimespan } from "../../helpers/LeaderboardTooltip";

//STYLES
import {
    HighlightedLink,
    Wrapper,
    Container,
    HeaderAngleIconLeft,
    HeaderAngleIconRight,
    HeaderContentContainer,
    HighlightTitle,
    Title,
    HighlightText,
    IconRow,
    IconCol,
    Text,
    SmallerText,
    TitleRow,
    TabWrapper,
    IconsGridWrapper,
    Tab,
    SearchPhaseSixIcon,
    InfoPhaseSixIcon,
    List,
    PlaceholderContainer,
    PlaceholderText,
    SelectSchoolButton,
    SelectSchoolButtonText,
    InactiveLink,
} from "./styles";

export interface LeaderboardProps {
    leaderBoardData: Array<LeaderboardStats> | undefined;
    metaData: any;
    currentUserStats: any;
    user: User;
    week: LeaderboardDataTimeParam;
    changeWeek: any;
    onScroll: () => void;
    refreshData: () => void;
    loadDataIncludingUser(position: number): void;
    allDataIsLoaded: boolean;
    toggleUseFirstName: any;
    isLoading: boolean;
    useFirstName: any;
    userAvatars: any;
    groups: Array<UserGroup>;
    selectedTab: LeaderboardTabs;
    setSelectedTab: (tab: LeaderboardTabs) => void;
    isSchoolTab: boolean;
    mySchoolStats: SchoolData | null;
    showSelectSchoolButton: boolean;
    hasOwnSchool: boolean;
    setHasOwnSchool: (hasOwnSchool: boolean) => void;
    isUserCheater?: boolean;
    showWarning?: (message: string) => void;
}

const Leaderboard: React.FC<LeaderboardProps> = (props) => {
    const selectedTimespan = useMemo(() => {
        const week = props.week;

        if (week === -1) {
            return LeaderboardTimespan.LAST_WEEK;
        } else if (week === 1000) {
            return LeaderboardTimespan.SINCE_2021;
        } else return LeaderboardTimespan.THIS_WEEK;
    }, [props.week]);

    const t = useT();
    const ListRef = useRef(null);

    const { isUserCheater, showWarning, currentUserStats, mySchoolStats, user, selectedTab } = props;
    const position = useRankingPosition(currentUserStats?.position, mySchoolStats?.position, mySchoolStats?.id);

    const [showInfo, setShowInfo] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showCurrentPositionInList, setShowCurrentPositionInList] = useState<boolean>(false);

    const userRoles = user.roles || [];
    const isAdult = userRoles.includes("adult");
    const showInfoToggleButton = !(["allSchools", "favourites"] as LeaderboardTabs[]).includes(selectedTab);

    const toggleInfoPopup = useCallback(() => {
        showInfoToggleButton && setShowInfo((currentStatus) => !currentStatus);
    }, [showInfoToggleButton]);

    const toggleSearchPopup = useCallback(() => {
        if (!isAdult) {
            if (showSearch) {
                props.refreshData();
            }
            setShowSearch(!showSearch);
        }
    }, [isAdult, showSearch, props.refreshData]);

    const serverMediaPath = process.env.REACT_APP_BACKEND_API_URL + "media/";

    //String constants for translation
    const refreshLabel = t("Refresh data", { _tags: "link" });
    const learnedCardsLabel = t("Learned Cards", { _tags: "label" });
    const goingOnForDaysLabel = t("still running:", { _tags: "title" });
    const lastWeek = t("Last week:", { _tags: "title" });
    const allDataLabel = t("All Data since 2021", { _tags: "title" });
    const goToPositionLabel = t("Jump to my position", { _tags: "link" });
    const allLearningPeopleTab = t("All learners", { _tags: "leaderboard,tab,label" });
    const mySchoolTab = t("My school", { _tags: "leaderboard,tab,label" });
    const allSchoolsTab = t("All schools", { _tags: "leaderboard,tab,label" });
    const favouritesTab = t("Favourites", { _tags: "leaderboard,tab,label" });
    const familyTab = t("Family", { _tags: "leaderboard,tab,label" });
    const days = t(" Days", { _tags: "days" });
    const loading_t = t("Loading", { _tags: "leaderboard, placeholder, message" });
    const noData_t = t("There is no data yet.", { _tags: "leaderboard, placeholder, message" });
    const selectYourSchool_t = t("Select your school", { _tags: "leaderboard, button" });
    const addFavouriteSchool_t = t("Add school to favourites", { _tags: "leaderboard, button" });

    const itemsRef = useRef<HTMLDivElement>(null);

    const setWeek = useCallback(
        (week: number) => {
            props.changeWeek(week);
        },
        [props.changeWeek]
    );

    const calcDaysToGo = () => {
        // set next sundays date as end date
        let end = new Date();
        end.setDate(end.getDate() + ((7 + 7 - end.getDay()) % 7));

        //calculate difference in days
        let today = new Date();
        const diffInTime = end.getTime() - today.getTime();
        const diffDays = diffInTime / (1000 * 3600 * 24);
        const daysLeftString = Math.round(diffDays).toFixed(0);

        return daysLeftString + " " + days;
    };

    //matching userAvatars with avatar from api to get mediaID
    const getUserAvatarUrl = (avatarId: string | undefined): string | undefined => {
        if (!avatarId || !props.userAvatars?.data) return undefined;
        const avatarGroups = props.userAvatars.data.replyContent.avatarGroups;
        const avatarGroupsKeys = Object.keys(avatarGroups);
        for (let i = 0; i < avatarGroupsKeys.length; i++) {
            const userAvatars = avatarGroups[avatarGroupsKeys[i]].userAvatars;
            for (let j = 0; j < userAvatars.length; j++) {
                if (avatarId === userAvatars[j].avatarId && userAvatars[j].avatarMediaId !== "Test") {
                    return serverMediaPath + userAvatars[j].avatarMediaId;
                }
            }
        }
    };

    const renderRefreshLink = () => {
        if (props.week > -1) {
            return (
                <HighlightedLink
                    onClick={() => props.refreshData()}
                    children={refreshLabel}
                />
            );
        }
        return <InactiveLink children={refreshLabel} />;
    };

    const renderTabs = () => {
        const tabs = [];
        tabs.push(
            <Tab
                key={"all_tab"}
                color={selectedTab === "all" ? "primary" : ""}
                onClick={() => props.setSelectedTab("all")}
            >
                <PhaseSixIcon
                    name={"group"}
                    highlighted={selectedTab === "all"}
                />
                {allLearningPeopleTab}
            </Tab>
        );
        if (userRoles.indexOf("student") > -1 || userRoles.indexOf("teacher") > -1) {
            tabs.push(
                <Tab
                    key={"mySchool_tab"}
                    color={selectedTab === "mySchool" ? "primary" : ""}
                    onClick={() => props.setSelectedTab("mySchool")}
                >
                    <PhaseSixIcon
                        name={"my-school"}
                        highlighted={selectedTab === "mySchool"}
                    />
                    {mySchoolTab}
                </Tab>
            );
        }
        if (
            userRoles.indexOf("student") > -1 ||
            userRoles.indexOf("teacher") > -1 ||
            userRoles.indexOf("parent") > -1
        ) {
            tabs.push(
                <Tab
                    key={"allSchools_tab"}
                    color={selectedTab === "allSchools" ? "primary" : ""}
                    onClick={() => props.setSelectedTab("allSchools")}
                >
                    <PhaseSixIcon
                        name={"school"}
                        highlighted={selectedTab === "allSchools"}
                    />
                    {allSchoolsTab}
                </Tab>
            );
            tabs.push(
                <Tab
                    key={"favourites_tab"}
                    color={selectedTab === "favourites" ? "primary" : ""}
                    onClick={() => props.setSelectedTab("favourites")}
                >
                    <PhaseSixIcon
                        name={"no-star"}
                        highlighted={selectedTab === "favourites"}
                    />
                    {favouritesTab}
                </Tab>
            );
        }
        if (
            userRoles.indexOf("teacher") > -1 ||
            userRoles.indexOf("parent") > -1 ||
            // has family group
            props.groups.some((group) => {
                return group.type === "Family";
            })
        ) {
            tabs.push(
                <Tab
                    key={"family_tab"}
                    color={selectedTab === "family" ? "primary" : ""}
                    onClick={() => props.setSelectedTab("family")}
                >
                    <PhaseSixIcon
                        name={"family"}
                        highlighted={selectedTab === "family"}
                    />
                    {familyTab}
                </Tab>
            );
        }
        return tabs;
    };

    const renderPreferencesPopup = () => {
        if (showInfo && props.currentUserStats) {
            return (
                <PersonalPreferencesPopupContent
                    togglePopup={toggleInfoPopup}
                    useFirstName={props.useFirstName}
                    toggleUseFirstName={props.toggleUseFirstName}
                    isModalActive={showInfo}
                />
            );
        }
    };

    const renderSearchPopup = () => {
        if (showSearch) {
            return (
                <LeaderboardSearchPopupContent
                    userId={props.currentUserStats ? props.currentUserStats.userId : "" + props.user.userDnsId}
                    togglePopup={toggleSearchPopup}
                    useFirstName={props.useFirstName}
                    toggleUseFirstName={props.toggleUseFirstName}
                    isModalActive={showSearch}
                    hasOwnSchool={props.hasOwnSchool}
                    setHasOwnSchool={props.setHasOwnSchool}
                />
            );
        }
    };

    const renderOwnLeaderboardItem = () => {
        if (props.showSelectSchoolButton) {
            return (
                <SelectSchoolButton onClick={toggleSearchPopup}>
                    <SelectSchoolButtonText>{selectYourSchool_t}</SelectSchoolButtonText>
                </SelectSchoolButton>
            );
        }
        if (selectedTab === "favourites" && props.leaderBoardData?.length === 0) {
            return (
                <SelectSchoolButton onClick={toggleSearchPopup}>
                    <SelectSchoolButtonText>{addFavouriteSchool_t}</SelectSchoolButtonText>
                </SelectSchoolButton>
            );
        }
        if (props.isSchoolTab && props.mySchoolStats) {
            const schoolUser: UserStats = {
                activatedCards: props.mySchoolStats.activatedCards,
                learnedCards: props.mySchoolStats.learnedCards,
                learnedCardsForTest: props.mySchoolStats.learnedCardsForTest,
                points: props.mySchoolStats.points,
                position: props.mySchoolStats.position,
                typedLetters: props.mySchoolStats.typedLetters,
                useFirstName: true,
                userAvatarId: "",
                userId: "" + props.mySchoolStats.id,
                userName: props.mySchoolStats.name,
                countryRegionName: props.mySchoolStats.countryRegionName,
                street: props.mySchoolStats.street,
                zip: props.mySchoolStats.zip,
                city: props.mySchoolStats.city,
            };
            return (
                <div key={"ownLeaderBoard_" + String(props.mySchoolStats.position)}>
                    <OwnLeaderBoardUserItem
                        user={schoolUser}
                        userAvatarLink={""}
                        isSchool={true}
                    />
                </div>
            );
        }
        if (props.currentUserStats) {
            return (
                <div key={"ownLeaderBoard_" + String(props.currentUserStats.position)}>
                    <OwnLeaderBoardUserItem
                        user={props.currentUserStats}
                        userAvatarLink={getUserAvatarUrl(props.currentUserStats.userAvatarId)}
                        isUserCheater={isUserCheater}
                        showWarning={showWarning}
                    />
                </div>
            );
        }
    };

    const participantsCount: string = props.metaData ? props.metaData.numUsers?.toLocaleString("de-DE") : "";
    const learnedCardsByAll: string = props.metaData ? props.metaData.learnedCardsByAll?.toLocaleString("de-DE") : "";

    const { ParticipiantsTooltip, LearnedCardsTooltip, GoToPositionTooltip } = useLeaderboardTooltips({
        participantsAmount: participantsCount,
        learnedCardsAmount: learnedCardsByAll,
        position,
        selectedTab: selectedTab,
        selectedTimespan,
    });

    const handleScrollList = useCallback(() => {
        if (props.allDataIsLoaded) return;
        props.onScroll();
    }, [props.onScroll, props.allDataIsLoaded]);

    //handling scroll to users position
    useEffect(() => {
        if (showCurrentPositionInList) {
            const position = props.currentUserStats?.position;
            // if the element with the current position exists in the DOM then scrollIntoView()
            if (itemsRef?.current) {
                itemsRef.current.scrollIntoView();
                setShowCurrentPositionInList(false);
            }
            // if the position is too far away and the element not in the DOM,
            // fetch the leaderboard data with the user's position
            else if (isSafeInteger(position)) {
                props.loadDataIncludingUser(position);
            }
        }
    }, [showCurrentPositionInList, itemsRef?.current, position, props.loadDataIncludingUser]);

    return (
        <Wrapper>
            <Container {...props}>
                <HeaderAngleIconLeft
                    onClick={() => {
                        switch (selectedTimespan) {
                            case LeaderboardTimespan.THIS_WEEK:
                                setWeek(-1);
                                break;
                            case LeaderboardTimespan.SINCE_2021:
                                setWeek(0);
                                break;
                            default:
                                return;
                        }
                    }}
                    week={props.week}
                >
                    <PhaseSixIcon
                        name="chevron-left"
                        highlighted={
                            selectedTimespan !== LeaderboardTimespan.LAST_WEEK || typeof props.week === "undefined"
                        }
                    />
                </HeaderAngleIconLeft>
                <HeaderContentContainer>
                    <Title>
                        {selectedTimespan === LeaderboardTimespan.LAST_WEEK
                            ? lastWeek
                            : selectedTimespan === LeaderboardTimespan.SINCE_2021
                            ? allDataLabel
                            : goingOnForDaysLabel}
                        &nbsp;
                        {selectedTimespan === LeaderboardTimespan.THIS_WEEK && (
                            <HighlightTitle>{calcDaysToGo()}</HighlightTitle>
                        )}
                    </Title>
                    <IconRow>
                        <IconCol>
                            <Tooltip
                                content={ParticipiantsTooltip}
                                position="bottom"
                            >
                                <PhaseSixIcon
                                    name={
                                        selectedTab === "allSchools" || selectedTab === "favourites"
                                            ? "school_building"
                                            : "group"
                                    }
                                    size="medium"
                                />

                                <Text>{participantsCount}</Text>
                            </Tooltip>
                            {renderRefreshLink()}
                        </IconCol>
                        <IconCol>
                            <Tooltip
                                content={LearnedCardsTooltip}
                                position="bottom"
                            >
                                <PhaseSixIcon
                                    name="leaderboard"
                                    highlighted
                                    size="big"
                                />
                                <SmallerText>{learnedCardsLabel}</SmallerText>
                            </Tooltip>
                            <HighlightText>{learnedCardsByAll}</HighlightText>
                        </IconCol>
                        <IconCol>
                            <Tooltip
                                content={GoToPositionTooltip}
                                position="bottom"
                            >
                                <PhaseSixIcon
                                    name="placement"
                                    size="medium"
                                />
                                <Text>{position}</Text>
                            </Tooltip>
                            {/* todo: fix this code so that it will work with a large amount of users/schools */}
                            <InactiveLink children={goToPositionLabel} />
                            {/*{position === "-" ? (*/}
                            {/*    <InactiveLink children={goToPositionLabel} />*/}
                            {/*) : (*/}
                            {/*    <HighlightedLink*/}
                            {/*        onClick={jumpToUserPosition}*/}
                            {/*        children={goToPositionLabel}*/}
                            {/*    />*/}
                            {/*)}*/}
                        </IconCol>
                    </IconRow>
                </HeaderContentContainer>
                <HeaderAngleIconRight
                    onClick={() => {
                        switch (selectedTimespan) {
                            case LeaderboardTimespan.LAST_WEEK:
                                setWeek(0);
                                break;
                            case LeaderboardTimespan.THIS_WEEK:
                                setWeek(1000);
                                break;
                            default:
                                return;
                        }
                    }}
                    week={props.week}
                >
                    <PhaseSixIcon
                        name="chevron-right"
                        highlighted={
                            selectedTimespan !== LeaderboardTimespan.SINCE_2021 && typeof props.week !== "undefined"
                        }
                    />
                </HeaderAngleIconRight>
            </Container>

            <TitleRow>
                <TabWrapper>{renderTabs()}</TabWrapper>
                <IconsGridWrapper>
                    <div>
                        {!isAdult && (
                            <SearchPhaseSixIcon
                                name="search"
                                onClick={toggleSearchPopup}
                            />
                        )}
                    </div>
                    <div>
                        {showInfoToggleButton && (
                            <InfoPhaseSixIcon
                                name="info"
                                onClick={toggleInfoPopup}
                            />
                        )}
                    </div>
                    {renderSearchPopup()}
                    {renderPreferencesPopup()}
                </IconsGridWrapper>
            </TitleRow>
            {!!props.leaderBoardData && (
                <List ref={ListRef}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleScrollList}
                        hasMore={!props.allDataIsLoaded}
                        useWindow={false}
                        initialLoad={false}
                        threshold={650}
                        getScrollParent={() => ListRef.current}
                    >
                        {props.leaderBoardData.length === 0 ? (
                            <PlaceholderContainer key={"leaderboardNoData"}>
                                <PlaceholderText>{noData_t}</PlaceholderText>
                            </PlaceholderContainer>
                        ) : (
                            props.leaderBoardData.map((data, index) => {
                                return (
                                    <div
                                        key={`leaderBoardList_${data.userId}_${index}`}
                                        ref={data.isCurrentUser ? itemsRef : null}
                                    >
                                        <LeaderBoardUserItem
                                            user={data}
                                            userAvatarLink={getUserAvatarUrl(data.userAvatarId)}
                                            isCurrentUser={data.isCurrentUser}
                                            selectedTab={selectedTab}
                                            userId={
                                                props.currentUserStats
                                                    ? props.currentUserStats.userId
                                                    : "" + props.user.userDnsId
                                            }
                                        />
                                    </div>
                                );
                            })
                        )}
                        {props.isLoading && (
                            <PlaceholderContainer key={"leaderboardLoading"}>
                                <PlaceholderText>{loading_t}</PlaceholderText>
                            </PlaceholderContainer>
                        )}
                    </InfiniteScroll>
                </List>
            )}
            {renderOwnLeaderboardItem()}
        </Wrapper>
    );
};

export default Leaderboard;
