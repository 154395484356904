//Libraries
import React, { ChangeEvent, useCallback, useContext, useEffect, useState } from "react";
import { useT } from "@transifex/react";
import { ThemeContext } from "styled-components";

//Components
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import {
    HeaderSection,
    Section,
    Row,
    HeadingContainer,
    Headline,
    CloseIcon,
    Information,
    ContentWrapper,
    SchoolSearchList,
    SchoolListEntry,
    SchoolDataWrapper,
    Name,
    Address,
    ActionButtons,
    ActionButtonsPlaceholder,
    HoverColorIcon,
} from "./styles";

//TYPES
import { SchoolData } from "p6m-user";
import Modal from "../../basic/modal/Modal";
import SearchTextInput from "../../basic/searchTextInput/SearchTextInput";
import { debounce } from "lodash";
import {
    addFavouriteSchool,
    addOwnSchool,
    deleteFavouriteSchool,
    getSchoolsByString,
} from "../../../networking/leaderBoard";

export interface LeaderboardSearchPopupContentProps {
    userId: string;
    togglePopup: any;
    useFirstName: boolean;
    toggleUseFirstName: any;
    isModalActive: boolean;
    hasOwnSchool: boolean;
    setHasOwnSchool: (hasOwnSchool: boolean) => void;
}

const LeaderboardSearchPopupContent: React.FC<LeaderboardSearchPopupContentProps> = (props) => {
    const t = useT();
    const [searchString, setSearchString] = useState("");
    const [searchValue, setSearchValue] = useState("");
    const [resultsList, setResultsList] = useState<Array<SchoolData>>([]);

    const title_t = t("Select your school or mark another school as favourite.", { _tags: "title" });
    const startTyping_t = t("Start typing...", { _tags: "input,placeholder,ellipsis" });
    const noSchoolFound_t = t("No school matches your search.", { _tags: "leaderboard,result" });

    const theme = useContext(ThemeContext);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
        updateSearchValue(event.target.value);
    };

    const handleClear = () => {
        updateSearchValue("");
        setSearchString("");
    };

    const updateSearchValue = useCallback(
        debounce((searchString) => {
            setSearchValue(searchString);
        }, 500),
        []
    );

    const loadSchools = useCallback(
        async (searchText: string) => {
            const {
                data: {
                    replyContent: { schools },
                },
            } = await getSchoolsByString(searchText, props.userId);
            setResultsList(schools);
        },
        [setResultsList, props.userId]
    );

    useEffect(() => {
        if (searchValue.length > 1) {
            loadSchools(searchValue);
        }
    }, [loadSchools, searchValue]);

    const toggleFavourite = (entry: SchoolData) => {
        if (entry.isFavorit) {
            deleteFavouriteSchool(entry.id, props.userId);
        } else {
            addFavouriteSchool(entry.id, props.userId);
        }
        // update locally
        let updatedList = resultsList.splice(0);
        for (let i = 0; i < updatedList.length; i++) {
            if (updatedList[i].id === entry.id) {
                updatedList[i].isFavorit = !updatedList[i].isFavorit;
                break;
            }
        }
        setResultsList(updatedList);
    };

    const selectOwnSchoolOnce = (entry: SchoolData) => {
        if (!props.hasOwnSchool) {
            addOwnSchool(entry.id, props.userId);
            props.setHasOwnSchool(true);

            // update locally
            let updatedList = resultsList.splice(0);
            const selectedSchoolIndex = updatedList.findIndex((school) => school.id === entry.id);
            updatedList[selectedSchoolIndex].isMine = true;
            setResultsList(updatedList);
        }
    };

    const renderSchoolEntry = (entry: SchoolData) => {
        return (
            <SchoolListEntry
                isMainSchool={entry.isMine}
                key={entry.id}
            >
                <SchoolDataWrapper>
                    <Name>{entry.name}</Name>
                    <Address>{[entry.street, entry.zip, entry.city].join(", ")}</Address>
                </SchoolDataWrapper>
                <ActionButtonsPlaceholder />
                <ActionButtons>
                    {entry.isMine ? (
                        <PhaseSixIcon
                            name={"star-full"}
                            highlighted
                        />
                    ) : (
                        <HoverColorIcon
                            name={entry.isFavorit ? "star-full" : "no-star"}
                            onClick={() => toggleFavourite(entry)}
                        />
                    )}
                    {!props.hasOwnSchool && (
                        <PhaseSixIcon
                            name="my-school"
                            onClick={() => selectOwnSchoolOnce(entry)}
                            highlighted={entry.isMine}
                        />
                    )}
                </ActionButtons>
            </SchoolListEntry>
        );
    };

    const renderModalContent = () => {
        return (
            <ContentWrapper id={"leaderboard-search-popup-content"}>
                <Section>
                    <SearchTextInput
                        onChange={handleChange}
                        onClear={handleClear}
                        value={searchString}
                        placeholder={startTyping_t}
                        autofocus
                        ariaLabel={startTyping_t}
                        ariaLabelledBy="LeaderboardSearchModalTitle"
                    />
                </Section>
                <Section>
                    {searchString.length === 0 ? null : resultsList.length === 0 ? (
                        <Information>{noSchoolFound_t}</Information>
                    ) : (
                        <SchoolSearchList>
                            {resultsList.map((schoolEntry) => renderSchoolEntry(schoolEntry))}
                        </SchoolSearchList>
                    )}
                </Section>
            </ContentWrapper>
        );
    };

    const renderModalHeader = () => {
        return (
            <HeaderSection>
                <Row>
                    <HeadingContainer>
                        <Headline id="LeaderboardSearchModalTitle">{title_t}</Headline>
                    </HeadingContainer>
                    <CloseIcon onClick={() => props.togglePopup(false)}>
                        <PhaseSixIcon name="close" />
                    </CloseIcon>
                </Row>
            </HeaderSection>
        );
    };

    return (
        <Modal
            active={props.isModalActive}
            title={title_t}
            headerComponent={renderModalHeader}
            modalBodyStyle={{ background: theme.colors.backgroundMain }}
            afterClose={props.togglePopup}
        >
            {renderModalContent()}
        </Modal>
    );
};

export default LeaderboardSearchPopupContent;
