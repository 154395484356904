// REACT
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

// REDUX
import { useDispatch, useSelector } from "react-redux";
import { actions, selectors } from "../../redux/learning/learningSlice";
import { actions as subjectsActions, actions as subjectActions } from "../../redux/subjects/subjectsSlice";
import { actions as goalsActions } from "../../redux/goals/goalsSlice";
import { actions as warningsActions } from "../../redux/warnings/warningsSlice";
import { selectors as userSelectors } from "../../redux/user/userSlice";

// COMPONENTS
import Component from "./Practice";

//HOOKS
import { useFirstPracticeResult } from "../../hooks/useFirstPracticeResult";
import { useShowGDPRModal } from "../../hooks/useShowGDPRModal";
import useLogPracticeEvents from "./useLogPracticeEvents";

const Practice: FunctionComponent = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { testId } = useParams<{ testId?: string }>();

    const cards = useSelector(selectors.cards);
    const areCardsLoaded = useSelector(selectors.areCardsLoaded);
    const subjectId = useSelector(selectors.subjectId);
    const activeSubject = useSelector(selectors.subject);
    const isFinished = useSelector(selectors.isFinished);
    const accelerate = useSelector(selectors.accelerate);
    const userId = useSelector(userSelectors.userId);

    const targetLanguage = useMemo(
        () => activeSubject?.subjectContent.secondaryLang,
        [activeSubject?.subjectContent.secondaryLang]
    );

    useLogPracticeEvents({ testId, isFinished, areCardsLoaded, targetLanguage });
    const hasFirstPractice = useFirstPracticeResult();
    const showGDPRModal = useShowGDPRModal();

    const [initializedAsFirstPractice, setInitializedAsFirstPractice] = useState<boolean>(false);

    const ownerId = useMemo(
        () => activeSubject?.subjectMetadata.subjectIdToOwner.ownerId || userId,
        [activeSubject, userId]
    );

    const showPracticeFinishedModal = useCallback(() => dispatch(actions.pushModal("done")), [dispatch]);

    useEffect(() => {
        dispatch(subjectActions.setActiveSubjectId(subjectId));
    }, [dispatch, subjectId]);

    useEffect(() => {
        if (!isFinished || !accelerate) return;

        // Added a delay to allow time to update the number of cards.
        setTimeout(() => {
            if (!ownerId) return;

            dispatch(
                subjectsActions.loadSubjectGroupedCardCount({
                    ownerId,
                    subjectId,
                    onLoadingFinished: showPracticeFinishedModal,
                })
            );
        }, 1000);
    }, [isFinished, accelerate, ownerId, dispatch, subjectId, showPracticeFinishedModal]);

    useEffect(() => {
        if (!isFinished) return;
        if (testId) {
            dispatch(actions.savePracticeTestResult(testId));
        }
    }, [dispatch, isFinished, testId]);

    useEffect(
        function () {
            if (!isFinished || hasFirstPractice) return;
            dispatch(goalsActions.finishFirstPractice());
        },
        [dispatch, isFinished, hasFirstPractice]
    );

    useEffect(() => {
        if (areCardsLoaded && !cards.length) {
            history.push("/home");
        } else if (cards.length) {
            dispatch(actions.fetch());
        }
    }, [dispatch, cards.length, areCardsLoaded]);

    useEffect(() => {
        dispatch(subjectActions.loadSubjectUnits(subjectId));
    }, [dispatch, subjectId]);

    useEffect(() => {
        dispatch(warningsActions.fetchWarnings());
    }, [dispatch]);

    useEffect(() => {
        if (!hasFirstPractice) {
            setInitializedAsFirstPractice(true);
        }
    }, []);

    useEffect(() => {
        return () => {
            if (!showGDPRModal && initializedAsFirstPractice) {
                if (window && (window as any)._sva) {
                    (window as any)._sva.invokeEvent("first_practice_finished_web_app");
                }
            }
        };
    }, [initializedAsFirstPractice, showGDPRModal]);

    return <>{!!cards && <Component />}</>;
};

export default Practice;
