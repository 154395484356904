import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;
export const SelectedItemInfo = styled.input`
    width: 50px;
    color: ${(props) => props.theme.colors.text};
    border: solid 1px ${(props) => props.theme.colors.backgroundMain};
    border-radius: ${(props) => props.theme.base.borderRadius};
    padding: 5px;
    margin: 0 0 0 5px;
    text-align: center;
`;

export const HiddenLabelForScreenReaders = styled.label`
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
`;
