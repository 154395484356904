// REACT
import React, { FunctionComponent, useState, useMemo, useCallback, useEffect, useContext } from "react";

//CONTEXT
import { SpecialCharacterContext } from "../../../../context/specialCharacterSelection";

//UTILS
import { getCharsByKey, getLangNames } from "../../../../helpers/charsList";

// COMPONENT
import Component from "./SpecialCharMenu";

type Props = {
    specialCharSelectRef?: React.MutableRefObject<HTMLDivElement | null>;
    lang?: string;
    onCharSelect: (selectedChar: string) => void;
    onClose: () => void;
    onLangSelect: (lang: string) => void;
};

const languageNames = getLangNames();
const defaultLangKey = "de";

export const SpecialCharMenu: FunctionComponent<Props> = (props) => {
    const { specialCharSelectRef, lang = defaultLangKey, onClose, onCharSelect, onLangSelect } = props;
    const { closeAllCharMenus } = useContext(SpecialCharacterContext);

    const [isUppercase, setUppercase] = useState(false);
    const [langSelectIsOpen, setLangSelectIsOpen] = useState(false);

    const handleLangSelectToggle = useCallback(
        (isOpen: boolean) => {
            setLangSelectIsOpen(isOpen);
            if (isOpen) {
                closeAllCharMenus({ newRef: specialCharSelectRef?.current as HTMLDivElement });
            }
        },
        [setLangSelectIsOpen, closeAllCharMenus, specialCharSelectRef]
    );

    const charObj = useMemo(() => {
        let langForChars;
        // to avoid duplicated entries
        switch (lang) {
            case "bg": // Bulgarian
            case "uk": // Ukrainian
                langForChars = "ru";
                break;
            case "bp": // Brazilian Portuguese
                langForChars = "pt";
                break;
            case "ag": // Ancient Greek
            case "el": // Greek
                langForChars = "greek";
                break;
            default:
                langForChars = lang;
        }

        return getCharsByKey(langForChars) || getCharsByKey(defaultLangKey);
    }, [lang]);

    const getChars = () => {
        const key: keyof typeof charObj = isUppercase ? "upperChars" : "chars";
        return charObj[key];
    };

    const handleSpecialCharMenuClose = useCallback(() => {
        if (langSelectIsOpen) {
            setLangSelectIsOpen(false);
        } else {
            onClose();
        }
    }, [langSelectIsOpen, onClose]);

    const toggleUpperCase = useCallback(() => {
        setUppercase((prevState) => !prevState);
    }, []);

    useEffect(() => {
        const listenerFn = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                handleSpecialCharMenuClose();
            }
        };
        window.addEventListener("keydown", listenerFn);

        return () => {
            window.removeEventListener("keydown", listenerFn);
        };
    }, [handleSpecialCharMenuClose]);

    return (
        <Component
            chars={getChars()}
            lang={charObj.name}
            langList={languageNames}
            isUppercase={isUppercase}
            langSelectIsOpen={langSelectIsOpen}
            onToggleLangSelect={handleLangSelectToggle}
            onLangSelect={onLangSelect}
            onClose={handleSpecialCharMenuClose}
            onCaseToggle={toggleUpperCase}
            onCharSelect={onCharSelect}
        />
    );
};
