import React, { FunctionComponent, memo } from "react";
import Response from "../../components/connected/response";
import { Header } from "../../components/complex/learning/Header";
import { Modals } from "../../components/complex/learning/Modals";
import { Cards } from "../../components/complex/learning/Cards";
import SpecialCharacterContextWrapper from "../../context/specialCharacterSelection";
import PracticeContextWrapper from "../../context/practice";
import { PracticeWrapper } from "./styles";

const Practice: FunctionComponent = () => {
    return (
        <>
            <SpecialCharacterContextWrapper>
                <PracticeContextWrapper>
                    <PracticeWrapper>
                        <Response />
                        <Header />
                        <Cards />
                    </PracticeWrapper>
                    <Modals />
                </PracticeContextWrapper>
            </SpecialCharacterContextWrapper>
        </>
    );
};

export default memo(Practice);
