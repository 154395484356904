// LIBRARIES
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useT } from "@transifex/react";

// TYPES
import { User, UserPreferences, IUserMetadata } from "p6m-user";

// COMPONENTS
import SideNavigationEntry from "../../complex/sideNavigationEntry/SideNavigationEntry";

// HELPERS
import { navigateElementsByClassName } from "../../../helpers/navigateElementsByClassName";

export interface SideNavigationProps {
    user: User;
    userPreferences: UserPreferences;
    userMetadata: IUserMetadata;
    isTeacher: boolean;
    isUserAnonymous: boolean;
    practiced50Cards: boolean;
    hasFirstPractice: boolean;
    isLibraryAccessDisabled: boolean;
    recommendUrl: string;
    homeName: string;
    starsCount: number;
    openContentModal: () => void;
    clickMenuButton: (args?: { showAddCardModal?: boolean }) => void;
}

interface FocusableElement extends HTMLElement {
    focus(): void;
}

const Sidebar = styled.div`
    height: 100%;
    margin: 0 0 0 10px;
    width: 100px;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin: 0;
    }
`;
const StyledContainer = styled.div`
    width: 120px;
    min-width: 120px;
    height: 100%;

    @media (max-width: ${process.env.REACT_APP_LOWER_GRID_STEP_BORDER}px) {
        width: 100%;
    }
`;

const SideNavigation: React.FC<SideNavigationProps> = (props) => {
    const t = useT();

    const t_games = t("Spiele", { _tags: "sidebar" });
    const t_library = t("Library", { _tags: "sidebar" });
    const t_add = t("Create cards", { _tags: "sidebar" });
    const t_dictionary = t("Dictionary", { _tags: "sidebar" });
    const t_reports = t("Reports", { _tags: "sidebar" });
    const t_shop = t("Shop", { _tags: "sidebar" });
    const t_libraryTooltipParentRestricted = t("Restricted by your Parents", { _tags: "sidebar,tooltip" });
    const t_libraryTooltipNoPractice = t("Have a first practice!", { _tags: "sidebar,tooltip" });
    const t_gamesTooltip = t("Practice 50 cards", { _tags: "sidebar,tooltip" });
    const [isFocused, setIsFocused] = useState(false);
    const libraryTooltip = props.hasFirstPractice ? t_libraryTooltipParentRestricted : t_libraryTooltipNoPractice;
    const [tabPressed, setTabPressed] = useState(false);

    let gamesIcon: "no-star" | "one-star" | "two-stars" | "three-stars" = "no-star";
    switch (props.starsCount) {
        case 1:
            gamesIcon = "one-star";
            break;
        case 2:
            gamesIcon = "two-stars";
            break;
        case 3:
            gamesIcon = "three-stars";
            break;
        default:
            break;
    }

    const handleClickAddCardMenuButton = () => {
        props.clickMenuButton({ showAddCardModal: true });
    };

    const handleClickGetContentMenuButton = () => {
        props.clickMenuButton();
        props.openContentModal();
    };

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (isFocused) {
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    navigateElementsByClassName("arrowNav", true);
                } else if (event.key === "ArrowUp") {
                    event.preventDefault();
                    navigateElementsByClassName("arrowNav", false);
                }
            }
        };

        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [isFocused]);

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === "Tab" && event.shiftKey) {
                setTabPressed(true);
            } else if (event.key === "Tab" && !tabPressed) {
                // this is needed to handle the first tab in a consistent way, for at least the following cases:
                // after login / after coming from "lernen" at corporate page / after reload
                event.preventDefault();
                setTabPressed(true);
                const focusableElements: NodeListOf<FocusableElement> = document.querySelectorAll(".arrowNav");
                const focusableArray: FocusableElement[] = Array.from(focusableElements);
                focusableArray[0]?.focus();
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [tabPressed]);

    return (
        <StyledContainer {...props}>
            <Sidebar
                onFocus={() => {
                    setIsFocused(true);
                }}
                onBlur={() => {
                    setIsFocused(false);
                }}
            >
                <SideNavigationEntry
                    title={props.homeName}
                    icon={"house"}
                    navTarget={"/home"}
                    key={"home"}
                    autofocus
                    tabIndex={1}
                    {...(props.isTeacher ? null : { onClick: props.clickMenuButton })}
                />
                <SideNavigationEntry
                    title={t_games}
                    icon={gamesIcon}
                    navTarget={"/games"}
                    onClick={props.clickMenuButton}
                    disabledTooltipText={t_gamesTooltip}
                    disabled={!props.practiced50Cards}
                    tabIndex={1}
                    key={"games"}
                />
                <SideNavigationEntry
                    title={t_library}
                    icon={"library"}
                    navTarget={"/manage"}
                    disabled={!props.hasFirstPractice || props.isLibraryAccessDisabled}
                    disabledTooltipText={libraryTooltip}
                    onClick={props.clickMenuButton}
                    tabIndex={1}
                    key={"library"}
                />
                <SideNavigationEntry
                    title={t_add}
                    icon={"add"}
                    navTarget={"/add"}
                    onClick={handleClickAddCardMenuButton}
                    tabIndex={1}
                    key={"add"}
                />
                <SideNavigationEntry
                    title={t_dictionary}
                    navTarget={"/dictionary"}
                    icon={"dictionary"}
                    onClick={props.clickMenuButton}
                    tabIndex={1}
                    key={"dictionary"}
                />
                <SideNavigationEntry
                    title={t_reports}
                    icon={"reports"}
                    navTarget={"/reports"}
                    onClick={props.clickMenuButton}
                    tabIndex={1}
                    key={"Reports"}
                />
                <SideNavigationEntry
                    title={t_shop}
                    icon={"cart"}
                    onClick={handleClickGetContentMenuButton}
                    key={"Shop"}
                    tabIndex={1}
                    navTarget={"#"}
                />
            </Sidebar>
        </StyledContainer>
    );
};

export default SideNavigation;
