// DEPS
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useT } from "@transifex/react";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

// COMPONENTS
import { LibrarySubjectEntry } from "../../basic/librarySubjectEntry/LibrarySubjectEntry";
import SearchTextInput from "../../basic/searchTextInput/SearchTextInput";
import { SubjectCards } from "./SubjectCards";

// REDUX
import { actions as libraryActions, subjectListSelectors } from "../../../redux/library/librarySlice";
import { actions as modalActions } from "../../../redux/modal/modalSlice";

// HELPERS
import { GlobalModalView } from "../../../helpers/Modal";

// TYPES
import { LibrarySubject } from "p6m-subjects";

// STYLES
import { Container, ContentContainer, Title, TitleContainer, EmptyResultContainer, EmptyResultText } from "./styles";

export const SubjectsPage = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const t = useT();
    const libraryTitle = t("Library", { _tags: "library,label" });
    const noSearchResultsText = t("No results were found.", {
        _tags: "noResultsText",
    });
    const [searchValue, setSearchValue] = useState("");
    const subjects = useSelector(subjectListSelectors.getSubjects);
    const totalCardsInLibrary = useSelector(subjectListSelectors.getTotalCardsInLibrary);
    const filteredCards = useSelector(subjectListSelectors.getFilteredCards);
    const filteredCardsCount = useSelector(subjectListSelectors.getFilteredCardsCount);

    const handleSearchValueChange = useMemo(
        () =>
            debounce(async (input: string) => {
                dispatch(libraryActions.searchSubjectListCards(input.trim()));
            }, 1000),
        [dispatch]
    );

    useEffect(() => {
        dispatch(libraryActions.loadSubjectList());

        return () => {
            dispatch(libraryActions.resetSubjectListState());
        };
    }, [dispatch]);

    const refreshCardList = useCallback(() => {
        dispatch(libraryActions.loadSubjectList());

        if (searchValue.trim() !== "") {
            dispatch(libraryActions.searchSubjectListCards(searchValue.trim()));
        }
    }, [dispatch, searchValue]);

    const onDeleteSubject = useCallback(
        (deleteSubjectId: string) => {
            const targetSubject = subjects.find((subject: LibrarySubject) => subject.id === deleteSubjectId);

            if (targetSubject) {
                dispatch(
                    modalActions.setData({
                        contentType: "subjects",
                        contentNames: [targetSubject.name],
                        itemIds: [
                            {
                                id: deleteSubjectId,
                                ownerId: targetSubject.ownerId,
                            },
                        ],
                        deletableCardCount: targetSubject.totalCards || 0,
                        selectedSubjectId: targetSubject.id,
                        refreshCardList: refreshCardList,
                    })
                );
                dispatch(modalActions.setModalView(GlobalModalView.DeleteContent));
            }
        },
        [dispatch, subjects, refreshCardList]
    );

    return (
        <Container>
            <TitleContainer>
                <Title id="librariesTitle">
                    {libraryTitle} ({totalCardsInLibrary.toLocaleString("de-DE")})
                </Title>
                <SearchTextInput
                    onChange={(event) => {
                        setSearchValue(event.target.value);
                        handleSearchValueChange(event.target.value);
                    }}
                    onClear={() => {
                        setSearchValue("");
                        dispatch(libraryActions.resetSubjectListSearch());
                    }}
                    value={searchValue}
                    ariaLabel={t("Search Libraries", { _tags: "dictionary,library" })}
                    ariaLabelledBy="librariesTitle"
                    fixedWidth
                />
            </TitleContainer>
            <ContentContainer>
                {filteredCardsCount === 0 && (
                    <EmptyResultContainer>
                        <EmptyResultText>{noSearchResultsText}</EmptyResultText>
                    </EmptyResultContainer>
                )}
                <>
                    {subjects.map((subject) => {
                        if (filteredCards[subject.id]?.length === 0) {
                            return null;
                        }

                        return (
                            <LibrarySubjectEntry
                                subjectId={subject.id}
                                key={subject.id}
                                onClick={() => history.push("/manage/" + subject.id + "/" + searchValue.trim())}
                                onDelete={onDeleteSubject}
                                totalCards={subject.totalCards}
                                title={subject.name}
                                primaryLang={subject.primaryLang}
                                secondaryLang={subject.secondaryLang}
                                coverImg={subject.cover}
                            >
                                {!!filteredCards[subject.id] && (
                                    <SubjectCards
                                        onSelectSubject={() =>
                                            history.push("/manage/" + subject.id + "/" + searchValue.trim())
                                        }
                                        subjectId={subject.id}
                                        cards={filteredCards[subject.id]}
                                        refreshCardList={refreshCardList}
                                    />
                                )}
                            </LibrarySubjectEntry>
                        );
                    })}
                </>
            </ContentContainer>
        </Container>
    );
};
