export interface FocusableElement extends HTMLElement {
    focus(): void;
}

export const navigateElementsByClassName = (
    classNameToLoopThrough: string,
    forward: boolean = true,
    canLoop: boolean = false,
    activeElement?: FocusableElement
): FocusableElement | undefined => {
    const focusableElements: NodeListOf<FocusableElement> = document.querySelectorAll(`.${classNameToLoopThrough}`);
    const focusableArray: FocusableElement[] = Array.from(focusableElements);
    const currentFocusIndex: number = focusableArray.indexOf(
        activeElement || (document.activeElement as FocusableElement)
    );

    let nextIndex: number;

    if (forward) {
        nextIndex = currentFocusIndex + 1;
        if (canLoop && nextIndex == focusableElements.length) nextIndex = 0;
    } else {
        nextIndex = currentFocusIndex - 1;
        if (canLoop && nextIndex == -1) nextIndex = focusableElements.length - 1;
    }

    if (nextIndex >= 0 && nextIndex < focusableElements.length) {
        focusableArray[nextIndex].focus();
        return focusableArray[nextIndex];
    }
};
