// LIBRARIES
import React, { useState, useContext, useRef, useCallback } from "react";
import { useT } from "@transifex/react";
import FocusLock from "react-focus-lock";

// COMPONENTS
import PhaseSixIcon from "../../basic/phaseSixIcon/PhaseSixIcon";
import PopUp from "../../basic/popupAnchored/Popup";
import { PopupContainer } from "../../complex/userSubjectsList/userSubjectListEntry/styles";

// CONSTANTS
import DictionaryConstants from "../../../constants/DictionaryConstants";

// HELPERS
import { keyDownHandlerForNavigatingElementsByClass } from "../../../helpers/keyDownHandlerForNavigatingElementsByClass";

// TYPES
import { AvailableLanguageCodes, DictionarySearchResult } from "p6m-dictionary";

// CONTEXT
import { LanguagesContext } from "../../../context/languages";

// STYLES
import {
    AngleIcon,
    AngleRightIcon,
    ClearInputButton,
    Container,
    FlagImage,
    FlagImageList,
    InputContainer,
    LanguageList,
    LanguageListEntry,
    LanguageSelection,
    LanguageText,
    NoResultsContainer,
    NoResultText,
    SearchResultContainer,
    SearchResultImage,
    SearchResultsContainer,
    SearchResultText,
    SelectedIconContainer,
    SelectToggle,
    StyledInput,
} from "./styles";

export interface SearchInDictionaryProps {
    searchValue: string;
    handleChange: (input: string) => any;
    clearSearch: () => any;
    searchResults: Array<DictionarySearchResult>;
    dictionarySearchLanguage: AvailableLanguageCodes;
    onLanguageSelect: (languageCode: AvailableLanguageCodes) => any;
    activePosition: number;
    increaseActiveIndex: () => void;
    decreaseActiveIndex: () => void;
    navigateToDictionary: (word: string) => void;
}

const SearchInDictionary = (props: SearchInDictionaryProps): JSX.Element => {
    const t = useT();
    const { getLanguageTranslation, getFlagSource } = useContext(LanguagesContext);

    const [isHighlighted, setIsHighlighted] = useState<boolean>(false);
    const [showLanguagesSelect, setShowLanguagesSelect] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(event.target.value);
    };

    const handleKeyPressedSearchResults = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "ArrowDown") {
            event.preventDefault();
            props.increaseActiveIndex();
        }
        if (event.key === "ArrowUp") {
            event.preventDefault();
            props.decreaseActiveIndex();
        }
        if (event.key === "Enter") {
            event.preventDefault();
            props.navigateToDictionary(props.searchResults[props.activePosition].word);
        }
    };

    const onLanguageSelect = (languageCode: AvailableLanguageCodes) => {
        props.onLanguageSelect(languageCode);
        setShowLanguagesSelect(false);
    };

    const toggleLanguageSelection = () => {
        props.clearSearch();
        setShowLanguagesSelect(!showLanguagesSelect);
    };

    const focusInput = () => {
        inputRef.current && inputRef.current.focus();
    };

    const clearInput = () => {
        props.clearSearch();
        focusInput();
    };

    const langCode = props.dictionarySearchLanguage;
    const langName = langCode === "" ? getLanguageTranslation("others") : getLanguageTranslation(langCode);

    const resultLangTranslation = t("Result language", {
        _tags: "languageImage, altText",
    });
    const dictionaryLangTranslation = t("Dictionary language", {
        _tags: "languageImage, altText",
    });
    const searchPlaceholder_t = t("Search Dictionary", { _tags: "dictionary,search" });
    const german_t = t("German", { _tags: "dictionary,list" });
    const wordNotFound_t = t("word not found", { _tags: "dictionary,list" });

    const contentAsCallback = useCallback(
        ({ closePopup }) => {
            const handleKeyPressedDictionaryLanguage = (
                event: React.KeyboardEvent<HTMLDivElement>,
                languageCode: AvailableLanguageCodes
            ) => {
                const onEnter = () => onLanguageSelect(languageCode);
                const onLeave = () => {
                    setShowLanguagesSelect(false);
                    closePopup();
                };
                keyDownHandlerForNavigatingElementsByClass(event, "languageListEntry", onEnter, onLeave);
            };

            return (
                <LanguageList>
                    <FocusLock autoFocus={true}>
                        {DictionaryConstants.targetLanguages.map((language, id) => {
                            const active = props.dictionarySearchLanguage === language.languageCode;
                            return (
                                <LanguageListEntry
                                    key={id}
                                    className="languageListEntry"
                                    data-autofocus={active}
                                    onClick={() => onLanguageSelect(language.languageCode)}
                                    onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) =>
                                        handleKeyPressedDictionaryLanguage(event, language.languageCode)
                                    }
                                    tabIndex={0}
                                >
                                    <FlagImageList
                                        alt=""
                                        src={getFlagSource("de")}
                                    />
                                    <AngleRightIcon name="chevron-right" />
                                    <FlagImageList
                                        alt=""
                                        src={getFlagSource(language.languageCode)}
                                    />
                                    <LanguageText>
                                        {`${german_t}-${getLanguageTranslation(language.languageCode || "others")}`}
                                    </LanguageText>
                                    <SelectedIconContainer>
                                        {active && <PhaseSixIcon name="exercise-ok" />}
                                    </SelectedIconContainer>
                                </LanguageListEntry>
                            );
                        })}
                    </FocusLock>
                </LanguageList>
            );
        },
        [props.dictionarySearchLanguage]
    );

    return (
        <Container onBlur={() => setIsHighlighted(false)}>
            <InputContainer isHighlighted={isHighlighted}>
                <PhaseSixIcon name="search" />
                <StyledInput
                    onChange={handleChange}
                    onKeyDown={handleKeyPressedSearchResults}
                    value={props.searchValue}
                    aria-label={searchPlaceholder_t}
                    placeholder={searchPlaceholder_t}
                    ref={inputRef}
                />
                {props.searchValue ? (
                    <ClearInputButton
                        tabIndex={0}
                        onFocus={() => setIsHighlighted(true)}
                        onKeyDown={(event: React.KeyboardEvent) => {
                            if (event.key === "Enter") clearInput();
                        }}
                    >
                        <PhaseSixIcon
                            name="exercise-failed"
                            highlighted={isHighlighted}
                            onClick={clearInput}
                        />
                    </ClearInputButton>
                ) : (
                    <LanguageSelection>
                        <PopupContainer>
                            <PopUp
                                onToggle={toggleLanguageSelection}
                                arrow={false}
                                position={["bottom", "right"]}
                                content={contentAsCallback}
                            >
                                <SelectToggle
                                    onClick={toggleLanguageSelection}
                                    tabIndex={-1}
                                >
                                    <FlagImage
                                        alt={`${dictionaryLangTranslation}: ${langName}`}
                                        src={getFlagSource(props.dictionarySearchLanguage)}
                                    />
                                    <AngleIcon>
                                        <PhaseSixIcon name={showLanguagesSelect ? "chevron-up" : "chevron-down"} />
                                    </AngleIcon>
                                </SelectToggle>
                            </PopUp>
                        </PopupContainer>
                    </LanguageSelection>
                )}
            </InputContainer>

            {props.searchValue && (
                <SearchResultsContainer>
                    {props.searchResults?.map((searchResult, index) => (
                        <SearchResultContainer
                            key={index}
                            active={index === props.activePosition}
                            onClick={() => props.navigateToDictionary(searchResult.word)}
                        >
                            <SearchResultImage
                                alt={`${resultLangTranslation}: ${getLanguageTranslation(searchResult.lang)}`}
                                src={getFlagSource(searchResult.lang)}
                            />
                            <SearchResultText>{searchResult.word}</SearchResultText>
                        </SearchResultContainer>
                    )) || (
                        <NoResultsContainer>
                            <NoResultText>{wordNotFound_t}</NoResultText>
                        </NoResultsContainer>
                    )}
                </SearchResultsContainer>
            )}
        </Container>
    );
};

export default SearchInDictionary;
