// DEPS
import React, { FunctionComponent, memo } from "react";
import { T } from "@transifex/react";

// COMPONENTS
import Modal, { Props as ModalProps } from "../../../../basic/modal/Modal";
import { Col, Row } from "../../../../basic/grid";
import { StarIcon } from "../Done/Done";
import { DefaultFocus } from "../../../../basic/defaultFocus/DefaultFocus";

// UTILS
import { getSmileImage } from "../../../../../helpers/smiles";

// STYLED COMPONENTS
import {
    InfoContainer,
    ModalHeader,
    ResultContainer,
    StarContainer,
    StyledIcon,
    InfoContainerDivider,
    ButtonRow,
    Buttons,
} from "../Done/styles";
import { OutlinedButton } from "../../../../basic/button/Button";

export type Props = {
    finished: number;
    image: string;
    backgroundColor: string;
    onGoHome: () => void;
    closeModal: () => void;
} & Partial<ModalProps>;

const Exit: FunctionComponent<Props> = (props) => {
    const { finished, image, onGoHome, closeModal, ...restProps } = props;

    return (
        <Modal
            active={true}
            headerComponent={false}
            footerComponent={false}
            modalBodyStyle={{
                backgroundColor: props.backgroundColor,
                paddingBottom: "20px",
            }}
            preventCloseOnBg
            {...restProps}
        >
            <DefaultFocus />
            <ModalHeader>
                <T
                    _str="Already give up?"
                    _tags="practice"
                />
            </ModalHeader>
            <InfoContainer>
                <Row horizontal="around">
                    <Col xs="auto">
                        <p>
                            <span>
                                {finished} <StyledIcon name="annotation" />
                            </span>
                            <br />
                            <T _str="practised in total" />
                        </p>
                    </Col>
                    <InfoContainerDivider />
                    <Col xs="auto">
                        <p>
                            <span>?</span>
                            <br />
                            <T _str="at the first attempt!" />
                        </p>
                    </Col>
                </Row>
            </InfoContainer>
            <ResultContainer>
                <StarContainer>
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                </StarContainer>
                <img
                    src={getSmileImage(image)}
                    alt={""}
                />
            </ResultContainer>
            <Buttons>
                <ButtonRow horizontal="center">
                    <Col xs="auto">
                        <OutlinedButton onClick={onGoHome}>
                            <StyledIcon name="house" />
                            &nbsp;
                            <T _str="Back to learning centre" />
                        </OutlinedButton>
                    </Col>
                    <Col xs="auto">
                        <OutlinedButton
                            highlighted
                            onClick={closeModal}
                        >
                            <StyledIcon name="learn" />
                            &nbsp;
                            <T _str="Continue Learning" />
                        </OutlinedButton>
                    </Col>
                </ButtonRow>
            </Buttons>
        </Modal>
    );
};

export default memo(Exit);
